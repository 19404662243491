import { Item } from "./Item";
import React, {
  useRef,
  useCallback,
  createContext,
  useState,
  useEffect,
} from "react";
import { Stack, Box } from "@mui/material";
import { CheckBox } from "views/molecules";
import { VariableSizeList } from "react-window";
import { AutoSizer } from "react-virtualized";
import { useWindowDimensions } from "utils/Hooks";

/**
 * Itemsのコンテキストです。
 */
export const ItemsContext = createContext({});

/**
 * 検索結果を表示するコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Items = (props) => {
  const {
    value = [],
    selectedItems = [],
    onClick = () => {},
    onCheckedChange = () => {},
    disabled = false,
    emptiesMessage = "該当の情報はありませんでした。",
  } = props;
  const [allChecked, setAllChecked] = useState(false);
  const rootRef = useRef();
  const sizeMap = useRef({});
  const setSize = useCallback((index, size) => {
    sizeMap.current = { ...sizeMap.current, [index]: size };
  }, []);
  const getSize = useCallback((index) => sizeMap.current[index] || 245, []);
  const windowSize = useWindowDimensions();

  useEffect(() => {
    setAllChecked(false);
  }, [value]);

  const handleClick = (index) => () => {
    onClick(value[index]);
  };

  const handleCheckedChangeAll = (e) => {
    const result = e.target.checked;
    setAllChecked(result);
    if (result) {
      onCheckedChange(value);
    } else {
      onCheckedChange([]);
    }
  };

  const handleCheckedChange = (index) => () => {
    const checkedChangeTarget = value[index];
    let result = [...selectedItems];
    const targetIndex = result.findIndex(
      (item) => item.id === checkedChangeTarget.id
    );
    if (targetIndex !== -1) {
      result.splice(targetIndex, 1);
    } else {
      result.push(checkedChangeTarget);
    }
    onCheckedChange(result);
  };

  if ((value?.length ?? 0) <= 0) {
    return <>{emptiesMessage}</>;
  }

  return (
    <ItemsContext.Provider value={{ setSize: setSize, windowSize: windowSize }}>
      <Stack spacing={2}>
        {disabled === false && (
          <Box>
            <CheckBox
              value={allChecked}
              label="一括"
              onChange={handleCheckedChangeAll}
            />
          </Box>
        )}
        <Box>
          <Stack spacing={2}>
            <div
              style={{
                height: "70vh",
                backgroundColor: "grey",
                borderRadius: "4px",
              }}
            >
              <AutoSizer>
                {({ height, width }) => (
                  <VariableSizeList
                    height={height}
                    itemCount={value?.length ?? 0}
                    itemSize={getSize}
                    width={width}
                    itemData={value}
                    ref={rootRef}
                  >
                    {({ data, index, style }) => (
                      <div style={style} key={data[index].id}>
                        <Item
                          index={index}
                          listRef={rootRef}
                          disabled={disabled}
                          value={data[index]}
                          onClick={handleClick(index)}
                          onCheckedChange={handleCheckedChange(index)}
                          checked={
                            selectedItems.findIndex(
                              (selectedItem) =>
                                selectedItem.id === data[index].id
                            ) !== -1
                          }
                        />
                      </div>
                    )}
                  </VariableSizeList>
                )}
              </AutoSizer>
            </div>
          </Stack>
        </Box>
      </Stack>
    </ItemsContext.Provider>
  );
};
