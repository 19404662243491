import { createContractor } from "api/graphql/mutations";
import { useQuery, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { getContractors } from "api/graphql/queries";

/**
 * 請負事業者の更新を行うコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props　その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const [updateFunction, { loading, error }] = useMutation(createContractor);
  const { id } = useParams();
  const getContractorsResult = useQuery(getContractors, {
    variables: {
      id: id,
    },
  });

  return render({
    ...props,
    id: id,
    data: getContractorsResult?.data?.getContractors,
    loading: loading || getContractorsResult.loading,
    error: error || getContractorsResult.error,
    updateFunction: updateFunction,
  });
};
