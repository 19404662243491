import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * ファイルアップローダコンポーネントです。
 * @param {string} accept 受け取るファイル種類
 * @param {string} dropzoneTitle タイトル
 * @param {string} buttonTitle タイトル
 * @param {string} label ラベル
 * @param {boolean} required 必須であるか
 * @param {boolean} error エラー
 * @fires FileUploader#onChange 変更時
 * @param {string} helperText 補助的テキスト
 * @param {boolean} disabled 無効設定
 * @param {boolean} multiple 複数あげれるようにするか
 * @param {number} maxFiles 最大ファイル数
 * @param {number} maxSize 最大ファイルサイズ
 * @param {number} minSize 最小ファイルサイズ
 */
export const FileUploader = containerPresententer(Container, Presententer);
