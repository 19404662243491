import {
  Construction,
  Description,
  Home,
  Search,
  Settings,
} from "@mui/icons-material";
import { Box, Container, IconButton, Typography } from "@mui/material";
import React from "react";

const Button = (props) => (
  <IconButton
    onClick={props.onClick}
    color="inherit"
    sx={{
      flex: "1",
      flexDirection: "column",
    }}
    {...props}
  >
    {props.icon}
    <Typography variant="caption">{props.text}</Typography>
  </IconButton>
);

/**
 * フッターを表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns JSX.Element
 */
export const Presententer = (props) => {
  return (
    <footer>
      <Box
        sx={{
          height: "56px",
          backgroundColor: "primary.main",
          position: "fixed",
          bottom: 0,
          width: "100%",
          color: "#FFF",
          zIndex: 11,
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              text={"検索"}
              onClick={props.onClickSearch}
              icon={<Search fontSize="small" />}
            />
            <Button
              text={"事前調査"}
              onClick={props.onClickConstruction}
              icon={<Construction fontSize="small" />}
              disabled={props.roleName === "user"}
            />
            <Button
              text={"ホーム"}
              onClick={props.onClickHome}
              icon={<Home fontSize="small" />}
            />
            <Button
              text={"申請"}
              onClick={props.onClickReport}
              icon={<Description fontSize="small" />}
              disabled={props.roleName === "controller" || props.roleName === "user"}
            />
            <Button
              text={"設定"}
              onClick={props.onClickSetting}
              icon={<Settings fontSize="small" />}
              disableRipple={true}
              disabled
            />
          </Box>
        </Container>
      </Box>
    </footer>
  );
};
