// ==========================================
//  フック処理群
// ==========================================

import { useState, useEffect } from "react";

/**
 * ウィンドウサイズを取得するフックです。
 * @returns {{width: number, height: number}}
 */
export const useWindowDimensions = () => {
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  };

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    const onResize = () => {
      setWindowDimensions(getWindowDimensions());
    };
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, []);

  return windowDimensions;
};
