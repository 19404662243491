/**
 * コピーライトを表示するコンテナコンポーネントです。
 * @param {object} props プロパティ
 * @param {render} props.render 引数を受けてJSX Elementを返すメソッド
 * @param {string} props.systemUrl　システムのURL
 * @param {string} props.providerName システム名
 * @returns {JSX.Element}
 */
const Container = ({
  render,
  systemUrl = "https://greener.co.jp",
  providerName = "Greener Inc. All Right Reserved.",
  ...props
}) => {
  return render({
    providerName: providerName,
    systemUrl: systemUrl,
    ...props,
  });
};

export default Container;
