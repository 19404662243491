// ==========================================
//  CSVに関する処理群
// ==========================================

import Encoding from "encoding-japanese";

/**
 * csvのBom作成します。
 * @param {string} source 文字列
 * @returns {object}
 */
const createBom = (source = "") => {
  const csv = source
    .split("\n")
    .reduce((result, line) => {
      result.push(line.split(",").join(","));
      return result;
    }, [])
    .join("\n");

  const unicodeList = [];
  for (let i = 0; i < csv.length; i += 1) {
    unicodeList.push(csv.charCodeAt(i));
  }

  const shiftJisCodeList = Encoding.convert(unicodeList, "sjis", "unicode");
  const uInt8List = new Uint8Array(shiftJisCodeList);

  return new Blob([uInt8List], { type: "text/csv" });
};

/**
 * ダウンロードします。
 * @param {string} fileName ファイル名
 * @param {string} source 文字データ
 */
export const download = (fileName = "download.csv", source = "") => {
  const csv = createBom(source);
  if (window.navigator.msSaveBlob) {
    window.navigator.msSaveBlob(csv, fileName);
  } else {
    const url = (window.URL || window.webkitURL).createObjectURL(csv);
    const download = document.createElement("a");
    document.body.appendChild(download);
    download.href = url;
    download.download = fileName;
    download.click();
    download.remove();
    (window.URL || window.webkitURL).revokeObjectURL(url);
  }
};
