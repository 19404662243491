import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Chip,
  Collapse,
  FormControlLabel,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { OmitText } from "views/atoms";
import { Labeling } from "views/atoms/Labeling";
import {
  AsbestosReportType,
  AsbestosResearchStatusType,
  ConstructionOrderStatusType,
} from "utils/Constants";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

/**
 * 事前調査タグを表示するコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
const PreResearchTag = (props) => {
  switch (props.value?.asbestosResearchStatusId) {
    case AsbestosResearchStatusType.Creating.value:
      return (
        <Chip
          label={AsbestosResearchStatusType.Creating.label}
          color="warning"
          size="small"
        />
      );
    case AsbestosResearchStatusType.SurveyCompleted.value:
      return (
        <Chip
          label={AsbestosResearchStatusType.SurveyCompleted.label}
          color="success"
          size="small"
        />
      );
    case AsbestosResearchStatusType.NoInvestigationRequired.value:
      return (
        <Chip
          label={AsbestosResearchStatusType.NoInvestigationRequired.label}
          color="default"
          size="small"
        />
      );
    default:
      return <></>;
  }
};

/**
 * 申請先設定タグを表示するコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
const AppliedTag = (props) => {
  if (!props.value?.setJurisdictionApply) {
    return <></>;
  }

  return (
    <Chip
      label={props.value?.setJurisdictionApply}
      color={
        props.value?.setJurisdictionApply === "申請先設定済"
          ? "success"
          : "error"
      }
      size="small"
    />
  );
};

/**
 * 調査結果タグを表示するコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
const SurveyResultTag = (props) => {
  switch (props.value?.asbestosReportStatusId) {
    case AsbestosReportType.Unreported.value:
      return (
        <Chip
          label={AsbestosReportType.Unreported.label}
          color="error"
          size="small"
        />
      );
    case AsbestosReportType.Reported.value:
      return (
        <Chip
          label={AsbestosReportType.Reported.label}
          color="success"
          size="small"
        />
      );
    case AsbestosReportType.ReReportingRequired.value:
      return (
        <Chip
          label={AsbestosReportType.ReReportingRequired.label}
          color="error"
          size="small"
        />
      );
    case AsbestosReportType.NoReportRequired.value:
      return (
        <Chip
          label={AsbestosReportType.NoReportRequired.label}
          color="default"
          size="small"
        />
      );
    default:
      return <></>;
  }
};

const OrderChip = (props) => {
  switch (props.value?.constructionOrderStatusId) {
    case ConstructionOrderStatusType.Negotiating.value:
      return (
        <Chip
          label={ConstructionOrderStatusType.Negotiating.label}
          color="error"
          size="small"
        />
      );
    case ConstructionOrderStatusType.Ordered.value:
      return (
        <Chip
          label={ConstructionOrderStatusType.Ordered.label}
          color="success"
          size="small"
        />
      );
    case ConstructionOrderStatusType.Lost.value:
      return (
        <Chip
          label={ConstructionOrderStatusType.Lost.label}
          color="default"
          size="small"
        />
      );
    default:
      return <></>;
  }
};

/**
 * 報告のアイテムを表示するプレゼンテーションコンポーネントです。
 */
export const Presententer = ({
  checked,
  value,
  onApply,
  onReport,
  onCheckedChange,
  open,
  root,
  onToggleExpandMore,
  onExpandedMore,
  disabled,
  stopOutput,
}) => {
  return (
    <div ref={root}>
      <Card
        sx={{
          backgroundColor: checked === true ? "#e3f2fd" : "#fff",
          marginBottom: "12px",
        }}
      >
        <CardContent>
          <Box
            display={"flex"}
            flexDirection="column"
            paddingBottom={1}
            paddingTop={1}
          >
            <Labeling title="タグ">
              <Box
                sx={{
                  overflowX: "auto",
                }}
              >
                <Stack direction="row" spacing={2}>
                  <OrderChip value={value} />
                  <PreResearchTag value={value} />
                  <AppliedTag value={value} />
                  <SurveyResultTag value={value} />
                  {value?.isOutputApplyCsvFile === true && (
                    <Chip label="CSV出力済み" color="default" size="small" />
                  )}
                </Stack>
              </Box>
            </Labeling>
            <Labeling title="工事名称">
              <Typography>{value?.name ?? ""}</Typography>
            </Labeling>
            <Labeling title="工事の実施期間">
              <Box
                display="flex"
                flexDirection={"row"}
                justifyContent="space-between"
              >
                <Typography>
                  <span>{`${value?.constructionStartDate ?? ""}`}</span>～
                  <span>{`${value?.constructionEndDate ?? ""}`}</span>
                </Typography>
                <ExpandMore
                  expand={open}
                  onClick={onToggleExpandMore}
                  aria-expanded={open}
                  aria-label="show more"
                  size="small"
                >
                  <ExpandMoreIcon fontSize="inherit" />
                </ExpandMore>
              </Box>
            </Labeling>
            <Collapse
              in={open}
              timeout="auto"
              unmountOnExit
              onExited={onExpandedMore}
              onEntered={onExpandedMore}
            >
              <Labeling title="工事現場の住所">
                <OmitText
                  style={{
                    fontSize: "16px",
                  }}
                >
                  {`〒${value?.workPostNo ?? ""} ${value?.workAddress ?? ""}`}
                </OmitText>
              </Labeling>
              <Labeling title="申請日">
                <Typography>{value?.asbestosReportDate ?? ""}</Typography>
              </Labeling>
              <Labeling title="申請番号">
                <Typography>{value?.surveyApplicationNo ?? ""}</Typography>
              </Labeling>
              <Labeling title="申請区分">
                <span>
                  {value?.sanitationLawRequestExist === "1" &&
                    "労働安全衛生法　"}
                  {value?.preventionLawRequestExist === "1" && "大気汚染防止法"}
                </span>
              </Labeling>
            </Collapse>
          </Box>
        </CardContent>
        <CardActions>
          <FormControlLabel
            checked={checked}
            onChange={onCheckedChange}
            control={<Checkbox />}
            label="申請対象として選択"
            disabled={disabled}
          />
          <Button size="large" onClick={onApply} disabled={disabled}>
            {"申請"}
          </Button>
          <Button size="large" onClick={onReport} disabled={disabled}>
            {"報告"}
          </Button>
        </CardActions>
      </Card>
    </div>
  );
};
