import { useMemo, useState, useEffect } from "react";
import { download } from "utils/csv";
import { DateTime } from "luxon";
import { getApplyCsvData } from "api/graphql/queries";
import { getApplyCsvData2 } from "api/graphql/queries";
import { useLazyQuery } from "@apollo/client";
import { AsbestosReportType } from "utils/Constants";

/**
 * 報告一覧を表示するコンテナコンポーネントです。
 */
export const Container = ({
  render,
  selectedValue = [],
  onClickApplyItem = () => {},
  onClickReportItem = () => {},
  onCheckedChangeItem = () => {},
  onImport = () => {},
  ...props
}) => {
  const {
    items,
    totalCount,
    pageCount,
    loading,
    error,
    hasNextPage,
    hasPreviousPage,
    onNext,
    onBack,
    onSearch,
    filter,
    page,
    pageSize,
    reload,
  } = props;

  const [open, setOpen] = useState(false);
  const [outputConfirm, setOutputConfirm] = useState(false);
  const [stopOutputConfirm, setStopOutputConfirm] = useState(false);
  const [allSelect, setAllSelect] = useState(false);
  const [search, setSearch] = useState(filter);
  const [getApplyCsvDataFunctions, getApplyCsvDataOptions] =
    useLazyQuery(getApplyCsvData);
  useEffect(() => {
    setSearch(filter);
  }, [filter]);
  const [getApplyCsvDataFunctions2, getApplyCsvDataOptions2] =
    useLazyQuery(getApplyCsvData2);
  useEffect(() => {
    setSearch(filter);
  }, [filter]);

  const updateStatus = (ids) => {
    return getApplyCsvDataFunctions({
      variables: {
        id: ids,
      },
    });
  };

  const updateStatus2 = (ids) => {
    return getApplyCsvDataFunctions2({
      variables: {
        id: ids,
      },
    });
  };

  const exportable = useMemo(() => {
    const count = selectedValue?.length ?? 0;
    return !(count > 0 && count < 101);
  }, [selectedValue]);

  const outputed = useMemo(() => {
    return (
      selectedValue?.findIndex(
        (item) => item?.isOutputApplyCsvFile ?? false
      ) !== -1
    );
  }, [selectedValue]);

  const stopOutput = useMemo(() => {
    return (
      selectedValue.findIndex(
        (item) =>
          item.setJurisdictionApply === "申請先未設定" ||
          [
            AsbestosReportType.Reported.value,
            AsbestosReportType.ReReportingRequired.value,
            AsbestosReportType.NoReportRequired.value,
          ].includes(item.asbestosReportStatusId)
      ) !== -1
    );
  }, [selectedValue]);

  const handleExport = () => {
    if (stopOutput) {
      setStopOutputConfirm(true);
      return;
    }
    if (outputed) {
      setOutputConfirm(true);
    } else {
      exportCSV();
    }
  };

  const handleExport2 = () => {
    exportCSV2();
  };

  const exportCSV = () => {
    updateStatus(selectedValue.map((item) => item.id)).then((res) => {
      download(
        `${DateTime.now().toFormat("yyyyMMddhhmm")}申請.csv`,
        res.data.getApplyCsvData.csvData
      );
      onCheckedChangeItem([]);
      setAllSelect(false);
    });
  };

  const exportCSV2 = () => {
    updateStatus2(selectedValue.map((item) => item.id)).then((res) => {
      download(
        `${DateTime.now().toFormat("yyyyMMddhhmm")}申請.csv`,
        res.data.getApplyCsvData2.csvData
      );
      onCheckedChangeItem([]);
      setAllSelect(false);
    });
  };

  const handlePositiveOutputCSV = () => {
    updateStatus(selectedValue.map((item) => item.id)).then((res) => {
      download(
        `${DateTime.now().toFormat("yyyyMMddhhmm")}申請.csv`,
        res.data.getApplyCsvData.csvData
      );

      onCheckedChangeItem([]);

      setOutputConfirm(false);

      setAllSelect(false);
    });
  };

  const handleCheckedChange = (data) => {
    const result = [...selectedValue];
    if (data.checked) {
      result.push(data.value);
    } else {
      const index = result.findIndex((item) => item.id === data.value.id);
      result.splice(index, 1);
    }

    onCheckedChangeItem(result);
  };

  const handleChangeSearch = (obj) => {
    setSearch(obj);
  };

  const handleClickSearch = () => {
    onSearch(search);
    setOpen(false);
  };

  const handleChangeAllSelect = (e) => {
    setAllSelect(e.target.checked);
    if (e.target.checked) {
      onCheckedChangeItem(items);
    } else {
      onCheckedChangeItem([]);
    }
  };

  return render({
    ...props,
    allSelect: allSelect,
    onChangeAllSelect: handleChangeAllSelect,
    open: open,
    outputConfirm: outputConfirm,
    stopOutputConfirm: stopOutputConfirm,
    exportable: exportable,
    onExport: handleExport,
    onExport2: handleExport2,
    onPositiveOutputCSV: handlePositiveOutputCSV,
    onImport: onImport,
    onSearch: () => setOpen(true),
    onClose: () => setOpen(false),
    onCloseOutputConfirm: () => setOutputConfirm(false),
    onCloseStopOutput: () => setStopOutputConfirm(false),
    value: items,
    selectedValue: selectedValue,
    loading: loading,
    onClickApplyItem: onClickApplyItem,
    onClickReportItem: onClickReportItem,
    onCheckedChangeItem: handleCheckedChange,
    pageCount: pageCount,
    page: page,
    onNext: onNext,
    onBack: onBack,
    search: search,
    onChangeSearch: handleChangeSearch,
    onClickSearch: handleClickSearch,
    disabled: getApplyCsvDataOptions.loading,
    totalCount: totalCount,
    hasNextPage: hasNextPage,
    hasPreviousPage: hasPreviousPage,
  });
};
