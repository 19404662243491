import { FormHelperText, Typography } from "@mui/material";
import React from "react";
import { InformationItem } from "views/atoms";
import { Group } from "views/molecules";

const getChecked = (value) => {
  if (typeof value === "string") {
    return value === "1";
  }

  if (typeof value === "boolean") {
    return value;
  }

  return false;
};

/**
 * 申請情報を表示するプレゼンテーションコンポーネントです。
 */
export const Presententer = (props) => {
  if (!props?.value) {
    return <FormHelperText>{"該当の情報はありません。"}</FormHelperText>;
  }

  return (
    <>
      <Group title={"申請先"}>
        {getChecked(props.value?.sanitationLawRequestExist) === true && (
          <Group
            title="【労働安全衛生法（石綿障害予防規則）申請先】"
            elevation={1}
            bold={false}
          >
            <InformationItem
              label={"工事現場の管轄労働局"}
              value={props.value?.laborBureau?.name}
            />
            <InformationItem
              label={"工事現場の管轄労働基準監督署"}
              value={props.value?.laborInspectionOffice?.name}
            />
          </Group>
        )}
        {getChecked(props.value?.preventionLawRequestExist) === true && (
          <Group title="【大気汚染防止法申請先】" elevation={1} bold={false}>
            <InformationItem
              label={"都道府県"}
              value={props.value?.requestPrefecture?.name}
            />
            <InformationItem
              label={"申請先自治体"}
              value={props.value?.requestMunicipality?.name}
            />
            <InformationItem
              label={"担当部署"}
              value={props.value?.requestMunicipalityDept?.name}
            />
          </Group>
        )}
        {getChecked(props.value?.sanitationLawReuestExist) === false &&
          getChecked(props.value?.preventionLawRequestExist) === false && (
            <Typography>{"なし"}</Typography>
          )}
      </Group>
    </>
  );
};
