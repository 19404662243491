import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router";
import { Items } from "./Items";
import { Pager } from "./Pager";
import { SearchForm } from "./SearchForm";

export const ConstructionList = (props) => {
  const {
    filter,
    filterQueryString,
    totalCount,
    pageSize,
    page,
    startCursor,
    endCursor,
    hasNextPage,
    hasPreviousPage,
    loading,
    error,
    handleNext,
    handlePrevious,
    handleSearch,
    items,
  } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const handleRegister = () => {
    navigate(`${location?.pathname}/create`, {
      state: {
        preSearchWord: filterQueryString,
        page: page,
        startCursor: startCursor,
        endCursor: endCursor,
      },
    });
  };

  const handleClickItem = (data) => {
    navigate(`${location?.pathname}/${data.id}/show`, {
      state: {
        preSearchWord: filterQueryString,
        page: page,
        startCursor: startCursor,
        endCursor: endCursor,
      },
    });
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Stack direction="row" justifyContent={"space-between"}>
        <Stack>
          <Button
            color="primary"
            variant="contained"
            onClick={() => setOpen(true)}
            disabled={props.loading}
          >
            {"検索"}
          </Button>
          <Typography
            sx={{
              fontSize: "12px",
            }}
          >
            検索結果: {totalCount ?? 0}件
          </Typography>
        </Stack>
        {(filter?.searchMode ?? false) === false && (
          <Box>
            <Button
              color="success"
              variant="contained"
              onClick={handleRegister}
              disabled={props.loading}
            >
              {"新規登録"}
            </Button>
          </Box>
        )}
      </Stack>
      <Box
        sx={{
          borderRadius: "12px",
          backgroundColor: "#efefef",
          padding: "10px",
          width: "100%",
          height: "65vh",
          overflow: "auto",
        }}
      >
        <Items
          value={items}
          loading={loading}
          error={error}
          onClickItem={handleClickItem}
        />
      </Box>
      <Pager
        pageCount={Math.ceil(totalCount / pageSize)}
        page={page}
        hasNextPage={hasNextPage}
        hasPreviousPage={hasPreviousPage}
        onNext={handleNext}
        onPrevious={handlePrevious}
        disabled={props.loading}
      />
      <SearchForm
        value={filter}
        onSubmit={handleSearch}
        open={open}
        onClose={() => setOpen(false)}
      />
    </Box>
  );
};
