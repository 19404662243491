import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import { ConstructionForm } from "views/organisms";
import { Review } from "views/organisms/Constructions/ConstructionForm/Child";

const Form = React.forwardRef((props, ref) => (
  <ConstructionForm {...props} _ref={ref} />
));

/**
 * 工事登録ページのプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <Box>
    <Stack direction={"row"} justifyContent="space-between">
      <Typography sx={{ mb: 2, textDecoration: "underline", fontWeight: 600 }}>
        工事情報登録
      </Typography>
      {props.toggle && (
        <Button
          color="inherit"
          variant="outlined"
          size="small"
          onClick={props.onBackList}
        >
          一覧に戻る
        </Button>
      )}
      {!props.toggle && (
        <Button
          color="inherit"
          variant="outlined"
          size="small"
          onClick={props.onBack}
        >
          戻る
        </Button>
      )}
    </Stack>
    {props.toggle && (
      <>
        <Form
          value={props.value}
          ref={props.formRef}
          onSubmit={props.onSubmit}
        />
        <Button
          color="success"
          variant="contained"
          size="small"
          onClick={props.onConfirm}
          fullWidth
        >
          確認
        </Button>
      </>
    )}
    {!props.toggle && (
      <>
        <Review value={props.value} />
        <Button
          color="success"
          variant="contained"
          size="small"
          onClick={props.onRegist}
          disabled={props.loading}
          fullWidth
        >
          登録
        </Button>
      </>
    )}
  </Box>
);
