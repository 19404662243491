import { useEffect } from "react";
import { useLocation } from "react-router";
import { routes } from "routes";

/**
 * 認証後のページテンプレートを表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {JSX.Element} children 子コンポーネント
 * @returns {JSX.Element}
 */
export function Container({ render, children, ...props }) {
  const { pathname } = useLocation();
  const path = routes.find((v) => v.path === pathname)?.title;

  useEffect(() => {
    const main = window.document.querySelector("#back-to-top-anchor + main");

    if (main) {
      main.scrollTop = 0;
    }
  }, [pathname]);

  return render({
    children: children,
    path: path,
  });
}
