import yup from "utils/yup";

export const basicSchema = yup.object().shape({
  sanitationLawRequestExist: yup
    .boolean()
    .nullable()
    .default(null)
    .test("required", "入力してください。", function (value) {
      return value === true || this.parent?.preventionLawRequestExist === true;
    }),
  laborBureau: yup.object().nullable().default(null),
  laborInspectionOffice: yup.object().nullable().default(null),
  preventionLawRequestExist: yup.boolean().nullable().default(null),
  requestPrefecture: yup.object().nullable().default(null),
  requestMunicipality: yup.object().nullable().default(null),
  requestMunicipalityDept: yup.object().nullable().default(null),
});
