/**
 * ページャーを表示するコンテナコンポーネントです。
 */
export const Container = ({
  render,
  pageCount,
  page,
  onNext = () => {},
  onBack = () => {},
  disabled = false,
  hasNextPage = false,
  hasPreviousPage = false,
  ...props
}) => {
  return render({
    ...props,
    pageCount: pageCount,
    page: page,
    onNext: onNext,
    onBack: onBack,
    disabled: disabled,
    hasNextPage: hasNextPage,
    hasPreviousPage: hasPreviousPage,
  });
};
