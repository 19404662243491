import {
  FormControl,
  FormHelperText,
  FormLabel,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { InputField } from "views/molecules";

/**
 * 郵便番号を入力するプレゼンテーションコンポーネントです。
 */
export const Presententer = (props) => {
  return (
    <FormControl>
      <FormLabel
        sx={{
          fontSize: "12px",
        }}
        required={props.required}
        id="postalCode-label"
      >
        {props?.label ?? ""}
      </FormLabel>
      <Stack direction={"row"} spacing={2}>
        <InputField
          name="front"
          value={props.value?.front ?? ""}
          onChange={(e) => props.onChange("front")(e.target.value)}
          inputProps={{ maxLength: 3 }}
          disabled={props.disabled}
        />
        <Typography>{"-"}</Typography>
        <InputField
          name="back"
          value={props.value?.back ?? ""}
          onChange={(e) => props.onChange("back")(e.target.value)}
          inputProps={{ maxLength: 4 }}
          disabled={props.disabled}
        />
      </Stack>
      <FormHelperText error={props.error}>{props.helperText}</FormHelperText>
    </FormControl>
  );
};
