import { Query } from "@apollo/client/react/components";
import { InputAdornment, Stack, Typography } from "@mui/material";
import {
  listBuildingFireproof,
  listBuildingStructure,
} from "api/graphql/queries";
import React from "react";
import { Controller } from "react-hook-form";
import { OtherWorks } from "utils/Constants";
import { AddressForm, SkeletonField } from "views/molecules";
import { ControlCheckbox } from "../ControlCheckbox";
import { ControlDatePicker } from "../ControlDatePicker";
import { ControlRadioGroup } from "../ControlRadioGroup";
import { ControlSelectItem } from "../ControlSelectItem";
import { ControlTextField } from "../ControlTextField";
import { Panel } from "../Panel";

const convertLaborInsuranceNoExist = (value) => {
  if (typeof value?.laborInsuranceNoExist === "boolean") {
    return value?.laborInsuranceNoExist;
  }

  if (typeof value?.laborInsuranceNoExist === "string") {
    return value?.laborInsuranceNoExist === "0" ? false : true;
  }

  return value?.laborInsuranceNoExist;
};

/**
 * ARS-フォームの表示を行うプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <Stack mt={1} mb={2} spacing={2}>
    <Panel title={"状況"}>
      <ControlRadioGroup
        name="constructionOrderStatus"
        options={[
          {
            value: "1",
            label: "交渉中",
          },
          {
            value: "2",
            label: "受注済",
          },
          {
            value: "3",
            label: "失注",
          },
        ]}
        row={true}
      />
    </Panel>
    <Panel title={"元方（元請）事業者情報"}>
      <ControlTextField label="事業者の名称" name="primeContractorName" />
      <ControlTextField
        label="代表者氏名"
        name="primeContractorRepresentativeName"
      />
      <Controller
        control={props.control}
        name={"primeContractor"}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <AddressForm
              name={""}
              value={value}
              onChange={onChange}
              error={error}
            />
          );
        }}
      />
      <ControlTextField
        label="電話番号（ハイフンなし）"
        name="primeContractorPhone"
        inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
      />
      <ControlTextField
        label="メールアドレス"
        name="primeContractorMailAddress"
      />
    </Panel>
    <Panel title={"工事発注者情報"}>
      <ControlTextField label="事業者の名称" name="ordererName" />
      <ControlTextField label="代表者氏名" name="ordererRepresentativeName" />
      <Controller
        control={props.control}
        name={"orderer"}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <AddressForm
              name={""}
              value={value}
              onChange={onChange}
              error={error}
            />
          );
        }}
      />
    </Panel>
    <Panel title={"工事現場情報"}>
      <div>
        <div
          style={{
            color: "darkgrey",
            fontSize: "11px",
            paddingLeft: "10px",
            paddingBottom: "10px",
          }}
        >
          {"労働保険番号"}
        </div>
        {!convertLaborInsuranceNoExist(props.value) && (
          <Stack direction={"row"} spacing={2}>
            <ControlTextField
              label="府県"
              name="laborInsuranceNoPrefecture"
              inputProps={{ maxLength: 2 }}
            />
            <ControlTextField
              label="所掌"
              name="laborInsuranceNoAdministration"
              inputProps={{ maxLength: 1 }}
            />
            <ControlTextField
              label="管轄"
              name="laborInsuranceNoJurisdiction"
              inputProps={{ maxLength: 2 }}
            />
            <ControlTextField
              label="基幹番号"
              name="laborInsuranceNoCoreNo"
              inputProps={{ maxLength: 6 }}
            />
            <ControlTextField
              label="枝番号"
              name="laborInsuranceNoBranchNo"
              inputProps={{ maxLength: 3 }}
            />
          </Stack>
        )}
        <ControlCheckbox
          name="laborInsuranceNoExist"
          label="なし（又は不明）"
        />
      </div>
      <Controller
        control={props.control}
        name={"work"}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <AddressForm
              name={""}
              value={value}
              onChange={onChange}
              error={error}
            />
          );
        }}
      />
      <ControlTextField label="工事の名称" name="name" />
      <ControlTextField
        label="工事の概要"
        name="summary"
        multiline={true}
        rows={4}
      />
    </Panel>
    <Panel title={"建築物の概要"}>
      <Stack direction={"row"} spacing={2}>
        <ControlDatePicker
          label="建築物又は工作物の新築工事の着工日"
          name="buildDate"
        />
        <ControlCheckbox name="buildDateExist" label="不明" />
      </Stack>
      <Query query={listBuildingFireproof}>
        {({ data, error, loading }) => {
          if (error) return error;
          if (loading) {
            return <SkeletonField number={1} />;
          } else {
            return (
              <ControlSelectItem
                label="耐火"
                name="buildingFireproof"
                options={data.listBuildingFireproof}
                optionValueField={"id"}
                optionNameField={"name"}
              />
            );
          }
        }}
      </Query>
      <Query query={listBuildingStructure}>
        {({ data, error, loading }) => {
          if (error) return error;
          if (loading) {
            return <SkeletonField number={1} />;
          } else {
            return (
              <ControlSelectItem
                label="構造"
                name="buildingStructure"
                options={data.listBuildingStructure}
                optionValueField={"id"}
                optionNameField={"name"}
              />
            );
          }
        }}
      </Query>
      <ControlTextField
        label="延べ面積"
        name="buildingFloorSpace"
        InputProps={{
          endAdornment: <InputAdornment position="start">㎡</InputAdornment>,
        }}
        inputProps={{ style: { textAlign: "right" } }}
      />
      <ControlTextField
        label="階数"
        name="groundFloors"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">地上</InputAdornment>
          ),
          endAdornment: <InputAdornment position="start">階建</InputAdornment>,
        }}
        inputProps={{ style: { textAlign: "right" } }}
      />
      <ControlTextField
        label="階数（地下階）"
        name="basementFloors"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">地下</InputAdornment>
          ),
          endAdornment: <InputAdornment position="start">階建</InputAdornment>,
        }}
        inputProps={{ style: { textAlign: "right" } }}
      />
      <Stack>
        <Typography
          sx={{
            fontSize: "12px",
          }}
        >
          <span
            style={{
              color: "grey",
            }}
          >
            {"その他工作物"}
          </span>
        </Typography>
        {OtherWorks.map((work, index) => (
          <ControlCheckbox key={index} name={work.id} label={work.label} />
        ))}
      </Stack>
      <ControlTextField
        label="解体の作業の対象となる床面積の合計"
        name="constructionFloorSpace"
        InputProps={{
          endAdornment: <InputAdornment position="start">㎡</InputAdornment>,
        }}
        inputProps={{ style: { textAlign: "right" } }}
      />
      <Stack direction={"row"} spacing={1} alignItems="center">
        <ControlDatePicker label="工事開始日" name="constructionStartDate" />
        <Typography>{" ～ "}</Typography>
        <ControlDatePicker label="工事終了日" name="constructionEndDate" />
      </Stack>
      <Stack direction={"row"} spacing={1}>
        <ControlTextField
          label="請負金額"
          name="amountBillion"
          InputProps={{
            endAdornment: <InputAdornment position="start">億</InputAdornment>,
          }}
          inputProps={{ style: { textAlign: "right" } }}
        />
        <ControlTextField
          label="請負金額"
          name="amountTenThousand"
          fullWidth={false}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">万円</InputAdornment>
            ),
          }}
          inputProps={{ style: { textAlign: "right" } }}
        />
      </Stack>
      <ControlTextField
        label={"自由記載欄"}
        name="freeEntryField"
        rows={4}
        multiline
      />
    </Panel>
    {props.uniqueInformationLabels?.labelName1 && (
      <ControlTextField
        label={props.uniqueInformationLabels?.labelName1}
        name="uniqueInformation1"
      />
    )}
    {props.uniqueInformationLabels?.labelName2 && (
      <ControlTextField
        label={props.uniqueInformationLabels?.labelName2}
        name="uniqueInformation2"
      />
    )}
    {props.uniqueInformationLabels?.labelName3 && (
      <ControlTextField
        label={props.uniqueInformationLabels?.labelName3}
        name="uniqueInformation3"
      />
    )}
    {props.uniqueInformationLabels?.labelName4 && (
      <ControlTextField
        label={props.uniqueInformationLabels?.labelName4}
        name="uniqueInformation4"
      />
    )}
    {props.uniqueInformationLabels?.labelName5 && (
      <ControlTextField
        label={props.uniqueInformationLabels?.labelName5}
        name="uniqueInformation5"
      />
    )}
  </Stack>
);
