import { Box } from "@mui/material";

/**
 * チップを表示するコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Chip = (props) => {
  const { sx, value } = props;

  if (!value) {
    return <></>;
  }

  return (
    <Box
      component={"span"}
      sx={(theme) => ({
        padding: "4px 10px",
        borderRadius: "20px",
        fontSize: "11px",
        color: "#fff",
        marginRight: theme.spacing(1),
        minWidth: "43px",
        textAlign: "center",
        ...sx,
      })}
    >
      {value}
    </Box>
  );
};
