import { Box, Skeleton, Stack } from "@mui/material";
import React from "react";

/**
 * loadingの時に表示するコンポーネントです。
 * @param {array} value
 * @param {number} height
 */
export const Presententer = (props) => {
  const { height, value } = props;

  return (
    <Stack spacing={2}>
      {value.map((_, i) => (
        <Box component="div" style={{ width: "100%" }} key={i}>
          <Skeleton height={height} variant="rect" width={"100%"} />
        </Box>
      ))}
    </Stack>
  );
};
