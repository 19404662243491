import { Box } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { DatePicker } from "views/atoms";

/**
 * ARS-フォームの表示を行うプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <Box>
      <Controller
        control={props.control}
        name={props.name}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <DatePicker
              label={props.label}
              value={value}
              onChange={onChange}
              inputFormat={props.format}
              error={!!error}
              helperText={error?.message}
            />
          );
        }}
      />
    </Box>
  );
};
