import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import IconButton from "@mui/material/IconButton";
import React from "react";

/**
 * その他ファイルを表示するコンポーネントです。
 * @param {string} type ファイル種類
 * @param {string} name 名前
 * @param {string} base64 base64文字列
 * @return JSX.Element
 */
export const OtherFile = ({ type, name, base64 }) => {
  const handleClick = (e) => {
    e.preventDefault();

    const blob = new Blob(["Hello, blob!"], {
      type: "application/octet-stream",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.download = name;
    a.href = base64;
    a.click();
    a.remove();
    URL.revokeObjectURL(url);
  };

  return (
    <IconButton color="primary" aria-label="other-file" onClick={handleClick}>
      <InsertDriveFileIcon />
    </IconButton>
  );
};
