import { Box } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { InputField } from "views/molecules";

/**
 * ARS-フォームの表示を行うプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <Box>
      <Controller
        control={props.control}
        name={props.name}
        defaultValue={props.defaultValue}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <InputField
              label={props.label}
              value={value ?? ""}
              onChange={onChange}
              error={Boolean(error)}
              helperText={error?.message}
              {...props}
            />
          );
        }}
      />
    </Box>
  );
};
