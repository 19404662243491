import { createConstruction } from "api/graphql/mutations";
import { useMutation } from "@apollo/client";

/**
 * 工事情報を登録するコンテナコンポーネントです
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props　その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const [createFunction, { loading, error }] = useMutation(createConstruction);

  return render({
    ...props,
    loading: loading,
    error: error,
    createFunction: createFunction,
  });
};
