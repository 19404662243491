import { Query } from "@apollo/client/react/components";
import { Box, Grid, LinearProgress } from "@mui/material";
import { listSearchResults } from "api/graphql/queries";
import React from "react";
import { SearchResult } from "views/organisms";
import { NotFound } from "views/pages";

/**
 * ARS-検索の結果の表示を行うプレゼンテーションコンポーネント。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <Box mt={2} mb={3}>
    <Grid container>
      <Query query={listSearchResults} variables={props.variables}>
        {({ data, error, loading }) => {
          if (error) return <NotFound {...error} />;
          if (loading) {
            return (
              <Grid item xs={12}>
                <LinearProgress color="secondary" />
              </Grid>
            );
          } else {
            return <SearchResult values={data.listSearchResults} />;
          }
        }}
      </Query>
    </Grid>
  </Box>
);
