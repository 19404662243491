import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 申請フォームを表示するコンポーネントです。
 * @param {string} id Id
 * @param {boolean} open 開閉
 * @fires Item#onClose 閉じるとき
 * @fires Item#onNotificationChange 変更通知
 * @returns JSX.Element
 */
export const ApplyForm = containerPresententer(Container, Presententer);
