import { Box, Skeleton, Typography } from "@mui/material";
import React from "react";
import { AutoSizer } from "react-virtualized";
import { Item } from "../Item";
import { VariableSizeList } from "react-window";
import { ItemsContext } from "./Container";

/**
 * 報告のアイテムを複数表示するプレゼンテーションコンポーネントです。
 */
export const Presententer = (props) => {
  return (
    <ItemsContext.Provider
      value={{ setSize: props.setSize, windowSize: props.windowSize }}
    >
      <Box
        sx={{
          borderRadius: "12px",
          backgroundColor: "#efefef",
          padding: "10px",
          width: "100%",
          height: "60vh",
          overflow: "auto",
        }}
      >
        {props.loading && <Skeleton animation={"wave"} height="100px" />}
        {!props.loading && props?.value?.length <= 0 && (
          <Typography>{"該当の情報はありません。"}</Typography>
        )}
        {!props.loading && (props?.value?.length ?? 0) > 0 && (
          <AutoSizer>
            {({ height, width }) => (
              <VariableSizeList
                width={width}
                height={height}
                itemSize={props.getSize}
                itemCount={props.value?.length ?? 0}
                ref={props.rootRef}
              >
                {({ index, style }) => {
                  return (
                    <div style={style}>
                      <Item
                        checked={
                          props.selectedValue.findIndex(
                            (item) => item?.id === props.value[index]?.id
                          ) !== -1
                        }
                        value={props.value[index]}
                        key={index}
                        index={index}
                        onApply={props.onApply(index)}
                        onReport={props.onReport(index)}
                        onCheckedChange={props.onCheckedChange(index)}
                        onExpandMore={props.onExpandMore(index)}
                        disabled={props.disabled}
                        listRef={props.rootRef}
                      />
                    </div>
                  );
                }}
              </VariableSizeList>
            )}
          </AutoSizer>
        )}
      </Box>
    </ItemsContext.Provider>
  );
};
