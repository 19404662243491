import { Query } from "@apollo/client/react/components";
import Alert from "@mui/material/Alert";
import { listMaterials } from "api/graphql/queries";
import React from "react";
import { ComboBox, SkeletonField } from "views/molecules";

/**
 * 建材名（一般名）を選択するコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const MaterialSelector = (props) => {
  const { value, onChange } = props;

  return (
    <Query query={listMaterials}>
      {({ data, error, loading }) => {
        if (loading) return <SkeletonField number={1} />;
        if (error) return <Alert severity="error">{error}</Alert>;
        if (!loading)
          return (
            <ComboBox
              name="materialName"
              value={value}
              onChange={onChange}
              label="建材名(一般名)"
              options={data.listMaterials}
            />
          );
      }}
    </Query>
  );
};
