import { useLazyQuery } from "@apollo/client";
import { searchReports } from "api/graphql/queries";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";

const defaultValue = {
  construction: {
    name: null,
    implementation: {
      start: null,
      end: null,
    },
    postal: {
      front: null,
      back: null,
    },
    address: {
      prefecture: null,
      city: null,
      town: null,
      other: null,
    },
    uniqueInformation: null,
  },
  apply: {
    completed : {
      start: null,
      end: null,
    },
    number: null,
    classification: ["1", "2"],
  },
  isNotOutputedCSV: false,
  isOutputedCSV: false,
  preResearchStatus: ["2"],
  applied: true,
  unapply: true,
  reportStatus: ["1", "3"],
};

export const useTable = () => {
  const pageSize = 100;
  const location = useLocation();
  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState(null);
  const [functions, options] = useLazyQuery(searchReports);

  useEffect(() => {
    if (!location?.search) {
      const result = {
        ...defaultValue,
      };

      functions({
        variables: {
          first: pageSize,
          last: undefined,
          before: undefined,
          after: undefined,
          filter: {
            constructionName: result?.construction?.name ?? null,
            constructionStartDate:
              result?.construction?.implementation?.start ?? null,
            constructionEndDate:
              result?.construction?.implementation?.end ?? null,
            workPostNoFront: result?.construction?.postal?.front ?? null,
            workPostNoBack: result?.construction?.postal?.back ?? null,
            workAddressPrefecture:
              result?.construction?.address?.prefecture ?? null,
            workAddressCity: result?.construction?.address?.city ?? null,
            workAddressTown: result?.construction?.address?.town ?? null,
            workAddressOther: result?.construction?.address?.other ?? null,
            uniqueInformation: result?.construction?.uniqueInformation ?? null,
            isCsvOutputCompleted: result?.isOutputedCSV ?? false,
            isCsvOutputNotCompleted: result?.isNotOutputedCSV ?? false,
            asbestosReportStartDate: result?.apply?.completed?.start ?? null,
            asbestosReportEndDate: result?.apply?.completed?.end ?? null,
            applicationNo: result?.apply?.number,
            sanitationLawRequestExist:
              !!result?.apply?.classification?.includes("1")
                ? result?.apply?.classification?.includes("1")
                  ? "1"
                  : "0"
                : null,
            preventionLawRequestExist:
              !!result?.apply?.classification?.includes("2")
                ? result?.apply?.classification?.includes("2")
                  ? "1"
                  : "0"
                : null,
            isJurisdictionSetCompleted: result?.applied,
            isJurisdictionSetNotCompleted: result?.unapply,
            isResearchStatusUnfinished:
              result?.preResearchStatus?.includes("1") ?? false,
            isResearchStatusCreated:
              result?.preResearchStatus?.includes("2") ?? false,
            isResearchStatusUnnecessary:
              result?.preResearchStatus?.includes("3") ?? false,
            isReportStatusNone: result?.reportStatus?.includes("1") ?? false,
            isReportStatusFinished:
              result?.reportStatus?.includes("2") ?? false,
            isReportStatusNeededAgain:
              result?.reportStatus?.includes("3") ?? false,
            isReportStatusUnnecessary:
              result?.reportStatus?.includes("4") ?? false,
            searchMode: null,
          },
        },
      }).then((res) => {
        setFilter(result);
        setPage(0);
      });
    } else {
      const result = {
        construction: null,
        apply: null,
        isNotOutputedCSV: null,
        isOutputedCSV: null,
        preResearchStatus: null,
        applied: null,
        unapply: null,
        reportStatus: null,
        ...queryString.parse(location.search),
      };

      functions({
        variables: {
          first: pageSize,
          last: undefined,
          before: undefined,
          after: undefined,
          filter: {
            constructionName: result?.construction?.name ?? null,
            constructionStartDate:
              result?.construction?.implementation?.start ?? null,
            constructionEndDate:
              result?.construction?.implementation?.end ?? null,
            workPostNoFront: result?.construction?.postal?.front ?? null,
            workPostNoBack: result?.construction?.postal?.back ?? null,
            workAddressPrefecture:
              result?.construction?.address?.prefecture ?? null,
            workAddressCity: result?.construction?.address?.city ?? null,
            workAddressTown: result?.construction?.address?.town ?? null,
            workAddressOther: result?.construction?.address?.other ?? null,
            uniqueInformation: result?.construction?.uniqueInformation ?? null,
            isCsvOutputCompleted: result?.isOutputedCSV,
            isCsvOutputNotCompleted: result?.isNotOutputedCSV,
            asbestosReportStartDate: result?.apply?.completed?.start ?? null,
            asbestosReportEndDate: result?.apply?.completed?.end ?? null,
            applicationNo: result?.apply?.number,
            sanitationLawRequestExist:
              !!result?.apply?.classification?.includes("1")
                ? result?.apply?.classification?.includes("1")
                  ? "1"
                  : "0"
                : null,
            preventionLawRequestExist:
              !!result?.apply?.classification?.includes("2")
                ? result?.apply?.classification?.includes("2")
                  ? "1"
                  : "0"
                : null,
            isJurisdictionSetCompleted: result?.applied,
            isJurisdictionSetNotCompleted: result?.unapply,
            isResearchStatusUnfinished:
              result?.preResearchStatus?.includes("1"),
            isResearchStatusCreated: result?.preResearchStatus?.includes("2"),
            isResearchStatusUnnecessary:
              result?.preResearchStatus?.includes("3"),
            isReportStatusNone: result?.reportStatus?.includes("1"),
            isReportStatusFinished: result?.reportStatus?.includes("2"),
            isReportStatusNeededAgain: result?.reportStatus?.includes("3"),
            isReportStatusUnnecessary: result?.reportStatus?.includes("4"),
            searchMode: result?.searchMode,
          },
        },
      }).then((res) => {
        setFilter(result);
        setPage(0);
      });
    }
  }, [location.search]);

  const handleNext = () => {
    functions({
      variables: {
        first: pageSize,
        last: undefined,
        before: undefined,
        after: options?.data?.searchReports?.pageInfo?.endCursor,
        filter: {
          constructionName: filter?.construction?.name ?? null,
          constructionStartDate:
            filter?.construction?.implementation?.start ?? null,
          constructionEndDate:
            filter?.construction?.implementation?.end ?? null,
          workPostNoFront: filter?.construction?.postal?.front ?? null,
          workPostNoBack: filter?.construction?.postal?.back ?? null,
          workAddressPrefecture:
            filter?.construction?.address?.prefecture ?? null,
          workAddressCity: filter?.construction?.address?.city ?? null,
          workAddressTown: filter?.construction?.address?.town ?? null,
          workAddressOther: filter?.construction?.address?.other ?? null,
          uniqueInformation: filter?.construction?.uniqueInformation ?? null,
          isCsvOutputCompleted: filter?.isOutputedCSV ?? false,
          isCsvOutputNotCompleted: filter?.isNotOutputedCSV ?? false,
          asbestosReportStartDate: filter?.apply?.completed?.start ?? null,
          asbestosReportEndDate: filter?.apply?.completed?.end ?? null,
          applicationNo: filter?.apply?.number,
          sanitationLawRequestExist: !!filter?.apply?.classification?.includes(
            "1"
          )
            ? filter?.apply?.classification?.includes("1")
              ? "1"
              : "0"
            : null,
          preventionLawRequestExist: !!filter?.apply?.classification?.includes(
            "2"
          )
            ? filter?.apply?.classification?.includes("2")
              ? "1"
              : "0"
            : null,
          isJurisdictionSetCompleted: filter?.applied,
          isJurisdictionSetNotCompleted: filter?.unapply,
          isResearchStatusUnfinished:
            filter?.preResearchStatus?.includes("1") ?? false,
          isResearchStatusCreated:
            filter?.preResearchStatus?.includes("2") ?? false,
          isResearchStatusUnnecessary:
            filter?.preResearchStatus?.includes("3") ?? false,
          isReportStatusNone: filter?.reportStatus?.includes("1") ?? false,
          isReportStatusFinished: filter?.reportStatus?.includes("2") ?? false,
          isReportStatusNeededAgain:
            filter?.reportStatus?.includes("3") ?? false,
          isReportStatusUnnecessary:
            filter?.reportStatus?.includes("4") ?? false,
          searchMode: filter?.searchMode,
        },
      },
    }).then((res) => {
      setPage((prevState) => prevState + 1);
    });
  };

  const handleBack = () => {
    functions({
      variables: {
        first: undefined,
        last: pageSize,
        before: options?.data?.searchReports?.pageInfo?.startCursor,
        after: undefined,
        filter: {
          constructionName: filter?.construction?.name ?? null,
          constructionStartDate:
            filter?.construction?.implementation?.start ?? null,
          constructionEndDate:
            filter?.construction?.implementation?.end ?? null,
          workPostNoFront: filter?.construction?.postal?.front ?? null,
          workPostNoBack: filter?.construction?.postal?.back ?? null,
          workAddressPrefecture:
            filter?.construction?.address?.prefecture ?? null,
          workAddressCity: filter?.construction?.address?.city ?? null,
          workAddressTown: filter?.construction?.address?.town ?? null,
          workAddressOther: filter?.construction?.address?.other ?? null,
          uniqueInformation: filter?.construction?.uniqueInformation ?? null,
          isCsvOutputCompleted: filter?.isOutputedCSV ?? false,
          isCsvOutputNotCompleted: filter?.isNotOutputedCSV ?? false,
          asbestosReportStartDate: filter?.apply?.completed?.start ?? null,
          asbestosReportEndDate: filter?.apply?.completed?.end ?? null,
          applicationNo: filter?.apply?.number,
          sanitationLawRequestExist: !!filter?.apply?.classification?.includes(
            "1"
          )
            ? filter?.apply?.classification?.includes("1")
              ? "1"
              : "0"
            : null,
          preventionLawRequestExist: !!filter?.apply?.classification?.includes(
            "2"
          )
            ? filter?.apply?.classification?.includes("2")
              ? "1"
              : "0"
            : null,
          isJurisdictionSetCompleted: filter?.applied,
          isJurisdictionSetNotCompleted: filter?.unapply,
          isResearchStatusUnfinished:
            filter?.preResearchStatus?.includes("1") ?? false,
          isResearchStatusCreated:
            filter?.preResearchStatus?.includes("2") ?? false,
          isResearchStatusUnnecessary:
            filter?.preResearchStatus?.includes("3") ?? false,
          isReportStatusNone: filter?.reportStatus?.includes("1") ?? false,
          isReportStatusFinished: filter?.reportStatus?.includes("2") ?? false,
          isReportStatusNeededAgain:
            filter?.reportStatus?.includes("3") ?? false,
          isReportStatusUnnecessary:
            filter?.reportStatus?.includes("4") ?? false,
          searchMode: filter?.searchMode,
        },
      },
    }).then((res) => {
      setPage((prevState) => prevState - 1);
    });
  };

  const handleSearch = (newFilter) => {
    functions({
      variables: {
        first: pageSize,
        last: undefined,
        before: undefined,
        after: undefined,
        filter: {
          constructionName: newFilter?.construction?.name ?? null,
          constructionStartDate:
            newFilter?.construction?.implementation?.start ?? null,
          constructionEndDate:
            newFilter?.construction?.implementation?.end ?? null,
          workPostNoFront: newFilter?.construction?.postal?.front ?? null,
          workPostNoBack: newFilter?.construction?.postal?.back ?? null,
          workAddressPrefecture:
            newFilter?.construction?.address?.prefecture ?? null,
          workAddressCity: newFilter?.construction?.address?.city ?? null,
          workAddressTown: newFilter?.construction?.address?.town ?? null,
          workAddressOther: newFilter?.construction?.address?.other ?? null,
          uniqueInformation: newFilter?.construction?.uniqueInformation ?? null,
          isCsvOutputCompleted: newFilter?.isOutputedCSV ?? false,
          isCsvOutputNotCompleted: newFilter?.isNotOutputedCSV ?? false,
          asbestosReportStartDate: newFilter?.apply?.completed?.start ?? null,
          asbestosReportEndDate: newFilter?.apply?.completed?.end ?? null,
          applicationNo: newFilter?.apply?.number,
          sanitationLawRequestExist: !!filter?.apply?.classification?.includes(
            "1"
          )
            ? filter?.apply?.classification?.includes("1")
              ? "1"
              : "0"
            : null,
          preventionLawRequestExist: !!filter?.apply?.classification?.includes(
            "2"
          )
            ? filter?.apply?.classification?.includes("2")
              ? "1"
              : "0"
            : null,
          isJurisdictionSetCompleted: newFilter?.applied,
          isJurisdictionSetNotCompleted: newFilter?.unapply,
          isResearchStatusUnfinished:
            newFilter?.preResearchStatus?.includes("1") ?? false,
          isResearchStatusCreated:
            newFilter?.preResearchStatus?.includes("2") ?? false,
          isResearchStatusUnnecessary:
            newFilter?.preResearchStatus?.includes("3") ?? false,
          isReportStatusNone: newFilter?.reportStatus?.includes("1") ?? false,
          isReportStatusFinished:
            newFilter?.reportStatus?.includes("2") ?? false,
          isReportStatusNeededAgain:
            newFilter?.reportStatus?.includes("3") ?? false,
          isReportStatusUnnecessary:
            newFilter?.reportStatus?.includes("4") ?? false,
          searchMode: newFilter?.searchMode,
        },
      },
    }).then((res) => {
      setPage(0);
      setFilter(newFilter);
    });
  };

  const reload = () => {
    options.refetch();
  };

  return {
    items: options?.data?.searchReports?.items,
    totalCount: options?.data?.searchReports?.totalCount ?? 0,
    pageCount: Math.ceil(
      (options?.data?.searchReports?.totalCount ?? 0) / pageSize
    ),
    loading: options.loading,
    error: options.error,
    hasNextPage: options?.data?.searchReports?.pageInfo?.hasNextPage ?? false,
    hasPreviousPage:
      options.data?.searchReports?.pageInfo?.hasPreviousPage ?? false,
    onNext: handleNext,
    onBack: handleBack,
    onSearch: handleSearch,
    filter: filter,
    page: page,
    pageSize: pageSize,
    reload: reload,
  };
};
