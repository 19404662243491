import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * データを選択するコンポーネントです。
 * @param {string} label ラベル
 * @param {boolean} required 必須かどうか
 * @param {object} value 値
 * @fires SelectItem#onChange 変更時
 * @param {array} options 選択項目
 * @param {boolean} error エラーかどうか
 * @param {boolean} loading ロード中であるか
 * @param {string} helperText 補助的テキスト
 * @param {boolean} disabled 無効にするか
 * @param {string} optionValueField オブジェクトの値のプロパティ名(id)
 * @param {string} optionNameField オブジェクトの名前のプロパティ名(name)
 * @returns {JSX.Element}
 */
export const SelectItem = containerPresententer(Container, Presententer);
