import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import { ApplicationPaths } from "api/api-authorization/ApiAuthorizationConstants";
import ApiAuthorizationRoutes from "api/api-authorization/ApiAuthorizationRoutes";
import Auth from "api/api-authorization/Auth";
import AuthorizeRoute from "api/api-authorization/AuthorizeRoute";
import authService from "api/api-authorization/AuthorizeService";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { routes } from "routes";
import {
  LoginActions,
  LogoutActions,
} from "api/api-authorization/ApiAuthorizationConstants";
import { Login } from "api/api-authorization/Login";
import { Logout } from "api/api-authorization/Logout";

const customFetch = async (uri, options) => {
  await authService.mightRefreshToken();
  const user = await authService.getOidcUser();
  options.headers.authorization = `Bearer ${user.access_token}`;
  return fetch(uri, options);
};

const httpLink = createHttpLink({
  uri: "/graphql",
  fetch: customFetch,
});

const authLink = setContext(async (_, { headers }) => {
  const token = await authService.getAccessToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const errorLink = onError((props) => {
  const { graphQLErrors, networkError, operation, forward } = props;

  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      switch (err.extensions.code) {
        // Apollo Server sets code to UNAUTHENTICATED
        // when an AuthenticationError is thrown in a resolver
        case "UNAUTHENTICATED":
          // Modify the operation context with a new token
          const oldHeaders = operation.getContext().headers;
          authService.getAccessToken().then((newToken) => {
            operation.setContext({
              headers: {
                ...oldHeaders,
                authorization: newToken,
              },
            });

            // Retry the request, returning the new observable
            return forward(operation);
          });
      }
    }
  }

  // To retry on network errors, we recommend the RetryLink
  // instead of the onError link. This just logs the error.
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 3,
    retryIf: (error, _operation) => !!error,
  },
});

const client = new ApolloClient({
  link: ApolloLink.from([errorLink, retryLink, authLink.concat(httpLink)]),
  cache: new InMemoryCache(),
});

const App = (props) => {
  return (
    <ApolloProvider client={client}>
      <Routes>
        {routes.map((route, index) => {
          const { component: Component, ...restRoute } = route;
          return (
            <Route
              {...restRoute}
              key={index}
              element={
                <Auth>
                  <AuthorizeRoute component={Component} />
                </Auth>
              }
            />
          );
        })}
        <Route
          path={ApplicationPaths.Login}
          element={<Login action={LoginActions.Login} />}
        />
        <Route
          path={ApplicationPaths.LoginFailed}
          element={<Login action={LoginActions.LoginFailed} />}
        />
        <Route
          path={ApplicationPaths.LoginCallback}
          element={<Login action={LoginActions.LoginCallback} />}
        />
        <Route
          path={ApplicationPaths.Profile}
          element={<Login action={LoginActions.Profile} />}
        />
        <Route
          path={ApplicationPaths.Register}
          element={<Login action={LoginActions.Register} />}
        />
        <Route
          path={ApplicationPaths.LogOut}
          element={<Logout action={LogoutActions.Logout} />}
        />
        <Route
          path={ApplicationPaths.LogOutCallback}
          element={<Logout action={LogoutActions.LogoutCallback} />}
        />
        <Route
          path={ApplicationPaths.LoggedOut}
          element={<Logout action={LogoutActions.LoggedOut} />}
        />
      </Routes>
    </ApolloProvider>
  );
};

export default App;
