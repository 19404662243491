import { useQuery } from "@apollo/client";
import { getUserInfo } from "api/graphql/queries";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

/**
 * ARS-ホームのコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, alert, ...props }) => {
  const navigate = useNavigate();

  const [roleName, setRoleName] = useState(null);
  useQuery(getUserInfo, {
    onCompleted: (data) => {
      setRoleName(data.getUserInfo.roleName);
    },
  });

  const handleClickIncompleteSurveyAlert = () => {
    navigate(`/constructions?searchMode=1`);
  };

  const handleClickUnappliedAlert = () => {
    navigate(`/report?searchMode=1`);
  };

  const handleClickWithdrawalAlert = () => {
    navigate(`/report?searchMode=2`);
  };

  return render({
    ...props,
    onClickIncompleteSurveryAlert: handleClickIncompleteSurveyAlert,
    onClickUnappliedAlert: handleClickUnappliedAlert,
    onClickWithdrawalAlert: handleClickWithdrawalAlert,
    alert: alert,
    roleName: roleName,
  });
};
