import { useLocation } from "react-router";
import queryString from "query-string";
/**
 * 詳細ページを表示するコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const location = useLocation();
  const variables = { ...queryString.parse(location?.search) };

  return render({ variables: variables, ...props });
};
