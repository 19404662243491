import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { Box, Card, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { InformationItem, Tel } from "views/atoms";
import { Download, InformationItemGroup } from "views/molecules";
import { TextCopyRight } from "views/organisms/Copyright";

/**
 * 建材の詳細を表示するコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Materials = (props) => {
  const { value } = props;

  return (
    <Stack>
      {value?.deleteFlg === true ?? (
        <Card
          variant="outlined"
          color="error"
          sx={{ borderWidth: "3px", borderColor: "#ff1744" }}
        >
          <Box p={2} border="secondary">
            <Typography align="center" variant="subtitle1">
              この建材は以下理由で削除されました。
            </Typography>
            <Typography align="center" variant="subtitle1">
              {value?.deleteReason ?? ""}
            </Typography>
          </Box>
        </Card>
      )}
      <InformationItemGroup title="建材情報">
        {value?.asbestosLevel < 3 && (
          <Grid item xs={12}>
            <Typography>
              <ReportProblemIcon fontSize="small" /> 工事に影響があります。
            </Typography>
          </Grid>
        )}
        <InformationItem value={value?.field} label="製品分野" />
        <InformationItem
          value={value?.asbestosLevel}
          label="アスベストレベル"
        />
        <InformationItem
          value={value?.productUsefulnesses?.map((v) => v.name).join("、")}
          label="使用例"
        />
        <InformationItem
          value={value?.material?.name}
          label="建材名（一般名）"
        />
        <InformationItem value={value?.name} label="製品名" />
        <InformationItem
          value={value?.manufactureMakers?.map((v) => v.name).join("、")}
          label="メーカー名（製造時）"
        />
        <InformationItem
          value={value?.currentMakers?.map((v) => v.name).join("、")}
          label="メーカー名（現在）"
        />
        <InformationItem value={value?.modelNo} label="型番・品番" />
        <InformationItem
          value={`${
            value?.periodYearStart ? value?.periodYearStart + "年" : ""
          }～${value?.periodYearEnd ? value?.periodYearEnd + "年" : ""}`}
          label="製造期間"
        />
        <InformationItem
          value={value?.asbestosContentConditions}
          label="石綿含有条件"
        />
        <InformationItem
          value={`${
            value?.asbestosContentPercentage
              ? `${value?.asbestosContentPercentage}%`
              : ""
          }${
            value?.productAsbestosContains?.length > 0
              ? `(${value?.productAsbestosContains
                  ?.map((v) => v.name)
                  .join("、")})`
              : ""
          }`}
          label="石綿含有率・種類"
        />
        <InformationItem
          value={` ${value?.certificationKind?.name ?? ""} ${
            value?.certificationNo ?? ""
          }`}
          label="不燃材料等認定番号"
        />
      </InformationItemGroup>
      <InformationItemGroup title="表示">
        <InformationItem
          value={value?.displayMaker ?? "情報なし"}
          label="メーカー名"
        />
        <InformationItem
          value={value?.displayName ?? "情報なし"}
          label="商品名"
        />
        <InformationItem
          value={value?.displayLotNo ?? "情報なし"}
          label="その他ロット番号"
        />
        <InformationItem
          value={value?.displayCertificationNo ?? "情報なし"}
          label="不燃材料等認定番号"
        />
        <InformationItem
          value={value?.displayJisNo ?? "情報なし"}
          label="JIS許可番号"
        />
        <InformationItem
          value={value?.displayAMark ?? "情報なし"}
          label="aマーク"
        />
        <InformationItem
          value={value?.uniqueDistinction}
          label="※固有の見分け方"
        />
      </InformationItemGroup>
      <InformationItemGroup title="メーカーが想定した使用方法">
        {value?.useMethodApplicables
          ?.reduce((result, current) => {
            if (!result?.includes?.(current.constructionPartName)) {
              result.push(current.constructionPartName);
            }
            return result;
          }, [])
          ?.map((constructionPartName) => ({
            constructionPartName: constructionPartName,
            names: value?.useMethodApplicables
              ?.filter(
                (applicable) =>
                  applicable.constructionPartName === constructionPartName
              )
              ?.map((applicable) => applicable.name)
              .join("、"),
          }))
          ?.map((v, i) => (
            <InformationItem
              key={i}
              value={v.names}
              label={v.constructionPartName}
            />
          ))}
      </InformationItemGroup>
      <InformationItemGroup title="素材の形状">
        <InformationItem value={value?.materialShape} label="素材・形状" />
        <InformationItem value={value?.materialShapeDetail} label="素材" />
        <InformationItem value={value?.cottonLike} label="形状" />
        <InformationItem value={value?.boardThickness} label="ボード状・厚さ" />
        <InformationItem
          value={value?.boardLength}
          label="ボード状・標準寸法"
        />
        <InformationItem value={value?.scrollThickness} label="巻物・厚さ" />
        <InformationItem value={value?.scrollLength} label="巻物・標準寸法" />
      </InformationItemGroup>
      <InformationItemGroup title="相談窓口">
        <InformationItem
          value={value?.supportMakers?.map((v) => v.name)}
          label="メーカー・名称"
        />
        <InformationItem
          value={
            <>
              {value?.supportMakers?.map((v, i) => (
                <Tel key={i} value={v.phone} />
              ))}
            </>
          }
          label="メーカー・TEL"
        />
        <InformationItem
          value={value?.relatedOrganization?.name}
          label="関係団体・名称"
        />
        <InformationItem
          value={<Tel value={value?.relatedOrganization?.phone} />}
          label="関係団体・TEL"
        />
      </InformationItemGroup>
      <InformationItemGroup>
        <TextCopyRight />
      </InformationItemGroup>
      <Download in={value?.material.existsPDF} material={value?.material} />
    </Stack>
  );
};
