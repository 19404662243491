import yup from "utils/yup";

export const basicSchema = yup.object().shape({
  name: yup.string().name().default(null).nullable(),
  laborInsuranceNumber: yup.object().shape({
    samePrimeContractor: yup.boolean().default(null).nullable(),
    prefecture: yup
      .number()
      .transform((curr, orig) => (orig === "" ? null : curr))
      .length(2)
      .default(null)
      .nullable(),
    admin: yup
      .number()
      .transform((curr, orig) => (orig === "" ? null : curr))
      .length(1)
      .default(null)
      .nullable(),
    jurisdiction: yup
      .number()
      .transform((curr, orig) => (orig === "" ? null : curr))
      .length(2)
      .default(null)
      .nullable(),
    coreNumber: yup
      .number()
      .transform((curr, orig) => (orig === "" ? null : curr))
      .length(6)
      .default(null)
      .nullable(),
    branchNumber: yup
      .number()
      .transform((curr, orig) => (orig === "" ? null : curr))
      .length(3)
      .default(null)
      .nullable(),
    nothing: yup.boolean().default(null).nullable(),
  }),
  postalCode: yup
    .object()
    .shape({
      front: yup
        .number()
        .transform((curr, orig) => (orig === "" ? null : curr))
        .default(null)
        .nullable()
        .postalCodeFront(),
      back: yup
        .number()
        .transform((curr, orig) => (orig === "" ? null : curr))
        .default(null)
        .nullable()
        .postalCodeBack(),
    })
    .default(null)
    .nullable(),
  prefecture: yup.string().em().default(null).nullable(),
  city: yup.string().name().default(null).nullable(),
  town: yup.string().name().default(null).nullable(),
  otherAddress: yup.string().name().default(null).nullable(),
  phone: yup
    .string()
    .halfNumeric()
    .phoneFirstNumber()
    .phone(),
  preliminarySurvey: yup
    .object()
    .shape({
      name: yup.string().name().default(null).nullable(),
      companyName: yup.string().em().default(null).nullable(),
    })
    .default(null)
    .nullable(),
  analyticalSurvey: yup
    .object()
    .shape({
      name: yup.string().name().default(null).nullable(),
      companyName: yup.string().em().default(null).nullable(),
    })
    .default(null)
    .nullable(),
  asbestosChiefName: yup.string().name().default(null).nullable(),
});
