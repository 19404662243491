import {
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  Skeleton,
} from "@mui/material";
import React from "react";

/**
 * データを選択するプレゼンテーションコンポーネントです。
 */
export const Presententer = (props) => {
  const {
    label,
    value,
    required,
    options,
    onChange,
    error,
    helperText,
    optionValueField,
    optionNameField,
    loading,
    disabled,
  } = props;

  if (loading) {
    return <Skeleton variant="text" />;
  }

  return (
    <FormControl fullWidth variant="outlined" size="small">
      <InputLabel
        shrink
        sx={{ background: "#fff", padding: "0 5px", marginLeft: "-5px" }}
      >
        {label}
      </InputLabel>
      <Select
        native
        value={value?.[optionValueField]}
        onChange={onChange}
        displayEmpty
        required={required}
        error={error}
        disabled={disabled}
      >
        <option
          value=""
          style={{
            color: "rgba(0,0,0, .4)",
          }}
        >
          未選択
        </option>
        {options?.map((option, index) => (
          <option key={index} value={option[optionValueField]}>
            {option[optionNameField]}
          </option>
        ))}
      </Select>
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </FormControl>
  );
};
