import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * ARS-テキストコントロールをを表示するコンポーネントです。
 * @param {string} label テキストのラベル
 * @param {string} name コントロール名
 */
export const ControlTextField = containerPresententer(Container, Presententer);
