import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { Button, MobileStepper } from "@mui/material";
import React from "react";

/**
 * ページャーを表示するプレゼンテーションコンポーネントです。
 */
export const Presententer = (props) => {
  return (
    <MobileStepper
      variant="text"
      steps={props.pageCount}
      position="static"
      activeStep={props.page}
      nextButton={
        <Button
          size="small"
          onClick={props.onNext}
          disabled={props.hasNextPage === false || props.disabled}
        >
          {"次へ"}
          <KeyboardArrowRight />
        </Button>
      }
      backButton={
        <Button
          size="small"
          onClick={props.onPrevious}
          disabled={props.hasPreviousPage === false || props.disabled}
        >
          <KeyboardArrowLeft />
          {"前へ"}
        </Button>
      }
    />
  );
};
