import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import Dropzone from "react-dropzone";

// e.target.files

const UploadButton = ({ title = "または画像を選択", getInputProps }) => {
  return (
    <>
      <input {...getInputProps()} />
      <Button variant="outlined" component="span" color="success">
        {title}
      </Button>
    </>
  );
};

/**
 * ファイルアップローダプレゼンテーションコンポーネントです。
 */
export const Presententer = (props) => {
  return (
    <FormControl>
      <FormLabel required={props.required}>{props.label}</FormLabel>
      <Dropzone
        onDropRejected={props.onRejection}
        onDrop={props.onDrop}
        disabled={props.disabled}
        multiple={props.multiple}
        maxFiles={props.maxFiles}
        maxSize={props.maxSize}
        minSize={props.minSize}
        accept={props.accept}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            style={{
              border: `1px dashed ${props.error ? "#f44336" : "darkgrey"}`,
              display: "flex",
              justifyContent: "center",
              borderRadius: "16px",
              padding: "16px",
            }}
            {...getRootProps()}
          >
            <Stack spacing={1}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "darkgrey",
                }}
              >
                <CloudUploadIcon sx={{ fontSize: 40 }} />
              </div>
              <Typography sx={{ color: "darkgrey" }}>
                {props.dropzoneTitle}
              </Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "darkgrey",
                }}
              >
                <UploadButton
                  title={props.buttonTitle}
                  getInputProps={getInputProps}
                />
              </div>
            </Stack>
          </div>
        )}
      </Dropzone>
      <FormHelperText required={props.required} error={props.error}>
        {props.helperText}
      </FormHelperText>
      {props.message?.map?.((item, index) => (
        <FormHelperText key={index} error={true}>
          {item}
        </FormHelperText>
      )) ?? <></>}
    </FormControl>
  );
};
