import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import React, { Fragment } from "react";

const InforText = (props) => (
  <Typography sx={{ fontSize: "14px" }}>{props.value}</Typography>
);

/**
 * ARS-企業一覧ページの表示を行うプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <Fragment>
    <Card
      sx={{
        mr: (theme) => theme.spacing(2),
      }}
      onClick={props.onClick}
    >
      <CardActionArea>
        <CardContent sx={{ p: 0 }}>
          <Box
            sx={{
              boxShadow: 1,
              borderRadius: "4px",
              p: 1,
              //mr: (theme) => theme.spacing(2),
            }}
          >
            <Stack direction="row" spacing={2} mb={1}>
              <InforText value={props.value?.name} />
              <InforText value={props.value?.phone} />
            </Stack>
            <Stack direction="row" spacing={2} mb={1}>
              <InforText value={props.value?.postNo} />
              <InforText value={props.value?.address} />
            </Stack>
            <Stack direction="row" spacing={1}>
              <InforText value={props.value?.laborInsuranceNumber} />
            </Stack>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  </Fragment>
);
