import { createContractor } from "api/graphql/mutations";
import { useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";

/**
 * 請負事業者の登録を行うコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props　その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const [createFunction, { loading, error }] = useMutation(createContractor);
  const { id } = useParams();

  return render({
    ...props,
    id: id,
    loading: loading,
    error: error,
    createFunction: createFunction,
  });
};
