import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 請負事業者フォームを表示するコンポーネントです。
 */
export const ContractsForm = containerPresententer(Container, Presententer);

/**
 * formValue to api
 */
export const invert = (value) => {
  if (!value || value.length <= 0) {
    return null;
  }

  return value.map((item) => invertItem(item)) ?? [];
};

const invertItem = (value) => {
  if (!value) {
    return null;
  }

  return {
    id: undefined,
    name: value?.name ?? "",
    laborInsuranceNoSamePrime: value?.laborInsuranceNumber?.samePrimeContractor
      ? "1"
      : "0",
    laborInsuranceNoPrefecture: value?.laborInsuranceNumber?.prefecture,
    laborInsuranceNoAdministration: value?.laborInsuranceNumber?.admin,
    laborInsuranceNoJurisdiction: value?.laborInsuranceNumber?.jurisdiction,
    laborInsuranceNoCoreNo: value?.laborInsuranceNumber?.coreNumber,
    laborInsuranceNoBranchNo: value?.laborInsuranceNumber?.branchNumber,
    laborInsuranceNoExist: value?.laborInsuranceNumber?.nothing ? "1" : "0",
    postNoFront: value?.postalCode?.front ?? "",
    postNoBack: value?.postalCode?.back ?? "",
    addressPrefecture: value?.prefecture ?? "",
    addressCity: value?.city ?? "",
    addressTown: value?.town ?? "",
    addressOther: value?.otherAddress ?? "",
    phone: value?.phone ?? "",
    preliminarySurveyorName: value?.preliminarySurvey?.name ?? "",
    preSurveyorTrainingInstitutionName:
      value?.preliminarySurvey?.companyName ?? "",
    analyticalSurveyorName: value?.analyticalSurvey?.name ?? "",
    anaSurveyorTrainingInstitutionName:
      value?.analyticalSurvey?.companyName ?? "",
    asbestosWorkChiefName: value?.asbestosChiefName ?? "",
  };
};

/**
 * api to formValue
 */
export const convert = (value) => {
  if (!value) {
    return null;
  }

  return value?.map((item) => convertItem(item)) ?? null;
};

const convertItem = (value) => {
  if (!value) {
    return null;
  }

  return {
    id: value?.id,
    name: value?.name,
    laborInsuranceNumber: {
      samePrimeContractor: value?.laborInsuranceNoSamePrime === "1",
      prefecture: value?.laborInsuranceNoPrefecture ?? "",
      admin: value?.laborInsuranceNoAdministration ?? "",
      jurisdiction: value?.laborInsuranceNoJurisdiction ?? "",
      coreNumber: value?.laborInsuranceNoCoreNo ?? "",
      branchNumber: value?.laborInsuranceNoBranchNo ?? "",
      nothing: value?.laborInsuranceNoExist === "1",
    },
    postalCode: {
      front: value?.postNoFront ?? "",
      back: value?.postNoBack ?? "",
    },
    prefecture: value?.addressPrefecture ?? "",
    city: value?.addressCity ?? "",
    town: value?.addressTown ?? "",
    otherAddress: value?.addressOther ?? "",
    preliminarySurvey: {
      name: value?.preliminarySurveyorName ?? "",
      companyName: value?.preSurveyorTrainingInstitutionName ?? "",
    },
    analyticalSurvey: {
      name: value?.analyticalSurveyorName ?? "",
      companyName: value?.anaSurveyorTrainingInstitutionName ?? "",
    },
    phone: value?.phone ?? "",
    asbestosChiefName: value?.asbestosWorkChiefName ?? "",
  };
};
