import { Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FreeWord } from "./FreeWord";
import { MakerSelector } from "./MakerSelector";
import { ManufacturingModelYear } from "./ManufacturingModelYear";
import { MaterialSelector } from "./MaterialSelector";
import { ProductSelector } from "./ProductSelector";
import { UsefulnessSelector } from "./UsefulnessSelector";

/**
 * 簡単検索のデフォルト値を表します。
 */
export const defaultValue = {
  keyword: null,
  productField: null,
  maker: null,
  material: null,
  usefulness: null,
  year: null,
};

/**
 * 簡単検索を表示するコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const SimpleSearcher = (props) => {
  const { value, onChange } = props;

  const handleChange = (name) => (data) => {
    onChange({
      ...value,
      [name]: data,
    });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FreeWord
          value={value?.keyword}
          onChange={(data) => handleChange("keyword")(data)}
        />
      </Grid>
      <Grid item xs={12}>
        <ProductSelector
          value={value?.productField}
          onChange={(data) => handleChange("productField")(data)}
        />
      </Grid>
      <Grid item xs={12}>
        <MakerSelector
          value={value?.maker}
          onChange={(data) => handleChange("maker")(data)}
        />
      </Grid>
      <Grid item xs={12}>
        <MaterialSelector
          value={value?.material}
          onChange={(data) => handleChange("material")(data)}
        />
      </Grid>
      <Grid item xs={12}>
        <UsefulnessSelector
          value={value?.usefulness}
          onChange={(data) => handleChange("usefulness")(data)}
        />
      </Grid>
      <Grid item xs={12}>
        <ManufacturingModelYear
          value={value?.year}
          onChange={(data) => handleChange("year")(data)}
        />
      </Grid>
    </Grid>
  );
};
