import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * モーダルでテーブルを表示するコンポーネントです
 * @param {boolean} open モーダルが有効か無効を設定
 * @param {func} onClose モーダルを閉じるイベントです
 * @param {object} children モーダルの内容
 */
export const ModalPopup = containerPresententer(Container, Presententer);
