import { gql } from "@apollo/client";

/* FIX */
export const listSearchResults = gql`
  query listSearchResults(
    $keyword: String
    $year: Int
    $productFieldId: ID
    $materialId: ID
    $makerId: ID
    $usefulnessId: ID
    $mode: Int
  ) {
    listSearchResults(
      keyword: $keyword
      year: $year
      productFieldId: $productFieldId
      makerId: $makerId
      materialId: $materialId
      usefulnessId: $usefulnessId
      mode: $mode
    ) {
      id
      asbestosLevel
      name
      fieldName
      makerName
      modelNumber
      isSanitary
      asbestosTargetMaterialId
    }
  }
`;

/* FIX */
export const getProduct = gql`
  query getProduct($id: ID!) {
    getProduct(id: $id) {
      productId
      name
      field
      periodYearStart
      periodYearEnd
      modelNo
      asbestosLevel
      asbestosContentConditions
      asbestosContentPercentage
      certificationNo
      displayMaker
      displayName
      displayLotNo
      displayCertificationNo
      displayJisNo
      displayAMark
      uniqueDistinction
      materialShape
      materialShapeDetail
      cottonLike
      boardThickness
      boardLength
      scrollThickness
      scrollLength
      deleteFlg
      deleteReason
      material {
        id
        name
        existsPDF
      }
      manufactureMakers {
        name
      }
      currentMakers {
        name
      }
      supportMakers {
        name
        phone
      }
      productAsbestosContains {
        name
      }
      useMethodApplicables {
        id
        name
        constructionPartName
      }
      productUsefulnesses {
        id
        name
      }
      relatedOrganization {
        name
        phone
      }
      certificationKind {
        name
      }
    }
  }
`;

/* FIX */
export const getSanitary = gql`
  query getSanitary($id: ID!) {
    getSanitary(id: $id) {
      sanitaryId
      maker
      field
      asbestosLevel
      productionStartYear
      productionStartMonth
      productionStartYearRemarks
      productionEndYear
      productionEndMonth
      productionEndYearRemarks
      productionQuantity
      importCountry
      saleStartYear
      saleStartMonth
      saleStartYearRemarks
      saleEndYear
      saleEndMonth
      saleEndYearRemarks
      salesStatus
      salesQuantity
      estimatedUseYear
      substitutability
      asbestosContentPercentage
      asbestosScatterNormalDetail
      asbestosScatterOtherDetail
      asbestosScatterDisposalDetail
      isClaim
      claimDetail
      reportMaterial
      manufacturingImport
      householdItemsUse
      asbestosUsage
      asbestosContainDisplay
      asbestosScatterNormal
      asbestosScatterOther
      asbestosScatterDisposal
      names {
        id
        name
      }
      informationDesk {
        id
        name
        phone
        url
      }
      sanitaryAsbestosContains {
        id
        name
      }
      modelNumbers {
        id
        name
      }
      administrativeSections {
        id
        name
      }
      sanitaryUsefulnesses {
        id
        name
      }
    }
  }
`;

export const getConstruction = gql`
  query getConstruction($id: ID!) {
    getConstruction(id: $id) {
      id
      necessityAsbestosResearch
      primeContractorName
      primeContractorRepresentativeName
      primeContractorPostNoFront
      primeContractorPostNoBack
      primeContractorAddressPrefecture
      primeContractorAddressCity
      primeContractorAddressTown
      primeContractorAddressOther
      primeContractorPhone
      primeContractorMailAddress
      ordererName
      ordererRepresentativeName
      ordererPostNoFront
      ordererPostNoBack
      ordererAddressPrefecture
      ordererAddressCity
      ordererAddressTown
      ordererAddressOther
      laborInsuranceNoExist
      laborInsuranceNoPrefecture
      laborInsuranceNoAdministration
      laborInsuranceNoJurisdiction
      laborInsuranceNoCoreNo
      laborInsuranceNoBranchNo
      workPostNoFront
      workPostNoBack
      workAddressPrefecture
      workAddressCity
      workAddressTown
      workAddressOther
      name
      summary
      buildDateExist
      buildDate
      buildingFloorSpace
      groundFloors
      basementFloors
      isReactionTank
      isHeatingFurnace
      isBoilerAndPressureVessel
      isPlumbingEquipment
      isIncinerator
      isChimney
      isStorageEquipment
      isPowerGenerationEquipment
      isSubstationEquipment
      isPowerDistributionEquipment
      isPowerTransmissionEquipment
      isTunnelCeilingBoard
      isPlatformHouse
      isSoundInsulationWall
      isLightweightFillProtectionPanel
      isRailwayStationsWall
      isTouristElevatorHoistwayEnclosure
      isShip
      constructionFloorSpace
      constructionStartDate
      constructionEndDate
      amountBillion
      amountTenThousand
      freeEntryField
      uniqueInformation1
      uniqueInformation2
      uniqueInformation3
      uniqueInformation4
      uniqueInformation5
      constructionRemarks
      constructionOrderStatus
      surveyApplicationNo
      createAt
      updateAt
      buildingStructure {
        id
        name
      }
      buildingFireproof {
        id
        name
      }
      constructionRegistManagers {
        id
        name
        phone
        mailAddress
        mainPosition
        subPosition
      }
      constructionSalesManagers {
        id
        name
        phone
        mailAddress
        mainPosition
        subPosition
      }
      constructionResearchManagers {
        id
        name
        phone
        mailAddress
        mainPosition
        subPosition
      }
    }
  }
`;

export const getContractors = gql`
  query getContractors($id: ID!) {
    getContractors(id: $id) {
      id
      name
      laborInsuranceNoSamePrime
      laborInsuranceNoExist
      laborInsuranceNoPrefecture
      laborInsuranceNoAdministration
      laborInsuranceNoJurisdiction
      laborInsuranceNoCoreNo
      laborInsuranceNoBranchNo
      postNoFront
      postNoBack
      addressPrefecture
      addressCity
      addressTown
      addressOther
      phone
      preliminarySurveyorName
      preSurveyorTrainingInstitutionName
      analyticalSurveyorName
      anaSurveyorTrainingInstitutionName
      asbestosWorkChiefName
      createAt
    }
  }
`;

export const getAsbestosResearch = gql`
  query getAsbestosResearch($id: ID!) {
    getAsbestosResearch(id: $id) {
      constructionId
      asbestosWorkStartDate
      preliminarySurveyEndDate
      analysisSurveyLocation
      preliminarySurveyorName
      preSurveyorTrainingInstitutionName
      analyticalSurveyorName
      anaSurveyorAffiliationName
      anaSurveyorTrainingInstitutionName
      asbestosWorkChiefName
      asbestosResearchStatusId
      asbestosResearchStatus
      createAt
      updateAt
      trainingRulesClassification {
        id
        content
      }
      asbestosResearchDetails {
        id
        cuttingExist
        judgedNoneVisually
        judgedNoneDesignDocuments
        judgedNoneAnalysis
        judgedNoneManufacturerCertification
        judgedNoneMadeDate
        workStepNegativePressureIsolation
        workStepIsolation
        workStepWetting
        workStepUseProtectiveEquipment
        asbestosTargetMaterial {
          id
          name
        }
        asbestosContainsResult {
          id
          content
        }
        asbestosWorkKind {
          id
          content
        }
      }
      asbestosResearchRemarkses {
        id
        remarks
        asbestosResearchRelatedFiles {
          id
          name
          fileImage
        }
        researchRemarksSearchCondition {
          searchMode
          freeWord
          modelYear
          productField {
            id
            name
          }
          maker {
            id
            name
          }
          material {
            id
            name
          }
          usefulness {
            id
            name
          }
        }
        researchRemarksSearchItems {
          searchResult {
            id
            name
            asbestosLevel
            fieldName
            makerName
            modelNumber
            isSanitary
            asbestosTargetMaterialId
          }
        }
        researchRemarksApplicableItems {
          searchResult {
            id
            name
            asbestosLevel
            fieldName
            makerName
            modelNumber
            isSanitary
            asbestosTargetMaterialId
          }
        }
      }
    }
  }
`;

export const getAsbestosReport = gql`
  query getAsbestosReport($id: ID!) {
    getAsbestosReport(id: $id) {
      constructionId
      sanitationLawRequestExist
      preventionLawRequestExist
      asbestosReportDate
      isOutputApplyCsvFile
      isJurisdictionSetCompleted
      sanitationLawRequestAuto
      preventionLawRequestAuto
      asbestosReportStatusId
      asbestosReportStatus
      createAt
      updateAt
      laborBureau {
        id
        bureauCode
        name
      }
      laborInspectionOffice {
        id
        bureauCode
        inspectionCode
        name
      }
      requestPrefecture {
        id
        prefectureCode
        name
      }
      requestMunicipality {
        id
        prefectureCode
        municipalityCode
        name
      }
      requestMunicipalityDept {
        id
        prefectureCode
        municipalityCode
        deptCode
        name
      }
    }
  }
`;

export const listProductFields = gql`
  query listProductFields {
    listProductFields {
      id
      name
      isSanitary
    }
  }
`;

export const listMaterials = gql`
  query listMaterials {
    listMaterials {
      id
      name
    }
  }
`;

export const listMakers = gql`
  query listMakers {
    listMakers {
      id
      name
    }
  }
`;

export const listProductMakers = gql`
  query listProductMakers {
    listProductMakers {
      id
      name
    }
  }
`;

export const listSanitaryMakers = gql`
  query listSanitaryMakers {
    listSanitaryMakers {
      id
      name
    }
  }
`;

export const listUsefulnesses = gql`
  query listUsefulnesses {
    listUsefulnesses {
      id
      name
    }
  }
`;

export const listProductUsefulnesses = gql`
  query listProductUsefulnesses {
    listProductUsefulnesses {
      id
      name
    }
  }
`;

export const listSanitaryUsefulnesses = gql`
  query listSanitaryUsefulnesses {
    listSanitaryUsefulnesses {
      id
      name
    }
  }
`;

export const listBuildingStructure = gql`
  query listBuildingStructure {
    listBuildingStructure {
      id
      name
    }
  }
`;

export const listBuildingFireproof = gql`
  query listBuildingFireproof {
    listBuildingFireproof {
      id
      name
    }
  }
`;

export const listTrainingRulesClassification = gql`
  query listTrainingRulesClassification {
    listTrainingRulesClassification {
      id
      name
    }
  }
`;

export const listLaborBureau = gql`
  query listLaborBureau {
    listLaborBureau {
      bureauCode
      name
    }
  }
`;

export const listLaborInspectionOffice = gql`
  query listLaborInspectionOffice($code: String!) {
    listLaborInspectionOffice(code: $code) {
      inspectionCode
      name
    }
  }
`;

export const listRequestPrefecture = gql`
  query listRequestPrefecture {
    listRequestPrefecture {
      prefectureCode
      name
    }
  }
`;

export const listRequestMunicipality = gql`
  query listRequestMunicipality($code: String!) {
    listRequestMunicipality(code: $code) {
      municipalityCode
      name
    }
  }
`;

export const listRequestMunicipalityDept = gql`
  query listRequestMunicipalityDept(
    $prefectureId: String!
    $municipalityId: String!
  ) {
    listRequestMunicipalityDept(
      requestPrefectureCode: $prefectureId
      requestMunicipalityCode: $municipalityId
    ) {
      deptCode
      name
    }
  }
`;

export const listAsbestosTargetMaterial = gql`
  query listAsbestosTargetMaterial {
    listAsbestosTargetMaterial {
      id
      name
    }
  }
`;

export const listAsbestosContainsResult = gql`
  query listAsbestosContainsResult {
    listAsbestosContainsResult {
      id
      content
    }
  }
`;

export const listAsbestosWorkKind = gql`
  query listAsbestosWorkKind {
    listAsbestosWorkKind {
      id
      content
    }
  }
`;

export const listCompanies = gql`
  query listCompanies {
    listCompanies {
      id
      name
      representativeName
      postNo
      addressPrefecture
      addressCity
      addressTown
      addressOther
      phone
      mailAddress
      laborInsuranceNoExistFlg
      laborInsuranceNoPrefecture
      laborInsuranceNoAdministration
      laborInsuranceNoJurisdiction
      laborInsuranceNoCoreNo
      laborInsuranceNoBranchNo
      isConstructionRegistApi
      isContentSurveyRegistApi
      isContentSurveyResultRegistApi
    }
  }
`;

export const searchConstructionOrders = gql`
  query searchConstructionOrders(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $filter: SearchableConstructionOrderFilterInputType
  ) {
    searchConstructionOrders(
      first: $first
      last: $last
      before: $before
      after: $after
      filter: $filter
    ) {
      items {
        id
        name
        summary
        constructionStartDate
        constructionEndDate
        postNo
        address
        constructionOrderStatusId
        constructionOrderStatus
        asbestosResearchStatusId
        asbestosResearchStatus
        asbestosReportStatusId
        asbestosReportStatus
        sanitationLawRequestExist
        preventionLawRequestExist
        surveyApplicationNo
        asbestosReportDate
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`;

export const getApplyCsvData = gql`
  query getApplyCsvData($id: [ID!]) {
    getApplyCsvData(id: $id) {
      csvData
    }
  }
`;

export const confirmCsvFile = gql`
  query confirmCsvFile(
    $fileName: String!
    $csvFileData: String!
  ) {
    confirmCsvFile(
      fileName: $fileName
      csvFileData: $csvFileData
    ) {
      applyCsvDataNormal {
        lineNo
        name
        constructionStartDate
        constructionEndDate
        workAddress
        surveyApplicationNo
        surveyApplicationDate
      }
      applyCsvDataError {
        lineNo
        name
        constructionStartDate
        constructionEndDate
        workAddress
        surveyApplicationNo
        surveyApplicationDate
      }
    }
  }
`;

export const searchReports = gql`
  query searchReports(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $filter: SearchableReportFilterInputType
  ) {
    searchReports(
      first: $first
      last: $last
      before: $before
      after: $after
      filter: $filter
    ) {
      items {
        id
        name
        constructionStartDate
        constructionEndDate
        workPostNo
        workAddress
        surveyApplicationNo
        asbestosReportDate
        constructionOrderStatusId
        constructionOrderStatus
        asbestosResearchStatusId
        asbestosResearchStatus
        asbestosReportStatusId
        asbestosReportStatus
        setJurisdictionApply
        sanitationLawRequestExist
        preventionLawRequestExist
        isOutputApplyCsvFile
        outputApplyCsvFile
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`;

export const getUniqueInformationLabel = gql`
  query getUniqueInformationLabel {
    getUniqueInformationLabel {
      labelName1
      labelName2
      labelName3
      labelName4
      labelName5
    }
  }
`;

export const getUserInfo = gql`
  query getUserInfo {
    getUserInfo {
      id
      roleName
    }
  }
`;

export const getApplyCsvData2 = gql`
  query getApplyCsvData2($id: [ID!]) {
    getApplyCsvData2(id: $id) {
      csvData
    }
  }
`;
