import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Box, IconButton, Stack, Typography, Button } from "@mui/material";
import React from "react";
import { FileUploader, Gallery, InputField } from "views/molecules";
import { InvestigationDetailForm } from "../InvestigationDetailForm";
import { InvestigationDetailView } from "../InvestigationDetailForm/View";

/**
 * 事前調査フォームの調査詳細を入力するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <>
      <Box
        border="solid #E5E5E5 1px"
        borderRadius={"5px"}
        padding="6px"
        marginBottom="24px"
      >
        <Box flexDirection={"row"} display="flex" justifyContent={"end"}>
          <IconButton
            aria-label="delete"
            onClick={props.onRemove}
            disabled={props.disabled}
          >
            <HighlightOffIcon fontSize="inherit" />
          </IconButton>
        </Box>
        <Stack spacing={3}>
          <InputField
            onChange={props.onChangeDetails}
            rows={4}
            multiline={true}
            label={"内容"}
            value={props.value?.content ?? ""}
            error={Boolean(props?.errors?.[`content`]?.message)}
            helperText={props?.errors?.[`content`]?.message}
            disabled={props.disabled}
          />
          <Stack spacing={1}>
            <Typography
              sx={{
                fontSize: "12px",
              }}
            >
              <span
                style={{
                  color: "grey",
                }}
              >
                {"調査"}
              </span>
            </Typography>
            <Stack spacing={2} direction="row">
              <Button
                variant="outlined"
                color="success"
                onClick={props.toggleInvestigateForm(true)}
              >
                {props.value?.investigate ? "再設定する" : "設定する"}
              </Button>
              {props.value?.investigate && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={props.toggleInvestigateView(true)}
                >
                  {"設定情報を見る"}
                </Button>
              )}
              {props.value?.investigate && (
                <Button
                  variant="outlined"
                  color="error"
                  onClick={props.onDeleteInvestigate}
                >
                  {"削除する"}
                </Button>
              )}
            </Stack>
          </Stack>
          <Typography
            sx={{
              fontSize: "12px",
            }}
          >
            <span
              style={{
                color: "grey",
              }}
            >
              {"ファイル"}
            </span>
          </Typography>
          <FileUploader
            onChange={props.onFileUpload}
            disabled={props.disabled}
            accept={[
              "image/png",
              "image/jpeg",
              "image/gif",
              "application/pdf",
              "application/vnd.ms-excel",
              "application/vnd.ms-excel.sheet.macroEnabled.12",
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              "application/vnd.ms-powerpoint",
              "application/vnd.ms-powerpoint.presentation.macroEnabled.12",
              "application/vnd.openxmlformats-officedocument.presentationml.presentation",
              "application/msword",
              "application/vnd.ms-word.document.macroEnabled.12",
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            ]}
          />
          <Gallery
            value={props.value?.files}
            onChange={props.onChangeFiles}
            disabled={props.disabled}
          />
        </Stack>
        <InvestigationDetailForm
          open={props.open}
          onClose={props.toggleInvestigateForm(false)}
          value={props.value?.investigate}
          onSave={props.onChangeInvestigate}
        />
      </Box>
      <InvestigationDetailView
        value={props.value?.investigate}
        open={props.openView}
        onClose={props.toggleInvestigateView(false)}
      />
    </>
  );
};
