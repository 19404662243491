import { getAddress } from "utils/Functions";

/**
 * 住所を入力するコンテナコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element} JSX.Element
 */
export const Container = ({
  render,
  name = "",
  value,
  onChange,
  error,
  props,
}) => {
  const handleChangePostalCodeFront = (n) => (d) => {
    let result = `${d}${value?.[`${name}PostNoBack`] ?? ""}`;
    if (result.length === 7) {
      getAddress(result).then((address) => {
        const { pref = null, city = null, town = null } = address;

        onChange({
          ...value,
          [`${name}AddressPrefecture`]: pref,
          [`${name}AddressCity`]: city,
          [`${name}AddressTown`]: town,
          [n]: d,
        });
      });
    } else {
      onChange({
        ...value,
        [n]: d,
      });
    }
  };

  const handleChangePostalCodeBack = (n) => (d) => {
    let result = `${value?.[`${name}PostNoFront`] ?? ""}${d}`;
    if (result.length === 7) {
      getAddress(result).then((address) => {
        const { pref = null, city = null, town = null } = address;

        onChange({
          ...value,
          [`${name}AddressPrefecture`]: pref,
          [`${name}AddressCity`]: city,
          [`${name}AddressTown`]: town,
          [n]: d,
        });
      });
    } else {
      onChange({
        ...value,
        [n]: d,
      });
    }
  };

  const handleChange = (name) => (data) => {
    onChange({
      ...value,
      [name]: data,
    });
  };

  return render({
    ...props,
    name: name,
    value: value,
    onChange: handleChange,
    onChangePostalCodeFront: handleChangePostalCodeFront,
    onChangePostalCodeBack: handleChangePostalCodeBack,
    error: error,
  });
};
