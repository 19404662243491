import { createContext, useCallback, useRef } from "react";
import { useWindowDimensions } from "utils/Hooks";

/**
 * Itemsのコンテキストです。
 */
export const ItemsContext = createContext({});

/**
 * 報告のアイテムを複数表示するコンテナコンポーネントです。
 */
export const Container = ({
  render,
  value = [],
  selectedValue = [],
  onApply = (data) => {},
  onReport = (data) => {},
  onCheckedChange = (data) => {},
  loading = false,
  disabled = false,
  ...props
}) => {
  const rootRef = useRef();
  const sizeMap = useRef({});
  const setSize = useCallback((index, size) => {
    sizeMap.current = { ...sizeMap.current, [index]: size };
  }, []);
  const getSize = useCallback((index) => sizeMap.current[index] || 294, []);
  const windowSize = useWindowDimensions();

  const handleApply = (index) => (data) => {
    onApply({
      index: index,
      value: data,
    });
  };

  const handleReport = (index) => (data) => {
    onReport({
      index: index,
      value: data,
    });
  };

  const handleCheckedChange = (index) => (data) => {
    onCheckedChange({
      index: index,
      ...data,
    });
  };

  const handleExpandMore = (index) => () => {
    rootRef.current.resetAfterIndex(index);
  };

  return render({
    ...props,
    value: value,
    selectedValue: selectedValue,
    onApply: handleApply,
    onReport: handleReport,
    onCheckedChange: handleCheckedChange,
    onExpandMore: handleExpandMore,
    loading: loading,
    disabled: disabled,
    windowSize: windowSize,
    setSize: setSize,
    getSize: getSize,
    rootRef: rootRef,
  });
};
