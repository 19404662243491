import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * loadingの時に表示するコンポーネントです。
 * @param {Number} number Skeletonの数字
 * @returns {JSX.Element}
 */
export const SkeletonProduct = containerPresententer(Container, Presententer);
