import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * フルスクリーンダイアログを表示するコンポーネントです
 */
export const FullScreenDialog = containerPresententer(
  Container,
  Presententer
);
