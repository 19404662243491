import { useQuery } from "@apollo/client";
import { getUserInfo } from "api/graphql/queries";
import { useState } from "react";
import { useNavigate } from "react-router";

/**
 * フッターを表示するプレゼンテーションコンポーネントです。
 */
export const Container = ({
  render,
  children,
  ...props
}) => {
  const [roleName, setRoleName] = useState(null);
  useQuery(getUserInfo, {
    onCompleted: (data) => {
      setRoleName(data.getUserInfo.roleName);
    },
  });

  const navigate = useNavigate();
  const handleClickHome = (e) => {
    e.preventDefault();
    navigate("/");
  };

  const handleClickSearch = (e) => {
    e.preventDefault();
    navigate("/search");
  };

  const handleClickConstruction = (e) => {
    e.preventDefault();
    navigate("/constructions");
  };
  const handleClickSetting = (e) => {
    e.preventDefault();
    navigate("/company");
  };

  const handleClickReport = (e) => {
    e.preventDefault();
    navigate("/report");
  };

  return render({
    ...props,
    onClickHome: handleClickHome,
    onClickSearch: handleClickSearch,
    onClickConstruction: handleClickConstruction,
    onClickSetting: handleClickSetting,
    onClickReport: handleClickReport,
    children: children,
    roleName: roleName,
  });
};
