import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";
import React from "react";

/**
 * チェックボックスを表示するプレゼンテーションコンポーネントです。
 */
export const Presententer = (props) => {
  return (
    <FormControl>
      <FormControlLabel
        label={props?.label}
        checked={props?.value}
        disabled={props.disabled}
        onChange={props.onChange}
        control={<Checkbox name={`${props.name}`} />}
      />
      <FormHelperText error={props.error}>{props.helperText}</FormHelperText>
    </FormControl>
  );
};
