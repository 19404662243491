// ==========================================
//  定数
// ==========================================

/**
 * その他工作物を表します。({ id, value, label })
 */
export const OtherWorks = [
  {
    id: "isReactionTank",
    value: "1",
    label: "反応槽",
  },
  {
    id: "isHeatingFurnace",
    value: "2",
    label: "加熱炉",
  },
  {
    id: "isBoilerAndPressureVessel",
    value: "3",
    label: "ボイラー及び圧力容器",
  },
  {
    id: "isPlumbingEquipment",
    value: "4",
    label: "配管設備（給水、排水、換気、暖房、冷房、排煙等の建築設備を除く。）",
  },
  {
    id: "isIncinerator",
    value: "5",
    label: "焼却設備",
  },
  {
    id: "isChimney",
    value: "6",
    label: "煙突（建築物に設ける排煙設備等の建築物を除く。）",
  },
  {
    id: "isStorageEquipment",
    value: "7",
    label: "貯蔵設備（穀物を貯蔵するための設備を除く。）",
  },
  {
    id: "isPowerGenerationEquipment",
    value: "8",
    label: "発電設備（太陽光発電設備及び風力発電設備を除く。）",
  },
  {
    id: "isSubstationEquipment",
    value: "9",
    label: "変電設備",
  },
  {
    id: "isPowerDistributionEquipment",
    value: "10",
    label: "配電設備",
  },
  {
    id: "isPowerTransmissionEquipment",
    value: "11",
    label: "送電設備（ケーブルを含む。）",
  },
  {
    id: "isTunnelCeilingBoard",
    value: "12",
    label: "トンネルの天井板",
  },
  {
    id: "isPlatformHouse",
    value: "13",
    label: "プラットフォームの上家",
  },
  {
    id: "isSoundInsulationWall",
    value: "14",
    label: "遮音壁",
  },
  {
    id: "isLightweightFillProtectionPanel",
    value: "15",
    label: "軽量盛土保護パネル",
  },
  {
    id: "isRailwayStationWall",
    value: "16",
    label: "鉄道の駅の地下式構造部分の壁及び天井板",
  },
  {
    id: "isTouristElevatorHoistwayEnclosure",
    value: "18",
    label: "観光用エレベーターの昇降路の囲い（建築物であるものを除く。）",
  },
  {
    id: "isShip",
    value: "17",
    label: "船舶",
  },
];

/**
 * 都道府県を表します。({ code, name })
 */
export const OptionPrefectures = [
  { code: 1, name: "北海道" },
  { code: 2, name: "青森県" },
  { code: 3, name: "岩手県" },
  { code: 4, name: "宮城県" },
  { code: 5, name: "秋田県" },
  { code: 6, name: "山形県" },
  { code: 7, name: "福島県" },
  { code: 8, name: "茨城県" },
  { code: 9, name: "栃木県" },
  { code: 10, name: "群馬県" },
  { code: 11, name: "埼玉県" },
  { code: 12, name: "千葉県" },
  { code: 13, name: "東京都" },
  { code: 14, name: "神奈川県" },
  { code: 15, name: "新潟県" },
  { code: 16, name: "富山県" },
  { code: 17, name: "石川県" },
  { code: 18, name: "福井県" },
  { code: 19, name: "山梨県" },
  { code: 20, name: "長野県" },
  { code: 21, name: "岐阜県" },
  { code: 22, name: "静岡県" },
  { code: 23, name: "愛知県" },
  { code: 24, name: "三重県" },
  { code: 25, name: "滋賀県" },
  { code: 26, name: "京都府" },
  { code: 27, name: "大阪府" },
  { code: 28, name: "兵庫県" },
  { code: 29, name: "奈良県" },
  { code: 30, name: "和歌山県" },
  { code: 31, name: "鳥取県" },
  { code: 32, name: "島根県" },
  { code: 33, name: "岡山県" },
  { code: 34, name: "広島県" },
  { code: 35, name: "山口県" },
  { code: 36, name: "徳島県" },
  { code: 37, name: "香川県" },
  { code: 38, name: "愛媛県" },
  { code: 39, name: "高知県" },
  { code: 40, name: "福岡県" },
  { code: 41, name: "佐賀県" },
  { code: 42, name: "長崎県" },
  { code: 43, name: "熊本県" },
  { code: 44, name: "大分県" },
  { code: 45, name: "宮崎県" },
  { code: 46, name: "鹿児島県" },
  { code: 47, name: "沖縄県" },
];

/**
 * 年を表します。({ id, name })
 */
export const OptionYears = [
  { id: "1950", name: "1950年（昭和25年）" },
  { id: "1951", name: "1951年（昭和26年）" },
  { id: "1952", name: "1952年（昭和27年）" },
  { id: "1953", name: "1953年（昭和28年）" },
  { id: "1954", name: "1954年（昭和29年）" },
  { id: "1955", name: "1955年（昭和30年）" },
  { id: "1956", name: "1956年（昭和31年）" },
  { id: "1957", name: "1957年（昭和32年）" },
  { id: "1958", name: "1958年（昭和33年）" },
  { id: "1959", name: "1959年（昭和34年）" },
  { id: "1960", name: "1960年（昭和35年）" },
  { id: "1961", name: "1961年（昭和36年）" },
  { id: "1962", name: "1962年（昭和37年）" },
  { id: "1963", name: "1963年（昭和38年）" },
  { id: "1964", name: "1964年（昭和39年）" },
  { id: "1965", name: "1965年（昭和40年）" },
  { id: "1966", name: "1966年（昭和41年）" },
  { id: "1967", name: "1967年（昭和42年）" },
  { id: "1968", name: "1968年（昭和43年）" },
  { id: "1969", name: "1969年（昭和44年）" },
  { id: "1970", name: "1970年（昭和45年）" },
  { id: "1971", name: "1971年（昭和46年）" },
  { id: "1972", name: "1972年（昭和47年）" },
  { id: "1973", name: "1973年（昭和48年）" },
  { id: "1974", name: "1974年（昭和49年）" },
  { id: "1975", name: "1975年（昭和50年）" },
  { id: "1976", name: "1976年（昭和51年）" },
  { id: "1977", name: "1977年（昭和52年）" },
  { id: "1978", name: "1978年（昭和53年）" },
  { id: "1979", name: "1979年（昭和54年）" },
  { id: "1980", name: "1980年（昭和55年）" },
  { id: "1981", name: "1981年（昭和56年）" },
  { id: "1982", name: "1982年（昭和57年）" },
  { id: "1983", name: "1983年（昭和58年）" },
  { id: "1984", name: "1984年（昭和59年）" },
  { id: "1985", name: "1985年（昭和60年）" },
  { id: "1986", name: "1986年（昭和61年）" },
  { id: "1987", name: "1987年（昭和62年）" },
  { id: "1988", name: "1988年（昭和63年）" },
  { id: "1989", name: "1989年（昭和64年・平成元年）" },
  { id: "1990", name: "1990年（平成2年）" },
  { id: "1991", name: "1991年（平成3年）" },
  { id: "1992", name: "1992年（平成4年）" },
  { id: "1993", name: "1993年（平成5年）" },
  { id: "1994", name: "1994年（平成6年）" },
  { id: "1995", name: "1995年（平成7年）" },
  { id: "1996", name: "1996年（平成8年）" },
  { id: "1997", name: "1997年（平成9年）" },
  { id: "1998", name: "1998年（平成10年）" },
  { id: "1999", name: "1999年（平成11年）" },
  { id: "2000", name: "2000年（平成12年）" },
  { id: "2001", name: "2001年（平成13年）" },
  { id: "2002", name: "2002年（平成14年）" },
  { id: "2003", name: "2003年（平成15年）" },
  { id: "2004", name: "2004年（平成16年）" },
  { id: "2005", name: "2005年（平成17年）" },
  { id: "2006", name: "2006年（平成18年）" },
  { id: "2007", name: "2007年（平成19年）" },
  { id: "2008", name: "2008年（平成20年）" },
  { id: "2009", name: "2009年（平成21年）" },
  { id: "2010", name: "2010年（平成22年）" },
  { id: "2011", name: "2011年（平成23年）" },
  { id: "2012", name: "2012年（平成24年）" },
  { id: "2013", name: "2013年（平成25年）" },
  { id: "2014", name: "2014年（平成26年）" },
  { id: "2015", name: "2015年（平成27年）" },
  { id: "2016", name: "2016年（平成28年）" },
  { id: "2017", name: "2017年（平成29年）" },
  { id: "2018", name: "2018年（平成30年）" },
  { id: "2019", name: "2019年（平成31年・令和元年）" },
  { id: "2020", name: "2020年（令和2年）" },
  { id: "2021", name: "2021年（令和3年）" },
  { id: "2022", name: "2022年（令和4年）" },
  { id: "2023", name: "2023年（令和5年）" },
  { id: "2024", name: "2024年（令和6年）" },
  { id: "2025", name: "2025年（令和7年）" },
  { id: "2026", name: "2026年（令和8年）" },
  { id: "2027", name: "2027年（令和9年）" },
  { id: "2028", name: "2028年（令和10年）" },
];

/**
 * アスベスト報告種類を表します。
 */
export const AsbestosReportType = {
  Unreported: {
    label: "未報告",
    value: 1,
  },
  Reported: {
    label: "報告済",
    value: 2,
  },
  ReReportingRequired: {
    label: "要再報告",
    value: 3,
  },
  NoReportRequired: {
    label: "報告不要",
    value: 4,
  },
};

/**
 * アスベスト事前調査状態を表します。
 */
export const AsbestosResearchStatusType = {
  Creating: {
    label: "作成中",
    value: 1,
  },
  SurveyCompleted: {
    label: "調査完了",
    value: 2,
  },
  NoInvestigationRequired: {
    label: "調査不要",
    value: 3,
  },
};

/**
 * 工事案件受注状態を表します。
 */
export const ConstructionOrderStatusType = {
  Negotiating: {
    label: "交渉中",
    value: 1,
  },
  Ordered: {
    label: "受注済",
    value: 2,
  },
  Lost: {
    label: "失注",
    value: 3,
  },
};
