import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 申請を入力するコンポーネントです。
 * @param {object} value 値
 * @fires Form#onChange 変更時
 * @param {object} errors エラーオブジェクト
 */
export const Form = containerPresententer(Container, Presententer);

/**
 * default values
 */
export const defaultValues = {
  sanitationLawRequestExist: "0",
  laborBureau: null,
  laborInspectionOffice: null,
  preventionLawRequestExist: "0",
  requestPrefecture: null,
  requestMunicipality: null,
  requestMunicipalityDept: null,
  freeEntryField: "",
};

/**
 * form value to api value
 */
export const invert = (value) => {
  if (!value) {
    return null;
  }

  const {
    constructionId,
    sanitationLawRequestExist,
    preventionLawRequestExist,
    laborBureau,
    laborInspectionOffice,
    requestPrefecture,
    requestMunicipality,
    requestMunicipalityDept,
    freeEntryField,
  } = value;

  const convertCheck = (v) => {
    if (typeof v === "boolean") {
      return v === true ? "1" : "0";
    } else {
      return v;
    }
  };

  return {
    id: constructionId,
    sanitationLawRequestExist: convertCheck(sanitationLawRequestExist),
    preventionLawRequestExist: convertCheck(preventionLawRequestExist),
    laborBureau: laborBureau?.bureauCode,
    laborInspectionOffice: laborInspectionOffice?.inspectionCode,
    requestPrefecture: requestPrefecture?.prefectureCode,
    requestMunicipality: requestMunicipality?.municipalityCode,
    requestMunicipalityDept: requestMunicipalityDept?.deptCode,
    freeEntryField: freeEntryField,
  };
};

/**
 * api value to form value
 */
export const convert = (value) => {
  if (!value) {
    return null;
  }

  const {
    constructionId,
    sanitationLawRequestExist,
    preventionLawRequestExist,
    freeEntryField,
    laborBureau,
    laborInspectionOffice,
    requestPrefecture,
    requestMunicipality,
    requestMunicipalityDept,
  } = value;

  return {
    constructionId: constructionId,
    sanitationLawRequestExist: sanitationLawRequestExist,
    preventionLawRequestExist: preventionLawRequestExist,
    freeEntryField: freeEntryField,
    laborBureau: laborBureau,
    laborInspectionOffice: laborInspectionOffice,
    requestPrefecture: requestPrefecture,
    requestMunicipality: requestMunicipality,
    requestMunicipalityDept: requestMunicipalityDept,
  };
};
