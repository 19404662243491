/**
 * ラジオボタングループを表示するコンテナコンポーネントです。
 * @param {object} render 引数を受けて、JSX.Elementを返すメソッド
 */
export const Container = ({
  render,
  label,
  value = "",
  onChange = () => {},
  defaultValue,
  name = "radioGroup",
  options = [],
  row = false,
  twiceRemovable = false,
  required = false,
  disabled = false,
  error = false,
  helperText = "",
  ...props
}) => {
  const handleChange = (e) => {
    onChange({
      name: name,
      value: e.target.value,
    });
  };

  const handleClickRadio = (optionValue) => () => {
    if (twiceRemovable && optionValue === value) {
      onChange({
        name: name,
        value: null,
      });
    }
  };

  return render({
    ...props,
    value: value,
    onChange: handleChange,
    onClickRadio: handleClickRadio,
    label: label,
    defaultValue: defaultValue,
    name: name,
    options: options,
    row: row,
    required: required,
    disabled: disabled,
    error: error,
    helperText: helperText,
  });
};
