import { Grid, Typography, Button, Stack, Alert } from "@mui/material";
import React from "react";
import { ListProduct } from "./Child";

/**
 * ARS-検索の結果ページを表示コンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <Grid container spacing={3}>
    <Grid item xs={12}>
      <Stack
        display={"flex"}
        flexDirection="row"
        justifyContent="space-between"
      >
        <div>
          <Typography>{`検索結果：${props.values?.length ?? 0}件`}</Typography>
        </div>
        <div>
          <Button
            variant="outlined"
            color="inherit"
            onClick={props.onBack}
            fullWidth={false}
          >
            {"戻る"}
          </Button>
        </div>
      </Stack>
    </Grid>
    {props?.values?.map?.((value, index) => (
      <ListProduct
        key={value.id}
        warning={value.asbestosLevel < 3}
        value={value}
        onClick={() => props.onClick(value)}
      />
    ))}
    {(props?.values?.length ?? 0) <= 0 && (
      <Alert severity="error">
        検索条件に該当する石綿(アスベスト)含有建材および製品はデータベース中にはありません
      </Alert>
    )}
  </Grid>
);
