import { gql } from "@apollo/client";

export const createCompany = gql`
  mutation createCompany($input: CreateCompanyInput!) {
    createCompany(input: $input) {
      id
      name
      representativeName
      postNo
      addressPrefectureAndCity
      addressTownAndOther
      phone
      mailAddress
      isExistLaborInsuranceNo
      laborInsuranceNo
      isConstructionRegistApi
      isContentSurveyRegistApi
      isContentSurveyResultRegistApi
    }
  }
`;

export const createConstruction = gql`
  mutation createConstruction(
    $constructionOrderStatus: Int,
    $uniqueInformation1: String,
    $uniqueInformation2: String,
    $uniqueInformation3: String,
    $uniqueInformation4: String,
    $uniqueInformation5: String,
    $necessityAsbestosResearch: String,
    $primeContractorName: String,
    $primeContractorRepresentativeName: String,
    $primeContractorPostNoFront: String,
    $primeContractorPostNoBack: String,
    $primeContractorAddressPrefecture: String,
    $primeContractorAddressCity: String,
    $primeContractorAddressTown: String,
    $primeContractorAddressOther: String,
    $primeContractorPhone: String,
    $primeContractorMailAddress: String
    $ordererName: String,
    $ordererRepresentativeName: String,
    $ordererPostNoFront: String,
    $ordererPostNoBack: String,
    $ordererAddressPrefecture: String,
    $ordererAddressCity: String,
    $ordererAddressTown: String,
    $ordererAddressOther: String,
    $laborInsuranceNoExist: String,
    $laborInsuranceNoPrefecture: String,
    $laborInsuranceNoAdministration: String,
    $laborInsuranceNoJurisdiction: String,
    $laborInsuranceNoCoreNo: String,
    $laborInsuranceNoBranchNo: String,
    $workPostNoFront: String,
    $workPostNoBack: String,
    $workAddressPrefecture: String,
    $workAddressCity: String,
    $workAddressTown: String,
    $workAddressOther: String,
    $name: String,
    $summary: String,
    $buildDateExist: String,
    $buildDate: Date,
    $buildingStructure: String,
    $buildingFireproof: String,
    $buildingFloorSpace: Int
    $groundFloors: Int,
    $basementFloors: Int,
    $constructionFloorSpace: Int,
    $constructionStartDate: Date,
    $constructionEndDate: Date,
    $amountBillion: Int,
    $amountTenThousand: Int,
    $freeEntryField: String,
    $constructionRemarks: String,
    $otherWorkInput: OtherWorkInputType,
    $registManagerInputs: [RegistManagerInputType!],
    $salesManagerInputs: [SalesManagerInputType!],
    $researchManagerInputs: [ResearchManagerInputType!],
  ) {
    createConstruction(
      constructionOrderStatus: $constructionOrderStatus,
      uniqueInformation1: $uniqueInformation1,
      uniqueInformation2: $uniqueInformation2,
      uniqueInformation3: $uniqueInformation3,
      uniqueInformation4: $uniqueInformation4,
      uniqueInformation5: $uniqueInformation5,
      necessityAsbestosResearch: $necessityAsbestosResearch,
      primeContractorName: $primeContractorName,
      primeContractorRepresentativeName: $primeContractorRepresentativeName,
      primeContractorPostNoFront: $primeContractorPostNoFront,
      primeContractorPostNoBack: $primeContractorPostNoBack,
      primeContractorAddressPrefecture: $primeContractorAddressPrefecture,
      primeContractorAddressCity: $primeContractorAddressCity,
      primeContractorAddressTown: $primeContractorAddressTown,
      primeContractorAddressOther: $primeContractorAddressOther,
      primeContractorPhone: $primeContractorPhone,
      primeContractorMailAddress: $primeContractorMailAddress
      ordererName: $ordererName,
      ordererRepresentativeName: $ordererRepresentativeName,
      ordererPostNoFront: $ordererPostNoFront,
      ordererPostNoBack: $ordererPostNoBack,
      ordererAddressPrefecture: $ordererAddressPrefecture,
      ordererAddressCity: $ordererAddressCity,
      ordererAddressTown: $ordererAddressTown,
      ordererAddressOther: $ordererAddressOther,
      laborInsuranceNoExist: $laborInsuranceNoExist,
      laborInsuranceNoPrefecture: $laborInsuranceNoPrefecture,
      laborInsuranceNoAdministration: $laborInsuranceNoAdministration,
      laborInsuranceNoJurisdiction: $laborInsuranceNoJurisdiction,
      laborInsuranceNoCoreNo: $laborInsuranceNoCoreNo,
      laborInsuranceNoBranchNo: $laborInsuranceNoBranchNo,
      workPostNoFront: $workPostNoFront,
      workPostNoBack: $workPostNoBack,
      workAddressPrefecture: $workAddressPrefecture,
      workAddressCity: $workAddressCity,
      workAddressTown: $workAddressTown,
      workAddressOther: $workAddressOther,
      name: $name,
      summary: $summary,
      buildDateExist: $buildDateExist,
      buildDate: $buildDate,
      buildingStructure: $buildingStructure,
      buildingFireproof: $buildingFireproof,
      buildingFloorSpace: $buildingFloorSpace,
      groundFloors: $groundFloors,
      basementFloors: $basementFloors,
      constructionFloorSpace: $constructionFloorSpace,
      constructionStartDate: $constructionStartDate,
      constructionEndDate: $constructionEndDate,
      amountBillion: $amountBillion,
      amountTenThousand: $amountTenThousand,
      freeEntryField: $freeEntryField,
      constructionRemarks: $constructionRemarks,
      otherWorkInput: $otherWorkInput,
      registManagerInputs: $registManagerInputs,
      salesManagerInputs: $salesManagerInputs,
      researchManagerInputs: $researchManagerInputs,
    ) {
      result
      message
      id
      constructionOrderStatus
      uniqueInformation1
      uniqueInformation2
      uniqueInformation3
      uniqueInformation4
      uniqueInformation5
      necessityAsbestosResearch
      primeContractorName
      primeContractorRepresentativeName
      primeContractorPostNoFront
      primeContractorPostNoBack
      primeContractorAddressPrefecture
      primeContractorAddressCity
      primeContractorAddressTown
      primeContractorAddressOther
      primeContractorPhone
      primeContractorMailAddress
      ordererName
      ordererRepresentativeName
      ordererPostNoFront
      ordererPostNoBack
      ordererAddressPrefecture
      ordererAddressCity
      ordererAddressTown
      ordererAddressOther
      laborInsuranceNoExist
      laborInsuranceNoPrefecture
      laborInsuranceNoAdministration
      laborInsuranceNoJurisdiction
      laborInsuranceNoCoreNo
      laborInsuranceNoBranchNo
      workPostNoFront
      workPostNoBack
      workAddressPrefecture
      workAddressCity
      workAddressTown
      workAddressOther
      name
      summary
      buildDateExist
      buildDate
      buildingStructure
      buildingFireproof
      buildingFloorSpace
      groundFloors
      basementFloors
      constructionFloorSpace
      constructionStartDate
      constructionEndDate
      amountBillion
      amountTenThousand
      freeEntryField
      constructionRemarks
      surveyApplicationNo
      createAt
      updateAt
      constructionRegistManagers {
        id
        name
        phone
        mailAddress
        mainPosition
        subPosition
      }
      constructionSalesManagers {
        id
        name
        phone
        mailAddress
        mainPosition
        subPosition
      }
      constructionResearchManagers {
        id
        name
        phone
        mailAddress
        mainPosition
        subPosition
      }
      otherWorkResult {
        isReactionTank
        isHeatingFurnace
        isBoilerAndPressureVessel
        isPlumbingEquipment
        isIncinerator
        isChimney
        isStorageEquipment
        isPowerGenerationEquipment
        isSubstationEquipment
        isPowerDistributionEquipment
        isPowerTransmissionEquipment
        isTunnelCeilingBoard
        isPlatformHouse
        isSoundInsulationWall
        isLightweightFillProtectionPanel
        isRailwayStationsWall
        isTouristElevatorHoistwayEnclosure
        isShip
      }
    }
  }
`;

export const updateConstruction = gql`
  mutation updateConstruction(
    $id: ID!,
    $constructionOrderStatus: Int,
    $uniqueInformation1: String,
    $uniqueInformation2: String,
    $uniqueInformation3: String,
    $uniqueInformation4: String,
    $uniqueInformation5: String,
    $necessityAsbestosResearch: String,
    $primeContractorName: String,
    $primeContractorRepresentativeName: String,
    $primeContractorPostNoFront: String,
    $primeContractorPostNoBack: String,
    $primeContractorAddressPrefecture: String,
    $primeContractorAddressCity: String,
    $primeContractorAddressTown: String,
    $primeContractorAddressOther: String,
    $primeContractorPhone: String,
    $primeContractorMailAddress: String
    $ordererName: String,
    $ordererRepresentativeName: String,
    $ordererPostNoFront: String,
    $ordererPostNoBack: String,
    $ordererAddressPrefecture: String,
    $ordererAddressCity: String,
    $ordererAddressTown: String,
    $ordererAddressOther: String,
    $laborInsuranceNoExist: String,
    $laborInsuranceNoPrefecture: String,
    $laborInsuranceNoAdministration: String,
    $laborInsuranceNoJurisdiction: String,
    $laborInsuranceNoCoreNo: String,
    $laborInsuranceNoBranchNo: String,
    $workPostNoFront: String,
    $workPostNoBack: String,
    $workAddressPrefecture: String,
    $workAddressCity: String,
    $workAddressTown: String,
    $workAddressOther: String,
    $name: String,
    $summary: String,
    $buildDateExist: String,
    $buildDate: Date,
    $buildingStructure: String,
    $buildingFireproof: String,
    $buildingFloorSpace: Int
    $groundFloors: Int,
    $basementFloors: Int,
    $constructionFloorSpace: Int,
    $constructionStartDate: Date,
    $constructionEndDate: Date,
    $amountBillion: Int,
    $amountTenThousand: Int,
    $freeEntryField: String,
    $constructionRemarks: String,
    $otherWorkInput: OtherWorkInputType,
    $registManagerInputs: [RegistManagerInputType!],
    $salesManagerInputs: [SalesManagerInputType!],
    $researchManagerInputs: [ResearchManagerInputType!],
  ) {
    updateConstruction(
      id: $id,
      constructionOrderStatus: $constructionOrderStatus,
      uniqueInformation1: $uniqueInformation1,
      uniqueInformation2: $uniqueInformation2,
      uniqueInformation3: $uniqueInformation3,
      uniqueInformation4: $uniqueInformation4,
      uniqueInformation5: $uniqueInformation5,
      necessityAsbestosResearch: $necessityAsbestosResearch,
      primeContractorName: $primeContractorName,
      primeContractorRepresentativeName: $primeContractorRepresentativeName,
      primeContractorPostNoFront: $primeContractorPostNoFront,
      primeContractorPostNoBack: $primeContractorPostNoBack,
      primeContractorAddressPrefecture: $primeContractorAddressPrefecture,
      primeContractorAddressCity: $primeContractorAddressCity,
      primeContractorAddressTown: $primeContractorAddressTown,
      primeContractorAddressOther: $primeContractorAddressOther,
      primeContractorPhone: $primeContractorPhone,
      primeContractorMailAddress: $primeContractorMailAddress
      ordererName: $ordererName,
      ordererRepresentativeName: $ordererRepresentativeName,
      ordererPostNoFront: $ordererPostNoFront,
      ordererPostNoBack: $ordererPostNoBack,
      ordererAddressPrefecture: $ordererAddressPrefecture,
      ordererAddressCity: $ordererAddressCity,
      ordererAddressTown: $ordererAddressTown,
      ordererAddressOther: $ordererAddressOther,
      laborInsuranceNoExist: $laborInsuranceNoExist,
      laborInsuranceNoPrefecture: $laborInsuranceNoPrefecture,
      laborInsuranceNoAdministration: $laborInsuranceNoAdministration,
      laborInsuranceNoJurisdiction: $laborInsuranceNoJurisdiction,
      laborInsuranceNoCoreNo: $laborInsuranceNoCoreNo,
      laborInsuranceNoBranchNo: $laborInsuranceNoBranchNo,
      workPostNoFront: $workPostNoFront,
      workPostNoBack: $workPostNoBack,
      workAddressPrefecture: $workAddressPrefecture,
      workAddressCity: $workAddressCity,
      workAddressTown: $workAddressTown,
      workAddressOther: $workAddressOther,
      name: $name,
      summary: $summary,
      buildDateExist: $buildDateExist,
      buildDate: $buildDate,
      buildingStructure: $buildingStructure,
      buildingFireproof: $buildingFireproof,
      buildingFloorSpace: $buildingFloorSpace,
      groundFloors: $groundFloors,
      basementFloors: $basementFloors,
      constructionFloorSpace: $constructionFloorSpace,
      constructionStartDate: $constructionStartDate,
      constructionEndDate: $constructionEndDate,
      amountBillion: $amountBillion,
      amountTenThousand: $amountTenThousand,
      freeEntryField: $freeEntryField,
      constructionRemarks: $constructionRemarks,
      otherWorkInput: $otherWorkInput,
      registManagerInputs: $registManagerInputs,
      salesManagerInputs: $salesManagerInputs,
      researchManagerInputs: $researchManagerInputs,
    ) {
      result
      message
      id
      constructionOrderStatus
      uniqueInformation1
      uniqueInformation2
      uniqueInformation3
      uniqueInformation4
      uniqueInformation5
      necessityAsbestosResearch
      primeContractorName
      primeContractorRepresentativeName
      primeContractorPostNoFront
      primeContractorPostNoBack
      primeContractorAddressPrefecture
      primeContractorAddressCity
      primeContractorAddressTown
      primeContractorAddressOther
      primeContractorPhone
      primeContractorMailAddress
      ordererName
      ordererRepresentativeName
      ordererPostNoFront
      ordererPostNoBack
      ordererAddressPrefecture
      ordererAddressCity
      ordererAddressTown
      ordererAddressOther
      laborInsuranceNoExist
      laborInsuranceNoPrefecture
      laborInsuranceNoAdministration
      laborInsuranceNoJurisdiction
      laborInsuranceNoCoreNo
      laborInsuranceNoBranchNo
      workPostNoFront
      workPostNoBack
      workAddressPrefecture
      workAddressCity
      workAddressTown
      workAddressOther
      name
      summary
      buildDateExist
      buildDate
      buildingStructure
      buildingFireproof
      buildingFloorSpace
      groundFloors
      basementFloors
      constructionFloorSpace
      constructionStartDate
      constructionEndDate
      amountBillion
      amountTenThousand
      freeEntryField
      constructionRemarks
      surveyApplicationNo
      createAt
      updateAt
      constructionRegistManagers {
        id
        name
        phone
        mailAddress
        mainPosition
        subPosition
      }
      constructionSalesManagers {
        id
        name
        phone
        mailAddress
        mainPosition
        subPosition
      }
      constructionResearchManagers {
        id
        name
        phone
        mailAddress
        mainPosition
        subPosition
      }
      otherWorkResult {
        isReactionTank
        isHeatingFurnace
        isBoilerAndPressureVessel
        isPlumbingEquipment
        isIncinerator
        isChimney
        isStorageEquipment
        isPowerGenerationEquipment
        isSubstationEquipment
        isPowerDistributionEquipment
        isPowerTransmissionEquipment
        isTunnelCeilingBoard
        isPlatformHouse
        isSoundInsulationWall
        isLightweightFillProtectionPanel
        isRailwayStationsWall
        isTouristElevatorHoistwayEnclosure
        isShip
      }
    }
  }
`;

export const deleteConstruction = gql`
  mutation deleteConstruction($id: ID!) {
    deleteConstruction(id: $id) {
      result
      message
    }
  }
`;

export const createContractor = gql`
  mutation createContractor(
    $id: ID!,
    $contractorInputs: [ContractorInputType!],
  ) {
    createContractor(
      id: $id,
      contractorInputs: $contractorInputs,
    ) {
      result
      message
      id
      name
      laborInsuranceNoSamePrime
      laborInsuranceNoExist
      laborInsuranceNoPrefecture
      laborInsuranceNoAdministration
      laborInsuranceNoJurisdiction
      laborInsuranceNoCoreNo
      laborInsuranceNoBranchNo
      postNoFront
      postNoBack
      addressPrefecture
      addressCity
      addressTown
      addressOther
      phone
      preliminarySurveyorName
      preSurveyorTrainingInstitutionName
      analyticalSurveyorName
      anaSurveyorTrainingInstitutionName
      asbestosWorkChiefName
    }
  }
`;

export const deleteContractor = gql`
  mutation deleteContractor($id: ID!) {
    deleteContractor(id: $id) {
      result
      message
    }
  }
`;

export const createAsbestosResearch = gql`
  mutation createAsbestosResearch(
    $id: ID!,
    $asbestosResearchStatus: String,
    $asbestosWorkStartDate: Date,
    $preliminarySurveyEndDate: Date,
    $analysisSurveyLocation: String,
    $preliminarySurveyorName: String,
    $preSurveyorTrainingInstitutionName: String,
    $trainingRulesClassification: String,
    $analyticalSurveyorName: String,
    $anaSurveyorAffiliationName: String,
    $anaSurveyorTrainingInstitutionName: String,
    $asbestosWorkChiefName: String,
    $asbestosResearchDetailInputs: [AsbestosResearchDetailInputType!],
    $asbestosResearchRemarksInputs: [AsbestosResearchRemarksInputType!],
  ) {
    createAsbestosResearch(
      id: $id,
      asbestosResearchStatus: $asbestosResearchStatus,
      asbestosWorkStartDate: $asbestosWorkStartDate,
      preliminarySurveyEndDate: $preliminarySurveyEndDate,
      analysisSurveyLocation: $analysisSurveyLocation,
      preliminarySurveyorName: $preliminarySurveyorName,
      preSurveyorTrainingInstitutionName: $preSurveyorTrainingInstitutionName,
      trainingRulesClassification: $trainingRulesClassification,
      analyticalSurveyorName: $analyticalSurveyorName,
      anaSurveyorAffiliationName: $anaSurveyorAffiliationName,
      anaSurveyorTrainingInstitutionName: $anaSurveyorTrainingInstitutionName,
      asbestosWorkChiefName: $asbestosWorkChiefName,
      asbestosResearchDetailInputs: $asbestosResearchDetailInputs,
      asbestosResearchRemarksInputs: $asbestosResearchRemarksInputs,
    ) {
      result
      message
      id
      asbestosResearchStatus
      asbestosWorkStartDate
      preliminarySurveyEndDate
      analysisSurveyLocation
      preliminarySurveyorName
      preSurveyorTrainingInstitutionName
      trainingRulesClassification
      analyticalSurveyorName
      anaSurveyorAffiliationName
      anaSurveyorTrainingInstitutionName
      asbestosWorkChiefName
      createAt
      updateAt
      asbestosResearchDetailResults {
        asbestosTargetMaterial
        asbestosContainsResult
        judgedNoneVisually
        judgedNoneDesignDocuments
        judgedNoneAnalysis
        judgedNoneManufacturerCertification
        judgedNoneMadeDate
        asbestosWorkKind
        cuttingExist
        workStepNegativePressureIsolation
        workStepIsolation
        workStepWetting
        workStepUseProtectiveEquipment
      }
      asbestosResearchRemarksResults {
        remarks
        asbestosResearchFilesResults {
          fileName
          fileImage
        }
        researchRemarksSearchConditionResult {
          searchMode
          freeWord
          modelYear
          productFieldId
          makerId
          materialId
          usefulnessId
        }
        researchRemarksSearchItemResults {
          id
        }
        researchRemarksApplicableItemResults {
          id
        }
      }
    }
  }
`;

export const updateAsbestosResearch = gql`
  mutation updateAsbestosResearch(
    $id: ID!,
    $asbestosResearchStatus: String,
    $asbestosWorkStartDate: Date,
    $preliminarySurveyEndDate: Date,
    $analysisSurveyLocation: String,
    $preliminarySurveyorName: String,
    $preSurveyorTrainingInstitutionName: String,
    $trainingRulesClassification: String,
    $analyticalSurveyorName: String,
    $anaSurveyorAffiliationName: String,
    $anaSurveyorTrainingInstitutionName: String,
    $asbestosWorkChiefName: String,
    $asbestosResearchDetailInputs: [AsbestosResearchDetailInputType!],
    $asbestosResearchRemarksInputs: [AsbestosResearchRemarksInputType!],
  ) {
    updateAsbestosResearch(
      id: $id,
      asbestosResearchStatus: $asbestosResearchStatus,
      asbestosWorkStartDate: $asbestosWorkStartDate,
      preliminarySurveyEndDate: $preliminarySurveyEndDate,
      analysisSurveyLocation: $analysisSurveyLocation,
      preliminarySurveyorName: $preliminarySurveyorName,
      preSurveyorTrainingInstitutionName: $preSurveyorTrainingInstitutionName,
      trainingRulesClassification: $trainingRulesClassification,
      analyticalSurveyorName: $analyticalSurveyorName,
      anaSurveyorAffiliationName: $anaSurveyorAffiliationName,
      anaSurveyorTrainingInstitutionName: $anaSurveyorTrainingInstitutionName,
      asbestosWorkChiefName: $asbestosWorkChiefName,
      asbestosResearchDetailInputs: $asbestosResearchDetailInputs,
      asbestosResearchRemarksInputs: $asbestosResearchRemarksInputs,
    ) {
      result
      message
      id
      asbestosResearchStatus
      asbestosWorkStartDate
      preliminarySurveyEndDate
      analysisSurveyLocation
      preliminarySurveyorName
      preSurveyorTrainingInstitutionName
      trainingRulesClassification
      analyticalSurveyorName
      anaSurveyorAffiliationName
      anaSurveyorTrainingInstitutionName
      asbestosWorkChiefName
      createAt
      updateAt
      asbestosResearchDetailResults {
        asbestosTargetMaterial
        asbestosContainsResult
        judgedNoneVisually
        judgedNoneDesignDocuments
        judgedNoneAnalysis
        judgedNoneManufacturerCertification
        judgedNoneMadeDate
        asbestosWorkKind
        cuttingExist
        workStepNegativePressureIsolation
        workStepIsolation
        workStepWetting
        workStepUseProtectiveEquipment
      }
      asbestosResearchRemarksResults {
        remarks
        asbestosResearchFilesResults {
          fileName
          fileImage
        }
        researchRemarksSearchConditionResult {
          searchMode
          freeWord
          modelYear
          productFieldId
          makerId
          materialId
          usefulnessId
        }
        researchRemarksSearchItemResults {
          id
        }
        researchRemarksApplicableItemResults {
          id
        }
      }
    }
  }
`;

export const deleteAsbestosResearch = gql`
  mutation deleteAsbestosResearch($id: ID!) {
    deleteAsbestosResearch(id: $id) {
      result
      message
    }
  }
`;

export const createAsbestosReport = gql`
  mutation createAsbestosReport(
    $id: ID!,
    $sanitationLawRequestExist: String,
    $preventionLawRequestExist: String,
    $laborBureau: String,
    $laborInspectionOffice: String,
    $requestPrefecture: String,
    $requestMunicipality: String,
    $requestMunicipalityDept: String,
  ) {
    createAsbestosReport(
      id: $id,
      sanitationLawRequestExist: $sanitationLawRequestExist,
      preventionLawRequestExist: $preventionLawRequestExist,
      laborBureau: $laborBureau,
      laborInspectionOffice: $laborInspectionOffice,
      requestPrefecture: $requestPrefecture,
      requestMunicipality: $requestMunicipality,
      requestMunicipalityDept: $requestMunicipalityDept,
    ) {
      result
      message
      id
      sanitationLawRequestExist
      preventionLawRequestExist
      laborBureau
      laborInspectionOffice
      requestPrefecture
      requestMunicipality
      requestMunicipalityDept
    }
  }
`;

export const updateAsbestosReport = gql`
  mutation updateAsbestosReport(
    $id: ID!,
    $sanitationLawRequestExist: String,
    $preventionLawRequestExist: String,
    $laborBureau: String,
    $laborInspectionOffice: String,
    $requestPrefecture: String,
    $requestMunicipality: String,
    $requestMunicipalityDept: String,
  ) {
    updateAsbestosReport(
      id: $id,
      sanitationLawRequestExist: $sanitationLawRequestExist,
      preventionLawRequestExist: $preventionLawRequestExist,
      laborBureau: $laborBureau,
      laborInspectionOffice: $laborInspectionOffice,
      requestPrefecture: $requestPrefecture,
      requestMunicipality: $requestMunicipality,
      requestMunicipalityDept: $requestMunicipalityDept,
    ) {
      result
      message
      id
      sanitationLawRequestExist
      preventionLawRequestExist
      laborBureau
      laborInspectionOffice
      requestPrefecture
      requestMunicipality
      requestMunicipalityDept
    }
  }
`;

export const deleteAsbestosReport = gql`
  mutation deleteAsbestosReport($id: ID!) {
    deleteAsbestosReport(id: $id) {
      result
      message
    }
  }
`;

export const updateSurveyApplicationNo = gql`
  mutation updateSurveyApplicationNo(
    $id: ID!,
    $surveyApplicationNo: String,
    $asbestosReportDate: Date,
    $asbestosReportStatus: String,
  ) {
    updateSurveyApplicationNo(
      id: $id,
      surveyApplicationNo: $surveyApplicationNo,
      asbestosReportDate: $asbestosReportDate,
      asbestosReportStatus: $asbestosReportStatus,
    ) {
      result
      message
      id
      surveyApplicationNo
    }
  }
`;

export const captureCsvFile = gql`
  mutation captureCsvFile(
    $fileName: String!
    $csvFileData: String!
  ) {
    captureCsvFile(
      fileName: $fileName
      csvFileData: $csvFileData
    ) {
      applyCsvDataNormal {
        lineNo
        name
        constructionStartDate
        constructionEndDate
        workAddress
        surveyApplicationNo
        surveyApplicationDate
      }
      applyCsvDataError {
        lineNo
        name
        constructionStartDate
        constructionEndDate
        workAddress
        surveyApplicationNo
        surveyApplicationDate
      }
    }
  }
`;

export const completeAsbestosResearch = gql`
  mutation completeAsbestosResearch(
    $id: ID!
    $isCompleteWhenWornings: Boolean
  ) {
    completeAsbestosResearch(
      id: $id
      isCompleteWhenWornings: $isCompleteWhenWornings
    ) {
      result
      message
      id
      isWorning
      isCompleted
    }
  }
`;
