import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 請負事業者情報を入力するコンポーネントです。
 */
export const ContractForm = containerPresententer(Container, Presententer);

/**
 * api value to form value
 */
export const convert = (value) => {
  return {
    id: value?.id,
    name: value?.name,
    laborInsuranceNumber: {
      samePrimeContractor: value?.laborInsuranceNoSamePrime === "1",
      nothing: value?.laborInsuranceNoExist === "1",
      prefecture: value?.laborInsuranceNoPrefecture,
      admin: value?.laborInsuranceNoAdministration,
      jurisdiction: value?.laborInsuranceNoJurisdiction,
      coreNumber: value?.laborInsuranceNoCoreNo,
      branchNumber: value?.laborInsuranceNoBranchNo,
    },
    postalCode: {
      front: value?.postNoFront,
      back: value?.postNoBack,
    },
    prefecture: value?.addressPrefecture,
    city: value?.addressCity,
    town: value?.addressTown,
    otherAddress: value?.addressOther,
    phone: value?.phone,
    preliminarySurvey: {
      name: value?.preliminarySurveyorName,
      companyName: value?.preSurveyorTrainingInstitutionName,
    },
    analyticalSurvey: {
      name: value?.analyticalSurveyorName,
      companyName: value?.anaSurveyorTrainingInstitutionName,
    },
    asbestosChiefName: value?.asbestosWorkChiefName,
  };
};

const checkToString = (value) => {
  if (typeof value === "boolean") {
    return value ? "1" : "0";
  }

  if (typeof value === "string") {
    return value;
  }

  return value;
};

/**
 * form value to api value
 */
export const invert = (value) => {
  return {
    id: value?.id,
    name: value?.name,
    laborInsuranceNoSamePrime: checkToString(
      value?.laborInsuranceNumber?.samePrimeContractor
    ),
    laborInsuranceNoExist: checkToString(value?.laborInsuranceNumber?.nothing),
    laborInsuranceNoPrefecture: value?.laborInsuranceNumber?.prefecture,
    laborInsuranceNoAdministration: value?.laborInsuranceNumber?.admin,
    laborInsuranceNoJurisdiction: value?.laborInsuranceNumber?.jurisdiction,
    laborInsuranceNoCoreNo: value?.laborInsuranceNumber?.coreNumber,
    laborInsuranceNoBranchNo: value?.laborInsuranceNumber?.branchNumber,
    postNoFront: value?.postalCode?.front,
    postNoBack: value?.postalCode?.back,
    addressPrefecture: value?.prefecture,
    addressCity: value?.city,
    addressTown: value?.town,
    addressOther: value?.otherAddress,
    phone: value?.phone,
    preliminarySurveyorName: value?.preliminarySurvey?.name,
    preSurveyorTrainingInstitutionName: value?.preliminarySurvey?.companyName,
    analyticalSurveyorName: value?.analyticalSurvey?.name,
    anaSurveyorTrainingInstitutionName: value?.analyticalSurvey?.companyName,
    asbestosWorkChiefName: value?.asbestosChiefName,
  };
};
