import { Box, Button } from "@mui/material";
import React from "react";
import { Item } from "./Item";
import AddIcon from "@mui/icons-material/Add";

/**
 * 事前調査フォームの調査詳細を入力するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <Box display={"flex"} flexDirection="column">
      <Box display={"flex"} flexDirection="column">
        {props.value?.map((item, index) => (
          <Item
            key={index}
            value={item}
            onChange={props.onChange(index)}
            onRemove={props.onRemove(index)}
            disabled={props.disabled}
          />
        ))}
      </Box>
      <Button
        variant="contained"
        onClick={props.onAdd}
        endIcon={<AddIcon />}
        disabled={props.disabled}
      >
        {"追加"}
      </Button>
    </Box>
  );
};
