import { Box, Checkbox, FormControlLabel } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";

/**
 * ARS-チェックボックスを表示するプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <Box>
    <Controller
      control={props.control}
      name={props.name}
      render={({ field: { value, onChange } }) => {
        return (
          <FormControlLabel
            control={<Checkbox onChange={onChange} />}
            label={props.label}
            checked={Number(value) === 1}
          />
        );
      }}
    />
  </Box>
);
