import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * CSV取り込みを行うプレゼンテーションコンポーネントです。
 * @fires Importer#onNotificationChange 変更通知
 * @returns {JSX.Element}
 */
export const Importer = containerPresententer(Container, Presententer);
