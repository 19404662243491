const defaultValues = {
  sanitationLawRequestExist: "0",
  laborBureau: null,
  laborInspectionOffice: null,
  preventionLawRequestExist: "0",
  requestPrefecture: null,
  requestMunicipality: null,
  requestMunicipalityDept: null,
};

/**
 * 申請を入力するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  value,
  onChange = () => {},
  loading = false,
  errors,
  ...props
}) => {
  const handleChange = (name) => (e) => {
    onChange({
      ...value,
      [name]: e.target.value,
    });
  };

  const handleChangeLaborBureau = (data) => {
    onChange({
      ...value,
      laborBureau: data,
      laborInspectionOffice: null,
    });
  };

  const handleChangeChecked = (name) => (e) => {
    onChange({
      ...value,
      [name]: e.target.checked,
    });
  };

  const handleChangePrefecture = (data) => {
    onChange({
      ...value,
      requestPrefecture: data,
      requestMunicipalityDept: null,
      requestMunicipality: null,
    });
  };

  const handleChangeMunicipality = (data) => {
    onChange({
      ...value,
      requestMunicipalityDept: null,
      requestMunicipality: data,
    });
  };

  const handleChangeSelect = (name) => (e) => {
    onChange({
      ...value,
      [name]: e,
    });
  };

  return render({
    ...props,
    value: {
      ...defaultValues,
      ...value,
    },
    onChange: handleChange,
    onChangeChecked: handleChangeChecked,
    onChangeSelect: handleChangeSelect,
    onChangeLaborBureau: handleChangeLaborBureau,
    onChangePrefecture: handleChangePrefecture,
    onChangeMunicipality: handleChangeMunicipality,
    errors: errors,
    loading: loading,
  });
};
