import { useEffect, useState } from "react";
import { OtherWorks } from "utils/Constants";
import { getUniqueInformationLabel } from "api/graphql/queries";
import { useQuery } from "@apollo/client";

/**
 * ARS-工事一覧ページを表示するコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, value, ...props }) => {
  const [otherWorks, setOtherWorks] = useState([]);
  const [uniqueInformationLabels, setUniqueInformationLabels] = useState(null);
  useQuery(getUniqueInformationLabel, {
    onCompleted: (data) => {
      setUniqueInformationLabels(data.getUniqueInformationLabel);
    },
  });

  useEffect(() => {
    setOtherWorks(
      [
        value?.isBoilerAndPressureVessel === true &&
          OtherWorks.find(
            (otherWork) => otherWork.id === "isBoilerAndPressureVessel"
          ).label,
        value?.isReactionTank === true &&
          OtherWorks.find((otherWork) => otherWork.id === "isReactionTank")
            .label,
        value?.isHeatingFurnace === true &&
          OtherWorks.find((otherWork) => otherWork.id === "isHeatingFurnace")
            .label,
        value?.isPlumbingEquipment === true &&
          OtherWorks.find((otherWork) => otherWork.id === "isPlumbingEquipment")
            .label,
        value?.isIncinerator === true &&
          OtherWorks.find((otherWork) => otherWork.id === "isIncinerator")
            .label,
        value?.isChimney === true &&
          OtherWorks.find((otherWork) => otherWork.id === "isChimney").label,
        value?.isStorageEquipment === true &&
          OtherWorks.find((otherWork) => otherWork.id === "isStorageEquipment")
            .label,
        value?.isPowerGenerationEquipment === true &&
          OtherWorks.find(
            (otherWork) => otherWork.id === "isPowerGenerationEquipment"
          ).label,
        value?.isSubstationEquipment === true &&
          OtherWorks.find(
            (otherWork) => otherWork.id === "isSubstationEquipment"
          ).label,
        value?.isPowerDistributionEquipment === true &&
          OtherWorks.find(
            (otherWork) => otherWork.id === "isPowerDistributionEquipment"
          ).label,
        value?.isPowerTransmissionEquipment === true &&
          OtherWorks.find(
            (otherWork) => otherWork.id === "isPowerTransmissionEquipment"
          ).label,
        value?.isTunnelCeilingBoard === true &&
          OtherWorks.find(
            (otherWork) => otherWork.id === "isTunnelCeilingBoard"
          ).label,
        value?.isPlatformHouse === true &&
          OtherWorks.find((otherWork) => otherWork.id === "isPlatformHouse")
            .label,
        value?.isSoundInsulationWall === true &&
          OtherWorks.find(
            (otherWork) => otherWork.id === "isSoundInsulationWall"
          ).label,
        value?.isLightweightFillProtectionPanel === true &&
          OtherWorks.find(
            (otherWork) => otherWork.id === "isLightweightFillProtectionPanel"
          ).label,
        value?.isRailwayStationWall === true &&
          OtherWorks.find(
            (otherWork) => otherWork.id === "isRailwayStationWall"
            ).label,
        value?.isTouristElevatorHoistwayEnclosure === true &&
            OtherWorks.find((otherWork) => otherWork.id === "isTouristElevatorHoistwayEnclosure").label,
        value?.isShip === true &&
          OtherWorks.find((otherWork) => otherWork.id === "isShip").label,
      ]
        .filter((item) => !!item)
        .join()
    );
  }, [value]);

  return render({
    value: value,
    otherWorks: otherWorks,
    uniqueInformationLabels: uniqueInformationLabels,
    ...props,
  });
};
