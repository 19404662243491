import { showError, showSuccess } from "ducks/NotificationMessages";
import QueryString from "query-string";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { convert as convertSurvey } from "views/organisms/Constructions/Survey/Form";

/**
 * 工事情報表示ページのコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  id,
  value,
  contractors,
  survey,
  updateFunction,
  deleteFunction,
  deleteContractorFunction,
  deleteAsbestosResearchFunction,
  reload,
  completeAsbestosResearchFunction,
  loading,
  report,
  ...props
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryString = { ...QueryString.parse(location.search) };
  const [formValue, setFormValue] = useState(value);
  const [tab, setTab] = useState(queryString?.["tab"] ?? "constructions");
  const [confirmError, setConfirmError] = useState(null);
  const [open, setOpen] = useState(false);
  const [warning, setWarning] = useState(null);
  const [completedLoading, setCompletedLoading] = useState(false);

  useEffect(() => {
    setFormValue(value);
  }, [value]);

  const handleEdit = () => {
    if (tab === "constructions") {
      navigate(`/constructions/${id}/show/update`, {
        state: {
          preSearchWord: location?.state?.preSearchWord,
          page: location?.state?.page,
          ...location?.state,
        },
      });
    } else if (tab === "contractors") {
      navigate(`${location.pathname}/contractors/update`, {
        state: {
          preSearchWord: location?.state?.preSearchWord,
          page: location?.state?.page,
          ...location?.state,
        },
      });
    } else if (tab === "survey") {
      navigate(`${location.pathname}/survey/update`, {
        state: {
          preSearchWord: location?.state?.preSearchWord,
          page: location?.state?.page,
          ...location?.state,
        },
      });
    }
  };

  const handleRegist = () => {
    if (tab === "contractors") {
      navigate(`${location.pathname}/contractors/create`, {
        state: {
          preSearchWord: location?.state?.preSearchWord,
          page: location?.state?.page,
          ...location?.state,
        },
      });
    } else if (tab === "survey") {
      navigate(`${location.pathname}/survey/create`, {
        state: {
          preSearchWord: location?.state?.preSearchWord,
          page: location?.state?.page,
          ...location?.state,
        },
      });
    }
  };

  const handleDelete = () => {
    if (tab === "constructions") {
      deleteFunction({
        variables: { id: id },
      }).then((res) => {
        if (res.data.deleteConstruction.result !== "NG") {
          dispatch(
            showSuccess({
              message: "削除しました。",
            })
          );

          navigate(
            `/constructions${
              location?.state?.preSearchWord
                ? `?${location?.state?.preSearchWord}`
                : ``
            }`,
            {
              replace: true,
              state: { ...location?.state },
            }
          );
        } else {
          dispatch(
            showError({
              message: res.data.deleteConstruction.message,
            })
          );
        }
      });
    } else if (tab === "contractors") {
      deleteContractorFunction({
        variables: { id: id },
      }).then(() => {
        reload();
      });
    } else if (tab === "survey") {
      deleteAsbestosResearchFunction({
        variables: { id: id },
      }).then(() => {
        reload();
      });
    }
  };

  const handleBackList = () => {
    navigate(
      `/constructions${
        location?.state?.preSearchWord
          ? `?${location?.state?.preSearchWord}`
          : ``
      }`,
      {
        replace: true,
        state: { ...location?.state },
      }
    );
  };

  const handleCompleted = () => {
    setCompletedLoading(true);

    completeAsbestosResearchFunction({
      variables: {
        id: id,
        isCompleteWhenWornings: false,
      },
    })
      .then((res) => {
        if (res.data.completeAsbestosResearch.result === "OK") {
          if (res.data.completeAsbestosResearch.isCompleted === true) {
            dispatch(
              showSuccess({
                message: "調査完了しました。",
              })
            );
            reload();
          } else {
            setWarning(res.data.completeAsbestosResearch.message);
          }
        } else {
          dispatch(
            showError({
              message: "入力に誤りがあるため、調査完了できませんでした。",
            })
          );
          setConfirmError(res.data.completeAsbestosResearch.message);
        }
      })
      .catch((err) => {
        dispatch(
          showError({
            message: "エラーが発生したため、調査完了できませんでした。",
          })
        );
      })
      .finally(() => {
        setCompletedLoading(false);
      });
  };

  const forceSurveyComplete = () => {
    setCompletedLoading(true);

    completeAsbestosResearchFunction({
      variables: {
        id: id,
        isCompleteWhenWornings: true,
      },
    })
      .then((res) => {
        if (res.data.completeAsbestosResearch.result === "OK") {
          dispatch(
            showSuccess({
              message: "調査完了しました。",
            })
          );
          setWarning(null);
          reload();
        } else {
          dispatch(
            showError({
              message: "入力に誤りがあるため、調査完了できませんでした。",
            })
          );
          setConfirmError(res.data.completeAsbestosResearch.message);
        }
      })
      .catch((err) => {
        dispatch(
          showError({
            message: "エラーが発生したため、調査完了できませんでした。",
          })
        );
      })
      .finally(() => {
        setCompletedLoading(false);
      });
  };

  return render({
    ...props,
    value: formValue,
    contractors: contractors,
    onEdit: handleEdit,
    onDelete: handleDelete,
    onBackList: handleBackList,
    setTab: (event, newValue) => setTab(newValue),
    onRegist: handleRegist,
    tab: tab,
    survey: convertSurvey(survey),
    onCompleted: handleCompleted,
    confirmError: confirmError,
    open: open,
    warning: warning,
    onForceSurveyComplete: forceSurveyComplete,
    onCancelSurveyComplete: () => setWarning(null),
    onOpenDeleteModal: () => setOpen(true),
    onCloseDeleteModal: () => setOpen(false),
    loading: loading,
    report: report,
    completedLoading: completedLoading,
  });
};
