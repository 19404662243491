import {
  Box,
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import React from "react";
import { FullScreenDialog, ConfirmDialog } from "views/molecules";
import { Items } from "../Items";
import { Pager } from "../Pager";
import { Searcher } from "./Searcher";

/**
 * 報告一覧を表示するプレゼンテーションコンポーネントです。
 */
export const Presententer = (props) => {
  return (
    <>
      <Box>
        <Box
          display={"flex"}
          flexDirection="row"
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Box>
            <Typography variant="h6">{"調査一覧"}</Typography>
            <Typography>
              {`選択件数：${props.selectedValue?.length ?? 0}件`}
            </Typography>
            <FormControlLabel
              checked={props.allSelect}
              onChange={props.onChangeAllSelect}
              control={<Checkbox />}
              label="一括"
              disabled={props.disabled}
            />
          </Box>
          <Box display={"flex"} flexDirection="row">
            <Button
              color="primary"
              variant="outlined"
              onClick={props.onSearch}
              disabled={props.disabled}
              sx={{
                marginRight: "6px",
              }}
            >
              {"検索"}
            </Button>
            <Button
              color="success"
              variant="outlined"
              disabled={props.disabled || props.exportable}
              onClick={props.onExport}
              sx={{
                marginRight: "6px",
              }}
            >
              {"出力"}
            </Button>
            <Button
              color="success"
              variant="outlined"
              disabled={props.disabled || props.exportable}
              onClick={props.onExport2}
              sx={{
                marginRight: "6px",
              }}
            >
              {"出力2"}
            </Button>
            <Button color="info" variant="outlined" onClick={props.onImport}>
              {"取込"}
            </Button>
          </Box>
        </Box>
        <Items
          value={props.value}
          selectedValue={props.selectedValue}
          loading={props.loading}
          onApply={props.onClickApplyItem}
          onReport={props.onClickReportItem}
          onCheckedChange={props.onCheckedChangeItem}
          disabled={props.disabled}
        />
        <Pager
          page={props.page}
          pageCount={props.pageCount}
          onNext={props.onNext}
          onBack={props.onBack}
          disabled={props.disabled}
          hasNextPage={props.hasNextPage}
          hasPreviousPage={props.hasPreviousPage}
        />
        <FullScreenDialog
          open={props.open}
          onClose={props.onClose}
          title="検索"
          actions={
            <Button autoFocus color="inherit" onClick={props.onClickSearch}>
              {"検索する"}
            </Button>
          }
        >
          <Searcher
            value={props.search}
            onChange={props.onChangeSearch}
            errors={null}
          />
        </FullScreenDialog>
      </Box>
      <ConfirmDialog
        title="確認"
        message="出力済の案件を選択しています。出力しますか？"
        open={props.outputConfirm}
        onPositive={props.onPositiveOutputCSV}
        onNegative={props.onCloseOutputConfirm}
        positiveText="はい"
        negativeText="いいえ"
      />
      <ConfirmDialog
        title="エラー"
        message="申請先設定済み・未報告の案件を選択してください。"
        open={props.stopOutputConfirm}
        onPositive={props.onCloseStopOutput}
        positiveText="はい"
        negativeText={null}
      />
    </>
  );
};
