import { Stack } from "@mui/material";
import React, { useState } from "react";
import { FullScreenDialog, InformationItemGroup } from "views/molecules";
import { Details } from "views/organisms/Constructions/Survey/Form/Details/InvestigationDetailForm/Details";
import { Items } from "views/organisms/Constructions/Survey/Form/Details/InvestigationDetailForm/List/Items";
import { Searcher } from "./Searcher";

/**
 * 調査詳細を表示するコンポーネントです。
 * @param {object} value 値
 * @param {boolean} open 開閉
 * @fires InvestigationDetailView#onClose 閉じるとき
 * @returns {JSX.Element}
 */
export const InvestigationDetailView = ({
  value,
  open = false,
  onClose = () => {},
}) => {
  const [details, setDetails] = useState(null);

  return (
    <FullScreenDialog title="設定情報" open={open} onClose={onClose}>
      <Stack spacing={2}>
        <InformationItemGroup title="検索条件">
          <Searcher value={value?.conditions} />
        </InformationItemGroup>
        <InformationItemGroup title="検索結果">
          <Items
            value={value?.results}
            disabled={true}
            onClick={(data) => setDetails(data)}
          />
        </InformationItemGroup>
        <InformationItemGroup title="該当品目">
          <Items
            value={value?.judgements}
            disabled={true}
            onClick={(data) => setDetails(data)}
            emptiesMessage="該当品目なし"
          />
        </InformationItemGroup>
        <Details
          value={details}
          open={Boolean(details)}
          onClose={() => setDetails(null)}
        />
      </Stack>
    </FullScreenDialog>
  );
};
