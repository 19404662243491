import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { itemsSelector, remove } from "ducks/NotificationMessages";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

/**
 * 通知メッセージを表示するコンポーネントです。
 * @returns JSX.Element
 */
export const NotificationMessages = () => {
  const items = useSelector(itemsSelector);
  const dispatch = useDispatch();

  const handleClose = (index) => () => {
    dispatch(remove(index));
  };

  return (
    <>
      {items?.map((item, index) => (
        <Snackbar
          open={true}
          autoHideDuration={6000}
          onClose={handleClose(index)}
          key={index}
        >
          <Alert
            onClose={handleClose(index)}
            severity={item.severity ?? "error"}
          >
            {item.message}
          </Alert>
        </Snackbar>
      )) ?? <></>}
    </>
  );
};
