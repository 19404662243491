import { Autocomplete, TextField, Typography } from "@mui/material";
import React from "react";

/**
 * ポップアップセレクションでデータを選択するコンポーネントです。
 * @param {object} props プロパティ
 */
export const Presententer = (props) => {
  const { label, value, onChange, options, required, placeholder, error } =
    props;

  return (
    <Autocomplete
      options={options}
      filterSelectedOptions
      getOptionLabel={(option) => option.name ?? ""}
      // getOptionSelected={(option, value) =>
      //   option.id ? option.id === value.id : ""
      // }
      renderOption={(params, option) => (
        <Typography {...params}>{option.name}</Typography>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          size="small"
          placeholder={placeholder}
          error={error}
        />
      )}
      noOptionsText="選択肢がありません。"
      value={value || null}
      onChange={(_, data) => onChange(data)}
    />
  );
};
