import React from "react";
import { Navigate } from "react-router-dom";
import { AuthenticatedTemplate } from "views/organisms";
import {
  ApplicationPaths,
  QueryParameterNames,
} from "./ApiAuthorizationConstants";

export default class AuthorizeRoute extends React.Component {
  generateRedirectUrl(returnUrl) {
    return `${ApplicationPaths.Login}?${
      QueryParameterNames.ReturnUrl
    }=${encodeURI(window.location.origin + returnUrl)}`;
  }

  render() {
    const { ready, authenticated, component: Component } = this.props;

    return !ready ? (
      <></>
    ) : authenticated ? (
      <AuthenticatedTemplate>{Component}</AuthenticatedTemplate>
    ) : (
      <Navigate to={this.generateRedirectUrl(window.location.pathname)} />
    );
  }
}
