import { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router";

/**
 * ARS-企業一覧ページのコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const location = useLocation();

  const handleRegister = useCallback(() => {
    navigate("company/regist");
  }, [navigate]);

  const handleUpdate = useCallback(
    (params) => {
      setTimeout(() => {
        navigate(`${location?.pathname}/update`, {
          state: {
            formValue: params,
          },
        });
      }, 500);
    },
    [navigate, location]
  );

  return render({
    ...props,
    onRegister: handleRegister,
    onUpdate: handleUpdate,
    open: open,
    onClickOpen: () => setOpen(true),
    onClickClose: () => setOpen(false),
  });
};
