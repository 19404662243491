import { Query } from "@apollo/client/react/components";
import Alert from "@mui/material/Alert";
import { listMakers } from "api/graphql/queries";
import React from "react";
import { ComboBox, SkeletonField } from "views/molecules";

/**
 * 製造メーカーの選択を行うコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const MakerSelector = (props) => {
  const { value, onChange } = props;

  return (
    <Query query={listMakers}>
      {({ data, error, loading }) => {
        if (loading) return <SkeletonField number={1} />;
        if (error) return <Alert severity="error">{error}</Alert>;
        if (!loading)
          return (
            <ComboBox
              name="maker"
              value={value}
              onChange={onChange}
              label="製造メーカー"
              options={data.listMakers}
            />
          );
      }}
    </Query>
  );
};
