import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  Stack,
  Typography,
} from "@mui/material";
import React, { Fragment } from "react";
import {
  AsbestosResearchStatusType,
  ConstructionOrderStatusType,
} from "utils/Constants";

const InforText = (props) => (
  <Typography sx={{ fontSize: "14px" }}>{props.value}</Typography>
);

const getDate = (value) => {
  if (!value?.constructionStartDate && !value?.constructionEndDate) {
    return "";
  }

  if (!value?.constructionStartDate && !!value?.constructionEndDate) {
    return value?.constructionEndDate;
  }

  if (!!value?.constructionStartDate && !value?.constructionEndDate) {
    return value?.constructionStartDate;
  }

  return `${value?.constructionStartDate}～${value?.constructionEndDate}`;
};

const PreSearchChip = ({ id }) => {
  switch (id) {
    case AsbestosResearchStatusType.Creating.value:
      return (
        <Chip
          label={AsbestosResearchStatusType.Creating.label}
          color="warning"
          size="small"
        />
      );
    case AsbestosResearchStatusType.SurveyCompleted.value:
      return (
        <Chip
          label={AsbestosResearchStatusType.SurveyCompleted.label}
          color="success"
          size="small"
        />
      );
    case AsbestosResearchStatusType.NoInvestigationRequired.value:
      return (
        <Chip
          label={AsbestosResearchStatusType.NoInvestigationRequired.label}
          color="default"
          size="small"
        />
      );
    default:
      return <></>;
  }
};

const OrderChip = ({ id }) => {
  switch (id) {
    case ConstructionOrderStatusType.Negotiating.value:
      return (
        <Chip
          label={ConstructionOrderStatusType.Negotiating.label}
          color="error"
          size="small"
        />
      );
    case ConstructionOrderStatusType.Ordered.value:
      return (
        <Chip
          label={ConstructionOrderStatusType.Ordered.label}
          color="success"
          size="small"
        />
      );
    case ConstructionOrderStatusType.Lost.value:
      return (
        <Chip
          label={ConstructionOrderStatusType.Lost.label}
          color="default"
          size="small"
        />
      );
    default:
      return <></>;
  }
};

/**
 * 工事一覧の項目を表示するプレゼンテーションコンポーネントです
 */
export const Presententer = (props) => (
  <Fragment>
    <Card
      sx={{
        mr: (theme) => theme.spacing(2),
      }}
      onClick={props.onClick}
    >
      <CardActionArea>
        <CardContent sx={{ p: 0 }}>
          <Box
            sx={{
              boxShadow: 1,
              borderRadius: "4px",
              p: 1,
            }}
          >
            <Stack direction="row" mb={1} spacing={2}>
              <OrderChip id={props.value?.constructionOrderStatusId} />
              <PreSearchChip id={props.value?.asbestosResearchStatusId} />
            </Stack>
            <Stack direction="row" spacing={2} mb={1}>
              <InforText value={props.value?.name ?? ""} />
              <InforText value={getDate(props.value)} />
            </Stack>
            <Stack direction="row" spacing={5}>
              <InforText value={props.value?.address ?? ""} />
            </Stack>
            <Stack direction="row" spacing={7}>
              <InforText value={props.value?.summary ?? ""} />
            </Stack>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  </Fragment>
);
