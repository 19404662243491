import queryString from "query-string";
import { useNavigate, useLocation } from "react-router";

/**
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (value) => {
    if (value.isSanitary) {
      navigate(`/sanitary?${queryString.stringify({ id: value.id })}`, {
        state: location.state,
      });
    } else {
      navigate(`/product?${queryString.stringify({ id: value.id })}`, {
        state: location.state,
      });
    }
  };

  const handleBack = () => {
    navigate(`/search`, {
      state: location.state,
    });
  };

  return render({
    onBack: handleBack,
    onClick: handleClick,
    ...props,
  });
};
