/**
 * モーダルでテーブルを表示するコンテナコンポーネントです
 * @param {boolean} open モーダルが有効か無効を設定
 * @param {func} onClose モーダルを閉じるイベントです
 * @param {object} children モーダルの内容
 * @callback render
 * @returns {JSX.Element} JSX 要素
 */
export const Container = ({ render, open, onClose, children, ...props }) => {
  return render({
    children: children,
    open: open,
    onClose: onClose,
    ...props,
  });
};
