import React from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { IconButton } from "@mui/material";

const DeleteButton = ({ onClick }) => {
  return (
    <IconButton aria-label="delete" onClick={onClick}>
      <HighlightOffIcon />
    </IconButton>
  );
};

/**
 * サムネイルの枠を表示するコンポーネントです。
 * @fires Frame#onClick クリック時
 * @param {number} width 幅
 * @param {number} height 高さ
 * @param {boolean} disabled 無効設定
 * @fires Frame#onDelete 削除時
 * @returns JSX.Element
 */
export const Frame = ({
  onClick,
  width,
  height,
  disabled = false,
  onDelete,
  ...props
}) => {
  return (
    <div
      className="root"
      style={{
        position: "relative",
      }}
    >
      <div
        className="content"
        onClick={onClick}
        style={{
          width: width,
          height: height,
        }}
      >
        {props.children}
      </div>
      <div
        className="delete"
        style={{
          position: "absolute",
          top: 0,
          right: 0,
        }}
      >
        {disabled === false && <DeleteButton onClick={onDelete} />}
      </div>
    </div>
  );
};
