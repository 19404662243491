import React from "react";
import { FormProvider } from "react-hook-form";
import { Form } from "./Child";

/**
 * ARS-工事情報登録ページの表示を行うプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = ({ methods, ...props }) => (
  <FormProvider {...methods}>
    <Form />
  </FormProvider>
);
