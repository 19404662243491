import { useQuery } from "@apollo/client";
import { listAsbestosContainsResult } from "api/graphql/queries";
import { useState, useEffect } from "react";

/**
 * ItemBのデフォルト値です。
 */
export const defaultValue = {
  existsAsbestos: null,
  basis: [],
  existsDisconnect: null,
  measure: [],
};

/**
 * 事前調査フォームの項目を表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  name,
  value,
  onChange = () => {},
  expanded = false,
  errors,
  disabled,
  ...props
}) => {
  const [expand, setExpand] = useState(expanded);
  useEffect(() => {
    setExpand(expanded);
  }, [expanded]);
  const asbestosContainsResult = useQuery(listAsbestosContainsResult);
  const handleChange = (e) => {
    onChange({
      ...value,
      [e.name]: e.value,
    });
  };

  return render({
    ...props,
    name: name,
    value: {
      ...defaultValue,
      ...value,
    },
    onChange: handleChange,
    expanded: expand,
    setExpand: setExpand,
    errors: errors,
    asbestosContainsResult: asbestosContainsResult,
    disabled: disabled,
  });
};
