import { Skeleton, Alert, Stack } from "@mui/material";
import React from "react";
import { AutoSizer } from "react-virtualized";
import { FixedSizeList } from "react-window";
import { Item } from "./Item";

/**
 * 工事一覧の項目を表示するコンポーネントです。
 * @param {array} value 値
 * @param {boolean} loading 読み込み中であるか
 * @param {boolean} error エラーであるか
 * @fires Items#onClickItem アイテムクリック時 データが返る
 * @returns {JSX.Element}
 */
export const Items = (props) => {
  const { value, loading, error, onClickItem } = props;
  const handleClickItem = (data) => () => {
    onClickItem(data);
  };

  if (loading) {
    return (
      <Stack spacing={2}>
        <Skeleton height={280} />
        <Skeleton height={280} />
        <Skeleton height={280} />
      </Stack>
    );
  }

  if (error) {
    return (
      <Alert severity="error">
        読み込み中にエラーが発生したため、表示できませんでした。
      </Alert>
    );
  }

  if (!value || value?.length <= 0) {
    return <Alert severity="error">工事情報はありませんでした。</Alert>;
  }

  return (
    <AutoSizer>
      {({ height, width }) => {
        return (
          <FixedSizeList
            height={height}
            width={width}
            itemCount={value?.length ?? 0}
            itemSize={280}
            itemData={value}
          >
            {({ data, index, style }) => {
              return (
                <div style={style}>
                  <Item
                    value={data[index]}
                    onClick={handleClickItem(data[index])}
                  />
                </div>
              );
            }}
          </FixedSizeList>
        );
      }}
    </AutoSizer>
  );
};
