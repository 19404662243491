import { Clear } from "@mui/icons-material";
import { IconButton, Stack, Typography } from "@mui/material";
import React from "react";

/**
 * 内容を囲むする表示を行うプレゼンテーションです。
 * @param {string} value 値
 * @param {string} label ラベル
 */
export const Presententer = (props) => {
  return (
    <Stack
      spacing={2}
      boxShadow={3}
      p={1}
      pt={props.deleteIcon ? 3 : 2}
      mt={3}
      borderRadius={1}
      sx={{
        position: "relative",
        marginTop: "24px !important",
      }}
    >
      <Typography
        sx={{
          position: "absolute",
          top: "-10px",
          left: "12px",
          backgroundColor: "#fff",
          padding: "0 6px",
        }}
      >
        {props.title}
      </Typography>
      {props.subTitle && (
        <Typography
          sx={{
            fontSize: "13px",
            color: (theme) => theme.palette.grey[500],
            letterSpacing: "2px",
            mt: 1,
          }}
        >
          {props.subTitle}
        </Typography>
      )}
      {props.deleteIcon ? (
        <IconButton
          onClick={props.onClick}
          sx={{
            position: "absolute",
            top: "-16px",
            right: "0",
            left: "auto",
          }}
        >
          <Clear />
        </IconButton>
      ) : (
        ""
      )}
      <Stack spacing={2} mt={2}>
        {props.children}
      </Stack>
    </Stack>
  );
};
