import { Paper } from "@mui/material";
import React from "react";
import { RadioGroup } from "views/molecules";
import { MaterialSearcher } from "./MaterialSearcher";
import { ProductSearcher } from "./ProductSearcher";
import { SimpleSearcher } from "./SimpleSearcher";

/**
 * 検索方法の値を表します。
 */
export const modeValues = [
  {
    label: "簡単",
    value: "1",
  },
  {
    label: "製品",
    value: "2",
  },
  {
    label: "建材",
    value: "3",
  },
];

/**
 * 検索のデフォルト値を表します。
 */
export const defaultValue = {
  mode: "1",
  data: null,
};

/**
 * 建材・製品検索を行うコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Searcher = (props) => {
  const { value, onChange } = props;

  const handleChangeMode = (data) => {
    onChange({
      mode: data.value,
      data: null,
    });
  };

  const handleChange = (name) => (data) => {
    onChange({
      ...value,
      [name]: data,
    });
  };

  return (
    <Paper elevation={4} sx={{ p: 2, pt: 3 }}>
      <div
        style={{
          margin: "8px",
        }}
      >
        <RadioGroup
          row={true}
          label="検索方法"
          value={value?.mode}
          onChange={handleChangeMode}
          options={modeValues}
        />
      </div>
      {value?.mode?.toString() === "1" && (
        <SimpleSearcher
          value={value?.data}
          onChange={(data) => handleChange("data")(data)}
        />
      )}
      {value?.mode?.toString() === "2" && (
        <ProductSearcher
          value={value?.data}
          onChange={(data) => handleChange("data")(data)}
        />
      )}
      {value?.mode?.toString() === "3" && (
        <MaterialSearcher
          value={value?.data}
          onChange={(data) => handleChange("data")(data)}
        />
      )}
    </Paper>
  );
};
