import { Stack, Typography, MenuItem } from "@mui/material";
import { InputField } from "../InputField";
import React from "react";
import { OptionPrefectures } from "utils/Constants";

/**
 * 住所を入力するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element} JSX.Element
 */
export const Presententer = (props) => {
  return (
    <Stack spacing={2}>
      <Stack spacing={2} direction={"row"}>
        <InputField
          label={"郵便番号"}
          onChange={(e) =>
            props.onChangePostalCodeFront(`${props.name}PostNoFront`)(
              e.target.value
            )
          }
          value={props.value?.[`${props.name}PostNoFront`] ?? ""}
          inputProps={{
            maxLength: 3,
          }}
          error={!!props.error?.[`${props.name}PostNoFront`]}
          helperText={props.error?.[`${props.name}PostNoFront`]?.message}
        />
        <Typography>{"-"}</Typography>
        <InputField
          label={"郵便番号"}
          onChange={(e) =>
            props.onChangePostalCodeBack(`${props.name}PostNoBack`)(
              e.target.value
            )
          }
          value={props.value?.[`${props.name}PostNoBack`] ?? ""}
          inputProps={{
            maxLength: 4,
          }}
          error={!!props.error?.[`${props.name}PostNoBack`]}
          helperText={props.error?.[`${props.name}PostNoBack`]?.message}
        />
      </Stack>
      <InputField
        select
        label={"都道府県"}
        value={props.value?.[`${props.name}AddressPrefecture`] ?? ""}
        onChange={(e) =>
          props.onChange(`${props.name}AddressPrefecture`)(e.target.value)
        }
        error={Boolean(props.error?.[`${props.name}AddressPrefecture`])}
        helperText={props.error?.[`${props.name}AddressPrefecture`]?.message}
      >
        <MenuItem value="">
          <em>未選択</em>
        </MenuItem>
        {OptionPrefectures.map((prefecture, index) => (
          <MenuItem key={index} value={prefecture.name}>
            {prefecture.name}
          </MenuItem>
        ))}
      </InputField>
      <InputField
        label={"市区町村"}
        onChange={(e) =>
          props.onChange(`${props.name}AddressCity`)(e.target.value)
        }
        value={props.value?.[`${props.name}AddressCity`] ?? ""}
        error={!!props.error?.[`${props.name}AddressCity`]}
        helperText={props.error?.[`${props.name}AddressCity`]?.message}
      />
      <InputField
        label={"町域名"}
        onChange={(e) =>
          props.onChange(`${props.name}AddressTown`)(e.target.value)
        }
        value={props.value?.[`${props.name}AddressTown`] ?? ""}
        error={!!props.error?.[`${props.name}AddressTown`]}
        helperText={props.error?.[`${props.name}AddressTown`]?.message}
      />
      <InputField
        label={"その他住所（続き）"}
        onChange={(e) =>
          props.onChange(`${props.name}AddressOther`)(e.target.value)
        }
        value={props.value?.[`${props.name}AddressOther`] ?? ""}
        error={!!props.error?.[`${props.name}AddressOther`]}
        helperText={props.error?.[`${props.name}AddressOther`]?.message}
      />
    </Stack>
  );
};
