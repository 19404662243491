import { CircularProgress } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

/**
 * PDFを表示するコンポーネントです。
 * @param {number} height 高さ
 * @param {number} width 幅
 * @fires PDF#onScroll スクロール時
 * @param {string} file ファイル
 * @returns JSX.Element
 */
export const PDF = ({ height, width, onScroll, file }) => {
  const [numPages, setNumPages] = useState(null);

  useEffect(() => {
    setNumPages(null);
  }, [file]);

  const handleLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <div
      onScroll={onScroll}
      style={{
        height: height,
        border: "1px solid #bdbdbd",
        borderRadius: "4px",
        overflow: "auto",
      }}
    >
      <Document
        file={file}
        loading={
          <div
            style={{
              padding: "30px",
              textAlign: "center",
            }}
          >
            <CircularProgress thickness={4} size={60} />
          </div>
        }
        onLoadSuccess={handleLoadSuccess}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            width={width ?? 900}
          />
        ))}
      </Document>
    </div>
  );
};
