import { Query } from "@apollo/client/react/components";
import { Alert, Box, Button, Grid, LinearProgress, Stack } from "@mui/material";
import { listCompanies } from "api/graphql/queries";
import React, { Fragment } from "react";
import { AutoSizer } from "react-virtualized";
import { FixedSizeList } from "react-window";
import { CompanyList } from "views/organisms";
// import { SearchForm } from "views/organisms/Construction/ConstructionForm/Child";
import { NotFound } from "views/pages";

const ButtonOverride = (props) => (
  <Button
    variant="contained"
    color="success"
    onClick={props.onClick}
    sx={{
      padding: "3px 24px",
      mr: (theme) => theme.spacing(1),
    }}
    disableElevation
    label={props.label}
    fullWidth={false}
    {...props}
  >
    {props.label}
  </Button>
);
const ITEM_SIZE = 127 + 16;
const Row =
  (params) =>
  ({ data, index, style }) => {
    return (
      <div style={style}>
        <CompanyList value={data[index]} onClick={() => params(data[index])} />
      </div>
    );
  };

/**
 * ARS-企業一覧ページのコンポーネント。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <Fragment>
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Stack direction="row" alignItems="center" mb={1}>
        <ButtonOverride label={"検索"} onClick={props.onClickOpen} />
        <ButtonOverride label={"新規登録"} onClick={props.onRegister} />
        {/* <Typography sx={{ fontSize: "14px", flex: 1, textAlign: "right" }}>
          検索結果: {props.value.length}件
        </Typography> */}
      </Stack>
      <Box sx={{ flex: "1 1 auto", mr: "-16px" }}>
        <Query query={listCompanies} variables={props.variables}>
          {({ data, error, loading }) => {
            if (error) return <NotFound {...error} />;
            if (loading) {
              return <LinearProgress color="secondary" />;
            } else if (data.listCompanies?.length === 0) {
              return (
                <Grid item xs={12}>
                  <Alert severity="error">
                    検索条件に該当する企業情報がありませんでした。
                  </Alert>
                </Grid>
              );
            } else {
              return (
                <AutoSizer>
                  {({ height, width }) => {
                    return (
                      <FixedSizeList
                        height={height}
                        width={width}
                        itemCount={
                          props.value?.length ?? data?.listCompanies?.length
                        }
                        itemSize={ITEM_SIZE}
                        itemData={props.value ?? data?.listCompanies}
                      >
                        {Row(props.onUpdate)}
                      </FixedSizeList>
                    );
                  }}
                </AutoSizer>
              );
            }
          }}
        </Query>
        {/* <AutoSizer>
          {({ height, width }) => {
            return (
              <FixedSizeList
                height={height}
                width={width}
                itemCount={props.value?.length}
                itemSize={ITEM_SIZE}
                itemData={props.value}
              >
                {Row(props.onUpdate)}
              </FixedSizeList>
            );
          }}
        </AutoSizer> */}
      </Box>
    </Box>
    {/* <SearchForm open={props.open} onClose={props.onClickClose} /> */}
  </Fragment>
);
