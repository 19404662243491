import { Query } from "@apollo/client/react/components";
import { Button, Grid, Grow, Paper } from "@mui/material";
import {
  listMakers,
  listMaterials,
  listProductFields,
  listUsefulnesses,
} from "api/graphql/queries";
import React from "react";
import { Controller } from "react-hook-form";
import { OptionYears } from "utils/Constants";
import {
  ComboBox,
  InputField,
  SelectItem,
  SkeletonField,
} from "views/molecules";

/**
 * ARS-かんたん検索タブの表示を行うプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <div>
    <Grow
      in={props.in}
      {...(props.in ? { timeout: 1000 } : {})}
      style={{ transformOrigin: "0 0 0" }}
    >
      <Paper elevation={4} sx={{ p: 2, pt: 3 }}>
        <form onSubmit={props.handleSubmit(props.onSubmit)}>
          <Grid container spacing={3}>
            {/* フリーワード */}
            <Grid item xs={12}>
              <Controller
                name="keyword"
                control={props.control}
                rules={{
                  validate: (value) => {
                    if (value === undefined || value === "") return;
                    return (
                      !value.match(/[<>\\"\\s',.;]/) || "不正な文字列です。"
                    );
                  },
                }}
                defaultValue=""
                render={({
                  field: { value, onChange },
                  formState: { errors },
                }) => (
                  <InputField
                    value={value}
                    onChange={onChange}
                    label="フリーワード"
                    error={Boolean(errors?.keyword)}
                    helperText={errors?.keyword?.message}
                  />
                )}
              />
            </Grid>

            {/* 製品分野 */}
            <Grid item xs={12}>
              <Query query={listProductFields} errorPolicy="all">
                {({ data, loading, error }) => {
                  if (loading) return <SkeletonField number={1} />;
                  if (error) return error;
                  if (!loading) {
                    return (
                      <Controller
                        name="productField"
                        control={props.control}
                        render={({ field: { value, onChange } }) => (
                          <SelectItem
                            value={value}
                            onChange={onChange}
                            label="製品分野"
                            options={data.listProductFields}
                          />
                        )}
                      />
                    );
                  }
                }}
              </Query>
            </Grid>

            {/* 製造メーカー */}
            <Grid item xs={12}>
              <Query query={listMakers}>
                {({ data, error, loading }) => {
                  if (loading) return <SkeletonField number={1} />;
                  if (error) return error;
                  if (!loading)
                    return (
                      <Controller
                        name="maker"
                        control={props.control}
                        render={({ field: { value, onChange } }) => (
                          <ComboBox
                            value={value}
                            onChange={onChange}
                            label="製造メーカー"
                            options={data.listMakers}
                          />
                        )}
                      />
                    );
                }}
              </Query>
            </Grid>

            {/* 建材名（一般名） */}
            <Grid item xs={12}>
              <Query query={listMaterials}>
                {({ data, error, loading }) => {
                  if (loading) return <SkeletonField number={1} />;
                  if (error) return error;
                  const materials = data.listMaterials;
                  if (!loading)
                    return (
                      <Controller
                        name="materialName"
                        control={props.control}
                        render={({ field: { value, onChange } }) => (
                          <ComboBox
                            value={value}
                            onChange={onChange}
                            label="建材名(一般名)"
                            options={materials}
                          />
                        )}
                      />
                    );
                }}
              </Query>
            </Grid>

            {/* 用途 */}
            <Grid item xs={12}>
              <Query query={listUsefulnesses}>
                {({ data, error, loading }) => {
                  if (loading) return <SkeletonField number={1} />;
                  if (error) return error;

                  return (
                    <Controller
                      name="usefulness"
                      control={props.control}
                      render={({ field: { value, onChange } }) => (
                        <ComboBox
                          value={value}
                          onChange={onChange}
                          label="用途"
                          options={data.listUsefulnesses}
                        />
                      )}
                    />
                  );
                }}
              </Query>
            </Grid>

            {/* 年式 */}
            <Grid item xs={12}>
              <Controller
                name="workYear"
                control={props.control}
                render={({ field: { value, onChange } }) => (
                  <SelectItem
                    value={value}
                    onChange={onChange}
                    label="製造年式"
                    options={OptionYears}
                  />
                )}
              />
            </Grid>

            {/* 検索ボタン */}
            <Grid item xs={12}>
              <Button
                color="primary"
                size="large"
                fullWidth
                variant="outlined"
                type="submit"
                disableElevation
              >
                検索する
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Grow>
  </div>
);
