import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

/**
 * ARS-検索ページのコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const location = useLocation();
  const [valueTab, setValueTab] = useState(
    location?.state?.formValues?.tabNumber ?? 0
  );
  const handleChangeValueTab = (event, newValue) => {
    event.preventDefault();
    setValueTab(newValue);
  };

  useEffect(() => {
    setValueTab(location?.state?.formValues?.tabNumber ?? 0);
  }, [setValueTab, location]);

  return render({
    valueTab: valueTab ?? 0,
    onChangeValueTab: handleChangeValueTab,
    ...props,
  });
};
