import { ItemA } from "./ItemA";
import { defaultValue as defaultValueItemA } from "./ItemA/Container";
import { defaultValue as defaultValueItemB } from "./ItemB/Container";
import { ItemB } from "./ItemB";
import { listAsbestosTargetMaterial } from "api/graphql/queries";
import { useQuery } from "@apollo/client";

const fields = [
  {
    label: "吹付け材",
    name: "spraying",
    Component: ItemA,
    defaultValue: defaultValueItemA,
    id: "01",
  },
  {
    label: "保温材",
    name: "heatRetention",
    Component: ItemA,
    defaultValue: defaultValueItemA,
    id: "02",
  },
  {
    label: "煙突断熱材",
    name: "chimney",
    Component: ItemA,
    defaultValue: defaultValueItemA,
    id: "03",
  },
  {
    label: "屋根用折版断熱材",
    name: "forRoof",
    Component: ItemA,
    defaultValue: defaultValueItemA,
    id: "04",
  },
  {
    label: "耐火被覆材",
    name: "fireproof",
    Component: ItemA,
    defaultValue: defaultValueItemA,
    id: "05",
  },
  {
    label: "仕上塗材",
    name: "finishingCoating",
    Component: ItemB,
    defaultValue: defaultValueItemB,
    id: "06",
  },
  {
    label: "スレート波板",
    name: "slateWavePlate",
    Component: ItemB,
    defaultValue: defaultValueItemB,
    id: "07",
  },
  {
    label: "スレートボード",
    name: "slateBoard",
    Component: ItemB,
    defaultValue: defaultValueItemB,
    id: "08",
  },
  {
    label: "屋根用化粧スレート",
    name: "makeupSlateForRoof",
    Component: ItemB,
    defaultValue: defaultValueItemB,
    id: "09",
  },
  {
    label: "けい酸カルシウム板第1種",
    name: "silicicAcidCalciumBoardFirstType",
    Component: ItemB,
    defaultValue: defaultValueItemB,
    id: "10",
  },
  {
    label: "押出成形セメント板",
    name: "extrusionFormationCementPlate",
    Component: ItemB,
    defaultValue: defaultValueItemB,
    id: "11",
  },
  {
    label: "パルプセメント板",
    name: "pulpCementPlate",
    Component: ItemB,
    defaultValue: defaultValueItemB,
    id: "12",
  },
  {
    label: "ビニル床タイル",
    name: "vinylFloorTile",
    Component: ItemB,
    defaultValue: defaultValueItemB,
    id: "13",
  },
  {
    label: "窯業系サイディング",
    name: "steamingSystemSiding",
    Component: ItemB,
    defaultValue: defaultValueItemB,
    id: "14",
  },
  {
    label: "石膏ボード",
    name: "plasterboard",
    Component: ItemB,
    defaultValue: defaultValueItemB,
    id: "15",
  },
  {
    label: "ロックウール吸音天井板",
    name: "lockWoolSoundAbsorbingCeilingBoard",
    Component: ItemB,
    defaultValue: defaultValueItemB,
    id: "16",
  },
  {
    label: "その他の材料",
    name: "otherMaterial",
    Component: ItemB,
    defaultValue: defaultValueItemB,
    id: "17",
  },
];

/**
 * 事前調査フォームを表示するコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  value,
  onChange,
  disabled = false,
  errors,
  ...props
}) => {
  const asbestosTargetMaterials = useQuery(listAsbestosTargetMaterial);

  const handleChange = (name) => (data) => {
    onChange({
      ...value,
      [name]: data,
    });
  };

  const handleChangePrimeContractor = (name) => (e) => {
    onChange({
      ...value,
      primeContractor: {
        ...value?.primeContractor,
        [name]: e.target.value,
      },
    });
  };

  const handleChangePrimeContractorRegulationClass = (name) => (data) => {
    onChange({
      ...value,
      primeContractor: {
        ...value?.primeContractor,
        [name]: data,
      },
    });
  };

  const handleChangeContractor = (name) => (e) => {
    onChange({
      ...value,
      contractor: {
        ...value?.contractor,
        [name]: e.target.value,
      },
    });
  };

  const autoCheck = (value, details) => {
    return details
      .reduce((result, detail) => {
        if (detail?.investigate?.judgements?.length > 0) {
          result = result.concat(detail.investigate.judgements);
        }
        return result;
      }, [])
      .reduce((judgements, judgement) => {
        if (
          !judgements
            .map((i) => i.asbestosTargetMaterialId)
            .includes(judgement.asbestosTargetMaterialId)
        ) {
          judgements.push({
            ...judgement,
          });
        }
        return judgements;
      }, [])
      .map((judgement) => {
        const field = fields.find(
          (i) => i.id === judgement.asbestosTargetMaterialId
        );

        return {
          [field.name]: {
            id: field.id,
            ...field.defaultValue,
            ...value?.[field.name],
            existsAsbestos: "01",
          },
        };
      })
      .reduce((fields, field) => {
        let key = Object.keys(field);
        fields[key] = field[key];
        return fields;
      }, {});
  };

  const handleChangeDetails = (data) => {
    onChange({
      ...value,
      details: data,
      ...autoCheck(value, data),
    });
  };

  const handleChangeUnnecessary = (e) => {
    onChange({
      ...value,
      unnecessary: e.target.checked,
    });
  };

  return render({
    ...props,
    fields: fields,
    value: value,
    onChange: handleChange,
    onChangePrimeContractor: handleChangePrimeContractor,
    onPrimeContractorRegulationClass:
      handleChangePrimeContractorRegulationClass,
    onChangeContractor: handleChangeContractor,
    onChangeDetails: handleChangeDetails,
    errors: errors,
    asbestosTargetMaterials: asbestosTargetMaterials,
    onChangeUnnecessary: handleChangeUnnecessary,
    disabled: value?.unnecessary || disabled,
  });
};
