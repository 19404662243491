import { Query } from "@apollo/client/react/components";
import Alert from "@mui/material/Alert";
import { listSearchResults } from "api/graphql/queries";
import React from "react";
import { SkeletonField } from "views/molecules";
import { Items } from "./Items";

/**
 * 検索結果フォームのデフォルト値を表します。
 */
export const defaultValue = {
  judgements: [],
  results: [],
};

/**
 * 検索結果を表示するコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const List = (props) => {
  const { value, conditions, onClick, onChange, disabled = false } = props;

  const handleChange = (name) => (data) => {
    onChange({
      ...defaultValue,
      ...value,
      [name]: data,
    });
  };

  return (
    <Query
      query={listSearchResults}
      variables={{
        mode: conditions?.mode,
        keyword: conditions?.data?.keyword,
        year: conditions?.data?.year?.id,
        productFieldId: conditions?.data?.productField?.id,
        materialId: conditions?.data?.material?.id,
        makerId: conditions?.data?.maker?.id,
        usefulnessId: conditions?.data?.usefulness?.id,
      }}
      onCompleted={(data) => handleChange("results")(data.listSearchResults)}
    >
      {({ data, error, loading }) => {
        if (error) return <Alert severity="error">{error}</Alert>;
        if (loading) {
          return <SkeletonField />;
        } else {
          return (
            <Items
              value={data?.listSearchResults ?? []}
              selectedItems={value?.judgements ?? []}
              onClick={onClick}
              onCheckedChange={(data) => handleChange("judgements")(data)}
              disabled={disabled}
            />
          );
        }
      }}
    </Query>
  );
};
