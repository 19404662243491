import { showError, showSuccess } from "ducks/NotificationMessages";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { invert } from "views/organisms/Constructions/ContractsForm";

/**
 * 請負事業者の登録を行うプレゼンテーションコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  id,
  loading,
  error,
  createFunction,
  ...props
}) => {
  const [isSubmit, setIsSubmit] = useState(false);
  const [value, setValue] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    setValue(null);
  }, []);

  const handleSave = () => {
    setIsSubmit(true);
    createFunction({
      variables: {
        id: id,
        contractorInputs: invert(value),
      },
    })
      .then((res) => {
        if (
          res.data.createContractor.findIndex(
            (item) => item.result === "NG"
          ) !== -1
        ) {
          dispatch(
            showError({
              message: "登録に失敗しました。",
            })
          );
        } else {
          dispatch(
            showSuccess({
              message: "登録しました。",
            })
          );
          navigate(`/constructions/${id}/show?tab=contractors`, {
            state: {
              preSearchWord: location?.state?.preSearchWord,
              ...location?.state,
            },
          });
        }
      })
      .catch(() => {
        dispatch(
          showError({
            message: "登録に失敗しました。",
          })
        );
      })
      .finally(() => {
        setIsSubmit(false);
      });
  };

  const handleChange = (data) => {
    setValue(data);
  };

  const handleCancel = () => {
    navigate(`/constructions/${id}/show?tab=contractors`, {
      state: {
        preSearchWord: location?.state?.preSearchWord,
        ...location?.state,
      },
    });
  };

  return render({
    loading: loading,
    error: error,
    isSubmit: isSubmit,
    value: value,
    onChange: handleChange,
    onSave: handleSave,
    onCancel: handleCancel,
    ...props,
  });
};
