import { createTheme } from "@mui/material";
import { deepPurple, indigo } from "@mui/material/colors";
import { jaJP as jaJPCore } from "@mui/material/locale";

const THEME = createTheme(
  {
    palette: {
      primary: {
        light: "#7986CB",
        main: "#3949AB",
        dark: "#283593",
        contrastText: "#fff",
      },
      secondary: {
        light: "#FFB74D",
        main: "#FB8C00",
        dark: "#EF6C00",
        contrastText: "#fff",
      },
      error: {
        light: "#e57373",
        main: "#f44336",
        dark: "#d32f2f",
        contrastText: "#fff",
      },
      required: {
        light: "#e57373",
        main: "#f44336",
        dark: "#d32f2f",
        contrastText: "#fff",
      },
      warning: {
        light: "#ffb74d",
        main: "#ffa726",
        dark: "#f57c00",
        contrastText: "#fff",
      },
      numbered: {
        main: "#43425D",
        contrastText: "#fff",
      },
      action: {
        disabled: "rgba(0, 0, 0, 0.26)",
        disabledBackground: "rgba(0, 0, 0, 0.12)",
        disabledOpacity: 0.38,
      },
      text: {
        primary: "rgba(0, 0, 0, 0.87)",
        secondary: "rgba(0, 0, 0, 0.54)",
        disabled: "#bdbdbd",
        hint: "#e0e0e0",
      },
      background: {
        paper: "#fff",
        default: "#fff",
      },
      white: {
        main: "#fff",
      },
      colorOpacity: {
        main: "#7986cb1f",
        border: "#7986cb7a",
      },
      icon: {
        toolbarUser: deepPurple[300],
        company: deepPurple[100],
        mainMenu: indigo[200],
      },
    },
    typography: {
      fontFamily: `'Avenir','Helvetica Neue','Helvetica','Arial', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro','メイリオ', 'Meiryo', '游ゴシック', 'Yu Gothic', 'ＭＳ Ｐゴシック', sans-serif`,
    },
    overrides: {
      MuiCssBaseline: {
        "@global": {
          body: {
            backgroundColor: "#fff",
          },
        },
      },
    },
  },
  jaJPCore
);

export default THEME;
