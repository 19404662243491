import React from "react";
import { Typography, Link } from "@mui/material";
/**
 * コピーライトを表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="http://www.greener.co.jp/">
        Greener
      </Link>
      {new Date().getFullYear()}
    </Typography>
  );
};
