import { Button, Paper, Stack } from "@mui/material";
import React from "react";
import { DatePicker } from "views/atoms";
import { FullScreenDialog, InputField, RadioGroup } from "views/molecules";
import { AsbestosReportType } from "utils/Constants";

/**
 * 報告フォーム表示するプレゼンテーションコンポーネントです。
 */
export const Presententer = (props) => {
  return (
    <FullScreenDialog
      open={props.open}
      onClose={props.onClose}
      title={`「${props.value.name ?? "工事名称なし"}」の報告`}
      actions={
        <Button
          autoFocus
          color="inherit"
          onClick={props.onRegist}
          disabled={props.loading}
        >
          {"登録"}
        </Button>
      }
    >
      <Paper>
        <Stack
          spacing={2}
          sx={{
            padding: 3,
          }}
        >
          <InputField
            label="申請番号"
            name="number"
            value={props.value?.number ?? ""}
            onChange={(e) => props.onChange("number")(e.target.value)}
            error={Boolean(props.errors?.number?.message)}
            helperText={props.errors?.number?.message}
            inputProps={{ maxLength: 10 }}
          />
          <DatePicker
            label={"申請日"}
            name="date"
            value={props.value?.date}
            onChange={(e) => props.onChange("date")(e)}
            error={!!props.errors?.date?.message}
            helperText={props.errors?.date?.message}
          />
          <RadioGroup
            label={"ステータス"}
            name="status"
            value={props.value?.status}
            onChange={(e) => props.onChange("status")(e.value)}
            options={[
              AsbestosReportType.Unreported,
              AsbestosReportType.Reported,
              AsbestosReportType.ReReportingRequired,
              AsbestosReportType.NoReportRequired,
            ]}
          />
        </Stack>
      </Paper>
    </FullScreenDialog>
  );
};
