import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Container,
  Dialog,
  DialogContent,
  IconButton,
  Slide,
  Toolbar,
  Box,
  Typography,
} from "@mui/material";
import React, { forwardRef } from "react";

const TRANSITION = forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const Presententer = (props) => {
  return (
    <Dialog
      fullScreen
      open={props?.open}
      TransitionComponent={TRANSITION}
      disableEnforceFocus
    >
      <AppBar>
        <Toolbar>
          <Box
            display={"flex"}
            flexDirection="row"
            justifyContent={"space-between"}
            sx={{
              width: "100%",
            }}
          >
            <Box display={"flex"} flexDirection="row" alignItems={"center"}>
              <IconButton
                edge="start"
                color="inherit"
                onClick={props.onClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography>{props.title ?? ""}</Typography>
            </Box>
            {props.actions}
          </Box>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Container
          maxWidth="xs"
          sx={{
            marginTop: 8,
          }}
        >
          {props.children}
        </Container>
      </DialogContent>
    </Dialog>
  );
};
