import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 情報の項目を表示するコンポーネントです。
 * @param {string} label ラベル 
 * @param {string} value 値
 */
export const InformationItem = containerPresententer(Container, Presententer);
