import { Dialog } from "@mui/material";
import React, { useState } from "react";
import { useWindowDimensions } from "utils/Hooks";
import { Stepper } from "./Stepper";
import { Thumbnail } from "./Thumbnail";

/**
 * 画像を表示するコンポーネントです。
 * @param {array} value 値
 * @param {number} height サムネイルの高さ
 * @param {number} width サムネイルの幅
 * @fires Gallery#onChange 変更時
 * @param {boolean} disabled 無効設定
 * @returns JSX.Element
 */
export const Gallery = ({
  value,
  height = 100,
  width = 100,
  onChange = () => {},
  disabled = false,
}) => {
  const [open, setOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const windowSize = useWindowDimensions();

  const handleClick = (index) => {
    setActiveStep(index);
    setOpen(true);
  };

  return (
    <>
      <Thumbnail
        value={value}
        height={height}
        width={width}
        onChange={onChange}
        disabled={disabled}
        onClick={handleClick}
      />
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Stepper
          step={activeStep}
          value={value}
          onChange={onChange}
          disabled={disabled}
          height={windowSize.height}
          width={windowSize.width}
        />
      </Dialog>
    </>
  );
};
