import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";

/**
 * 詳細を囲うモーダルを表示するコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Modal = (props) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      scroll={"body"}
      aria-labelledby="title"
      aria-describedby="description"
    >
      <DialogTitle id="title">{props.title}</DialogTitle>
      <DialogContent dividers={false}>{props.children}</DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>{"閉じる"}</Button>
      </DialogActions>
    </Dialog>
  );
};
