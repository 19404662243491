import React from "react";
import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";
import { Box, Typography } from "@mui/material";

/**
 * コピーライトを表示するコンポーネントです。
 */
export const Copyright = containerPresententer(Container, Presententer);

export const TextCopyRight = () => (
  <Box mb={0}>
    <Typography
      variant="caption"
      component="p"
      color="textSecondary"
      align="center"
    >
      国土交通省・経済産業省　石綿（アスベスト）含有建材データベース（2021年03月18日参照）
    </Typography>
  </Box>
);
