import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import {
  Box,
  Button,
  IconButton,
  MobileStepper,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { FileViewer } from "./FileViewer";

/**
 * ステップ方式で画像を表示するコンポーネントです。
 * @param {array} value 値
 * @fires Stepper#onChange 変更時
 * @param {number} step アクティブなステップ数
 * @param {boolean} disabled 無効設定
 * @returns JSX.Element
 */
export const Stepper = ({
  value,
  width,
  height,
  onChange,
  step = 0,
  disabled = false,
}) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = value?.length ?? 0;

  useEffect(() => {
    setActiveStep(step);
  }, [step]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleDelete = (index) => () => {
    const result = [...value];
    result.splice(index, 1);
    onChange(result);
  };

  if (!value || value.length <= 0) {
    return <></>;
  }

  return (
    <Box>
      <Paper
        square
        elevation={0}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: 50,
          pl: 2,
          bgcolor: "background.default",
        }}
      >
        <div>
          <Typography>{value?.[activeStep]?.name}</Typography>
        </div>
        {disabled === false && (
          <div>
            <IconButton onClick={handleDelete(activeStep)} size={"small"}>
              <DeleteIcon />
            </IconButton>
          </div>
        )}
      </Paper>
      <FileViewer
        file={value?.[activeStep]}
        height={height * 0.6}
      />
      <MobileStepper
        variant="text"
        steps={value.length}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            {"次へ"}
            <KeyboardArrowRight />
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            <KeyboardArrowLeft />
            {"前へ"}
          </Button>
        }
      />
    </Box>
  );
};
