import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 住所を入力するコンポーネントです。
 * @param {object} props プロパティ
 * @param {string} props.name コンポーネント名
 * @param {object} props.value 値
 * @fires AddressForm#onChange 変更時
 * @param {object} props.error エラー
 * @returns {JSX.Element} JSX.Element
 */
export const AddressForm = containerPresententer(Container, Presententer);
