import { useEffect, useState } from "react";

/**
 *引数をうけてJSX Elemntを返します。
  @callback render
 */
export const Container = ({ render, number, height = 56, width, ...props }) => {
  const [value, setValue] = useState([]);
  useEffect(() => {
    const courses = [...Array(number)];
    setValue(courses);
  }, [number]);
  return render({
    value: value,
    height: height,
    width: width,
    ...props,
  });
};
