import { Query } from "@apollo/client/react/components";
import { Alert, CircularProgress } from "@mui/material";
import { getProduct, getSanitary } from "api/graphql/queries";
import React from "react";
import { Materials } from "./Materials";
import { Products } from "./Products";
import { Modal } from "../Modal";

/**
 * 建材・製品の詳細を表示するコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Details = (props) => {
  const { value, open, onClose } = props;

  if (!value || !value?.id) {
    return <></>;
  }

  if (value.isSanitary) {
    return (
      <Modal open={open} onClose={onClose} title="詳細">
        <Query
          query={getSanitary}
          variables={{
            id: value?.id,
          }}
        >
          {({ data, error, loading }) => {
            if (error) return <Alert severity="error">{error}</Alert>;
            if (loading) {
              return <CircularProgress />;
            } else {
              return <Products value={data.getSanitary} />;
            }
          }}
        </Query>
      </Modal>
    );
  } else {
    return (
      <Modal open={open} onClose={onClose} title="詳細">
        <Query
          query={getProduct}
          variables={{
            id: value?.id,
          }}
        >
          {({ data, error, loading }) => {
            if (error) return <Alert severity="error">{error}</Alert>;
            if (loading) {
              return <CircularProgress />;
            } else {
              return <Materials value={data.getProduct} />;
            }
          }}
        </Query>
      </Modal>
    );
  }
};
