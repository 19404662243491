import { useMutation, useQuery } from "@apollo/client";
import { updateConstruction } from "api/graphql/mutations";
import { getConstruction } from "api/graphql/queries";
import { useParams } from "react-router-dom";

/**
 * 工事情報を更新するコンテナコンポーネントです
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props　その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const { id } = useParams();
  const { loading, error, data } = useQuery(getConstruction, {
    variables: { id: id },
  });

  const [updateFunction, updateOptions] = useMutation(updateConstruction);

  return render({
    id: id,
    loading: loading || updateOptions.loading,
    error: error || updateOptions.error,
    data: data?.getConstruction,
    updateFunction: updateFunction,
    ...props,
  });
};
