const defaultValue = {
  front: null,
  back: null,
};

/**
 * 郵便番号を入力するコンテナコンポーネントです。
 */
export const Container = ({
  render,
  label = "",
  value,
  onChange = () => {},
  disabled = false,
  required = false,
  error = false,
  helperText = "",
  ...props
}) => {
  const handleChange = (name) => (date) => {
    onChange({
      ...defaultValue,
      ...value,
      [name]: date,
    });
  };

  return render({
    ...props,
    label: label,
    value: {
      ...defaultValue,
      ...value
    },
    onChange: handleChange,
    disabled: disabled,
    error: error,
    helperText: helperText,
  });
};
