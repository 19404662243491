/**
 * チェックボックスのグループを表示するコンテナコンポーネントです。
 */
export const Container = ({
  render,
  name = "checkbox",
  label = "",
  value = [],
  onChange = () => {},
  required = false,
  row = false,
  disabled = false,
  error = false,
  helperText = "",
  ...props
}) => {
  const handleChange = (index) => (e) => {
    const result = value ? [...value] : [];
    if (e.target.checked) {
      result.push(e.target.value);
    } else {
      result.splice(value.indexOf(e.target.value), 1);
    }

    onChange({
      name: name,
      value: (result?.length ?? 0) <= 0 ? null : result,
    });
  };

  return render({
    ...props,
    disabled: disabled,
    name: name,
    label: label,
    value: value,
    onChange: handleChange,
    required: required,
    error: error,
    helperText: helperText,
    row: row,
  });
};
