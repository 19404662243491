import { Query } from "@apollo/client/react/components";
import { Box, Button, Grid, Link, Typography } from "@mui/material";
import { getSanitary } from "api/graphql/queries";
import React from "react";
import { InformationItem, Tel } from "views/atoms";
import { SkeletonProduct } from "views/molecules";
import { NotFound } from "views/pages";

/**
 * 詳細ページでタイトルを表示するコンポネントです。
 * @param {String} props.label タイトル
 */
const ChipTitle = (props) => {
  return (
    <Grid item xs={12}>
      <Box mt={2}>
        <Typography
          variant="h6"
          sx={{ borderBottom: "2px solid rgba(0, 0, 0, 0.42)" }}
        >
          {props.label}
        </Typography>
      </Box>
    </Grid>
  );
};

/**
 * 詳細ページの表示を行うプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <div>
    {
      <Query query={getSanitary} variables={props.variables}>
        {({ data, error, loading }) => {
          if (error) return <NotFound {...error} />;
          if (loading) {
            return <SkeletonProduct number={6} />;
          } else {
            return (
              <Grid container spacing={3}>
                {/* 一般情報 */}
                <GeneralSection {...data.getSanitary} onBack={props.onBack} />

                {/* 問い合わせ窓口 */}
                <InquiryCounterSection {...data.getSanitary} />
              </Grid>
            );
          }
        }}
      </Query>
    }
  </div>
);

const GeneralSection = (props) => {
  return (
    <Grid item xs={12}>
      <Box display={"flex"} flexDirection="row" justifyContent={"end"}>
        <Button color="inherit" variant="outlined" onClick={props.onBack}>
          {"戻る"}
        </Button>
      </Box>

      {/* 製品情報 */}
      <ChipTitle label="製品情報" />

      {/* 製品分野 */}
      <InformationItem value={props?.field} label="製品分野" />

      {/* 製品名 */}
      <InformationItem
        value={props?.names?.map((v) => v.name).join("、")}
        label="製品名"
      />

      {/* 製造メーカー */}
      <InformationItem value={props?.maker} label="製造メーカー" />

      {/* 該当製品の型番 */}
      <InformationItem
        value={props?.modelNumbers?.map((v) => v.name).join("、")}
        label="該当製品の型番"
      />

      {/* アスベストレベル */}
      <InformationItem value={props?.asbestosLevel} label="アスベストレベル" />

      {/* 使用例 */}
      <InformationItem
        value={props?.sanitaryUsefulnesses?.map((v) => v.name).join("、")}
        label="使用例"
      />

      {/* 報告用材料種別 */}
      <InformationItem value={props?.reportMaterial} label="報告用材料種別" />

      {/* 石綿の種類 */}
      <InformationItem
        value={props?.sanitaryAsbestosContains?.map((v) => v.name).join("、")}
        label="石綿の種類"
      />

      {/* 製造・輸入期間 */}
      <InformationItem
        value={
          (props?.productionStartYear === null
            ? ""
            : props?.productionStartYear + "年") +
          (props?.productionStartMonth === null
            ? ""
            : props?.productionStartMonth + "月") +
          (props?.productionStartYearRemarks === null
            ? ""
            : `(${props?.productionStartYearRemarks})`) +
          " ～ " +
          (props?.productionEndYear === null
            ? ""
            : props?.productionEndYear + "年") +
          (props?.productionEndMonth === null
            ? ""
            : props?.productionEndMonth + "月") +
          (props?.productionEndYearRemarks === null
            ? ""
            : `(${props?.productionEndYearRemarks})`)
        }
        label="製造・輸入期間"
      />

      {/* 製造・輸入の別 */}
      <InformationItem
        value={props?.manufacturingImport}
        label="製造・輸入の別"
      />

      {/* 製造・輸入数量 (累計) */}
      <InformationItem
        value={props?.productionQuantity}
        label="製造・輸入数量 (累計)"
      />

      {/* 輸入相手国 */}
      <InformationItem value={props?.importCountry} label="輸入相手国" />

      {/* 販売期間 */}
      <InformationItem
        value={
          (props?.saleStartYear === null ? "" : props?.saleStartYear + "年") +
          (props?.saleStartMonth === null ? "" : props?.saleStartMonth + "月") +
          (props?.saleStartYearRemarks === null
            ? ""
            : `(${props?.saleStartYearRemarks})`) +
          `${props?.saleStartYear || props?.saleEndYear ? " ～ " : ""}` +
          (props?.saleEndYear === null ? "" : props?.saleEndYear + "年") +
          (props?.saleEndMonth === null ? "" : props?.saleEndMonth + "月") +
          (props?.saleEndYearRemarks === null
            ? ""
            : `(${props?.saleEndYearRemarks})`)
        }
        label="販売期間"
      />

      {/* 販売状況 */}
      <InformationItem
        value={props?.salesStatus}
        label="販売の状況（8/31時点で販売を終了していない場合のみ）"
      />

      {/* 販売台数 */}
      <InformationItem value={props?.salesQuantity} label="販売台数（累計）" />

      {/* 当該家庭用品の想定使用年数 */}
      <InformationItem
        value={props?.estimatedUseYear}
        label="当該家庭用品の想定使用年数"
      />

      {/* 家庭用品中の使用箇所 */}
      <InformationItem
        value={props?.householdItemsUse}
        label="家庭用品中の使用箇所 (部位)"
      />

      {/* 石綿の使用形態 */}
      <InformationItem value={props?.asbestosUsage} label="石綿の使用形態" />

      {/* 当該製品における代替可能性 */}
      <InformationItem
        value={props?.substitutability}
        label="当該製品における代替可能性"
      />

      {/* 石綿を含有している旨の表示の有無 */}
      <InformationItem
        value={props?.asbestosContainDisplay}
        label="石綿を含有している旨の表示の有無"
      />

      {/* 石綿含有質量（%）*/}
      <InformationItem
        value={props?.asbestosContentPercentage}
        label="石綿含有質量（%）"
      />

      {/* 石綿の環境への放出可能性（通常使用時） */}
      <InformationItem
        value={
          (props?.asbestosScatterNormal === null
            ? ""
            : "【" + props?.asbestosScatterNormal + "】") +
          " " +
          (props?.asbestosScatterNormalDetail ?? "")
        }
        label="石綿の環境への放出可能性（通常使用時）"
      />

      {/* 石綿の環境への放出可能性（通常使用時以外） */}
      <InformationItem
        value={
          (props?.asbestosScatterOther === null
            ? ""
            : "【" + props?.asbestosScatterOther + "】") +
          " " +
          (props?.asbestosScatterOtherDetail ?? "")
        }
        label="石綿の環境への放出可能性（通常使用時以外）"
      />

      {/* 石綿の環境への放出可能性（廃棄時） */}
      <InformationItem
        value={
          (props?.asbestosScatterDisposal === null
            ? ""
            : "【" + props?.asbestosScatterDisposal + "】") +
          " " +
          (props?.asbestosScatterDisposalDetail ?? "")
        }
        label="石綿の環境への放出可能性（廃棄時）"
      />

      {/* クレームの有無 */}
      <InformationItem
        value={
          (props?.isClaim === false ? "【無】" : "【有】") +
          " " +
          (props?.claimDetail ?? "")
        }
        label="クレームの有無"
      />

      {/* 経済産業者の担当課 */}
      <InformationItem
        value={props?.administrativeSections?.map((v) => v.name).join("、")}
        label="経済産業者の担当課"
      />
    </Grid>
  );
};

const InquiryCounterSection = (props) => (
  <Grid item xs={12}>
    <ChipTitle label="お問い合わせ窓口" />

    <InformationItem
      value={props?.informationDesk?.name}
      label="メーカー・名称"
    />

    <InformationItem
      value={<Tel value={props?.informationDesk?.phone} />}
      label="メーカー・TEL"
    />

    <InformationItem
      value={
        <Link
          href={props?.informationDesk?.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {props?.informationDesk?.url}
        </Link>
      }
      label="メーカー・URL"
    />
  </Grid>
);
