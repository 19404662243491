import { Grid } from "@mui/material";
import React from "react";
import { FreeWord } from "./FreeWord";
import { ProductMakerSelector } from "./ProductMakerSelector";
import { ManufacturingModelYear } from "./ManufacturingModelYear";
import { MaterialSelector } from "./MaterialSelector";
import { ProductUsefulnessesSelector } from "./ProductUsefulnessesSelector";

/**
 * 建材検索のデフォルト値を表します。
 */
export const defaultValue = {
  keyword: null,
  maker: null,
  material: null,
  usefulness: null,
  year: null,
};

/**
 * 建材検索を表示するコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const MaterialSearcher = (props) => {
  const { value, onChange } = props;

  const handleChange = (name) => (data) => {
    onChange({
      ...value,
      [name]: data,
    });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FreeWord
          value={value?.keyword}
          onChange={(data) => handleChange("keyword")(data)}
        />
      </Grid>
      <Grid item xs={12}>
        <ProductMakerSelector
          value={value?.maker}
          onChange={(data) => handleChange("maker")(data)}
          product={false}
        />
      </Grid>
      <Grid item xs={12}>
        <MaterialSelector
          value={value?.material}
          onChange={(data) => handleChange("material")(data)}
        />
      </Grid>
      <Grid item xs={12}>
        <ProductUsefulnessesSelector
          value={value?.usefulness}
          onChange={(data) => handleChange("usefulness")(data)}
        />
      </Grid>
      <Grid item xs={12}>
        <ManufacturingModelYear
          value={value?.year}
          onChange={(data) => handleChange("year")(data)}
        />
      </Grid>
    </Grid>
  );
};
