const defaultValue = {
  existsAsbestos: null,
  basis: [],
  type: null,
  existsDisconnect: null,
  measure: [],
};

/**
 * 事前調査の項目を表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, title, value, ...props }) => {
  return render({
    title: title,
    value: {
      ...defaultValue,
      ...value,
    },
    ...props,
  });
};
