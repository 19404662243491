import React from "react";
import { FullScreenDialog } from "views/molecules/FullScreenDialog";
import { ApplyForm } from "./ApplyForm";
import { Importer } from "./Importer";
import { ReportForm } from "./ReportForm";
import { Table } from "./Table";

/**
 * 報告のプレゼンテーションコンポーネントです。
 */
export const Presententer = (props) => {
  return (
    <>
      <Table
        {...props.tableParams}
        selectedValue={props.selected}
        onClickApplyItem={props.onClickApplyItem}
        onClickReportItem={props.onClickReportItem}
        onCheckedChangeItem={props.onCheckedChangeItem}
        onImport={props.onImport}
      />
      {props.apply && (
        <ApplyForm
          name={props.apply.name}
          value={props.apply}
          id={props.apply.id}
          open={Boolean(props.apply)}
          onClose={props.onCloseApplyForm}
          onNotificationChange={props.onApplyNotificationChange}
        />
      )}
      <FullScreenDialog
        open={props.importer}
        onClose={props.onCloseImport}
        title="取込"
      >
        <Importer onNotificationChange={props.onImportNotificationChange} />
      </FullScreenDialog>
      {props.report && (
        <ReportForm
          value={props.report}
          open={Boolean(props.report)}
          onClose={props.onCloseReportForm}
          onNotificationChange={props.onReportNotificationChange}
        />
      )}
    </>
  );
};
