import { useState, useEffect } from "react";

/**
 * ファイルアップローダコンテナコンポーネントです。
 */
export const Container = ({
  render,
  accept = "image/*",
  dropzoneTitle = "ここにファイルをドロップ",
  buttonTitle,
  label = "",
  required = false,
  error = false,
  onChange = (params) => console.log(params),
  helperText = "",
  disabled = false,
  multiple = true,
  maxFiles,
  maxSize,
  minSize,
  ...props
}) => {
  const [message, setMessage] = useState(null);

  useEffect(() => {
    setMessage("");
  }, []);

  const handleDrop = (params) => {
    setMessage(null);
    onChange(params);
  };

  const handleRejection = (params) => {

    let result = [];
    for (let param of params) {
      for (let err of param.errors) {
        switch (err.code) {
          case "file-invalid-type":
            result.push(param.file.name + "ファイル形式が違います。");
            break;
          case "file_too_large":
            result.push(param.file.name + "ファイルサイズが大きすぎます。");
            break;
          case "file_too_small":
            result.push(param.file.name + "ファイルサイズが小さすぎます。");
            break;
          case "too-many-files":
            result.push(param.file.name + "ファイル数が多すぎます。");
            break;
          default:
            result.push(param.file.name + "不明なエラーです。");
            break;
        }
      }
    }
    setMessage(result);
  };

  return render({
    ...props,
    accept: accept,
    label: label,
    helperText: helperText,
    error: error,
    required: required,
    onDrop: handleDrop,
    onRejection: handleRejection,
    dropzoneTitle: dropzoneTitle,
    buttonTitle: buttonTitle,
    disabled: disabled,
    multiple: multiple,
    maxFiles: maxFiles,
    maxSize: maxSize,
    minSize: minSize,
    message: message,
  });
};
