import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * ARS-確認ページを表示するコンポーネントです。
 * @param {object} value 工事情報
 * @param {func} onBack 戻るボタン
 */
export const Review = containerPresententer(Container, Presententer);
