import { Box, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { ConfirmDialog, FullScreenDialog } from "views/molecules";
import { Items } from "../ConstructionList/Items";

/**
 * 類似工事案件を選択するコンポーネントです。
 * @param {array} options 選択する値
 * @fires SimilarContractionSelector#onSelected 選択された値
 * @param {boolean} open 開閉状態
 * @fires SimilarContractionSelector#onClose 閉じられた時
 * @returns {JSX.Element}
 */
export const SimilarContractionSelector = ({
  options = [],
  onSelected = (data) => console.log("選択された値: ", data),
  open = false,
  onClose = () => console.log("閉じられた"),
}) => {
  const [selected, setSelected] = useState(null);
  const [confirm, setConfirm] = useState(false);

  if (!options || !Array.isArray(options) || options.length <= 0) {
    return <></>;
  }

  return (
    <FullScreenDialog open={open} title="事前調査複製選択" onClose={onClose}>
      <Stack spacing={2}>
        <Typography>
          {"事前調査を反映する工事案件を選択してください。"}
        </Typography>
        <Box
          sx={{
            borderRadius: "12px",
            backgroundColor: "#efefef",
            padding: "10px",
            width: "100%",
            height: "65vh",
            overflow: "auto",
          }}
        >
          <Items
            value={options}
            loading={false}
            error={null}
            onClickItem={(data) => {
              setSelected(data);
              setConfirm(true);
            }}
          />
        </Box>
      </Stack>
      <ConfirmDialog
        title={"確認"}
        message={"選択した工事案件の事前調査を反映しますか？"}
        open={confirm}
        positiveText="はい"
        negativeText="いいえ"
        onPositive={() => {
          onSelected(selected);
          setConfirm(false);
          onClose();
        }}
        onNegative={() => setConfirm(false)}
      />
    </FullScreenDialog>
  );
};
