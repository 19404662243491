import { Box } from "@mui/material";
import React from "react";
import { FileViewer } from "./FileViewer";
import { Frame } from "./Frame";

/**
 * サムネイルを表示するコンポーネントです。
 * @param {array} value ファイル配列
 * @param {number} height サムネイルの高さ
 * @param {number} width サムネイルの幅
 * @fires Thumbnail#onChange 変更時
 * @fires Thumbnail#onClick ファイルクリック時
 * @param {boolean} disabled 無効設定
 * @returns JSX.Element
 */
export const Thumbnail = ({
  value,
  onChange = () => {},
  onClick = () => {},
  height,
  width,
  disabled = false,
}) => {
  const handleDelete = (index) => () => {
    const result = [...value];
    result.splice(index, 1);
    onChange(result);
  };

  const handleClick = (index) => () => {
    onClick(index);
  };

  if (!value || value.length <= 0) {
    return <></>;
  }

  return (
    <Box display="flex" flexWrap={"wrap"} justifyContent="center" gap="4px 4px">
      {value.map((item, index) => {
        return (
          <React.Fragment key={index}>
            <Frame
              disabled={disabled}
              height={height}
              width={width}
              onDelete={handleDelete(index)}
              onClick={handleClick(index)}
            >
              <FileViewer file={item} height={height} width={width} />
            </Frame>
          </React.Fragment>
        );
      })}
    </Box>
  );
};
