export const Container = ({
  render,
  open,
  title,
  children,
  onClose = () => {},
  actions,
  ...props
}) => {
  return render({
    ...props,
    open: open,
    children: children,
    onClose: onClose,
    actions: actions,
    title: title,
  });
};
