/**
 * InformationItemをグルーピングするコンテナコンポーネントです。
 * @param {*} param0
 * @returns
 */
export const Container = ({ render, title, ...props }) => {
  return render({
    title: title,
    ...props,
  });
};
