/**
 * ページャーを表示するコンテナコンポーネントです。
 */
export const Container = ({
  render,
  pageCount = 0,
  page = 0,
  hasNextPage = false,
  hasPreviousPage = false,
  onNext = () => {},
  onPrevious = () => {},
  disabled = false,
  ...props
}) => {
  return render({
    ...props,
    pageCount: pageCount,
    page: page,
    onNext: onNext,
    onPrevious: onPrevious,
    disabled: disabled,
    hasNextPage: hasNextPage,
    hasPreviousPage: hasPreviousPage,
  });
};
