/**
 * ポップアップセレクションでデータを選択するコンポーネントです。
 * @param {string} label セレクションのラベル
 * @param {string} placeholder プレースホルダー
 * @param {boolean} required 必須かどうかを設定する
 * @param {object} value 選択されたアイテムです
 * @param {func} onChange 選択するアイテムが更新するイベントです
 * @param {array} options アイテム配列
 */
export const Container = ({
  render,
  label = "タイトル",
  required = false,
  value,
  onChange,
  options = [],
  placeholder = "",
  error,
  disabled = false,
  ...props
}) => {
  return render({
    label: label,
    placeholder: placeholder,
    required: required,
    value: value,
    onChange: onChange,
    options: options,
    error: error,
    disabled: disabled,
    ...props,
  });
};
