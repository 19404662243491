import { Box, Typography } from "@mui/material";
import React from "react";

/**
 * ラベリングするコンポーネントです。
 * @param {string} title タイトル
 * @param {JSX.ELement} children 子要素
 * @returns {JSX.Element}
 */
export const Labeling = ({ title, children }) => {
  return (
    <Box display={"flex"} flexDirection="column" paddingBottom={"6px"}>
      <Typography
        sx={{
          fontSize: "11px",
          color: "gray",
        }}
      >
        {title}
      </Typography>
      {children}
    </Box>
  );
};
