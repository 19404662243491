import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * ARS-工事情報登録ページを表示するコンポーネントです。
 * @param {object} value 工事情報
 * @param {boolean} review 詳細ページの決定
 */
export const ConstructionForm = containerPresententer(Container, Presententer);
