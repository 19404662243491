import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { Grid, Box } from "@mui/material";
import clsx from "clsx";
import React from "react";
import { AsbestosLevel } from "../AsbestosLevel";
import { ModelNumber } from "../ModelNumber";
import { Warning } from "../Warning";
import { Chip } from "../Chip";
import { blue, deepPurple, green, grey, orange } from "@mui/material/colors";

/**
 * ARS-検索の結果ページを表示コンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = ({
  classes,
  onClick,
  warning,
  value,
  matches,
  ...props
}) => (
  <Grid item xs={12}>
    <Box
      sx={(theme) => ({
        boxShadow: warning
          ? "none"
          : "0px 1px 2px -1px rgb(0 0 0 / 20%), 0px 1px 2px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
        padding: "12px",
        overflow: "hidden",
        borderRadius: "4px",
        border: warning && "2px solid #ff9800",
        cursor: "pointer",
        transition: "0.25s all linear",
        "& > div": {
          marginBottom: theme.spacing(1),
        },
        "& > div:last-child": {
          marginBottom: 0,
        },
        "&:hover": {
          transition: "0.15s all linear",
          boxShadow:
            "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
        },
      })}
      onClick={onClick}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {value?.isSanitary === true && (
          <Chip
            sx={{
              backgroundColor: green[600],
            }}
            value={"製品"}
          />
        )}
        {value?.isSanitary === false && (
          <Chip
            sx={{
              backgroundColor: blue[500],
            }}
            value={"建材"}
          />
        )}
        <Chip
          sx={{
            backgroundColor: deepPurple[300],
          }}
          value={value?.fieldName}
        />
        {warning && matches === false && <Warning />}
        <AsbestosLevel level={value?.asbestosLevel} />
      </div>
      {warning && matches === true && <Warning />}
      <div
        style={{
          fontSize: "1rem",
        }}
      >
        <span>{value.makerName}</span>
      </div>
      <div
        style={{
          color: grey[800],
          fontSize: "14px",
        }}
      >
        <span>{value.name}</span>
      </div>
      <ModelNumber value={value?.modelNumber} />
    </Box>
  </Grid>
);
