import { MenuItem, TextField } from "@mui/material";
import React from "react";
import { OptionPrefectures } from "utils/Constants";

/**
 * 都道府県を選択するプレゼンテーションコンポーネントです。
 */
export const Presententer = (props) => {
  return (
    <TextField
      select
      required={props.required}
      disabled={props.disabled}
      name={props.name}
      label={props.label}
      value={props.value}
      onChange={props.onChange}
      error={Boolean(props.error)}
      helperText={props.helperText}
      InputLabelProps={{ shrink: true }}
      size="small"
    >
      <MenuItem value="">
        <em>未選択</em>
      </MenuItem>
      {OptionPrefectures.map((prefecture, index) => (
        <MenuItem key={index} value={prefecture.name}>
          {prefecture.name}
        </MenuItem>
      ))}
    </TextField>
  );
};
