import { useFormContext } from "react-hook-form";

/**
 * ARS-工事一覧ページを表示するコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, onChange, ...props }) => {
  const { control } = useFormContext();

  const handleChange = (data) => {
    onChange && onChange(data);
  };

  return render({ control: control, onChange: handleChange, ...props });
};
