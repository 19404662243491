import { useForm } from "react-hook-form";
import { useEffect, useImperativeHandle } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { basicSchema } from "./Validations";
import { getAddress } from "utils/Functions";

const defaultValue = {
  name: "",
  laborInsuranceNumber: {
    samePrimeContractor: false,
    prefecture: null,
    admin: null,
    jurisdiction: null,
    coreNumber: null,
    branchNumber: null,
    nothing: false,
  },
  postalCode: null,
  prefecture: "",
  city: "",
  town: "",
  otherAddress: "",
  phone: "",
  preliminarySurvey: null,
  analyticalSurvey: null,
  asbestosChiefName: "",
};

/**
 * 請負事業者情報を入力するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  value,
  onSubmit = () => {},
  onError = () => {},
  ...props
}) => {
  const methods = useForm({
    mode: "onBlur",
    defaultValues: {
      ...defaultValue,
      ...value,
    },
    resolver: yupResolver(basicSchema),
  });

  const { reset, trigger, formState, control, watch, clearErrors, setValue } =
    methods;

  useEffect(() => {
    reset({
      ...defaultValue,
      ...value,
    });
  }, [reset, value]);

  useImperativeHandle(props._ref, () => ({
    submit: () => {
      clearErrors();
      trigger().then((res) => {
        if (res) {
          onSubmit && onSubmit(watch());
        } else {
          onError(formState.errors);
        }
      });
    },
  }));

  const handleChangePostalCode = (value) => {
    const postalCode = `${value?.front ?? ""}${value?.back ?? ""}`;
    if (postalCode.length === 7) {
      getAddress(postalCode).then((address) => {
        const { pref = null, city = null, town = null } = address;
        setValue("postalCode", value);
        setValue("prefecture", pref);
        setValue("city", city);
        setValue("town", town);
      });
    } else {
      setValue("postalCode", value);
    }
  };

  return render({
    ...props,
    control: control,
    onChangePostalCode: handleChangePostalCode,
  });
};
