import React from "react";
import { InformationItem } from "views/atoms";
import { InformationItemGroup } from "views/molecules";
import { modeValues } from "../Searcher";

export const Searcher = ({ value }) => {
  return (
    <InformationItemGroup>
      <InformationItem
        label="検索方法"
        value={
          modeValues.find(
            (item) => item.value?.toString() === value?.mode?.toString()
          )?.label ?? ""
        }
      />
      {value?.data?.keyword && (
        <InformationItem
          label="フリーワード"
          value={value?.data?.keyword ?? ""}
        />
      )}
      {value?.data?.productField && (
        <InformationItem
          label="製品分野"
          value={value?.data?.productField?.name ?? ""}
        />
      )}
      {value?.data?.maker && (
        <InformationItem
          label="製造メーカー"
          value={value?.data?.maker?.name ?? ""}
        />
      )}
      {value?.data?.material && (
        <InformationItem
          label="建材名(一般名)"
          value={value?.data?.material?.name ?? ""}
        />
      )}
      {value?.data?.usefulness && (
        <InformationItem
          label="用途"
          value={value?.data?.usefulness?.name ?? ""}
        />
      )}
      {value?.data?.year && (
        <InformationItem
          label="製造年式"
          value={value?.data?.year?.name ?? ""}
        />
      )}
    </InformationItemGroup>
  );
};
