import React from "react";
import { ConstructionList } from "views/organisms/Constructions";

/**
 * 工事一覧を表示するのプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <ConstructionList {...props.listParams} />
);
