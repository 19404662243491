
import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * ページャーを表示するコンポーネントです。
 * @param {number} pageCount ページの総数
 * @param {number} page 現在のページ
 * @fires Pager#onNext 次へ
 * @fires Pager#onBack 前へ
 * @returns JSX.Element
 */
export const Pager = containerPresententer(Container, Presententer);
