import { grey, red } from "@mui/material/colors";

/**
 * アスベストレベルを表示するコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const AsbestosLevel = (props) => {
  const { level = 1 } = props;

  return (
    <span
      style={{
        width: "32px",
        height: "32px",
        border: "1px solid rgba(0, 0, 0, 0.23)",
        display: "block",
        marginLeft: "auto",
        textAlign: "center",
        fontSize: "30px",
        lineHeight: "26px",
        fontWeight: "bold",
        color: grey[700],
        position: "relative",
      }}
    >
      a
      <sup
        style={{
          position: "absolute",
          top: "-7px",
          right: "-8px",
          fontSize: "14px",
          width: "18px",
          lineHeight: "18px",
          height: "18px",
          backgroundColor: red[400],
          color: "#fff",
          zIndex: "10",
          textAlign: "center",
          borderRadius: "50%",
          fontWeight: "normal",
        }}
      >
        {level}
      </sup>
    </span>
  );
};
