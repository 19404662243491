/**
 * 情報の項目を表示するコンポーネントです。
 * @param {string} label ラベル
 * @returns {JSX.Element}
 */
export const Container = ({ render, label = "", value, ...props }) => {
  return render({
    ...props,
    label: label,
    value: value,
  });
};
