/**
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {JSX.Element} children 子コンポーネント
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  return render({
    ...props,
  });
};
