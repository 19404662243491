import { useMutation } from "@apollo/client";
import { Box, Button, Typography } from "@mui/material";
import { captureCsvFile } from "api/graphql/mutations";
import React from "react";
import { ApplyList } from "./ApplyList";

/**
 * CSV取り込み確認を行うコンポーネントです。
 */
export const ConfirmImportStep = ({
  value,
  result,
  onNotificationChange = () => {},
  onDecided = () => {},
  onBack = () => {},
  loading = false,
}) => {
  const [captureCsvFileFunctions, captureCsvFileOptions] = useMutation(
    captureCsvFile,
    {
      onCompleted: (data) => {
        onNotificationChange();
      },
      onError: (data) => {
        console.log(data);
      },
    }
  );

  return (
    <Box paddingTop={2} display="flex" flexDirection={"column"} width={"100%"}>
      <Typography>{"以下の内容を取り込みます。よろしいですか？"}</Typography>
      <ApplyList rows={result} />
      <Box display="flex" justifyContent="space-between">
        <Button disabled={loading} onClick={onBack} color="secondary">
          {"前へ"}
        </Button>
        <Button
          disabled={loading || captureCsvFileOptions.loading}
          onClick={() =>
            captureCsvFileFunctions({
              variables: {
                fileName: value?.name,
                csvFileData: value?.base64,
              },
            })
          }
        >
          {"取り込み"}
        </Button>
      </Box>
    </Box>
  );
};
