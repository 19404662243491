import { containerPresententer, withForwardRef } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 日付の選択をテキストで表示するコンポーネントです
 * @param {string} value 日付
 * @param {func} onChange 日付を更新するイベントです。
 * @param {string} label 日付テキストのラベル
 * @param {boolean} required 必須設定 例）true
 * @param {boolean} error エラー
 */
export const DatePicker = withForwardRef(
  containerPresententer(Container, Presententer)
);
