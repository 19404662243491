import { showError, showSuccess } from "ducks/NotificationMessages";
import { DateTime } from "luxon";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { scrollTop } from "views/organisms/AuthenticatedTemplate";

/**
 * 工事情報を更新するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  id,
  data,
  loading,
  error,
  updateFunction,
  ...props
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const formRef = useRef(null);
  const [value, setValue] = useState(null);
  const [toggle, setToggle] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    setValue(data);
  }, [data]);

  const handleSubmit = (data) => {
    setValue(data);
    setToggle(false);
  };

  const handleConfirm = () => {
    scrollTop();
    formRef.current.submit();
  };

  const handleBack = () => {
    setToggle(true);
  };

  const getDate = (value, date) => {
    if (!value) {
      return null;
    }

    if (value === "") {
      return null;
    }

    let result = DateTime.fromJSDate(value);

    if (result.isValid) {
      if (date) {
        return result.toISODate();
      } else {
        return result.toISOTime();
      }
    }

    result = DateTime.fromFormat(value, "yyyy/MM/dd");

    if (result.isValid) {
      if (date) {
        return result.toISODate();
      } else {
        return result.toISOTime();
      }
    }

    result = DateTime.fromFormat(value, "yyyy/MM");

    if (result.isValid) {
      if (date) {
        return result.toISODate();
      } else {
        return result.toISOTime();
      }
    }

    return value;
  };

  const convertCheckbox = (value) => {
    if (typeof value === "boolean") {
      return value ? "1" : "0";
    }

    return value;
  };

  const handleUpdate = () => {
    const {
      buildDate,
      constructionStartDate,
      constructionEndDate,
      asbestosWorkStartDate,
      preliminarySurveyEndDate,
      constructionOrderStatus,
      constructionFloorSpace,
      buildingFireproof,
      buildingStructure,
      buildingFloorSpace,
      laborInsuranceNoExist,
      buildDateExist,
      groundFloors,
      basementFloors,
      amountBillion,
      amountTenThousand,
      freeEntryField,
      primeContractor,
      orderer,
      work,
      ...other
    } = value;

    updateFunction({
      variables: {
        id: id,
        ...other,
        constructionOrderStatus: constructionOrderStatus,
        buildDate: getDate(buildDate, true),
        constructionStartDate: getDate(constructionStartDate, true),
        constructionEndDate: getDate(constructionEndDate, true),
        asbestosWorkStartDate: getDate(asbestosWorkStartDate, true),
        preliminarySurveyEndDate: getDate(preliminarySurveyEndDate, false),
        buildingFireproof: buildingFireproof?.id,
        buildingStructure: buildingStructure?.id,
        buildingFloorSpace:
          (buildingFloorSpace ?? "") === "" ? null : buildingFloorSpace,
        laborInsuranceNoExist: convertCheckbox(laborInsuranceNoExist),
        buildDateExist: convertCheckbox(buildDateExist),
        groundFloors: (groundFloors ?? "") === "" ? null : groundFloors,
        basementFloors: (basementFloors ?? "") === "" ? null : basementFloors,
        amountBillion: (amountBillion ?? "") === "" ? null : amountBillion,
        constructionFloorSpace:
          (constructionFloorSpace ?? "") === "" ? null : constructionFloorSpace,
        amountTenThousand:
          (amountTenThousand ?? "") === "" ? null : amountTenThousand,
        freeEntryField: freeEntryField,
        primeContractorPostNoFront: primeContractor?.PostNoFront,
        primeContractorPostNoBack: primeContractor?.PostNoBack,
        primeContractorAddressPrefecture: primeContractor?.AddressPrefecture,
        primeContractorAddressCity: primeContractor?.AddressCity,
        primeContractorAddressTown: primeContractor?.AddressTown,
        primeContractorAddressOther: primeContractor?.AddressOther,
        ordererPostNoFront: orderer?.PostNoFront,
        ordererPostNoBack: orderer?.PostNoBack,
        ordererAddressPrefecture: orderer?.AddressPrefecture,
        ordererAddressCity: orderer?.AddressCity,
        ordererAddressTown: orderer?.AddressTown,
        ordererAddressOther: orderer?.AddressOther,
        workPostNoFront: work?.PostNoFront,
        workPostNoBack: work?.PostNoBack,
        workAddressPrefecture: work?.AddressPrefecture,
        workAddressCity: work?.AddressCity,
        workAddressTown: work?.AddressTown,
        workAddressOther: work?.AddressOther,
        otherWorkInput: {
          isReactionTank: value.isReactionTank,
          isHeatingFurnace: value.isHeatingFurnace,
          isBoilerAndPressureVessel: value.isBoilerAndPressureVessel,
          isPlumbingEquipment: value.isPlumbingEquipment,
          isIncinerator: value.isIncinerator,
          isChimney: value.isChimney,
          isStorageEquipment: value.isStorageEquipment,
          isPowerGenerationEquipment: value.isPowerGenerationEquipment,
          isSubstationEquipment: value.isSubstationEquipment,
          isPowerDistributionEquipment: value.isPowerDistributionEquipment,
          isPowerTransmissionEquipment: value.isPowerTransmissionEquipment,
          isTunnelCeilingBoard: value.isTunnelCeilingBoard,
          isPlatformHouse: value.isPlatformHouse,
          isSoundInsulationWall: value.isSoundInsulationWall,
          isLightweightFillProtectionPanel:
            value.isLightweightFillProtectionPanel,
          isRailwayStationsWall: value.isRailwayStationsWall,
          isTouristElevatorHoistwayEnclosure: value.isTouristElevatorHoistwayEnclosure,
          isShip: value.isShip,
        },
      },
    })
      .then((res) => {
        if (res.data.updateConstruction.result === "OK") {
          dispatch(
            showSuccess({
              message: "更新しました。",
            })
          );
          navigate(
            `/constructions/${id}/show?tab=constructions${
              location?.state?.preSearchWord
                ? `&${location?.state?.preSearchWord}`
                : ``
            }`,
            {
              replace: true,
              state: { ...location?.state },
            }
          );
        } else {
          dispatch(
            showError({
              message: "更新に失敗しました。",
            })
          );
        }
      })
      .catch(() => {
        dispatch(
          showError({
            message: "更新に失敗しました。",
          })
        );
      });
  };

  const convert = (v) => {
    if (!v) {
      return null;
    }
    const {
      constructionOrderStatus,
      buildDateExist,
      laborInsuranceNoExist,
      primeContractorPostNoFront,
      primeContractorPostNoBack,
      primeContractorAddressPrefecture,
      primeContractorAddressCity,
      primeContractorAddressTown,
      primeContractorAddressOther,
      ordererPostNoFront,
      ordererPostNoBack,
      ordererAddressPrefecture,
      ordererAddressCity,
      ordererAddressTown,
      ordererAddressOther,
      workPostNoFront,
      workPostNoBack,
      workAddressPrefecture,
      workAddressCity,
      workAddressTown,
      workAddressOther,
    } = v;

    const convertConstructionOrderStatus = (obj) => {
      switch (obj) {
        case "交渉中":
          return "1";
        case "受注済":
          return "2";
        case "失注":
          return "3";
        default:
          return obj;
      }
    };

    return {
      ...v,
      primeContractor: {
        PostNoFront: primeContractorPostNoFront,
        PostNoBack: primeContractorPostNoBack,
        AddressPrefecture: primeContractorAddressPrefecture,
        AddressCity: primeContractorAddressCity,
        AddressTown: primeContractorAddressTown,
        AddressOther: primeContractorAddressOther,
      },
      orderer: {
        PostNoFront: ordererPostNoFront,
        PostNoBack: ordererPostNoBack,
        AddressPrefecture: ordererAddressPrefecture,
        AddressCity: ordererAddressCity,
        AddressTown: ordererAddressTown,
        AddressOther: ordererAddressOther,
      },
      work: {
        PostNoFront: workPostNoFront,
        PostNoBack: workPostNoBack,
        AddressPrefecture: workAddressPrefecture,
        AddressCity: workAddressCity,
        AddressTown: workAddressTown,
        AddressOther: workAddressOther,
      },
      constructionOrderStatus: convertConstructionOrderStatus(
        constructionOrderStatus
      ),
      buildDateExist: buildDateExist === "1",
      laborInsuranceNoExist: laborInsuranceNoExist === "1",
    };
  };

  const handleBackList = () => {
    navigate(`/constructions/${id}/show?tab=constructions`, {
      state: {
        preSearchWord: location?.state?.preSearchWord,
        ...location?.state,
      },
    });
  };

  return render({
    ...props,
    formRef: formRef,
    value: value,
    toggle: toggle,
    onSubmit: handleSubmit,
    onConfirm: handleConfirm,
    onUpdate: handleUpdate,
    onBack: handleBack,
    onBackList: handleBackList,
    convert: convert,
  });
};
