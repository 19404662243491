import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Stack,
  Typography,
  FormGroup,
  FormLabel,
  TextField,
  MenuItem,
} from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { InputField } from "views/molecules";
import { OptionPrefectures } from "utils/Constants";

const LaborInsuranceNumberField = ({ value, onChange, error }) => {
  const handleSamePrimeContractor = (e) => {
    onChange({
      nothing: false,
      ...value,
      samePrimeContractor: e.target.checked,
    });
  };

  const handleNothing = (e) => {
    onChange({
      samePrimeContractor: false,
      ...value,
      nothing: e.target.checked,
    });
  };

  const handleChange = (e) => {
    onChange({
      samePrimeContractor: false,
      nothing: false,
      ...value,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <FormGroup>
      <FormLabel
        sx={{
          fontSize: "12px",
        }}
        component="legend"
      >
        労働保険番号
      </FormLabel>
      <Stack
        spacing={2}
        sx={{
          border: "solid 1px darkgrey",
          borderRadius: "4px",
          padding: "16px",
        }}
      >
        <FormControlLabel
          control={<Checkbox onChange={handleSamePrimeContractor} />}
          checked={value?.samePrimeContractor ?? false}
          label={"元方（元請）事業と同じ"}
        />
        <Stack spacing={2} direction={"row"} alignItems="center">
          <InputField
            name="prefecture"
            value={value?.prefecture ?? ""}
            onChange={handleChange}
            inputProps={{ maxLength: 2 }}
            error={!!error?.prefecture?.message}
          />
          <span>-</span>
          <InputField
            name="admin"
            value={value?.admin ?? ""}
            onChange={handleChange}
            inputProps={{ maxLength: 1 }}
            error={!!error?.admin?.message}
          />
          <span>-</span>
          <InputField
            name="jurisdiction"
            value={value?.jurisdiction ?? ""}
            onChange={handleChange}
            inputProps={{ maxLength: 2 }}
            error={!!error?.jurisdiction?.message}
          />
          <span>-</span>
          <InputField
            name="coreNumber"
            value={value?.coreNumber ?? ""}
            onChange={handleChange}
            inputProps={{ maxLength: 6 }}
            error={!!error?.coreNumber?.message}
          />
          <span>-</span>
          <InputField
            name="branchNumber"
            value={value?.branchNumber ?? ""}
            onChange={handleChange}
            inputProps={{ maxLength: 3 }}
            error={!!error?.branchNumber?.message}
          />
        </Stack>
        <FormControlLabel
          control={<Checkbox onChange={handleNothing} />}
          checked={value?.nothing}
          label={"なし（又は不明）"}
        />
      </Stack>
      <FormHelperText error={!!error}>
        {error?.samePrimeContractor?.message ||
          error?.prefecture?.message ||
          error?.admin?.message ||
          error?.jurisdiction?.message ||
          error?.coreNumber?.message ||
          error?.branchNumber?.message ||
          error?.nothing?.message ||
          error?.message ||
          ""}
      </FormHelperText>
    </FormGroup>
  );
};

const PostalCodeInputField = ({ value, onChange, error }) => {
  const handleChange = (e) => {
    onChange({
      ...value,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <FormGroup>
      <FormLabel
        sx={{
          fontSize: "12px",
        }}
        component="legend"
        required={true}
      >
        郵便番号
      </FormLabel>
      <Stack direction={"row"} spacing={2}>
        <InputField
          name="front"
          value={value?.front ?? ""}
          onChange={handleChange}
          inputProps={{ maxLength: 3 }}
          error={!!error?.front?.message}
        />
        <span>-</span>
        <InputField
          name="back"
          value={value?.back ?? ""}
          onChange={handleChange}
          inputProps={{ maxLength: 4 }}
          error={!!error?.back?.message}
        />
      </Stack>
      <FormHelperText error={!!error}>
        {error?.front?.message || error?.back?.message || ""}
      </FormHelperText>
    </FormGroup>
  );
};

const PreliminarySurvey = ({ value, onChange, error }) => {
  const handleChange = (e) => {
    onChange({
      ...value,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Stack spacing={2}>
      <Typography>事前調査を実施した者</Typography>
      <InputField
        name="name"
        label="氏名"
        value={value?.name}
        onChange={handleChange}
        error={!!error?.name?.message}
        message={error?.name?.message}
      />
      <InputField
        name="companyName"
        label="講習実施機関の名称"
        value={value?.companyName}
        onChange={handleChange}
        error={!!error?.companyName?.message}
        message={error?.companyName?.message}
      />
    </Stack>
  );
};

const AnalyticalSurvey = ({ value, onChange, error }) => {
  const handleChange = (e) => {
    onChange({
      ...value,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Stack spacing={2}>
      <Typography>分析調査を実施した者</Typography>
      <InputField
        name="name"
        label="氏名"
        value={value?.name}
        onChange={handleChange}
        error={!!error?.name?.message}
        message={error?.name?.message}
      />
      <InputField
        name="companyName"
        label="講習実施機関の名称"
        value={value?.companyName}
        onChange={handleChange}
        error={!!error?.companyName?.message}
        message={error?.companyName?.message}
      />
    </Stack>
  );
};

/**
 * 請負事業者情報を入力するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <Stack
    p={2}
    spacing={2}
    sx={{ backgroundColor: (theme) => theme.palette.background.paper }}
  >
    <Typography>請負事業者情報</Typography>
    <Stack spacing={2}>
      <Controller
        control={props.control}
        name={"name"}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <InputField
              label={"事業者の名称"}
              value={value}
              required={true}
              onChange={onChange}
              error={!!error}
              helperText={error?.message}
            />
          );
        }}
      />
      <Controller
        control={props.control}
        name={"laborInsuranceNumber"}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <LaborInsuranceNumberField
              value={value}
              required={true}
              onChange={onChange}
              error={error}
            />
          );
        }}
      />
      <Controller
        control={props.control}
        name={"postalCode"}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <PostalCodeInputField
              value={value}
              required={true}
              onChange={props.onChangePostalCode}
              error={error}
            />
          );
        }}
      />
      <Controller
        control={props.control}
        name={"prefecture"}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <TextField
            select
            size="small"
            label={"都道府県"}
            value={value ?? ""}
            onChange={onChange}
            error={!!error}
            helperText={error?.message}
            InputLabelProps={{ shrink: true }}
          >
            {OptionPrefectures.map((prefecture, index) => (
              <MenuItem key={index} value={prefecture.name}>
                {prefecture.name}
              </MenuItem>
            ))}
          </TextField>
        )}
      />
      <Controller
        control={props.control}
        name={"city"}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <InputField
              label={"市区町村"}
              value={value}
              required={true}
              onChange={onChange}
              error={!!error}
              helperText={error?.message}
            />
          );
        }}
      />
      <Controller
        control={props.control}
        name={"town"}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <InputField
              label={"町域名"}
              value={value}
              required={true}
              onChange={onChange}
              error={!!error}
              helperText={error?.message}
            />
          );
        }}
      />
      <Controller
        control={props.control}
        name={"otherAddress"}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <InputField
              label={"その他住所（続き）"}
              value={value}
              required={false}
              onChange={onChange}
              error={!!error}
              helperText={error?.message}
            />
          );
        }}
      />
      <Controller
        control={props.control}
        name={"phone"}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <InputField
              label={"電話番号（ハイフンなし）"}
              value={value}
              required={true}
              onChange={onChange}
              error={!!error}
              helperText={error?.message}
            />
          );
        }}
      />
      <Controller
        control={props.control}
        name={"preliminarySurvey"}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <PreliminarySurvey
              value={value}
              required={true}
              onChange={onChange}
              error={error}
            />
          );
        }}
      />
      <Controller
        control={props.control}
        name={"analyticalSurvey"}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <AnalyticalSurvey
              value={value}
              required={true}
              onChange={onChange}
              error={error}
            />
          );
        }}
      />
      <Controller
        control={props.control}
        name={"asbestosChiefName"}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <Stack spacing={2}>
              <Typography>作業に係る石綿作業主任者</Typography>
              <InputField
                label={"氏名"}
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error?.message}
              />
            </Stack>
          );
        }}
      />
    </Stack>
  </Stack>
);
