import { KeyboardArrowUp } from "@mui/icons-material";
import { Box, Fab, Zoom } from "@mui/material";
import React from "react";

/**
 * トップにスクロールの表示を行うプレゼンテーションコンポーネントです。
 */
export const Presententer = (props) => {
  return (
    <Zoom in={props.trigger}>
      <Box
        onClick={props.handleClick}
        role="presentation"
        sx={{
          position: "fixed",
          bottom: (theme) => theme.spacing(15),
          right: (theme) => theme.spacing(2),
          zIndex: "1000",
        }}
      >
        <Fab color="default" size="small" aria-label="scroll back to top">
          <KeyboardArrowUp />
        </Fab>
      </Box>
    </Zoom>
  );
};
