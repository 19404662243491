import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import { ItemView } from "./ItemView";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import { ContractForm } from "./ContractForm";
import { Skeleton, FormHelperText } from "@mui/material";

const Form = React.forwardRef((props, ref) => {
  return <ContractForm {...props} _ref={ref} />;
});

const Item = ({ value, onEdit, onDelete, isSubmit, editable }) => {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="content"
        id="header"
      >
        <Typography>{value?.name ?? ""}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={2}>
          {editable && (
            <Stack spacing={2} direction="row">
              <Button variant="contained" onClick={onEdit} disabled={isSubmit}>
                編集
              </Button>
              <Button
                variant="outlined"
                onClick={onDelete}
                disabled={isSubmit}
                color="error"
              >
                削除
              </Button>
            </Stack>
          )}
          <ItemView value={value} />
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

const Items = ({ value, onEdit, onDelete, isSubmit, editable }) => {
  if (!value || value.length <= 0) {
    return <FormHelperText>{"該当の情報はありません。"}</FormHelperText>;
  } else {
    return (
      <div>
        {value?.map((item, index) => {
          return (
            <Item
              key={index}
              value={item}
              onEdit={onEdit(index, item)}
              onDelete={onDelete(index)}
              isSubmit={isSubmit}
              editable={editable}
            />
          );
        })}
      </div>
    );
  }
};

const ModalForm = ({
  value,
  open = false,
  onClose = () => {},
  onSubmit = () => {},
  formRef = null,
}) => {
  const handleSubmit = (data) => {
    onSubmit(data);
  };

  const handleDecided = () => {
    formRef.current.submit();
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "95vw",
            maxWidth: "600px",
            height: "90vh",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            overflow: "auto",
            p: 4,
          }}
        >
          <Stack spacing={2}>
            <Form value={value} onSubmit={handleSubmit} ref={formRef} />
            <Stack spacing={2} direction={"row"} justifyContent="center">
              <Button
                variant="contained"
                onClick={handleDecided}
                color="info"
                fullWidth
              >
                決定
              </Button>
              <Button
                variant="outlined"
                onClick={onClose}
                color="error"
                fullWidth
              >
                キャンセル
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Fade>
    </Modal>
  );
};

/**
 * 請負事業者フォームを表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  if (props.loading) {
    return <Skeleton />;
  }

  return (
    <>
      <Stack spacing={2}>
        {props.editable && (
          <Button
            variant="contained"
            onClick={props.onAdd}
            disabled={props.isSubmit}
          >
            追加
          </Button>
        )}
        <Items
          value={props.value}
          onEdit={props.onEdit}
          onDelete={props.onDelete}
          isSubmit={props.isSubmit}
          editable={props.editable}
        />
      </Stack>
      <ModalForm
        value={props.selected?.value}
        open={props.open}
        onClose={props.onCloseModalForm}
        onSubmit={props.onModalFormSubmit}
        formRef={props.formRef}
      />
    </>
  );
};
