import { Stack, Typography, Paper } from "@mui/material";
import React from "react";

/**
 * 項目をグルーピングするプレゼンテーションコンポーネントです。
 */
export const Presententer = (props) => {
  return (
    <Paper
      elevation={props.elevation}
      sx={{
        marginTop: "16px",
        marginBottom: "48px",
      }}
    >
      <Stack
        spacing={3}
        sx={{
          padding: "16px",
        }}
      >
        <Typography
          sx={{
            position: "relative",
            top: "-27px",
            fontWeight: props.bold && "bold",
            marginBottom: "-16px",
          }}
          variant={props.variant}
        >
          {props.title}
        </Typography>
        {props.children}
      </Stack>
    </Paper>
  );
};
