import { useMutation, useQuery } from "@apollo/client";
import {
  deleteConstruction,
  updateConstruction,
  deleteContractor,
  deleteAsbestosResearch,
  completeAsbestosResearch,
} from "api/graphql/mutations";
import {
  getConstruction,
  getContractors,
  getAsbestosResearch,
  getAsbestosReport,
} from "api/graphql/queries";
import { useParams } from "react-router-dom";
import { useEffect, useCallback } from "react";

/**
 * 工事情報を表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props　その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const { id } = useParams();
  const { loading, error, data, refetch } = useQuery(getConstruction, {
    variables: { id: id },
  });

  const contractorsResult = useQuery(getContractors, {
    variables: { id: id },
  });

  const asbestosResearchResult = useQuery(getAsbestosResearch, {
    variables: { id: id },
  });

  const asbestosReportResult = useQuery(getAsbestosReport, {
    variables: { id: id },
  });

  const [completeAsbestosResearchFunction, completeAsbestosResearchOptions] =
    useMutation(completeAsbestosResearch);

  const reload = useCallback(() => {
    return Promise.all([
      refetch(),
      contractorsResult.refetch(),
      asbestosResearchResult.refetch(),
      asbestosReportResult.refetch(),
    ]);
  }, [id]);

  useEffect(() => {
    reload();
  }, [reload]);

  const [updateFunction, updateOptions] = useMutation(updateConstruction);
  const [deleteFunction, deleteOptions] = useMutation(deleteConstruction);
  const [deleteContractorFunction, deleteContractorOptions] =
    useMutation(deleteContractor);
  const [deleteAsbestosResearchFunction, deleteAsbestosResearchOptions] =
    useMutation(deleteAsbestosResearch);

  return render({
    id: id,
    loading:
      loading ||
      updateOptions.loading ||
      deleteOptions.loading ||
      contractorsResult.loading ||
      deleteContractorOptions.loading ||
      deleteAsbestosResearchOptions.loading ||
      completeAsbestosResearchOptions.loading ||
      asbestosResearchResult.loading ||
      asbestosReportResult.loading,
    error:
      error ||
      updateOptions.error ||
      deleteOptions.error ||
      contractorsResult.error ||
      deleteContractorOptions.error ||
      deleteAsbestosResearchOptions.error ||
      completeAsbestosResearchOptions.error ||
      asbestosResearchResult.error ||
      asbestosReportResult.error,
    value: data?.getConstruction,
    contractors: contractorsResult.data?.getContractors,
    survey: asbestosResearchResult.data?.getAsbestosResearch,
    report: asbestosReportResult.data?.getAsbestosReport,
    updateFunction: updateFunction,
    deleteFunction: deleteFunction,
    deleteContractorFunction: deleteContractorFunction,
    deleteAsbestosResearchFunction: deleteAsbestosResearchFunction,
    reload: reload,
    completeAsbestosResearchFunction: completeAsbestosResearchFunction,
    ...props,
  });
};
