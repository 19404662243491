import { AppBar, Box, Tab, Tabs } from "@mui/material";
import React from "react";
import { Material, Product, Simple } from "views/organisms";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

/**
 * ARS-検索の表示を行うプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <Box pb={2}>
    <AppBar position="static">
      <Tabs
        textColor="inherit"
        value={props.valueTab}
        onChange={props.onChangeValueTab}
        centered
      >
        <Tab label="かんたん検索" {...a11yProps(0)} />
        <Tab label="製品検索" {...a11yProps(1)} />
        <Tab label="建材検索" {...a11yProps(2)} />
      </Tabs>
    </AppBar>
    <TabPanel value={props.valueTab} index={0}>
      <Simple in={props.valueTab === 0} tabValue={0} />
    </TabPanel>
    <TabPanel value={props.valueTab} index={1}>
      <Product in={props.valueTab === 1} tabValue={1} />
    </TabPanel>
    <TabPanel value={props.valueTab} index={2}>
      <Material in={props.valueTab === 2} tabValue={2} />
    </TabPanel>
  </Box>
);
