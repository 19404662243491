/**
 * データを選択するコンテナコンポーネントです。
 */
export const Container = ({
  render,
  label = "タイトル",
  required = false,
  value,
  onChange = () => {},
  options = [],
  error,
  disabled = false,
  optionValueField = "id",
  optionNameField = "name",
  helperText,
  loading = false,
  ...props
}) => {
  const handleChange = (e) => {
    onChange(
      options.find((option) => option[optionValueField] === e.target.value) ??
        null
    );
  };

  return render({
    ...props,
    label: label,
    required: required,
    value: value,
    onChange: handleChange,
    options: options,
    error: error,
    disabled: disabled,
    optionValueField: optionValueField,
    optionNameField: optionNameField,
    helperText: helperText,
    loading: loading,
  });
};
