import React from "react";

/**
 * テキストがはみ出した際に省略するコンポーネントです。
 * @param {string} children 子要素
 * @param {object} blockProps ブロック要素のプロパティ
 * @param {object} props テキスト要素のプロパティ
 * @returns {JSX.Element}
 */
export const OmitText = ({ children, blockProps, ...props }) => {
  return (
    <div
      title={children}
      style={{
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
      }}
      {...blockProps}
    >
      <span {...props}>{children}</span>
    </div>
  );
};
