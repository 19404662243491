import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 都道府県を選択するコンポーネントです。
 */
export const PrefecturesSelector = containerPresententer(
  Container,
  Presententer
);
