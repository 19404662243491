import { Stack } from "@mui/material";
import React from "react";
import { InformationItem } from "views/atoms";
import { InformationItemGroup } from "views/molecules";

const LaborInsuranceNumber = ({ value }) => {
  const getText = (value) => {
    if (
      !value?.prefecture ||
      !value?.admin ||
      !value?.jurisdiction ||
      !value?.coreNumber ||
      !value?.branchNumber
    ) {
      return "";
    }

    return `${value?.prefecture}-${value?.admin}-${value?.jurisdiction}-${value?.coreNumber}-${value?.branchNumber}`;
  };

  return (
    <InformationItem
      label="労働保険番号"
      value={
        <>
          {value?.samePrimeContractor === true ? "元方（元請）事業と同じ" : ""}
          {value?.nothing === true ? "なし（又は不明）" : ""}
          {value?.nothing === false ? getText(value) : ""}
        </>
      }
    />
  );
};

const PreliminarySurvey = ({ value }) => {
  return (
    <InformationItemGroup title="事前調査を実施した者">
      <Stack spacing={2}>
        <InformationItem label="氏名" value={value?.name ?? ""} />
        <InformationItem
          label="講習実施機関の名称"
          value={value?.companyName ?? ""}
        />
      </Stack>
    </InformationItemGroup>
  );
};

const AnalyticalSurvey = ({ value }) => {
  return (
    <InformationItemGroup title="分析調査を実施した者">
      <Stack spacing={2}>
        <InformationItem label="氏名" value={value?.name ?? ""} />
        <InformationItem
          label="講習実施機関の名称"
          value={value?.companyName ?? ""}
        />
      </Stack>
    </InformationItemGroup>
  );
};

const getAddress = ({ value }) => {
  if (!value?.prefecture) {
    return "";
  }

  return `〒${value?.postalCode?.front ?? ""}-${
    value?.postalCode?.back ?? ""
  } ${value.prefecture}${value.city}${value.town}${value.otherAddress}`;
};

/**
 * 請負事業者情報を入力するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <Stack
    p={2}
    spacing={2}
    sx={{ backgroundColor: (theme) => theme.palette.background.paper }}
  >
    <Stack spacing={2}>
      <LaborInsuranceNumber value={props?.value?.laborInsuranceNumber} />
      <InformationItem label="住所" value={getAddress(props)} />
      <InformationItem
        label="電話番号（ハイフンなし）"
        value={props?.value?.phone ?? ""}
      />
      <PreliminarySurvey value={props?.value?.preliminarySurvey} />
      <AnalyticalSurvey value={props?.value?.analyticalSurvey} />
      <InformationItemGroup title="作業に係る石綿作業主任者">
        <InformationItem
          label="氏名"
          value={props?.value?.asbestosChiefName ?? ""}
        />
      </InformationItemGroup>
    </Stack>
  </Stack>
);
