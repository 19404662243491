import { Container, Fade, Modal } from "@mui/material";
import React from "react";

/**
 * テーブルの表示を行うコンテナプレゼンテーションです
 * @param {boolean} open モーダルが有効か無効を設定
 * @param {func} onClose モーダルを閉じるイベントです
 */
export const Presententer = (props) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={props.open}
      onClose={props.onClose}
      closeAfterTransition={true}
      disableEnforceFocus
    >
      <Fade in={props.open}>
        <Container
          maxWidth="sm"
          className="modal_container"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: (theme) => theme.spacing(2),
          }}
        >
          {props.children}
        </Container>
      </Fade>
    </Modal>
  );
};
