import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 工事情報を検索するコンポーネントです
 * @param {boolean} open ダイアログのオープン
 * @param {func} onClose ダイアログをオフするイベント
 */
export const SearchForm = containerPresententer(Container, Presententer);
