import queryString from "query-string";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router";

/**
 * ARS-建材検索タブを表示するコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    reset,
    trigger,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });
  const onSubmit = async () => {
    await trigger();
    if (!Object.keys(errors ?? {}).length) {
      const Obj = {
        keyword: watch().keyword ?? "",
        makerId: watch().maker?.id ?? "",
        materialId: watch().materialName?.id ?? "",
        usefulnessId: watch().usefulness?.id ?? "",
        year: watch().workYear?.id ?? "",
        mode: "3",
      };
      for (const param in Obj) {
        if (Obj[param] === "") {
          delete Obj[param];
        }
      }

      navigate(`/search/result?${queryString.stringify({ ...Obj })}`, {
        state: {
          formValues: {
            result: { ...watch() },
            tabNumber: props.tabValue,
          },
        },
      });
    }
  };

  //製造メーカー　と製造年式
  useEffect(() => {
    if (location?.state?.formValues?.tabNumber === props.tabValue) {
      reset({
        ...location?.state?.formValues?.result,
      });
    }
  }, [reset, location, props.tabValue]);

  return render({
    ...props,
    control: control,
    handleSubmit: handleSubmit,
    onSubmit: onSubmit,
  });
};
