import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { Fab, Zoom } from "@mui/material";
import React from "react";

/**
 *　ダウンロードアイコンの表示を行うプレゼンテーションコンポーネントです。
 @param {string} label セレクションのラベル
 */
export const Presententer = (props) => {
  return (
    <Zoom
      in={props.in}
      timeout={props.transitionDuration}
      style={{
        transitionDelay: `${props.in ? props.transitionDuration.exit : 0}ms`,
      }}
      unmountOnExit
    >
      <Fab
        aria-label="download"
        sx={{
          position: "fixed",
          bottom: (theme) => theme.spacing(9),
          right: (theme) => theme.spacing(2),
        }}
        color="secondary"
        size="small"
        onClick={() => props.onClickDownload(props.material)}
      >
        <CloudDownloadIcon />
      </Fab>
    </Zoom>
  );
};
