import { useState, useRef } from "react";

/**
 * 請負事業者フォームを表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  value = [],
  onChange = (data) => console.log(data),
  loading = false,
  isSubmit = false,
  editable = true,
  ...props
}) => {
  const [selected, setSelected] = useState(null);
  const [open, setOpen] = useState(false);
  const formRef = useRef(null);

  const handleAdd = () => {
    setSelected(null);
    setOpen(true);
  };

  const handleEdit = (index, item) => () => {
    setSelected((prevState) => ({
      ...prevState,
      index: index,
      value: item,
    }));
    setOpen(true);
  };

  const handleModalFormSubmit = (data) => {
    const result = value ? [...value] : [];

    if (selected) {
      result.splice(selected.index, 1, data);
    } else {
      result.push(data);
    }

    onChange(result);
    setOpen(false);
  };

  const handleDelete = (index) => () => {
    const result = value ? [...value] : [];
    result.splice(index, 1);
    onChange(result);
  };

  return render({
    ...props,
    value: value,
    formRef: formRef,
    onAdd: handleAdd,
    onEdit: handleEdit,
    onDelete: handleDelete,
    open: open,
    selected: selected,
    onCloseModalForm: () => setOpen(false),
    onModalFormSubmit: handleModalFormSubmit,
    loading: loading,
    isSubmit: isSubmit,
    editable: editable,
  });
};
