import { Card, CardActionArea, CardContent, Chip, Stack } from "@mui/material";
import React from "react";
import { coalesce, dateFormat, showDateRange } from "utils/Functions";
import { Labeling } from "views/atoms";
import {
  AsbestosResearchStatusType,
  ConstructionOrderStatusType,
  AsbestosReportType,
} from "utils/Constants";

const PreSearchChip = ({ id }) => {
  switch (id) {
    case AsbestosResearchStatusType.Creating.value:
      return (
        <Chip
          label={AsbestosResearchStatusType.Creating.label}
          color="warning"
          size="small"
        />
      );
    case AsbestosResearchStatusType.SurveyCompleted.value:
      return (
        <Chip
          label={AsbestosResearchStatusType.SurveyCompleted.label}
          color="success"
          size="small"
        />
      );
    case AsbestosResearchStatusType.NoInvestigationRequired.value:
      return (
        <Chip
          label={AsbestosResearchStatusType.NoInvestigationRequired.label}
          color="default"
          size="small"
        />
      );
    default:
      return <></>;
  }
};

const OrderChip = ({ id }) => {
  switch (id) {
    case ConstructionOrderStatusType.Negotiating.value:
      return (
        <Chip
          label={ConstructionOrderStatusType.Negotiating.label}
          color="error"
          size="small"
        />
      );
    case ConstructionOrderStatusType.Ordered.value:
      return (
        <Chip
          label={ConstructionOrderStatusType.Ordered.label}
          color="success"
          size="small"
        />
      );
    case ConstructionOrderStatusType.Lost.value:
      return (
        <Chip
          label={ConstructionOrderStatusType.Lost.label}
          color="default"
          size="small"
        />
      );
    default:
      return <></>;
  }
};

const ReportChip = ({ id }) => {
  switch (id) {
    case AsbestosReportType.Unreported.value:
      return (
        <Chip
          label={AsbestosReportType.Unreported.label}
          color="error"
          size="small"
        />
      );
    case AsbestosReportType.Reported.value:
      return (
        <Chip
          label={AsbestosReportType.Reported.label}
          color="success"
          size="small"
        />
      );
    case AsbestosReportType.ReReportingRequired.value:
      return (
        <Chip
          label={AsbestosReportType.ReReportingRequired.label}
          color="error"
          size="small"
        />
      );
    case AsbestosReportType.NoReportRequired.value:
      return (
        <Chip
          label={AsbestosReportType.NoReportRequired.label}
          color="default"
          size="small"
        />
      );
    default:
      return <></>;
  }
};

/**
 * 工事一覧の項目を表示するコンポーネントです。
 * @param {object} value 値
 * @fires Item#onClick クリック時
 * @returns {JSX.Element}
 */
export const Item = (props) => {
  const { value, onClick } = props;

  return (
    <Card
      sx={{
        margin: 2,
      }}
      onClick={onClick}
    >
      <CardActionArea>
        <CardContent>
          <Labeling title="タグ">
            <Stack direction="row" mb={1} spacing={2}>
              <OrderChip id={value?.constructionOrderStatusId} />
              <PreSearchChip id={value?.asbestosResearchStatusId} />
              <ReportChip id={value?.asbestosReportStatusId} />
            </Stack>
          </Labeling>
          <Labeling title="名称">{coalesce(value?.name, "未設定")}</Labeling>
          <Labeling title="実施期間">
            {showDateRange(
              dateFormat(
                value?.constructionStartDate,
                "yyyy-MM-dd",
                "yyyy/MM/dd"
              ),
              dateFormat(
                value?.constructionEndDate,
                "yyyy-MM-dd",
                "yyyy/MM/dd"
              ),
              "未設定"
            )}
          </Labeling>
          <Labeling title="現場の住所">
            {coalesce(value?.address, "未設定")}
          </Labeling>
          <Labeling title="概要">{coalesce(value?.summary, "未設定")}</Labeling>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
