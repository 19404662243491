import { yupResolver } from "@hookform/resolvers/yup";
import { showError, showSuccess } from "ducks/NotificationMessages";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  defaultValue,
  invert
} from "views/organisms/Constructions/Survey/Form";
import { basicSchema } from "views/organisms/Constructions/Survey/Form/Validations";

/**
 * 事前調査を登録するコンテナコンポーネントです
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props　その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  id,
  data,
  createFunction,
  loading,
  error,
  fetchSimilarSurvey,
  similarFormOpen,
  similarConstructions,
  toggleSimilarFormOpen,
  comfirmOpen,
  toggleConfirmOpen,
  ...props
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const [warning, setWarning] = useState(null);
  const {
    trigger,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(basicSchema),
    defaultValues: {
      ...defaultValue,
      ...data,
    },
  });

  useEffect(() => {
    reset({
      ...defaultValue,
      ...data,
    });
  }, [reset, data]);

  const handleChange = (data) => {
    reset(data);
  };

  const handleBack = () => {
    navigate(`/constructions/${id}/show?tab=survey`, {
      state: {
        preSearchWord: location?.state?.preSearchWord,
        ...location?.state,
      },
    });
  };

  const handleSave = async () => {
    const result = await trigger();

    if (result) {
      createFunction({
        variables: {
          ...invert(watch(), "1"),
          id: id,
        },
      })
        .then((res) => {
          if (res.data.createAsbestosResearch.result === "OK") {
            dispatch(
              showSuccess({
                message: "途中保存しました。",
              })
            );
            navigate(
              `/constructions/${id}/show?tab=survey${
                location?.state?.preSearchWord
                  ? `&${location?.state?.preSearchWord}`
                  : ``
              }`,
              {
                replace: true,
                state: location?.state,
              }
            );
          } else {
            dispatch(
              showError({
                message: "途中保存に失敗しました。",
              })
            );
          }
        })
        .catch(() => {
          dispatch(
            showError({
              message: "途中保存に失敗しました。",
            })
          );
        });
    }
  };

  return render({
    ...props,
    value: watch(),
    onBack: handleBack,
    onChange: handleChange,
    toggle: toggle,
    loading: loading,
    error: error,
    errors: errors,
    warning: warning,
    onPositive: () => {
      setWarning(null);
      setToggle(false);
    },
    onNegative: () => setWarning(null),
    onSave: handleSave,
    similarFormOpen: similarFormOpen,
    similarConstructions: similarConstructions,
    toggleSimilarFormOpen: toggleSimilarFormOpen,
    comfirmOpen: comfirmOpen,
    toggleConfirmOpen: toggleConfirmOpen,
    fetchSimilarSurvey: fetchSimilarSurvey,
  });
};
