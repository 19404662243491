import React from "react";

/**
 * InformationItemをグルーピングするプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <div
      style={{
        margin: "16px 0 16px 0",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {props.title && <div>{props.title}</div>}
      <div
        style={{
          paddingLeft: "16px",
        }}
      >
        {props.children}
      </div>
    </div>
  );
};
