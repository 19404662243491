/**
 * 事前調査フォームの調査詳細を入力するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  value = [],
  onChange = () => {},
  errors,
  disabled = false,
  ...props
}) => {
  const handleChange = (index) => (data) => {
    let result = value ? [...value] : [];
    result.splice(index, 1, data);
    onChange(result);
  };

  const handleAdd = () => {
    let result = value ? [...value] : [];
    result.push({
      content: "",
      files: [],
    });
    onChange(result);
  };

  const handleRemove = (index) => () => {
    let result = value ? [...value] : [];
    result.splice(index, 1);
    onChange(result);
  }

  return render({
    ...props,
    value: value,
    onChange: handleChange,
    onAdd: handleAdd,
    onRemove: handleRemove,
    errors: errors,
    disabled: disabled,
  });
};
