import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import { ConfirmDialog } from "views/molecules/ConfirmDialog";
import { SimilarContractionSelector } from "views/organisms/Constructions/SimilarContractionSelector";
import { Form } from "views/organisms/Constructions/Survey";

/**
 * 事前調査を登録するプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <Box>
    <Stack direction={"row"} justifyContent="space-between">
      <Typography sx={{ mb: 2, textDecoration: "underline", fontWeight: 600 }}>
        事前調査登録
      </Typography>
      {props.toggle === false && (
        <Button
          color="inherit"
          variant="outlined"
          size="small"
          onClick={props.onBack}
        >
          戻る
        </Button>
      )}
    </Stack>
    <Form
      value={props.value}
      onChange={props.onChange}
      errors={props.errors}
      disabled={props.loading}
    />
    <Box
      sx={{
        paddingTop: 3,
      }}
    >
      <Stack direction={"row"} justifyContent="space-between" spacing={2}>
        <Button
          color="inherit"
          variant="outlined"
          size="small"
          fullWidth
          onClick={props.onSave}
          disabled={props.loading}
        >
          途中保存
        </Button>
      </Stack>
    </Box>
    <SimilarContractionSelector
      options={props.similarConstructions}
      onSelected={props.fetchSimilarSurvey}
      open={props.similarFormOpen}
      onClose={() => props.toggleSimilarFormOpen(false)}
    />
    <ConfirmDialog
      title={"確認"}
      message={
        "類似した工事案件が存在します。類似した工事案件のデータを反映しますか？"
      }
      open={props.comfirmOpen}
      positiveText="はい"
      negativeText="いいえ"
      onPositive={() => {
        props.toggleSimilarFormOpen(true);
        props.toggleConfirmOpen(false);
      }}
      onNegative={() => props.toggleConfirmOpen(false)}
    />
  </Box>
);
