import { Query } from "@apollo/client/react/components";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { Box, Button, Card, Grid, Typography } from "@mui/material";
import { getProduct } from "api/graphql/queries";
import React from "react";
import { InformationItem, Tel } from "views/atoms";
import { Download, SkeletonProduct } from "views/molecules";
import { TextCopyRight } from "views/organisms/Copyright";
import { NotFound } from "views/pages";

/**
 * 詳細ページでタイトルを表示するコンポネントです。
 * @param {String} props.label タイトル
 */
const ChipTitle = (props) => {
  return (
    <Grid item xs={12}>
      <Box mt={2}>
        <Typography
          variant="h6"
          sx={{ borderBottom: "2px solid rgba(0, 0, 0, 0.42)" }}
        >
          {props.label}
        </Typography>
      </Box>
    </Grid>
  );
};

/**
 * 詳細ページの表示を行うプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <div>
    {
      <Query query={getProduct} variables={props.variables}>
        {({ data, error, loading }) => {
          if (error) return <NotFound {...error} />;
          if (loading) {
            return <SkeletonProduct number={6} />;
          } else {
            return (
              <Grid container spacing={3}>
                {/* 一般情報 */}
                <GeneralSection {...data.getProduct} onBack={props.onBack} />

                {/* 表示 */}
                <DisplaySection {...data.getProduct} />

                {/* メーカーが想定した使用方法 */}
                <ManufacturerSection {...data.getProduct} />

                {/* 素材の形状 */}
                <ShapeSection {...data.getProduct} />

                {/* 相談窓口 */}
                <InquiryCounterSection {...data.getProduct} />

                {/* ダウンロード */}

                <Download
                  in={data.getProduct?.material.existsPDF}
                  material={data.getProduct?.material}
                />

                <Grid item xs={12}>
                  <TextCopyRight />
                </Grid>
              </Grid>
            );
          }
        }}
      </Query>
    }
  </div>
);

const GeneralSection = (props) => {
  return (
    <Grid item xs={12}>
      <Grid item xs={12}>
        <Box display={"flex"} flexDirection="row" justifyContent={"end"}>
          <Button color="inherit" variant="outlined" onClick={props.onBack}>
            {"戻る"}
          </Button>
        </Box>
      </Grid>
      {props?.deleteFlg ?? (
        <Grid item xs={12}>
          <Card
            variant="outlined"
            color="error"
            sx={{ borderWidth: "3px", borderColor: "#ff1744" }}
          >
            <Box p={2} border="secondary">
              <Typography align="center" variant="subtitle1">
                この建材は以下理由で削除されました。
              </Typography>
              <Typography align="center" variant="subtitle1">
                {props?.deleteReason ?? ""}
              </Typography>
            </Box>
          </Card>
        </Grid>
      )}
      {/* 建材情報 */}
      <ChipTitle label="建材情報" />
      {props?.asbestosLevel < 3 && (
        <Grid item xs={12}>
          <Typography>
            <ReportProblemIcon fontSize="small" /> 工事に影響があります。
          </Typography>
        </Grid>
      )}
      {/* 製品分野 */}
      <InformationItem value={props.field} label="製品分野" />
      {/* アスベストレベル */}
      <InformationItem value={props?.asbestosLevel} label="アスベストレベル" />
      {/* 使用例 */}
      <InformationItem
        value={props?.productUsefulnesses?.map((v) => v.name).join("、")}
        label="使用例"
      />
      {/* 建材名（一般名） */}
      <InformationItem value={props?.material?.name} label="建材名（一般名）" />
      {/* 製品名 */}
      <InformationItem value={props?.name} label="製品名" />
      {/* メーカー名（製造時） */}
      <InformationItem
        value={props?.manufactureMakers?.map((v) => v.name).join("、")}
        label="メーカー名（製造時）"
      />
      {/* メーカー名（現在） */}
      <InformationItem
        value={props?.currentMakers?.map((v) => v.name).join("、")}
        label="メーカー名（現在）"
      />
      {/* 型番・品番 */}
      <InformationItem value={props?.modelNo} label="型番・品番" />
      {/* 製造期間 */}
      <InformationItem
        value={`${props.periodYearStart ? props.periodYearStart + "年" : ""}～${
          props.periodYearEnd ? props.periodYearEnd + "年" : ""
        }`}
        label="製造期間"
      />
      {/* 石綿含有条件 */}
      <InformationItem
        value={props?.asbestosContentConditions}
        label="石綿含有条件"
      />
      {/* 石綿含有率・種類 */}
      <InformationItem
        value={`${props?.asbestosContentPercentage ?? ""}${
          props?.productAsbestosContains?.length > 0
            ? `(${props?.productAsbestosContains
                ?.map((v) => v.name)
                .join("、")})`
            : ""
        }`}
        label="石綿含有率・種類"
      />
      {/* 不燃材料等認定番号 */}
      <InformationItem
        value={` ${props?.certificationKind?.name ?? ""} ${
          props?.certificationNo ?? ""
        }`}
        label="不燃材料等認定番号"
      />
    </Grid>
  );
};

const DisplaySection = (props) => (
  <Grid item xs={12}>
    <ChipTitle label="表示" />

    {/* 表示：メーカー名 */}
    <InformationItem
      value={props?.displayMaker ?? "情報なし"}
      label="メーカー名"
    />

    {/* 表示：商品名 */}
    <InformationItem value={props?.displayName ?? "情報なし"} label="商品名" />

    {/* 表示：その他ロット番号 */}
    <InformationItem
      value={props?.displayLotNo ?? "情報なし"}
      label="その他ロット番号"
    />

    {/* 表示：不燃材料等認定番号 */}
    <InformationItem
      value={props?.displayCertificationNo ?? "情報なし"}
      label="不燃材料等認定番号"
    />

    {/* 表示：JIS許可番号 */}
    <InformationItem
      value={props?.displayJisNo ?? "情報なし"}
      label="JIS許可番号"
    />

    {/* 表示：aマーク */}
    <InformationItem
      value={props?.displayAMark ?? "情報なし"}
      label="aマーク"
    />

    {/* 表示：※固有の見分け方 */}
    <InformationItem value={props?.uniqueDistinction} label="※固有の見分け方" />
  </Grid>
);

const ManufacturerSection = (props) => {
  const newProps = props?.useMethodApplicables
    .reduce((acc, cur) => {
      if (!acc.includes(cur.constructionPartName)) {
        acc.push(cur.constructionPartName);
      }
      return acc;
    }, [])
    .map((item) => {
      return {
        constructionPartName: item,
        name: props?.useMethodApplicables
          .filter((i) => i.constructionPartName === item)
          .map((i) => i.name)
          .join("、"),
      };
    });
  return (
    <Grid item xs={12}>
      <ChipTitle label="メーカーが想定した使用方法" />

      {newProps?.map((v, i) => (
        <InformationItem
          key={i}
          value={v.name}
          label={v.constructionPartName}
        />
      ))}
    </Grid>
  );
};

const ShapeSection = (props) => (
  <Grid item xs={12}>
    <ChipTitle label="素材の形状" />

    <InformationItem value={props?.materialShape} label="素材・形状" />

    <InformationItem value={props?.materialShapeDetail} label="素材" />

    <InformationItem value={props?.cottonLike} label="形状" />

    <InformationItem value={props?.boardThickness} label="ボード状・厚さ" />

    <InformationItem value={props?.boardLength} label="ボード状・標準寸法" />

    <InformationItem value={props?.scrollThickness} label="巻物・厚さ" />

    <InformationItem value={props?.scrollLength} label="巻物・標準寸法" />
  </Grid>
);
const InquiryCounterSection = (props) => {
  return (
    <Grid item xs={12}>
      <ChipTitle label="相談窓口" />

      <InformationItem
        value={props?.supportMakers?.map((v) => v.name).join()}
        label="メーカー・名称"
      />

      <InformationItem
        value={
          <>
            {props?.supportMakers?.map((v, i) => (
              <Tel key={i} value={v.phone} />
            ))}
          </>
        }
        label="メーカー・TEL"
      />

      <InformationItem
        value={props?.relatedOrganization?.name}
        label="関係団体・名称"
      />

      <InformationItem
        value={<Tel value={props?.relatedOrganization?.phone} />}
        label="関係団体・TEL"
      />
    </Grid>
  );
};
