import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * ARS-日付選択コントロールをを表示するコンポーネントです。
 * @param {string} label テキストのラベル
 * @param {string} name コントロール名
 */
export const ControlDatePicker = containerPresententer(Container, Presententer);
