import { useQuery } from "@apollo/client";
import { getUniqueInformationLabel } from "api/graphql/queries";
import { useState } from "react";
import { useFormContext } from "react-hook-form";

/**
 * ARS-工事一覧ページを表示するコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const method = useFormContext();
  const [uniqueInformationLabels, setUniqueInformationLabels] = useState(null);
  useQuery(getUniqueInformationLabel, {
    onCompleted: (data) => {
      setUniqueInformationLabels(data.getUniqueInformationLabel);
    },
  });

  return render({
    ...props,
    value: method.watch(),
    control: method.control,
    uniqueInformationLabels: uniqueInformationLabels,
  });
};
