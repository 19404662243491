import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 報告一覧を表示するコンポーネントです。
 * @param {array} value 値
 * @param {array} selectedValue 選択された値
 * @param {boolean} loading 読み込み中
 * @param {object} filter 検索オブジェクト
 * @fires Table#onClickApplyItem 申請クリック時
 * @fires Table#onClickReportItem 報告クリック時
 * @fires Table#onCheckedChangeItem アイテムチェック変更時
 * @param {number} page 現在のページ
 * @param {number} pageSize ページのサイズ
 * @fires Table#onNext 次へ選択時
 * @fires Table#onBack 前へ選択時
 * @fires Table#onSearch 検索時
 */
export const Table = containerPresententer(Container, Presententer);
