import { useNavigate, useLocation } from "react-router-dom";

/**
 * 詳細ページを表示するコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleBack = () => {
    navigate(-1, {
      state: location.state,
    });
  };

  return render({ onBack: handleBack, ...props });
};
