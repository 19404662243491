import { Link, Stack } from "@mui/material";
import React from "react";
import { InformationItem, Tel } from "views/atoms";
import { InformationItemGroup } from "views/molecules";

/**
 * 製品の詳細を表示するコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Products = (props) => {
  const { value } = props;

  return (
    <Stack>
      <InformationItemGroup title="製品情報">
        <InformationItem value={value?.field} label="製品分野" />
        <InformationItem
          value={value?.names?.map((v) => v.name).join("、")}
          label="製品名"
        />
        <InformationItem value={value?.maker} label="製造メーカー" />
        <InformationItem
          value={value?.modelNumbers?.map((v) => v.name).join("、")}
          label="該当製品の型番"
        />
        <InformationItem
          value={value?.asbestosLevel}
          label="アスベストレベル"
        />
        <InformationItem
          value={value?.sanitaryUsefulnesses?.map((v) => v.name).join("、")}
          label="使用例"
        />
        <InformationItem value={value?.reportMaterial} label="報告用材料種別" />
        <InformationItem
          value={value?.sanitaryAsbestosContains?.map((v) => v.name).join("、")}
          label="石綿の種類"
        />
        <InformationItem
          value={
            (value?.productionStartYear === null
              ? ""
              : value?.productionStartYear + "年") +
            (value?.productionStartMonth === null
              ? ""
              : value?.productionStartMonth + "月") +
            (value?.productionStartYearRemarks === null
              ? ""
              : `(${value?.productionStartYearRemarks})`) +
            " ～ " +
            (value?.productionEndYear === null
              ? ""
              : value?.productionEndYear + "年") +
            (value?.productionEndMonth === null
              ? ""
              : value?.productionEndMonth + "月") +
            (value?.productionEndYearRemarks === null
              ? ""
              : `(${value?.productionEndYearRemarks})`)
          }
          label="製造・輸入期間"
        />
        <InformationItem
          value={value?.manufacturingImport}
          label="製造・輸入の別"
        />
        <InformationItem
          value={value?.productionQuantity}
          label="製造・輸入数量 (累計)"
        />
        <InformationItem value={value?.importCountry} label="輸入相手国" />
        <InformationItem
          value={
            (value?.saleStartYear === null ? "" : value?.saleStartYear + "年") +
            (value?.saleStartMonth === null
              ? ""
              : value?.saleStartMonth + "月") +
            (value?.saleStartYearRemarks === null
              ? ""
              : `(${value?.saleStartYearRemarks})`) +
            `${value?.saleStartYear || value?.saleEndYear ? " ～ " : ""}` +
            (value?.saleEndYear === null ? "" : value?.saleEndYear + "年") +
            (value?.saleEndMonth === null ? "" : value?.saleEndMonth + "月") +
            (value?.saleEndYearRemarks === null
              ? ""
              : `(${value?.saleEndYearRemarks})`)
          }
          label="販売期間"
        />
        <InformationItem
          value={value?.salesStatus}
          label="販売の状況（8/31時点で販売を終了していない場合のみ）"
        />
        <InformationItem
          value={value?.salesQuantity}
          label="販売台数（累計）"
        />
        <InformationItem
          value={value?.estimatedUseYear}
          label="当該家庭用品の想定使用年数"
        />
        <InformationItem
          value={value?.householdItemsUse}
          label="家庭用品中の使用箇所 (部位)"
        />
        <InformationItem value={value?.asbestosUsage} label="石綿の使用形態" />
        <InformationItem
          value={value?.substitutability}
          label="当該製品における代替可能性"
        />
        <InformationItem
          value={value?.asbestosContainDisplay}
          label="石綿を含有している旨の表示の有無"
        />
        <InformationItem
          value={value?.asbestosContentPercentage}
          label="石綿含有質量（%）"
        />
        <InformationItem
          value={
            (value?.asbestosScatterNormal === null
              ? ""
              : "【" + value?.asbestosScatterNormal + "】") +
            " " +
            (value?.asbestosScatterNormalDetail ?? "")
          }
          label="石綿の環境への放出可能性（通常使用時）"
        />
        <InformationItem
          value={
            (value?.asbestosScatterOther === null
              ? ""
              : "【" + value?.asbestosScatterOther + "】") +
            " " +
            (value?.asbestosScatterOtherDetail ?? "")
          }
          label="石綿の環境への放出可能性（通常使用時以外）"
        />
        <InformationItem
          value={
            (value?.asbestosScatterDisposal === null
              ? ""
              : "【" + value?.asbestosScatterDisposal + "】") +
            " " +
            (value?.asbestosScatterDisposalDetail ?? "")
          }
          label="石綿の環境への放出可能性（廃棄時）"
        />
        <InformationItem
          value={
            (value?.isClaim === false ? "【無】" : "【有】") +
            " " +
            (value?.claimDetail ?? "")
          }
          label="クレームの有無"
        />
        <InformationItem
          value={value?.administrativeSections?.map((v) => v.name).join("、")}
          label="経済産業者の担当課"
        />
      </InformationItemGroup>
      <InformationItemGroup title={"お問い合わせ窓口"}>
        <InformationItem
          value={value?.informationDesk?.name}
          label="メーカー・名称"
        />
        <InformationItem
          value={<Tel value={value?.informationDesk?.phone} />}
          label="メーカー・TEL"
        />
        <InformationItem
          value={
            <Link
              href={value?.informationDesk?.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {value?.informationDesk?.url}
            </Link>
          }
          label="メーカー・URL"
        />
      </InformationItemGroup>
    </Stack>
  );
};
