import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * loadingの時に表示するコンポーネントです。
 * @param {Number} number Skeletonの数字
 * @param {Number} width Skeleton大きさ
 * @param {Number} height Skeleton高さ
 */
export const SkeletonField = containerPresententer(Container, Presententer);
