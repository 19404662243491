import { Button, LinearProgress, Stack } from "@mui/material";
import React from "react";
import { ContractsForm } from "views/organisms/Constructions/ContractsForm";

/**
 * 請負事業者の登録を行うプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <Stack spacing={2}>
      {props.loading && <LinearProgress />}
      <ContractsForm
        onChange={props.onChange}
        value={props.value}
        editable={true}
        isSubmit={props.loading}
      />
      <Stack spacing={2} direction="row">
        <Button
          variant="contained"
          onClick={props.onSave}
          disabled={props.loading}
        >
          {"保存する"}
        </Button>
        <Button
          variant="outlined"
          onClick={props.onCancel}
          disabled={props.loading}
        >
          {"保存せずに戻る"}
        </Button>
      </Stack>
    </Stack>
  );
};
