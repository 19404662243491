import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 郵便番号を入力するコンポーネントです。
 * @param {string} name 名前
 * @param {string} label ラベル
 * @param {boolean} value 郵便番号 ({ front: null, back: null })
 * @fires PostalCodeField#onChange 変更時
 * @param {boolean} disabled 無効設定
 * @param {boolean} required 必須であるか
 * @param {boolean} error エラーであるか
 * @param {string} helperText 補助的テキスト
 */
export const PostalCodeField = containerPresententer(Container, Presententer);
