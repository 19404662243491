import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { CheckBoxGroup, RadioGroup } from "views/molecules";

const getExistsAsbestos = (value) => {
  if (!value?.existsAsbestos) {
    return undefined;
  }

  return value.existsAsbestos !== "03";
};

/**
 * 事前調査フォームの項目を表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  if (props.asbestosContainsResult.loading) {
    return <Skeleton />;
  }

  return (
    <Accordion
      expanded={props.expanded}
      onChange={() => props.setExpand(!props.expanded)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="content"
        id="header"
      >
        <Typography>{props.title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={2}>
          <RadioGroup
            row={true}
            name={`existsAsbestos`}
            value={props.value?.existsAsbestos}
            onChange={props.onChange}
            twiceRemovable={true}
            label={"石綿含有の有無"}
            options={props?.asbestosContainsResult?.data?.listAsbestosContainsResult
              ?.map((item) => ({ ...item }))
              ?.map((item) => ({
                value: item.id,
                label: item.content,
              }))}
            error={Boolean(props?.errors?.[`existsAsbestos`]?.message)}
            helperText={props?.errors?.[`existsAsbestos`]?.message}
            disabled={props.disabled}
          />
          <CheckBoxGroup
            row={true}
            name={`basis`}
            value={props.value?.basis}
            onChange={props.onChange}
            disabled={
              (getExistsAsbestos(props.value) === undefined
                ? true
                : getExistsAsbestos(props.value)) || props.disabled
            }
            label={"含有無しと判断した根拠"}
            options={[
              {
                value: "visually",
                label: "１：目視",
              },
              {
                value: "designDocuments",
                label: "２：設計図書（４を除く。）",
              },
              {
                value: "analysis",
                label: "３：分析",
              },
              {
                value: "proof",
                label: "４：建設材料等の製造者による証明",
              },
              {
                value: "date",
                label: "５：建築材料等の製造年月日",
              },
            ]}
            error={Boolean(props?.errors?.[`basis`]?.message)}
            helperText={props?.errors?.[`basis`]?.message}
          />
          <RadioGroup
            row={true}
            name={`existsDisconnect`}
            value={props.value?.existsDisconnect}
            onChange={props.onChange}
            label={"切断等の有無"}
            twiceRemovable={true}
            disabled={
              (getExistsAsbestos(props.value) === undefined
                ? true
                : !getExistsAsbestos(props.value)) || props.disabled
            }
            options={[
              {
                value: "on",
                label: "有",
              },
              {
                value: "off",
                label: "無",
              },
            ]}
            error={Boolean(props?.errors?.[`existsDisconnect`]?.message)}
            helperText={props?.errors?.[`existsDisconnect`]?.message}
          />
          <CheckBoxGroup
            row={true}
            name={`measure`}
            value={props.value?.measure}
            onChange={props.onChange}
            label={"作業時の措置"}
            disabled={
              (getExistsAsbestos(props.value) === undefined
                ? true
                : !getExistsAsbestos(props.value)) || props.disabled
            }
            options={[
              {
                value: "negativePressureIsolation",
                label: "負圧隔離",
              },
              {
                value: "isolation",
                label: "隔離",
              },
              {
                value: "wetting",
                label: "湿潤化",
              },
              {
                value: "toolUse",
                label: "呼吸用保護具の使用",
              },
            ]}
            error={Boolean(props?.errors?.[`measure`]?.message)}
            helperText={props?.errors?.[`measure`]?.message}
          />
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};
