import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * ARS-チェックボックスを表示するコンポーネントです。
 * @param {string} label テキストのラベル
 * @param {string} name コントロール名
 * @param {array} options 配列の選択
 */
export const ControlCheckbox = containerPresententer(Container, Presententer);
