
import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 工事一覧を表示するのコンポーネントです。
 * @param {array} value　工事一覧情報
 */
export const List = containerPresententer(Container, Presententer);
