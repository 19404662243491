import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useImperativeHandle } from "react";
import { useForm } from "react-hook-form";
import { basicSchema } from "views/organisms/Constructions/ConstructionForm/Validations";

const defaultValue = {
  constructionOrderStatus: "1",
  primeContractorRepresentativeName: null,
  primeContractorPostNoFront: null,
  primeContractorPostNoBack: null,
  primeContractorAddressPrefecture: null,
  primeContractorAddressCity: null,
  primeContractorAddressTown: null,
  primeContractorAddressOther: null,
  primeContractorPhone: null,
  primeContractorMailAddress: null,
  ordererName: null,
  ordererRepresentativeName: null,
  ordererPostNoFront: null,
  ordererPostNoBack: null,
  ordererAddressPrefecture: null,
  ordererAddressCity: null,
  ordererAddressTown: null,
  ordererAddressOther: null,
  laborInsuranceNoExist: false,
  laborInsuranceNoPrefecture: null,
  laborInsuranceNoAdministration: null,
  laborInsuranceNoJurisdiction: null,
  laborInsuranceNoCoreNo: null,
  laborInsuranceNoBranchNo: null,
  workPostNoFront: null,
  workPostNoBack: null,
  workAddressPrefecture: null,
  workAddressCity: null,
  workAddressTown: null,
  workAddressOther: null,
  name: null,
  summary: null,
  buildDateExist: "0",
  buildDate: null,
  buildingFireproof: null,
  buildingStructure: null,
  buildingFloorSpace: null,
  groundFloors: null,
  basementFloors: null,
  otherWorks: null,
  constructionFloorSpace: null,
  constructionStartDate: null,
  constructionEndDate: null,
  amountBillion: null,
  amountTenThousand: null,
  asbestosWorkStartDate: null,
  preliminarySurveyEndDate: null,
  analysisSurveyLocation: null,
  freeEntryField: null,
  uniqueInformation1: null,
  uniqueInformation2: null,
  uniqueInformation3: null,
  uniqueInformation4: null,
  uniqueInformation5: null,
  isBoilerAndPressureVessel: false,
  isChimney: false,
  isHeatingFurnace: false,
  isIncinerator: false,
  isLightweightFillProtectionPanel: false,
  isPlatformHouse: false,
  isPlumbingEquipment: false,
  isPowerDistributionEquipment: false,
  isPowerGenerationEquipment: false,
  isPowerTransmissionEquipment: false,
  isRailwayStationsWall: false,
  isReactionTank: false,
  isTouristElevatorHoistwayEnclosure: false,
  isShip: false,
  isSoundInsulationWall: false,
  isStorageEquipment: false,
  isSubstationEquipment: false,
  isTunnelCeilingBoard: false,
};

/**
 * ARS-フォームを表示するコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  value,
  onSubmit = () => {},
  onError = () => {},
  ...props
}) => {
  const methods = useForm({
    mode: "onBlur",
    defaultValues: {
      ...defaultValue,
      ...value,
    },
    resolver: yupResolver(basicSchema),
  });

  const { reset, trigger, formState, getValues } = methods;

  useEffect(() => {
    reset({
      ...defaultValue,
      ...value,
    });
  }, [reset, value]);

  useImperativeHandle(props._ref, () => ({
    submit: () => {
      trigger().then((res) => {
        if (res) {
          onSubmit && onSubmit(getValues());
        } else {
          onError(formState.errors);
        }
      });
    },
  }));

  return render({
    methods: methods,
    ...props,
  });
};
