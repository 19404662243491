import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 報告フォームのコンポーネントです。
 * @param {boolean} open 開閉
 * @fires ReportForm#onClose 閉じるとき
 * @param {object} value 値
 * @fires ReportForm#onNotificationChange 変更通知
 */
export const ReportForm = containerPresententer(Container, Presententer);
