import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * チェックボックスのグループを表示するコンポーネントです。
 * @param {string} name 名前
 * @param {string} label ラベル
 * @param {array} value 選択された値
 * @param {array} options 選択する値 ({ label: "", value: "" })
 * @fires CheckBoxGroup#onChange 変更時
 * @param {boolean} required 必須であるか
 * @param {boolean} row 横並びさせるか
 * @param {boolean} disabled 無効設定
 * @param {boolean} error エラー設定
 * @param {string} helperText 補助的テキスト
 */
export const CheckBoxGroup = containerPresententer(Container, Presententer);
