import yup from "utils/yup";

const itemASchema = yup
  .object()
  .shape({
    existsAsbestos: yup.string().nullable().default(null),
    basis: yup.array().nullable().default(null),
    type: yup.string().nullable().default(null),
    existsDisconnect: yup.string().nullable().default(null),
    measure: yup.array().nullable().default(null),
  })
  .default(null)
  .nullable();

const itemBSchema = yup
  .object()
  .shape({
    existsAsbestos: yup.string().nullable().default(null),
    basis: yup.array(),
    existsDisconnect: yup.string().nullable().default(null),
    measure: yup.array().nullable().default(null),
  })
  .default(null)
  .nullable();

export const basicSchema = yup.object().shape({
  asbestosWorkStartDate: yup.date().default(null).nullable(),
  preliminarySurveyEndDate: yup.date().nullable().default(null),
  analysisSurveyLocation: yup.string().default(null).nullable(),
  primeContractor: yup.object().shape({
    preSurveyorName: yup.string().name().default(null).nullable(),
    preSurveyorTrainingInstitutionName: yup
      .string()
      .em()
      .default(null)
      .nullable(),
    regulationClass: yup.object().default(null).nullable(),
    analysisName: yup.string().name().default(null).nullable(),
    affiliationName: yup.string().name().default(null).nullable(),
    analysisTrainingInstitutionName: yup.string().em().default(null).nullable(),
    asbestosWorkChiefName: yup.string().name().default(null).nullable(),
  }),
  spraying: itemASchema,
  heatRetention: itemASchema,
  chimney: itemASchema,
  forRoof: itemASchema,
  fireproof: itemASchema,
  finishingCoating: itemBSchema,
  slateWavePlate: itemBSchema,
  slateBoard: itemBSchema,
  makeupSlateForRoof: itemBSchema,
  silicicAcidCalciumBoardFirstType: itemBSchema,
  extrusionFormationCementPlate: itemBSchema,
  pulpCementPlate: itemBSchema,
  vinylFloorTile: itemBSchema,
  steamingSystemSiding: itemBSchema,
  plasterboard: itemBSchema,
  lockWoolSoundAbsorbingCeilingBoard: itemBSchema,
  otherMaterial: itemBSchema,
});
