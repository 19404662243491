import { Query } from "@apollo/client/react/components";
import Alert from "@mui/material/Alert";
import { listProductFields } from "api/graphql/queries";
import React from "react";
import { SelectItem, SkeletonField } from "views/molecules";

/**
 * 製品分野の選択を行うコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const ProductSelector = (props) => {
  const { onChange, value, sanitary = false } = props;

  return (
    <Query query={listProductFields} errorPolicy="all">
      {({ data, loading, error }) => {
        if (loading) return <SkeletonField number={1} />;
        if (error) return <Alert severity="error">{error}</Alert>;
        if (!loading) {
          return (
            <SelectItem
              name="product"
              value={value}
              onChange={onChange}
              label="製品分野"
              options={
                sanitary
                  ? data.listProductFields.filter((v) => v.isSanitary === true)
                  : data.listProductFields
              }
            />
          );
        }
      }}
    </Query>
  );
};
