import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import { InformationItem } from "views/atoms";
import { Gallery } from "views/molecules";
import { InvestigationDetailView } from "../../Form/Details/InvestigationDetailForm/View";

/**
 * 事前調査の調査詳細を表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <Box
      border="solid #E5E5E5 1px"
      borderRadius={"5px"}
      padding="6px"
      marginBottom="24px"
    >
      <Stack spacing={3}>
        {props.value?.content && (
          <InformationItem label={"内容"} value={props.value?.content} />
        )}
        {props.value?.investigate?.conditions && (
          <InformationItem
            title={"調査"}
            value={
              <Button
                variant="outlined"
                color="primary"
                onClick={() => props.toggle(true)}
              >
                {"設定情報を見る"}
              </Button>
            }
          />
        )}
        {props.value?.files && props.value.files.length > 0 && (
          <div>
            <Typography
              sx={{
                fontSize: "12px",
                color: "darkgray",
              }}
            >
              {"ファイル"}
            </Typography>
            <Gallery value={props.value?.files} disabled={true} />
          </div>
        )}
      </Stack>
      <InvestigationDetailView
        value={props.value?.investigate}
        open={props.open}
        onClose={() => props.toggle(false)}
      />
    </Box>
  );
};
