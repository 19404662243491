import React from "react";
import { OptionYears } from "utils/Constants";
import { SelectItem } from "views/molecules";

/**
 * 製造年式を選択するコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const ManufacturingModelYear = (props) => {
  const { value, onChange } = props;

  return (
    <SelectItem
      name="manufacturingModelYear"
      value={value}
      onChange={onChange}
      label="製造年式"
      options={OptionYears}
    />
  );
};
