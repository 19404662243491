import React, { useState, useEffect } from "react";
import { FullScreenDialog } from "views/molecules";
import { Searcher, defaultValue as seacherOfDefaultValue } from "./Searcher";
import { List } from "./List";
import { Details } from "./Details";
import { Button, Stack, Slide } from "@mui/material";

/**
 * 調査詳細のデフォルト値を表します。
 */
export const defaultValue = {
  // 検索条件
  conditions: seacherOfDefaultValue,
  // 検索結果
  results: null,
  // アスベストの判断
  judgements: null,
};

const options = [
  {
    title: "調査詳細の入力 - 検索条件",
    value: "conditions",
    Form: (props) => (
      <Slide
        key={props.key}
        direction="right"
        in={props.in ?? false}
        mountOnEnter
        unmountOnExit
      >
        <div>
          <Searcher
            value={props.value.conditions}
            onChange={props.onChangeConditions}
          />
        </div>
      </Slide>
    ),
    Actions: (props) => (
      <Button
        variant="text"
        color="inherit"
        onClick={props.onSearch}
        disabled={props.disabled}
      >
        {"検索"}
      </Button>
    ),
  },
  {
    title: "調査詳細の入力 - 検索結果とアスベスト判断",
    value: "results",
    Form: (props) => (
      <Slide
        key={props.key}
        direction="right"
        in={props.in ?? false}
        mountOnEnter
        unmountOnExit
      >
        <div>
          <List
            value={props.value}
            conditions={props.value.conditions}
            disabled={props.disabled}
            onChange={props.onChangeList}
            onClick={props.onClick}
          />
        </div>
      </Slide>
    ),
    Actions: (props) => (
      <Stack flexDirection="row">
        <Button
          variant="text"
          color="inherit"
          onClick={props.onBack}
          disabled={props.disabled}
        >
          {"戻る"}
        </Button>
        <Button
          variant="text"
          color="inherit"
          onClick={props.onSave}
          disabled={props.disabled}
        >
          {"保存"}
        </Button>
      </Stack>
    ),
  },
];

/**
 * 調査詳細を入力するコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const InvestigationDetailForm = (props) => {
  const [details, setDetails] = useState(null);
  const [formValue, setFormValue] = useState(defaultValue);
  const [formStep, setFormStep] = useState("conditions");
  const { open = false, onClose = () => {}, onSave, value, disabled } = props;

  useEffect(() => {
    if (open) {
      setFormStep("conditions");
      setFormValue({
        ...defaultValue,
        ...value,
      });
    }
  }, [value, open]);

  const handleBack = () => {
    setFormStep("conditions");
  };

  const handleSearch = () => {
    setFormStep("results");
  };

  const handleChangeConditions = (data) => {
    setFormValue((prevState) => ({
      ...prevState,
      results: null,
      judgements: null,
      conditions: data,
    }));
  };

  const handleChangeList = (data) => {
    setFormValue((prevState) => ({
      ...prevState,
      ...data,
    }));
  };

  const option = options.find((o) => o.value === formStep);

  return (
    <>
      <FullScreenDialog
        open={open}
        title={option.title}
        onClose={onClose}
        actions={option.Actions({
          onBack: handleBack,
          onSearch: handleSearch,
          onSave: () => onSave(formValue),
        })}
      >
        {options.map((option, index) =>
          option.Form({
            key: index,
            value: formValue,
            disabled: disabled,
            in: formStep === option.value,
            onChangeConditions: handleChangeConditions,
            onChangeList: handleChangeList,
            onClick: (data) => {
              setDetails(data);
            },
          })
        )}
      </FullScreenDialog>
      <Details
        value={details}
        open={Boolean(details)}
        onClose={() => setDetails(null)}
      />
    </>
  );
};
