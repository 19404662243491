import { Query } from "@apollo/client/react/components";
import Alert from "@mui/material/Alert";
import {
  listUsefulnesses
} from "api/graphql/queries";
import React from "react";
import { ComboBox, SkeletonField } from "views/molecules";

/**
 * 用途を選択するコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const UsefulnessSelector = (props) => {
  const { value, onChange } = props;

  return (
    <Query query={listUsefulnesses}>
      {({ data, error, loading }) => {
        if (loading) return <SkeletonField number={1} />;
        if (error) return <Alert severity="error">{error}</Alert>;
        return (
          <ComboBox
            name="usefulness"
            value={value}
            onChange={onChange}
            label="用途"
            options={data.listUsefulnesses}
          />
        );
      }}
    </Query>
  );
};
