import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 事前調査フォームの調査詳細を入力するコンポーネントです。
 * @param {object} value 値
 * @fires Item#onChange 変更時
 * @fires Item#onRemove 除外時
 * @param {object} errors エラー
 */
export const Item = containerPresententer(Container, Presententer);
