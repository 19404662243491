import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  FormHelperText,
} from "@mui/material";
import React from "react";

/**
 * ラジオボタングループを表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 */
export const Presententer = (props) => {
  return (
    <FormControl>
      <FormLabel
        required={props.required}
        id="radio-buttons-group-label"
        sx={{
          fontSize: "12px",
        }}
      >
        {props?.label ?? ""}
      </FormLabel>
      <RadioGroup
        row={props.row}
        aria-labelledby="radio-buttons-group-label"
        defaultValue={props.defaultValue}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
      >
        {console.log(props.options)}
        {props.options.map((option, index) => (
          <FormControlLabel
            key={index}
            label={option?.label}
            value={option?.value}
            control={
              <Radio
                disabled={props.disabled}
                onClick={props.onClickRadio(option?.value)}
              />
            }
          />
        )) ?? <></>}
      </RadioGroup>
      <FormHelperText error={props.error}>{props.helperText}</FormHelperText>
    </FormControl>
  );
};
