import NewReleasesIcon from "@mui/icons-material/NewReleases";
import WarningIcon from "@mui/icons-material/Warning";
import {
  Avatar,
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";

/**
 * ARS-ホームのコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <Grid container direction="column" spacing={5}>
    {(props.alert?.incompleteConstruction === true && props.roleName !== "user" ||
      props.alert?.unapplied === true && props.roleName !== "user" && props.roleName !== "controller" ||
      props.alert?.needToWithdraw === true && props.roleName !== "user" && props.roleName !== "controller") && (
      <Grid item>
        <Paper elevation={4}>
          <List disablePadding={true}>
            <ListItem>
              <ListItemAvatar>
                <Avatar
                  sx={{
                    color: "#fff",
                    backgroundColor: "red",
                  }}
                >
                  <WarningIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography
                    variant="h5"
                    display="inline"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    アラート
                  </Typography>
                }
              />
            </ListItem>
            {props.alert?.incompleteConstruction === true && props.roleName !== "user" && (
              <>
                <Divider component="li" />
                <ListItem button onClick={props.onClickIncompleteSurveryAlert}>
                  <ListItemText
                    primary={
                      <Typography variant="body1">
                        事前調査が未完了の工事があります。
                      </Typography>
                    }
                  />
                </ListItem>
              </>
            )}
            {props.alert?.unapplied === true && props.roleName !== "user" && props.roleName !== "controller" && (
              <>
                <Divider component="li" />
                <ListItem button onClick={props.onClickUnappliedAlert}>
                  <ListItemText
                    primary={
                      <Typography variant="body1">
                        事前調査結果が未申請の工事があります。
                      </Typography>
                    }
                  />
                </ListItem>
              </>
            )}
            {props.alert?.needToWithdraw === true && props.roleName !== "user" && props.roleName !== "controller" && (
              <>
                <Divider component="li" />
                <ListItem button onClick={props.onClickWithdrawalAlert}>
                  <ListItemText
                    primary={
                      <Typography variant="body1">
                        申請内容の変更または取下げが必要な事前調査があります。
                      </Typography>
                    }
                  />
                </ListItem>
              </>
            )}
          </List>
        </Paper>
      </Grid>
    )}
    <Grid item>
      <Paper elevation={4}>
        <List disablePadding={true}>
          <ListItem>
            <ListItemAvatar>
              <Avatar
                sx={{
                  color: "#fff",
                  backgroundColor: (theme) => theme.palette.secondary.main,
                }}
              >
                <NewReleasesIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography
                  variant="h5"
                  display="inline"
                  //className={classes.home_label}
                >
                  News
                </Typography>
              }
            />
          </ListItem>
          <ListItem button>
            <ListItemText
              primary={
                <Box>
                  <Typography variant="caption">
                    ARS(Asbestos Research System) Ver.2.1 リリース
                  </Typography>
                  <Typography variant="subtitle2" color="textSecondary">
                    2022年05月01日
                  </Typography>
                  <Typography variant="caption" paragraph>
                    ARS(Asbestos Research System) Ver.2.1 をリリースいたしました。
                  </Typography>
                </Box>
              }
            />
          </ListItem>
          <ListItem button>
            <ListItemText
              primary={
                <Box>
                  <Typography variant="caption">
                    アスベスト含有製品検索機能 Ver.1.1 リリース
                  </Typography>
                  <Typography variant="subtitle2" color="textSecondary">
                    2021年06月01日
                  </Typography>
                  <Typography variant="caption" paragraph>
                    アスベスト含有製品検索機能 Ver.1.1 をリリースいたしました。
                  </Typography>
                </Box>
              }
            />
          </ListItem>
          <ListItem button>
            <ListItemText
              primary={
                <Box>
                  <Typography variant="caption">
                    アスベスト含有建材検索機能リリース
                  </Typography>
                  <Typography variant="subtitle2" color="textSecondary">
                    2021年04月01日
                  </Typography>
                  <Typography variant="caption" paragraph>
                    アスベスト含有建材検索機能をリリースいたしました。
                  </Typography>
                </Box>
              }
            />
          </ListItem>
        </List>
      </Paper>
    </Grid>
  </Grid>
);
