import { useTheme } from "@mui/material";
import authService from "api/api-authorization/AuthorizeService";

/**
 *ポップアップセレクションでデータを選択するコンポーネントです。
 *引数をうけてJSX Elemntを返します
 */
export const Container = ({ render, ...props }) => {
  const theme = useTheme();
  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const handleClickDownload = (material) => {
    authService.getAccessToken().then((token) => {
      fetch(`download?id=${material.id}`, {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + token,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("network error");
          }
          return response.blob();
        })
        .then((blob) => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.href = url;
          a.download = material.name;
          document.body.appendChild(a);
          a.click();
          a.remove();
        })
        .catch((error) => {
          console.error("download error:", error);
        });
    });
  };
  return render({
    transitionDuration: transitionDuration,
    onClickDownload: handleClickDownload,
    ...props,
  });
};
