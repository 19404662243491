import { Link as LinkMateial, Typography } from "@mui/material";
import React from "react";

/**
 * コピーライトを表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
const Presententer = (props) => {
  const { providerName, systemUrl } = props;
  return (
    <Typography color="textSecondary" align="center" variant="caption">
      {"© "}
      <LinkMateial
        color="inherit"
        href={systemUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        {` ${providerName}. `}
      </LinkMateial>
      {"All Right Reserved."}
    </Typography>
  );
};

export default Presententer;
