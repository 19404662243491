import { blobToDataURL } from "utils/Functions";
import { useState } from "react";

const defaultValue = {
  content: "",
  investigate: null,
  files: [],
};

/**
 * 事前調査フォームの調査詳細を入力するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  title = "調査詳細",
  value = defaultValue,
  onChange = () => {},
  onRemove = () => {},
  errors,
  disabled = false,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [openView, setOpenView] = useState(false);
  const handleChangeDetails = (e) => {
    onChange({
      ...value,
      content: e.target.value,
    });
  };

  const convertFile = async (file) => {
    return {
      name: file.name,
      size: file.size,
      type: file.type,
      base64: await blobToDataURL(file),
    };
  };

  const handleUploadFiles = async (files) => {
    const result = value?.files ? [...value.files] : [];

    onChange({
      ...value,
      files: result.concat(
        await Promise.all(files?.map((file) => convertFile(file)))
      ),
    });
  };

  const handleChangeFiles = (data) => {
    onChange({
      ...value,
      files: data,
    });
  };

  const handleChangeInvestigate = (data) => {
    onChange({
      ...value,
      investigate: data,
    });
    setOpen(false);
  };

  const handleDeleteInvestigate = () => {
    onChange({
      ...value,
      investigate: null,
    });
  };

  return render({
    ...props,
    title: title,
    value: value,
    onChangeDetails: handleChangeDetails,
    onChangeFiles: handleChangeFiles,
    onFileUpload: handleUploadFiles,
    onChangeInvestigate: handleChangeInvestigate,
    onDeleteInvestigate: handleDeleteInvestigate,
    onRemove: onRemove,
    errors: errors,
    disabled: disabled,
    open: open,
    toggleInvestigateForm: (value) => () => setOpen(value),
    openView: openView,
    toggleInvestigateView: (value) => () => setOpenView(value),
  });
};
