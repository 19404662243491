import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import { Form } from "views/organisms/Constructions/Survey";

/**
 * 事前調査を更新するプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <Box>
    <Stack direction={"row"} justifyContent="space-between">
      <Typography sx={{ mb: 2, textDecoration: "underline", fontWeight: 600 }}>
        事前調査更新
      </Typography>
      <Button
        color="inherit"
        variant="outlined"
        size="small"
        onClick={props.onBack}
      >
        戻る
      </Button>
    </Stack>
    <Form
      value={props.value}
      onChange={props.onChange}
      errors={props.errors}
      disabled={props.loading}
    />
    <Box
      sx={{
        paddingTop: 3,
      }}
    >
      <Stack direction={"row"} justifyContent="space-between" spacing={2}>
        {props.loading === false && (
          <Button
            color="inherit"
            variant="outlined"
            size="small"
            fullWidth
            onClick={props.onSave}
          >
            途中保存
          </Button>
        )}
        {props.loading === true && (
          <LoadingButton size="small" loading variant="outlined" fullWidth>
            {"途中保存"}
          </LoadingButton>
        )}
      </Stack>
    </Box>
  </Box>
);
