/**
 * 項目をグルーピングするコンテナコンポーネントです。
 * @callback render
 */
export const Container = ({
  render,
  title,
  elevation = 3,
  variant = "body1",
  bold = true,
  ...props
}) => {
  return render({
    title: title,
    elevation: elevation,
    variant: variant,
    bold: bold,
    ...props,
  });
};
