import { useMutation, useQuery } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  createAsbestosReport,
  updateAsbestosReport,
} from "api/graphql/mutations";
import { getAsbestosReport } from "api/graphql/queries";
import { showError, showSuccess } from "ducks/NotificationMessages";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import {
  convert,
  defaultValues,
  invert,
} from "views/organisms/Constructions/Apply/Form";
import { basicSchema } from "views/organisms/Constructions/Apply/Form/Validations";

/**
 * 申請フォームを表示するコンテナコンポーネントです。
 */
export const Container = ({
  render,
  open = false,
  onClose = () => {},
  id,
  name = "",
  onNotificationChange = () => {},
  ...props
}) => {
  const dispatch = useDispatch();
  const [isUpdate, setIsUpdate] = useState(false);
  const [dialog, setDialog] = useState(false);
  const form = useForm({
    resolver: yupResolver(basicSchema),
    defaultValues: defaultValues,
  });
  const get = useQuery(getAsbestosReport, {
    variables: {
      id: id,
    },
  });
  const [createFunction, createOptions] = useMutation(createAsbestosReport);
  const [updateFunction, updateOptions] = useMutation(updateAsbestosReport);

  useEffect(() => {
    get
      .refetch({
        id: id,
      })
      .then((res) => {
        setIsUpdate(!!res.data.getAsbestosReport);
        form.reset(convert(res.data.getAsbestosReport));
      });
  }, [id]);

  const upsert = (value) => {
    if (isUpdate) {
      return updateFunction({
        variables: {
          ...invert(value),
          id: id,
        },
      });
    } else {
      return createFunction({
        variables: {
          ...invert(value),
          id: id,
        },
      });
    }
  };

  const handleSaveApply = async () => {
    const result = await form.trigger();
    if (result) {
      setDialog(true);
    }
  };

  const handlePositive = () => {
    upsert(form.watch())
      .then((res) => {
        if (
          res.data?.createAsbestosReport?.result === "OK" ||
          res.data?.updateAsbestosReport?.result === "OK"
        ) {
          dispatch(
            showSuccess({
              message: "保存しました。",
            })
          );
          onNotificationChange();
        } else {
          dispatch(
            showError({
              message: "保存に失敗しました。",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          showError({
            message: "保存に失敗しました。",
          })
        );
      });
  };

  const handleChange = (value) => {
    form.reset(value);
  };

  return render({
    ...props,
    open: open,
    onClose: onClose,
    loading:
      (get?.loading ?? true) || createOptions.loading || updateOptions.loading,
    dialog: dialog,
    onSaveApply: handleSaveApply,
    errors: form?.formState?.errors,
    onPositive: handlePositive,
    onNegative: () => setDialog(false),
    onChange: handleChange,
    value: form.watch(),
    name: name,
  });
};
