import { useFormContext } from "react-hook-form";

/**
 * ARS-工事一覧ページを表示するコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const { control } = useFormContext();
  return render({ control: control, ...props });
};
