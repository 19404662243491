import React from "react";
import { Img } from "./Img";
import { OtherFile } from "./OtherFile";
import { PDF } from "./PDF";

/**
 * ファイルを表示するコンポーネントです。
 * @param {object} file ファイルオブジェクト(name, type, base64, size)
 * @param {number} height 高さ
 * @param {number} width 幅
 * @returns JSX.Element
 */
export const FileViewer = ({ file, height, width }) => {
  const isImage = (type) => type?.startsWith("data:image/") ?? false;
  const isPDF = (type) => type?.startsWith("data:application/pdf") ?? false;
  return (
    <>
      {isImage(file.base64) && (
        <Img alt={file.name} src={file.base64} height={height} width={width} />
      )}
      {isPDF(file.base64) && (
        <PDF file={file.base64} height={height} width={width} />
      )}
      {!isImage(file.base64) && !isPDF(file.base64) && (
        <OtherFile name={file.name} type={file.type} base64={file.base64} />
      )}
    </>
  );
};
