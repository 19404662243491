import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { createAsbestosResearch } from "api/graphql/mutations";
import {
  getAsbestosResearch,
  getConstruction,
  searchConstructionOrders,
} from "api/graphql/queries";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { convert } from "views/organisms/Constructions/Survey/Form";

/**
 * 事前調査を登録するコンテナコンポーネントです
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props　その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const [similarSurvey, setSimilarSurvey] = useState(null);
  const [similarConstructions, setSimilarConstructions] = useState([]);
  const [similarFormOpen, setSimilarFormOpen] = useState(false);
  const [comfirmOpen, setConfirmOpen] = useState(false);
  const [createFunction, { loading, error }] = useMutation(
    createAsbestosResearch
  );
  const { id } = useParams();
  const [searchConstructionOrdersFunction, searchConstractionOrdersParams] =
    useLazyQuery(searchConstructionOrders, {
      onCompleted: (data) => {
        const resSimilarConstructions =
          data.searchConstructionOrders.items?.filter((item) => item.id !== id);
        setSimilarConstructions(resSimilarConstructions);
        setConfirmOpen(resSimilarConstructions.length > 0);
      },
    });

  const constructionResult = useQuery(getConstruction, {
    variables: { id: id },
    onCompleted: (res) => {
      const result = res.getConstruction;
      const {
        workPostNoFront,
        workPostNoBack,
        workAddressPrefecture,
        workAddressCity,
        workAddressTown,
        workAddressOther,
      } = result;

      if (
        !!workPostNoFront ||
        !!workPostNoBack ||
        !!workAddressPrefecture ||
        !!workAddressCity ||
        !!workAddressTown ||
        !!workAddressOther
      ) {
        searchConstructionOrdersFunction({
          variables: {
            filter: {
              workPostNoFront: workPostNoFront,
              workPostNoBack: workPostNoBack,
              workAddressPrefecture: workAddressPrefecture,
              workAddressCity: workAddressCity,
              workAddressTown: workAddressTown,
              workAddressOther: workAddressOther,
              isResearchStatusUnfinished: false,
              isResearchStatusCreated: true,
              isResearchStatusUnnecessary: false,
            },
          },
        });
      }
    },
  });

  const [asbestosResearch, asbestosResearchParams] = useLazyQuery(
    getAsbestosResearch,
    {
      variables: {
        id: id,
      },
      onCompleted: (data) => {
        if (data.getAsbestosResearch) {
          setSimilarSurvey(convert(data.getAsbestosResearch));
        }
      },
    }
  );

  const fetchSimilarSurvey = (data) => {
    asbestosResearch({
      variables: {
        id: data.id,
      },
    });
  };

  return render({
    ...props,
    id: id,
    loading:
      loading || constructionResult.loading || asbestosResearchParams.loading,
    error: error || constructionResult.error || asbestosResearchParams.error,
    createFunction: createFunction,
    fetchSimilarSurvey: fetchSimilarSurvey,
    data: similarSurvey,
    similarFormOpen: similarFormOpen,
    similarConstructions: similarConstructions,
    toggleSimilarFormOpen: (o) => setSimilarFormOpen(o),
    comfirmOpen: comfirmOpen,
    toggleConfirmOpen: (o) => setConfirmOpen(o),
  });
};
