import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  FormControl,
  FormHelperText,
} from "@mui/material";
import React from "react";

/**
 * チェックボックスのグループを表示するプレゼンテーションコンポーネントです。
 */
export const Presententer = (props) => {
  return (
    <FormControl>
      <FormLabel required={props.required} id="checkbox-group-label">
        {props?.label ?? ""}
      </FormLabel>
      <FormGroup row={props.row}>
        {props.options.map((option, index) => (
          <FormControlLabel
            key={index}
            label={option?.label}
            value={option?.value}
            control={
              <Checkbox
                name={`${props.name}[${index}]`}
                checked={props.value?.includes(option?.value) ?? false}
                disabled={props.disabled ? props.disabled : option.disabled}
                onChange={props.onChange(index)}
              />
            }
          />
        )) ?? <></>}
      </FormGroup>
      {props.helperText && (
        <FormHelperText error={props.error}>{props.helperText}</FormHelperText>
      )}
    </FormControl>
  );
};
