import { Stack } from "@mui/material";
import React from "react";
import { InformationItem } from "views/atoms";
import { InformationItemGroup } from "views/molecules";

const getExistsAsbestosText = (value) => {
  if (value) {
    switch (value) {
      case "01":
        return "有";
      case "02":
        return "みなし";
      case "03":
        return "無";
      default:
        return "";
    }
  } else {
    return "";
  }
};

const getExistsAsbestos = (value) => {
  if (!value) {
    return false;
  } else {
    return value !== "03";
  }
};

const getBasisText = (value) => {
  if (!value) {
    return "";
  }

  const items = [
    {
      value: "visually",
      name: "１：目視",
    },
    {
      value: "designDocuments",
      name: "２：設計図書（４を除く。）",
    },
    {
      value: "analysis",
      name: "３：分析",
    },
    {
      value: "proof",
      name: "４：建設材料等の製造者による証明",
    },
    {
      value: "date",
      name: "５：建築材料等の製造年月日",
    },
  ];

  return items
    .filter((item) => value.includes(item.value))
    .map((item) => item.name)
    .join();
};

const getTypeText = (value) => {
  if (!value) {
    return "";
  }

  switch (value) {
    case "01":
      return "除去";
    case "02":
      return "封じ込め";
    case "03":
      return "囲い込み";
    default:
      return "";
  }
};

const getMeasureText = (value) => {
  if (!value) {
    return "";
  }

  const items = [
    {
      value: "negativePressureIsolation",
      name: "負圧隔離",
    },
    {
      value: "isolation",
      name: "隔離",
    },
    {
      value: "wetting",
      name: "湿潤化",
    },
    {
      value: "toolUse",
      name: "呼吸用保護具の使用",
    },
  ];

  return items
    .filter((item) => value?.includes(item?.value))
    .map((item) => item.name)
    .join();
};

/**
 * 事前調査の項目を表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <Stack spacing={2}>
      <InformationItemGroup title={props.title}>
        <InformationItem
          label={"石綿含有の有無"}
          value={getExistsAsbestosText(props.value.existsAsbestos)}
        />
        {!getExistsAsbestos(props.value.existsAsbestos) && (
          <InformationItem
            label={"含有無しと判断した根拠"}
            value={getBasisText(props.value.basis)}
          />
        )}
        {props?.value?.type &&
          getExistsAsbestos(props.value.existsAsbestos) && (
            <InformationItem
              label={"作業の種類"}
              value={getTypeText(props.value.type)}
            />
          )}
        {getExistsAsbestos(props.value.existsAsbestos) && (
          <InformationItem
            label={"切断等の有無"}
            value={props.value.existsDisconnect === "on" ? "有" : "無"}
          />
        )}
        {getExistsAsbestos(props.value.existsAsbestos) && (
          <InformationItem
            label={"作業時の措置"}
            value={getMeasureText(props.value.measure)}
          />
        )}
      </InformationItemGroup>
    </Stack>
  );
};
