
const fields = [
  {
    label: "吹付け材",
    name: "spraying",
  },
  {
    label: "保温材",
    name: "heatRetention",
  },
  {
    label: "煙突断熱材",
    name: "chimney",
  },
  {
    label: "屋根用折版断熱材",
    name: "forRoof",
  },
  {
    label: "耐火被覆材",
    name: "fireproof",
  },
  {
    label: "仕上塗材",
    name: "finishingCoating",
  },
  {
    label: "スレート波板",
    name: "slateWavePlate",
  },
  {
    label: "スレートボード",
    name: "slateBoard",
  },
  {
    label: "屋根用化粧スレート",
    name: "makeupSlateForRoof",
  },
  {
    label: "けい酸カルシウム板第1種",
    name: "silicicAcidCalciumBoardFirstType",
  },
  {
    label: "押出成形セメント板",
    name: "extrusionFormationCementPlate",
  },
  {
    label: "パルプセメント板",
    name: "pulpCementPlate",
  },
  {
    label: "ビニル床タイル",
    name: "vinylFloorTile",
  },
  {
    label: "蒸業系サイディング",
    name: "steamingSystemSiding",
  },
  {
    label: "石膏ボード",
    name: "plasterboard",
  },
  {
    label: "ロックウール吸音天井板",
    name: "lockWoolSoundAbsorbingCeilingBoard",
  },
  {
    label: "その他の材料",
    name: "otherMaterial",
  },
];

/**
 * 事前調査を表示するコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, value, ...props }) => {
  return render({
    ...props,
    fields: fields,
    value: value,
  });
};
