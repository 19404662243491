import { Button } from "@mui/material";
import React from "react";
import { FullScreenDialog } from "views/molecules/FullScreenDialog";
import { Form as ApplyForm } from "views/organisms/Constructions/Apply";
import { ConfirmDialog } from "views/molecules";

/**
 * 申請フォームを表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 */
export const Presententer = (props) => {
  return (
    <FullScreenDialog
      title={`「${props.name ?? "工事名称なし"}」${"申請情報入力"}`}
      open={props.open}
      onClose={props.onClose}
      actions={
        <Button
          disabled={props.loading}
          color="inherit"
          onClick={props.onSaveApply}
        >
          {"保存"}
        </Button>
      }
    >
      <ApplyForm
        value={props.value}
        onChange={props.onChange}
        errors={props.errors}
      />
      <ConfirmDialog
        open={props.dialog}
        onPositive={props.onPositive}
        onNegative={props.onNegative}
      />
    </FullScreenDialog>
  );
};
