import { useState } from "react";

/**
 * CSV取り込みを行うコンテナコンポーネントです。
 */
export const Container = ({
  render,
  onNotificationChange = () => {},
  ...props
}) => {
  const [active, setActive] = useState(0);
  const [value, setValue] = useState(null);

  const steps = [
    {
      title: "ファイル選択",
    },
    {
      title: "取込",
    },
  ];

  const handleDecided = () => {
    onNotificationChange();
  };

  const handleNextFileUpload = (csv, result) => {
    setActive((prevState) => prevState + 1);
    setValue({
      csv: csv,
      result: result,
    });
  };

  const handleBack = () => {
    setActive((prevState) => prevState - 1);
  };

  return render({
    ...props,
    active: active,
    steps: steps,
    value: value,
    onNextFileUpload: handleNextFileUpload,
    onBack: handleBack,
    onDecided: handleDecided,
  });
};
