import { grey } from "@mui/material/colors";

/**
 * 型番を表示するコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const ModelNumber = (props) => {
  const { value } = props;

  if (!value) {
    return <></>;
  }

  return (
    <div
      style={{
        fontSize: "13px",
        color: grey[600],
      }}
    >
      <span
        style={{
          textOverflow: "ellipsis",
          whiteSpace: "normal",
          display: "-webkit-box",
          WebkitLineClamp: 3,
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
        }}
      >
        {value}
      </span>
    </div>
  );
};
