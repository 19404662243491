import { Box, Typography } from "@mui/material";
import React from "react";

/**
 * 情報の項目を表示するコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <Box fullWidth sx={{
      paddingTop: "8px",
      paddingBottom: "8px"
    }}>
      <Typography
        sx={{
          fontSize: "12px",
          color: "darkgray",
        }}
      >
        {props.label}
      </Typography>
      <Box>
        <Typography
          borderBottom={1}
          borderColor={(theme) => theme.palette.grey[500]}
          minHeight={"25px"}
        >
          {props.value}
        </Typography>
      </Box>
    </Box>
  );
};
