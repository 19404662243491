import { Query } from "@apollo/client/react/components";
import { Skeleton } from "@mui/material";
import { listTrainingRulesClassification } from "api/graphql/queries";
import React from "react";
import { DatePicker } from "views/atoms";
import { CheckBox, Group, InputField, SelectItem } from "views/molecules";
import { Details } from "./Details";

/**
 * 事前調査フォームの表示を行うプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  if (props.asbestosTargetMaterials.loading) {
    return <Skeleton />;
  }

  return (
    <>
      <Group>
        <CheckBox
          name="unnecessary"
          label="調査不要(解体が無い軽微な工事)"
          value={props.value?.unnecessary}
          onChange={props.onChangeUnnecessary}
        />
      </Group>
      <Group title={"建築物の概要"}>
        <DatePicker
          label={"石綿に関する作業の開始時期"}
          inputFormat="yyyy/MM"
          name="asbestosWorkStartDate"
          value={props.value?.asbestosWorkStartDate}
          onChange={props.onChange("asbestosWorkStartDate")}
          helperText={props.errors?.asbestosWorkStartDate?.message}
          error={!!props.errors?.asbestosWorkStartDate}
          disabled={props.disabled}
        />
        <DatePicker
          label={"事前調査の終了年月日"}
          name="preliminarySurveyEndDate"
          value={props.value?.preliminarySurveyEndDate}
          onChange={props.onChange("preliminarySurveyEndDate")}
          helperText={props.errors?.preliminarySurveyEndDate?.message}
          error={props.errors?.preliminarySurveyEndDate}
          disabled={props.disabled}
        />
        <InputField
          label={"分析による調査を行った箇所"}
          name="analysisSurveyLocation"
          value={props.value?.analysisSurveyLocation ?? ""}
          onChange={(e) =>
            props.onChange("analysisSurveyLocation")(e.target.value)
          }
          error={props.errors?.analysisSurveyLocation}
          disabled={props.disabled}
          multiline={true}
          rows={4}
        />
      </Group>
      <Group title={"元方（元請）事業者の調査、分析を実施した者"}>
        <Group title="【事前調査を実施した者】" elevation={1} bold={false}>
          <InputField
            label="氏名"
            name="preSurveyorName"
            value={props.value?.primeContractor?.preSurveyorName ?? ""}
            onChange={props.onChangePrimeContractor("preSurveyorName")}
            error={Boolean(props.errors?.primeContractor?.preSurveyorName)}
            helperText={props.errors?.primeContractor?.preSurveyorName?.message}
            disabled={props.disabled}
          />
          <InputField
            label="講習実施機関の名称"
            name="preSurveyorTrainingInstitutionName"
            value={
              props.value?.primeContractor
                ?.preSurveyorTrainingInstitutionName ?? ""
            }
            onChange={props.onChangePrimeContractor(
              "preSurveyorTrainingInstitutionName"
            )}
            error={
              !!props.errors?.primeContractor
                ?.preSurveyorTrainingInstitutionName
            }
            helperText={
              props.errors?.primeContractor?.preSurveyorTrainingInstitutionName
                ?.message
            }
            disabled={props.disabled}
          />
          <Query query={listTrainingRulesClassification}>
            {({ data, error, loading }) => {
              if (error) {
                return error;
              } else {
                return (
                  <SelectItem
                    label="建築物石綿含有建材調査者講習登録規定の区分"
                    value={props.value?.primeContractor?.regulationClass}
                    options={data?.listTrainingRulesClassification}
                    optionValueField={"id"}
                    optionNameField={"name"}
                    loading={loading}
                    onChange={props.onPrimeContractorRegulationClass(
                      "regulationClass"
                    )}
                    error={!!props.errors?.primeContractor?.regulationClass}
                    helperText={
                      props.errors?.primeContractor?.regulationClass?.message
                    }
                    disabled={props.disabled}
                  />
                );
              }
            }}
          </Query>
        </Group>
        <Group title="【分析調査を実施した者】" elevation={1} bold={false}>
          <InputField
            label="氏名"
            name="analysisName"
            value={props.value?.primeContractor?.analysisName ?? ""}
            onChange={props.onChangePrimeContractor("analysisName")}
            error={!!props.errors?.primeContractor?.analysisName}
            helperText={props.errors?.primeContractor?.analysisName?.message}
            disabled={props.disabled}
          />
          <InputField
            label="所属する機関又は法人の名称"
            name="affiliationName"
            value={props.value?.primeContractor?.affiliationName ?? ""}
            onChange={props.onChangePrimeContractor("affiliationName")}
            error={!!props.errors?.primeContractor?.affiliationName}
            helperText={props.errors?.primeContractor?.affiliationName?.message}
            disabled={props.disabled}
          />
          <InputField
            label="講習実施機関の名称"
            name="analysisTrainingInstitutionName"
            value={
              props.value?.primeContractor?.analysisTrainingInstitutionName ??
              ""
            }
            onChange={props.onChangePrimeContractor(
              "analysisTrainingInstitutionName"
            )}
            error={
              !!props.errors?.primeContractor?.analysisTrainingInstitutionName
            }
            helperText={
              props.errors?.primeContractor?.analysisTrainingInstitutionName
                ?.message
            }
            disabled={props.disabled}
          />
        </Group>
        <Group title="作業に係る石綿作業主任者氏名" elevation={1} bold={false}>
          <InputField
            label=""
            name="asbestosWorkChiefName"
            value={props.value?.primeContractor?.asbestosWorkChiefName ?? ""}
            onChange={props.onChangePrimeContractor("asbestosWorkChiefName")}
            error={!!props.errors?.primeContractor?.asbestosWorkChiefName}
            helperText={
              props.errors?.primeContractor?.asbestosWorkChiefName?.message
            }
            disabled={props.disabled}
          />
        </Group>
      </Group>
      <Group title="調査詳細" elevation={2} bold={true}>
        <Details
          value={props.value?.details}
          errors={props.errors?.details}
          onChange={props.onChangeDetails}
          disabled={props.disabled}
        />
      </Group>
      {props.fields.map((field, index) => (
        <React.Fragment key={index}>
          <field.Component
            name={field.name}
            title={field.label}
            value={{
              ...props.value?.[field.name],
              id: props.asbestosTargetMaterials?.data?.listAsbestosTargetMaterial
                ?.map((material) => ({ ...material }))
                .find((material) => material.name === field.label)?.id,
            }}
            onChange={props.onChange(field.name)}
            expanded={!!props.value?.[field.name]}
            errors={props.errors?.[field.name]}
            disabled={props.disabled}
          />
        </React.Fragment>
      )) ?? <></>}
    </>
  );
};
