import { useEffect, useState } from "react";

/**
 * loadingの時に表示するコンテナコンポーネントです。
 * @returns {JSX.Element}
 */
export const Container = ({ render, number, ...props }) => {
  const [value, setValue] = useState([]);

  useEffect(() => {
    const courses = [...Array(number)];
    setValue(courses);
  }, [number]);

  return render({
    value: value,
    ...props,
  });
};
