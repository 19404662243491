import yup from "utils/yup";

const addressSchema = yup.object().shape({
  PostNoFront: yup
    .number()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .postalCodeFront()
    .nullable()
    .default(null),
  PostNoBack: yup
    .number()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .postalCodeBack()
    .nullable()
    .default(null),
  AddressPrefecture: yup.string().em().nullable().default(null),
  AddressCity: yup.string().em().nullable().default(null),
  AddressTown: yup.string().em().nullable().default(null),
  AddressOther: yup.string().em().nullable().default(null),
});

export const basicSchema = yup.object().shape({
  primeContractorName: yup.string().name().nullable().default(null),
  primeContractorRepresentativeName: yup
    .string()
    .name()
    .nullable()
    .default(null),
  primeContractor: addressSchema,
  primeContractorPhone: yup
    .string()
    .halfNumeric()
    .phoneFirstNumber()
    .phone()
    .nullable()
    .default(null),
  primeContractorMailAddress: yup.string().email().nullable().default(null),
  ordererName: yup.string().name().nullable().default(null),
  ordererRepresentativeName: yup.string().name().nullable().default(null),
  orderer: addressSchema,
  laborInsuranceNoExist: yup.string().nullable().default(null),
  laborInsuranceNoPrefecture: yup
    .string()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .halfNumeric()
    .length(2)
    .nullable()
    .default(null),
  laborInsuranceNoAdministration: yup
    .string()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .halfNumeric()
    .length(1)
    .nullable()
    .default(null),
  laborInsuranceNoJurisdiction: yup
    .string()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .halfNumeric()
    .length(2)
    .nullable()
    .default(null),
  laborInsuranceNoCoreNo: yup
    .string()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .halfNumeric()
    .length(6)
    .nullable()
    .default(null),
  laborInsuranceNoBranchNo: yup
    .string()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .halfNumeric()
    .length(3)
    .nullable()
    .default(null),
  work: addressSchema,
  name: yup.string().em().nullable().default(null),
  summary: yup.string().nullable().default(null),
  buildDateExist: yup.string().nullable().default(null),
  buildDate: yup.date().nullable().default(null),
  buildingFireproof: yup.object().nullable().default(null),
  buildingStructure: yup.object().nullable().default(null),
  buildingFloorSpace: yup
    .number()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .integer()
    .nullable()
    .default(null),
  groundFloors: yup
    .number()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .min(0)
    .default(null)
    .nullable(),
  basementFloors: yup
    .number()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .min(0)
    .default(null)
    .integer()
    .nullable(),
  constructionFloorSpace: yup
    .number()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .integer()
    .nullable()
    .default(null),
  constructionStartDate: yup
    .date()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .nullable()
    .default(null),
  constructionEndDate: yup
    .date()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .nullable()
    .default(null),
  amountBillion: yup
    .number()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .nullable()
    .default(null),
  amountTenThousand: yup
    .number()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .nullable()
    .default(null),
  freeEntryField: yup
    .string()
    .name()
    .nullable()
    .default(null)
    .test("freeEntryValidation", "全角で入力してください。", function (value) {
      if (!value) {
        return true;
      }

      return value.match(/^[[^\x01-\x7E]|[\r\n]]+$/gims) === null;
    }),
  uniqueInformation1: yup.string().nullable().default(null),
  uniqueInformation2: yup.string().nullable().default(null),
  uniqueInformation3: yup.string().nullable().default(null),
  uniqueInformation4: yup.string().nullable().default(null),
  uniqueInformation5: yup.string().nullable().default(null),
});
