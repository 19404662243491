import { Link } from "@mui/material";

/**
 * 電話番号を表示するコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Tel = (props) => {
  const { value, ...other } = props;

  if (!value) {
    return <></>;
  } else {
    return (
      <Link
        href={`tel:${value}`}
        {...other}
        sx={{
          fontSize: "16px",
          "@media(min-width: 768px)": {
            pointerEvents: "none",
          },
        }}
      >
        {value}
      </Link>
    );
  }
};
