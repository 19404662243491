import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { orange } from "@mui/material/colors";
import { Box } from "@mui/material";

/**
 * 警告を表示するコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Warning = (props) => {
  return (
    <Box
      component={"span"}
      sx={{
        color: orange[500],
        fontSize: "12px",
        fontWeight: "bold",
        "& .MuiSvgIcon-root": {
          verticalAlign: "sub",
        },
        ...props.sx,
      }}
    >
      <ReportProblemIcon fontSize="small" />
      工事に影響があります。
    </Box>
  );
};
