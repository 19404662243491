import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 報告のアイテムを表示するコンポーネントです。
 * @param {object} value 値
 * @param {boolean} checked 選択されているか
 * @fires Item#onApply 申請クリック時
 * @fires Item#onReport 報告クリック時
 * @fires Item#onCheckedChange チェック変更時
 * @returns JSX.Element
 */
export const Item = containerPresententer(Container, Presententer);
