import reducer, {
  show,
  remove,
  clear,
  showSuccess,
  showError,
  showWarning,
  showInfo,
  selector,
  itemsSelector,
} from "./slice";

export {
  show,
  remove,
  clear,
  showSuccess,
  showError,
  showWarning,
  showInfo,
  selector,
  itemsSelector,
};

export default reducer;
