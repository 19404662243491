import React from "react";
import {
  Home,
  Search,
  SearchResult,
  Sanitary,
  Product,
  ConstructionsList,
  ConstructionsCreate,
  ConstructionsUpdate,
  ConstructionsShow,
  ConstructionsContractorsCreate,
  ConstructionsContractorsUpdate,
  ConstructionsSurveyCreate,
  ConstructionsSurveyUpdate,
  CompanyList,
  Report,
} from "views/pages";

export const routes = [
  {
    title: "ホーム",
    path: "/",
    component: <Home />,
  },
  {
    title: "検索",
    path: "/search",
    component: <Search />,
  },
  {
    title: "検索結果",
    path: "/search/result",
    component: <SearchResult />,
  },
  {
    title: "製品詳細",
    path: "/sanitary",
    component: <Sanitary />,
  },
  {
    title: "建材詳細",
    path: "/product",
    component: <Product />,
  },
  {
    title: "工事情報",
    path: "/constructions",
    component: <ConstructionsList />,
  },
  {
    title: "工事情報登録",
    path: "/constructions/create",
    component: <ConstructionsCreate />,
  },
  {
    title: "工事情報",
    path: "/constructions/:id/show",
    component: <ConstructionsShow />,
  },
  {
    title: "工事情報更新",
    path: "/constructions/:id/show/update",
    component: <ConstructionsUpdate />,
  },
  {
    title: "請負事業者情報登録",
    path: "/constructions/:id/show/contractors/create",
    component: <ConstructionsContractorsCreate />,
  },
  {
    title: "請負事業者情報更新",
    path: "/constructions/:id/show/contractors/update",
    component: <ConstructionsContractorsUpdate />,
  },
  {
    title: "事前調査情報登録",
    path: "/constructions/:id/show/survey/create",
    component: <ConstructionsSurveyCreate />,
  },
  {
    title: "事前調査情報更新",
    path: "/constructions/:id/show/survey/update",
    component: <ConstructionsSurveyUpdate />,
  },
  {
    title: "企業情報",
    path: "/company",
    component: <CompanyList />,
  },
  {
    title: "報告",
    path: "/report",
    component: <Report />,
  },
];
