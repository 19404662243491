import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";

/**
 * 申請リストを表示するコンポーネントです。
 * @param {object} props プロパティ
 * @param {array} props.rows 行
 * @returns {array}
 */
export const ApplyList = ({ rows = [] }) => {
  return (
    <TableContainer component={Paper} sx={{ maxHeight: "50vh" }}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              sx={{
                whiteSpace: "nowrap",
              }}
            >
              {"行番号"}
            </TableCell>
            <TableCell
              align="center"
              sx={{
                whiteSpace: "nowrap",
              }}
            >
              {"申請番号"}
            </TableCell>
            <TableCell
              align="center"
              sx={{
                whiteSpace: "nowrap",
              }}
            >
              {"申請日"}
            </TableCell>
            <TableCell
              align="center"
              sx={{
                whiteSpace: "nowrap",
              }}
            >
              {"工事の名称"}
            </TableCell>
            <TableCell
              align="center"
              sx={{
                whiteSpace: "nowrap",
              }}
            >
              {"工事の実施開始期間"}
            </TableCell>
            <TableCell
              align="center"
              sx={{
                whiteSpace: "nowrap",
              }}
            >
              {"工事の実施終了期間"}
            </TableCell>
            <TableCell
              align="center"
              sx={{
                whiteSpace: "nowrap",
              }}
            >
              {"工事現場の住所"}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map?.((row, index) => (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                align="center"
                sx={{
                  whiteSpace: "nowrap",
                }}
              >
                {row.lineNo}
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  whiteSpace: "nowrap",
                }}
              >
                {row.surveyApplicationNo}
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  whiteSpace: "nowrap",
                }}
              >
                {row.surveyApplicationDate}
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  whiteSpace: "nowrap",
                }}
              >
                {row.name}
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  whiteSpace: "nowrap",
                }}
              >
                {row.constructionStartDate}
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  whiteSpace: "nowrap",
                }}
              >
                {row.constructionEndDate}
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  whiteSpace: "nowrap",
                }}
              >
                {row.workAddress}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
