import { Box } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { SelectItem } from "views/molecules";

/**
 * ARS-フォームの表示を行うプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <Box>
    <Controller
      control={props.control}
      name={props.name}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <SelectItem
          options={props.options}
          label={props.label}
          value={value}
          onChange={(e) => {
            onChange(e);
            props.onChange(e);
          }}
          optionValueField={props.optionValueField}
          optionNameField={props.optionNameField}
          error={Boolean(error)}
          helperText={error?.message}
        />
      )}
    />
  </Box>
);
