import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 項目をグルーピングするコンポーネントです。
 * @param {string} title タイトル
 * @param {number} elevation Paperオブジェクトのelevation
 * @param {string} variant Typographのvariant
 * @param {boolean} bold タイトルを太字にするか
 */
export const Group = containerPresententer(Container, Presententer);
