import useScrollTrigger from "@mui/material/useScrollTrigger";

/**
 * トップにスクロールを表示するコンテナコンポーネントです。
 */
export const Container = ({ render, ...props }) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 50,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );
    if (anchor) {
      anchor.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  return render({
    handleClick: handleClick,
    trigger: trigger,
  });
};
