
import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * ARS-企業一覧ページを表示するコンポーネントです。
 * @param {object} value 工事情報
 */
export const CompanyList = containerPresententer(Container, Presententer);
