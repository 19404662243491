import { updateAsbestosResearch } from "api/graphql/mutations";
import { useQuery, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { getAsbestosResearch } from "api/graphql/queries";
import { convert } from "views/organisms/Constructions/Survey/Form";

/**
 * 事前調査を更新するコンテナコンポーネントです
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props　その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const { id } = useParams();
  const [updateFunction, { loading, error }] = useMutation(
    updateAsbestosResearch
  );
  const getAsbestosResearchFunctions = useQuery(getAsbestosResearch, {
    variables: {
      id: id,
    },
  });

  return render({
    ...props,
    id: id,
    data:
      getAsbestosResearchFunctions?.data?.getAsbestosResearch &&
      convert(getAsbestosResearchFunctions?.data?.getAsbestosResearch),
    loading: loading || getAsbestosResearchFunctions.loading,
    error: error || getAsbestosResearchFunctions.error,
    updateFunction: updateFunction,
  });
};
