import React from "react";
import { InputField } from "views/molecules";

/**
 * フリーワードを入力するコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const FreeWord = (props) => {
  const { value, onChange } = props;

  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <InputField
      value={value ?? ""}
      onChange={handleChange}
      label="フリーワード"
      inputProps={{ pattern: `^[<>'-,;&+/|:;=*_?]+$` }}
    />
  );
};
