import { TextField } from "@mui/material";
import React from "react";

/**
 * テキストフィールドの表示を行うプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <TextField
      size="small"
      variant={"outlined"}
      value={props.value ?? ""}
      onChange={props.onChange}
      fullWidth
      multiline={props.multiline}
      required={props.required}
      InputLabelProps={{ shrink: true }}
      error={props.error ?? false}
      helperText={props?.helperText ?? ""}
      {...props}
    />
  );
};
