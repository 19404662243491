import { Stack, Typography } from "@mui/material";
import { DateTime } from "luxon";
import React from "react";
import { InformationItem } from "views/atoms";
import { Panel } from "../Panel";

const convertDate = (date) => {
  if (!date) {
    return "";
  }

  let result = DateTime.fromJSDate(date);
  if (result.isValid) {
    return result.toFormat("yyyy/MM/dd");
  }

  return date;
};

const Title = (props) => (
  <Typography sx={{ textDecoration: "underline" }}>{props.title}</Typography>
);

const getOrderStatusText = (id) => {
  switch (id) {
    case "1":
      return "交渉中";
    case "2":
      return "受注済";
    case "3":
      return "失注";
    default:
      return id;
  }
};

const checkedValue = (value) => {
  if (typeof value === "boolean") {
    return value;
  }

  return value === "1";
};

/**
 * ARS-確認の表示を行うプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <>
    <Stack mt={1} m={2} spacing={2}>
      <Panel title={"状況"}>
        <InformationItem
          label=""
          value={getOrderStatusText(props?.value?.constructionOrderStatus)}
        />
      </Panel>
      <Panel title={"元方（元請）事業者情報"}>
        <InformationItem
          label="事業者の名称"
          value={props?.value?.primeContractorName ?? ""}
        />
        <InformationItem
          label="代表者氏名"
          value={props?.value?.primeContractorRepresentativeName ?? ""}
        />
        <InformationItem
          label="郵便番号"
          value={`${
            props?.value?.primeContractor?.PostNoFront ??
            props?.value?.primeContractorPostNoFront ??
            ""
          }-${
            props?.value?.primeContractor?.PostNoBack ??
            props?.value?.primeContractorPostNoBack ??
            ""
          }`}
        />
        <InformationItem
          label="都道府県"
          value={
            props?.value?.primeContractor?.AddressPrefecture ??
            props?.value?.primeContractorAddressPrefecture ??
            ""
          }
        />
        <InformationItem
          label="市区町村"
          value={
            props?.value?.primeContractor?.AddressCity ??
            props?.value?.primeContractorAddressCity ??
            ""
          }
        />
        <InformationItem
          label="町域名"
          value={
            props?.value?.primeContractor?.AddressTown ??
            props?.value?.primeContractorAddressTown ??
            ""
          }
        />
        <InformationItem
          label="その他住所（続き）"
          value={
            props?.value?.primeContractor?.AddressOther ??
            props?.value?.primeContractorAddressOther ??
            ""
          }
        />
        <InformationItem
          label="電話番号"
          value={props?.value?.primeContractorPhone ?? ""}
        />
        <InformationItem
          label="メールアドレス"
          value={props?.value?.primeContractorMailAddress ?? ""}
        />
      </Panel>
      <Panel title={"工事発注者情報"}>
        <InformationItem
          label="事業者の名称"
          value={props?.value?.ordererName ?? ""}
        />
        <InformationItem
          label="代表者氏名"
          value={props?.value?.ordererRepresentativeName ?? ""}
        />
        <InformationItem
          label="郵便番号"
          value={`${
            props?.value?.orderer?.PostNoFront ??
            props?.value?.ordererPostNoFront ??
            ""
          }-${
            props?.value?.orderer?.PostNoBack ??
            props?.value?.ordererPostNoBack ??
            ""
          }`}
        />
        <InformationItem
          label="都道府県"
          value={
            props?.value?.orderer?.AddressPrefecture ??
            props?.value?.ordererAddressPrefecture ??
            ""
          }
        />
        <InformationItem
          label="市区町村"
          value={
            props?.value?.orderer?.AddressCity ??
            props?.value?.ordererAddressCity ??
            ""
          }
        />
        <InformationItem
          label="町域名"
          value={
            props?.value?.orderer?.AddressTown ??
            props?.value?.ordererAddressTown ??
            ""
          }
        />
        <InformationItem
          label="その他住所（続き）"
          value={
            props?.value?.orderer?.AddressOther ??
            props?.value?.ordererAddressOther ??
            ""
          }
        />
      </Panel>
      <Panel title={"工事現場情報"}>
        <InformationItem
          label="労働保険番号"
          value={
            !checkedValue(props?.value?.laborInsuranceNoExist)
              ? getLaborInsuranceNumber(props?.value)
              : "なし又は不明"
          }
        />
        <InformationItem
          label="郵便番号"
          value={`${
            props?.value?.work?.PostNoFront ??
            props?.value?.workPostNoFront ??
            ""
          }-${
            props?.value?.work?.PostNoBack ?? props?.value?.workPostNoBack ?? ""
          }`}
        />
        <InformationItem
          label="都道府県"
          value={
            props?.value?.work?.AddressPrefecture ??
            props?.value?.workAddressPrefecture ??
            ""
          }
        />
        <InformationItem
          label="市区町村"
          value={
            props?.value?.work?.AddressCity ??
            props?.value?.workAddressCity ??
            ""
          }
        />
        <InformationItem
          label="町域名"
          value={
            props?.value?.work?.AddressTown ??
            props?.value?.workAddressTown ??
            ""
          }
        />
        <InformationItem
          label="その他住所（続き）"
          value={
            props?.value?.work?.AddressOther ??
            props?.value?.workAddressOther ??
            ""
          }
        />
        <InformationItem label="工事の名称" value={props?.value?.name ?? ""} />
        <InformationItem
          label="工事の概要"
          value={
            <span
              style={{
                display: "inline-block",
                whiteSpace: "pre-wrap",
              }}
            >
              {props?.value?.summary ?? ""}
            </span>
          }
        />
      </Panel>
      <Panel title={"建築物の概要"}>
        <InformationItem
          label="建築物又は工作物の新築工事の着工日"
          value={
            props?.value?.buildDateExist === "0"
              ? props?.value?.buildDate
                ? convertDate(props?.value?.buildDate)
                : "不明"
              : ""
          }
        />
        <InformationItem
          label="耐火"
          value={props?.value?.buildingFireproof?.name ?? ""}
        />
        <InformationItem
          label="構造"
          value={props?.value?.buildingStructure?.name ?? ""}
        />
        <InformationItem
          label="延べ面積"
          value={(props?.value?.buildingFloorSpace ?? "") + "㎡"}
        />
        <InformationItem
          label="階数"
          value={"地上" + (props?.value?.groundFloors ?? "") + "階建"}
        />
        <InformationItem
          label="階数（地下階）"
          value={"地下" + (props?.value?.basementFloors ?? "") + "階建"}
        />
        <InformationItem label="その他工作物" value={props.otherWorks} />
        <InformationItem
          label="解体の作業の対象となる床面積の合計"
          value={(props?.value?.constructionFloorSpace ?? "") + "㎡"}
        />
        <InformationItem
          label="解体工事又は改修工事の実施期間"
          value={`${convertDate(
            props?.value?.constructionStartDate
          )}～${convertDate(props?.value?.constructionEndDate)}`}
        />
        <InformationItem
          label="請負金額"
          value={`${
            props?.value?.amountBillion
              ? props?.value?.amountBillion + "億"
              : ""
          } ${
            props?.value?.amountTenThousand
              ? props?.value?.amountTenThousand + "万円"
              : ""
          }`}
        />
        <InformationItem
          label={"自由記載欄"}
          value={
            <span
              style={{
                display: "inline-block",
                whiteSpace: "pre-wrap",
              }}
            >
              {props?.value?.freeEntryField ?? ""}
            </span>
          }
        />
      </Panel>
      {(!!props.uniqueInformationLabels?.labelName1 ||
        !!props.uniqueInformationLabels?.labelName2 ||
        !!props.uniqueInformationLabels?.labelName3 ||
        !!props.uniqueInformationLabels?.labelName4 ||
        !!props.uniqueInformationLabels?.labelName5) && (
        <Panel>
          {(props.uniqueInformationLabels?.labelName1 ?? "") !== "" && (
            <InformationItem
              label={props.uniqueInformationLabels?.labelName1}
              value={props?.value?.uniqueInformation1 ?? ""}
            />
          )}
          {(props.uniqueInformationLabels?.labelName2 ?? "") !== "" && (
            <InformationItem
              label={props.uniqueInformationLabels?.labelName2}
              value={props?.value?.uniqueInformation2 ?? ""}
            />
          )}
          {(props.uniqueInformationLabels?.labelName3 ?? "") !== "" && (
            <InformationItem
              label={props.uniqueInformationLabels?.labelName3}
              value={props?.value?.uniqueInformation3 ?? ""}
            />
          )}
          {(props.uniqueInformationLabels?.labelName4 ?? "") !== "" && (
            <InformationItem
              label={props.uniqueInformationLabels?.labelName4}
              value={props?.value?.uniqueInformation4 ?? ""}
            />
          )}
          {(props.uniqueInformationLabels?.labelName5 ?? "") !== "" && (
            <InformationItem
              label={props.uniqueInformationLabels?.labelName5}
              value={props?.value?.uniqueInformation5 ?? ""}
            />
          )}
        </Panel>
      )}
      {props?.value?.contractorApplicables?.map((contractor, index) => (
        <Stack key={index} spacing={2}>
          <Title title="請負事業者" />
          <InformationItem label="会社名" value={contractor?.name ?? ""} />
          <InformationItem
            label="住所"
            value={getContractorsAddress(contractor)}
          />
          <InformationItem label="電話番号" value={contractor?.phone ?? ""} />
          <InformationItem
            label="労働保険番号"
            value={getLaborInsuranceNumber(contractor)}
          />
          <InformationItem
            label="事前調査実施者氏名"
            value={contractor?.preliminarySurveyorName ?? ""}
          />
          <InformationItem
            label="事前調査実施者機関名"
            value={contractor?.preSurveyorTrainingInstitutionName ?? ""}
          />
          <InformationItem
            label="分析調査実施者氏名"
            value={contractor?.analyticalSurveyorName ?? ""}
          />
          <InformationItem
            label="分析調査実施者機関名"
            value={contractor?.anaSurveyorTrainingInstitutionName ?? ""}
          />
          <InformationItem
            label="石綿作業主任者氏名"
            value={contractor?.asbestosWorkChiefName ?? ""}
          />
        </Stack>
      ))}
    </Stack>
  </>
);

const getContractorsPostalCode = (value) => {
  return value?.postNoFront
    ? `〒${value?.postNoFront}-${value?.postNoBack} `
    : "";
};

const getContractorsAddress = (value) => {
  return `${getContractorsPostalCode(value)} ${value?.addressPrefecture ?? ""}${
    value?.addressCity ?? ""
  }${value?.addressTown ?? ""}${value?.addressOther ?? ""}`;
};

const getLaborInsuranceNumber = (value) => {
  return `${value?.laborInsuranceNoPrefecture ?? ""}${
    value?.laborInsuranceNoAdministration ?? ""
  }${value?.laborInsuranceNoJurisdiction ?? ""}-${
    value?.laborInsuranceNoCoreNo ?? ""
  }-${value?.laborInsuranceNoBranchNo ?? ""}`;
};
