import { FormHelperText } from "@mui/material";
import React from "react";
import { dateFormat } from "utils/Functions";
import { InformationItem } from "views/atoms";
import { Group } from "views/molecules";
import { Details } from "./Details";
import { Item } from "./Item";

/**
 * 事前調査の表示を行うプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  if (!props?.value) {
    return <FormHelperText>{"該当の情報はありません。"}</FormHelperText>;
  }

  return (
    <>
      <Group title={"状況"}>
        <InformationItem label="" value={props?.value?.status ?? ""} />
      </Group>
      <Group title={"建築物の概要"}>
        <InformationItem
          label="石綿に関する作業の開始時期"
          value={dateFormat(
            props?.value?.asbestosWorkStartDate,
            "yyyy-MM-dd",
            "yyyy/MM"
          )}
        />
        <InformationItem
          label="事前調査の終了年月日"
          value={dateFormat(
            props?.value?.preliminarySurveyEndDate,
            "yyyy-MM-dd",
            "yyyy/MM/dd"
          )}
        />
        <InformationItem
          label="分析による調査を行った箇所"
          value={
            <span
              style={{
                display: "inline-block",
                whiteSpace: "pre-wrap",
              }}
            >
              {props?.value?.analysisSurveyLocation ?? ""}
            </span>
          }
        />
      </Group>
      <Group title={"元方（元請）事業者の調査、分析を実施した者"}>
        <Group title="【事前調査を実施した者】" elevation={1} bold={false}>
          <InformationItem
            label={"氏名"}
            value={props.value?.primeContractor?.preSurveyorName}
          />
          <InformationItem
            label={"講習実施機関の名称"}
            value={
              props.value?.primeContractor?.preSurveyorTrainingInstitutionName
            }
          />
          <InformationItem
            label={"建築物石綿含有建材調査者講習登録規定の区分"}
            value={props.value?.primeContractor?.regulationClass?.content}
          />
        </Group>
        <Group title="【分析調査を実施した者】" elevation={1} bold={false}>
          <InformationItem
            label={"氏名"}
            value={props.value?.primeContractor?.analysisName}
          />
          <InformationItem
            label={"所属する機関又は法人の名称"}
            value={props.value?.primeContractor?.affiliationName}
          />
          <InformationItem
            label={"講習実施機関の名称"}
            value={
              props.value?.primeContractor?.analysisTrainingInstitutionName
            }
          />
        </Group>
        <Group title="作業に係る石綿作業主任者氏名" elevation={1} bold={false}>
          <InformationItem
            label={""}
            value={props.value?.primeContractor?.asbestosWorkChiefName}
          />
        </Group>
      </Group>
      {props.value?.details?.length > 0 && (
        <Group title={"調査詳細"} elevation={2} bold={true}>
          {props.value?.details?.map((detail, index) => {
            return <Details key={index} value={detail} />;
          })}
        </Group>
      )}
      {props.fields.map((field, index) => (
        <React.Fragment key={index}>
          {props.value?.[field.name] && (
            <Item title={field.label} value={props.value?.[field.name]} />
          )}
        </React.Fragment>
      )) ?? <></>}
    </>
  );
};
