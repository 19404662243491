import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 認証後のページテンプレートを表示するコンポーネントです。
 */
export const AuthenticatedTemplate = containerPresententer(
  Container,
  Presententer
);

/**
 * スクロールの位置を一番上に移動させます。
 */
export const scrollTop = () => {
  const main = window.document.querySelector("#back-to-top-anchor + main");

  if (main) {
    main.scrollTop = 0;
  }
};
