import { Box, Grid, Skeleton } from "@mui/material";
import React from "react";

/**
 * loadingの時に表示するコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  const { value } = props;

  return (
    <Grid container spacing={3}>
      {value.map((_, i) => (
        <Grid item xs={12} key={i}>
          <Box component="div" style={{ width: "100%" }} key={i} mb={3}>
            <Skeleton height={20} variant="text" width="30%" />
            <Skeleton height={45} variant="rect" />
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};
