import { useContext, useEffect, useRef, useState } from "react";
import { ItemsContext } from "../Items/Container";

/**
 * 報告のアイテムを表示するコンテナコンポーネントです。
 */
export const Container = ({
  render,
  checked,
  value,
  onApply = () => {},
  onReport = () => {},
  onCheckedChange = (data) => {},
  onExpandMore = () => {},
  index,
  disabled = false,
  listRef,
  ...props
}) => {
  const root = useRef();
  const [open, setOpen] = useState(false);
  const { setSize, windowSize } = useContext(ItemsContext);
  useEffect(() => {
    if (root.current) {
      setSize(index, root.current.offsetHeight + 14);
      listRef.current.resetAfterIndex(index);
    }
  }, [windowSize]);

  const handleApply = () => {
    onApply(value);
  };

  const handleReport = () => {
    onReport(value);
  };

  const handleCheckedChange = (e) => {
    onCheckedChange({
      checked: e.target.checked,
      value: value,
    });
  };

  const handleToggleExpandMore = () => {
    setOpen(!open);
  };

  const handleExpandedMore = () => {
    setSize(index, root.current.offsetHeight + 14);
    onExpandMore();
  };

  return render({
    ...props,
    value: value,
    checked: checked,
    onApply: handleApply,
    onReport: handleReport,
    onCheckedChange: handleCheckedChange,
    onToggleExpandMore: handleToggleExpandMore,
    onExpandedMore: handleExpandedMore,
    open: open,
    root: root,
    disabled: disabled,
  });
};
