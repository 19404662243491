import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import React from "react";
import { ConfirmDialog } from "views/molecules";
import { ContractsForm, SurveyView } from "views/organisms/Constructions";
import { Review } from "views/organisms/Constructions/ConstructionForm/Child";
import { convert as convertContractors } from "views/organisms/Constructions/ContractsForm";
import { AsbestosResearchStatusType } from "utils/Constants";

const UpsertActionArea = ({
  visibility = {
    regist: false,
    edit: false,
    delete: false,
  },
  onRegist = () => {},
  onEdit = () => {},
  onDelete = () => {},
  loading,
}) => {
  if (loading === true || loading === undefined) {
    return <></>;
  }

  return (
    <Stack spacing={2} direction={"row"}>
      {visibility.regist === true && (
        <Button variant="outlined" color={"primary"} onClick={onRegist}>
          登録する
        </Button>
      )}
      {visibility.edit === true && (
        <Button variant="outlined" color={"success"} onClick={onEdit}>
          編集する
        </Button>
      )}
      {visibility.delete === true && (
        <Button variant="outlined" color={"error"} onClick={onDelete}>
          削除する
        </Button>
      )}
    </Stack>
  );
};

const ConfirmError = (
  { messages } = {
    messages: "",
  }
) => {
  if (!messages) {
    return <></>;
  }

  return (
    <div>
      {messages.split("\n").map((message, index) => (
        <Typography
          sx={{
            color: "red",
            marginBottom: 1,
          }}
          key={index}
        >
          {message}
        </Typography>
      ))}
    </div>
  );
};

/**
 * 工事情報表示ページのプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <Box>
    <Box
      display={"flex"}
      flexDirection={"row"}
      justifyContent="space-between"
      width="100%"
    >
      <Box>
        <Typography
          sx={{ mb: 2, textDecoration: "underline", fontWeight: 600 }}
        >
          工事情報
        </Typography>
      </Box>
      <Stack spacing={2} direction={"row"}>
        <Button variant="outlined" color={"inherit"} onClick={props.onBackList}>
          一覧に戻る
        </Button>
        {props.loading === false &&
          props.survey?.status !==
            AsbestosResearchStatusType.SurveyCompleted.label && (
            <Button
              variant="contained"
              color={"success"}
              onClick={props.onCompleted}
              disabled={props.completedLoading}
            >
              調査完了にする
            </Button>
          )}
      </Stack>
    </Box>
    <TabContext value={props.tab}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <TabList onChange={props.setTab} aria-label="lab API tabs example">
          <Tab label="工事" value={"constructions"} />
          <Tab label="請負" value={"contractors"} />
          <Tab label="事前調査" value={"survey"} />
        </TabList>
      </Box>
      <Box>
        <TabPanel value={"constructions"}>
          <ConfirmError messages={props.confirmError} />
          <UpsertActionArea
            visibility={{
              regist: false,
              edit: true,
              delete: !props.report?.asbestosReportStatus
                ? true
                : props.report?.asbestosReportStatus !== "報告済" &&
                  props.report?.asbestosReportStatus !== "要再報告",
            }}
            onEdit={props.onEdit}
            onDelete={props.onOpenDeleteModal}
            loading={props.loading}
          />
          {props.loading === true && <CircularProgress />}
          {props.loading === false && <Review value={props.value} />}
        </TabPanel>
      </Box>
      <TabPanel value={"contractors"}>
        <ConfirmError messages={props.confirmError} />
        <UpsertActionArea
          visibility={{
            regist: !props.contractors || props.contractors.length <= 0,
            edit: (props.contractors?.length ?? 0) > 0,
            delete: (props.contractors?.length ?? 0) > 0,
          }}
          onRegist={props.onRegist}
          onEdit={props.onEdit}
          onDelete={props.onDelete}
          loading={props.loading}
        />
        {props.loading === true && <CircularProgress />}
        {props.loading === false && (
          <ContractsForm
            value={convertContractors(props.contractors)}
            editable={false}
          />
        )}
      </TabPanel>
      <TabPanel value={"survey"}>
        <ConfirmError messages={props.confirmError} />
        <UpsertActionArea
          visibility={{
            regist: !props.survey,
            edit: !!props.survey,
            delete: !!props.survey,
          }}
          onRegist={props.onRegist}
          onEdit={props.onEdit}
          onDelete={props.onDelete}
          loading={props.loading}
        />
        {props.loading === true && <CircularProgress />}
        {props.loading === false && <SurveyView value={props.survey} />}
      </TabPanel>
    </TabContext>
    <ConfirmDialog
      title="削除確認"
      message="削除します。よろしいですか？"
      open={props.open}
      onPositive={props.onDelete}
      onNegative={props.onCloseDeleteModal}
      disabled={props.loading}
      positiveText={"削除する"}
      negativeText={"キャンセル"}
    ></ConfirmDialog>
    <ConfirmDialog
      title="更新確認"
      message={
        <div
          style={{
            whiteSpace: "pre-wrap",
          }}
        >{`以下の警告がありますが、完了しますか？\n\n${props.warning}`}</div>
      }
      open={Boolean(props.warning)}
      onPositive={props.onForceSurveyComplete}
      onNegative={props.onCancelSurveyComplete}
      disabled={props.loading}
      positiveText={"はい"}
      negativeText={"いいえ"}
    ></ConfirmDialog>
  </Box>
);
