import React from "react";
import { Skeleton } from "@mui/material";

/**
 * 画像を表示するコンポーネントです。
 * @param {uri} src 表示する画像ファイルを指定します（必須属性）
 * @param {string} alt 画像を表示できないブラウザの場合に、画像の代替テキストを指定します。
 * @param {string} name 画像の名前を指定します。
 * @param {number|string} width 画像の横幅をピクセル、パーセンテージで指定します。
 * @param {number|string} height 画像の高さをピクセル、パーセンテージで指定します。
 * @param {string} align 表示位置と、テキストが画像を回りこむかどうかを指定します。"top"|"middle"|"bottom"|"left"|"right"
 * @param {number} border 画像の周りにおけるボーダーラインの太さを指定します。
 * @param {object} props その他プロパティ
 * @returns JSX.Element
 */
export const Img = ({
  src,
  alt,
  name,
  width,
  height,
  align,
  border,
  ...props
}) => {
  if (!src) {
    return <Skeleton variant="rectangular" width={width} height={height} />;
  }

  return (
    <div style={{
      width: `${width}px`,
      height: `${height}px`
    }}>
      <img
        src={src}
        alt={alt}
        name={name}
        align={align}
        border={border}
        width={"100%"}
        height={"100%"}
        style={{
          objectFit: "cover",
        }}
        {...props}
      />
    </div>
  );
};
