import useMediaQuery from "@mui/material/useMediaQuery";

/**
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const matches = useMediaQuery("(max-width: 480px)");

  return render({
    matches: matches,
    ...props,
  });
};
