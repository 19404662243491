import { useState } from "react";

/**
 * 事前調査の調査詳細を表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, value, ...props }) => {
  const [open, setOpen] = useState(false);

  return render({
    ...props,
    open: open,
    toggle: (o) => setOpen(o),
    value: value,
  });
};
