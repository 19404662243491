// ==========================================
//  関数群
// ==========================================

import { DateTime } from "luxon";

/**
 * BlobをDataURL文字列に変換します。
 * @param {Blob} blob Blobオブジェクト
 * @returns {string} base64文字列
 */
export const blobToDataURL = (blob) => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};

/**
 * DataURL文字列からbase64を取り出します。
 * @param {string} value DataURL文字列
 * @returns {string}
 */
export const fetchBase64 = (value) => {
  return value.replace(/data:.*\/.*;base64,/, "");
};

/**
 * urlStringからFileオブジェクトを生成します。
 * @param {string} urlString url文字列
 * @param {string} fileName ファイル名
 * @param {object} options オプション
 * @returns {File} Fileオブジェクト
 */
export const urlStringToFile = (urlString, fileName, options) => {
  if (urlString && fileName) {
    return fetch(urlString)
      .then((response) => response.blob())
      .then((blob) => new File([blob], fileName, options));
  } else {
    return Promise.reject();
  }
};

/**
 * DataUrlを生成します。
 * @param {string} contentType コンテンツ種類
 * @param {string} base64String base64文字列
 * @returns {string} DataUrl
 */
export const createDataUrl = (contentType, base64String) => {
  return `data:${contentType};base64,${base64String}`;
};

/**
 * ファイルオブジェクトをフォームの値に変換します。
 * @param {File} file ファイルオブジェクト
 * @returns {object}
 */
export const convertFileToFormValue = async (file) => {
  return {
    name: file?.name,
    size: file?.size,
    type: file?.type,
    base64: await blobToDataURL(file),
  };
};

/**
 * 期間表示の文字列を返します。
 * @param {string} start 期間開始
 * @param {string} end 期間終了
 * @param {string} emptiesString 空だった場合に表示する文字列
 * @returns string (start ～ end || "")
 */
export const showDateRange = (start, end, emptiesString = "") => {
  if (!start && !end) {
    return emptiesString;
  }

  return `${start ?? ""} ～ ${end ?? ""}`;
};

/**
 * 指定された値が空であるか確認します。
 * @param {object} value 値
 * @returns {boolean} true:空である。 false:空ではない。
 */
export const isEmpty = (value) => {
  return (
    !value ||
    (value.hasOwnProperty("length") && value.length === 0) ||
    (value.constructor === Object && Object.keys(value).length === 0)
  );
};

/**
 * nullではない最初のオブジェクトを返します。
 * @param  {...any} params 可変長引数
 * @returns {object}
 */
export const coalesce = (...params) => {
  for (let param of params) {
    if (!isEmpty(param)) {
      return param;
    }
  }
  return null;
};

/**
 * 郵便番号から住所情報を取得します。
 * @param {string} postalCode 郵便番号(ハイフンあり無し関係ない)
 * @param {integer} limit 取得上限(デフォルト20)
 * @returns {Promise}
 * @remarks {
 *   postalCode: 郵便番号(ハイフンなし)
 *   prefecture: {
 *     code: 都道府県コード
 *     name: 都道府県名
 *     kana: カナ
 *   },
 *   city: 市区町村名
 *   cityKana: 市区町村カナ
 *   town: 町域名
 *   townKana: 町域名カナ
 * }
 */
export const getAddress = (postalCode) => {
  if (!postalCode) {
    return null;
  }

  return fetch(`https://api.zipaddress.net/?zipcode=${postalCode}`)
    .then((response) => response.json())
    .then((response) => {
      if (response.code === 200) {
        return {
          ...response.data,
        };
      } else {
        console.log(
          `エラーが発生したため、zipaddressから住所の検索に失敗しました。`,
          response.message
        );

        throw response.message;
      }
    })
    .catch((error) => {
      console.log(
        "エラーが発生したため、zipaddressから住所の検索に失敗しました。",
        error
      );
      return error;
    })
    .finally(() => {});
};

/**
 * 郵便番号文字列を前半と後半に分割します。分割できない場合nullが返されます。
 * @param {string} value 郵便番号文字列(を渡してください。)
 * @returns {object}
 * @remarks {
 *   front:
 *   back:
 * }
 */
export const splitPostalCode = (value = "") => {
  if (value.length === 7) {
    return {
      front: value.substring(0, 4),
      back: value.substring(4),
    };
  }

  if (value.length === 8 && value.indexOf("-") !== -1) {
    const result = value.split("-");
    return {
      front: result[0],
      back: result[1],
    };
  }

  return null;
};

/**
 * 郵便番号文字列を結合します。
 * @param {string} front 前半4桁文字列
 * @param {string} back 後半3桁文字列
 * @param {boolean} includeHyphen ハイフンを含めるか
 * @returns {string}
 */
export const joinPostalCode = (
  front = "",
  back = "",
  includeHyphen = false
) => {
  if (isEmpty(front) || isEmpty(back)) {
    return null;
  }

  if (front.length === 4 && back.length === 3) {
    return includeHyphen === true ? `${front}-${back}` : `${front}${back}`;
  }

  return null;
};

/**
 * Dateをフォーマットします。
 * @param {Date} value Date
 * @param {string} srcFormat 入力フォーマット
 * @param {string} destFormat 出力するフォーマット
 * @returns {string}
 */
export const dateFormat = (
  value,
  srcFormat = "yyyy-MM-dd",
  destFormat = "yyyy/MM/dd"
) => {
  if (!value) {
    return null;
  }

  if (!srcFormat || typeof srcFormat !== "string") {
    throw new Error("srcFormatを指定してください");
  }

  if (!destFormat || typeof destFormat !== "string") {
    throw new Error("destFormatを指定してください");
  }

  return DateTime.fromFormat(value, srcFormat).toFormat(destFormat);
};
