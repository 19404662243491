import { Box, Step, StepLabel, Stepper } from "@mui/material";
import React from "react";
import { ConfirmImportStep } from "./ConfirmImportStep";
import { FileSelectStep } from "./FileSelectStep";

/**
 * CSV取り込みを行うプレゼンテーションコンポーネントです。
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <Box>
      <Stepper activeStep={props.active}>
        {props.steps.map((step, index) => (
          <Step key={index}>
            <StepLabel>{step.title}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box height={"100%"} display="flex" justifyContent={"center"}>
        {props.active === 0 && (
          <FileSelectStep onNext={props.onNextFileUpload} />
        )}
        {props.active === 1 && (
          <ConfirmImportStep
            value={props.value?.csv}
            result={props.value?.result}
            onNotificationChange={props.onDecided}
            onBack={props.onBack}
          />
        )}
      </Box>
    </Box>
  );
};
