import { Alert } from "@mui/material";
import React from "react";
/**
 * ARS-妥当な結果がないページを行うプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <Alert severity="error">
    {props?.message
      ? props?.message
      : "検索条件に該当する石綿(アスベスト)含有建材はデータベース中にはありません"}
  </Alert>
);
