import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = { items: [] };

const NotificationMessages = createSlice({
  name: "notificationMessages",
  initialState,
  reducers: {
    show: (state, action) => {
      state.items.push(action.payload);
    },
    showSuccess: (state, action) => {
      const { message, ...other } = action.payload;
      state.items.push({
        severity: "success",
        message: message,
        ...other,
      });
    },
    showError: (state, action) => {
      const { message, ...other } = action.payload;
      state.items.push({
        severity: "error",
        message: message,
        ...other,
      });
    },
    showWarning: (state, action) => {
      const { message, ...other } = action.payload;
      state.items.push({
        severity: "warning",
        message: message,
        ...other,
      });
    },
    showInfo: (state, action) => {
      const { message, ...other } = action.payload;
      state.items.push({
        severity: "info",
        message: message,
        ...other,
      });
    },
    remove: (state, action) => {
      state.items.splice(action.payload, 1);
    },
    clear: (state, action) => {
      state.items = [];
    },
  },
});

/**
 * ストアから通知全体を習得します。
 * @param {object} store ストア
 * @returns {object}
 */
export const selector = (store) => store.NotificationMessages;

/**
 * ストアからすべての通知を習得します。
 */
export const itemsSelector = createSelector(
  selector,
  (notificationMessages) => notificationMessages.items
);

export const {
  show,
  remove,
  clear,
  showSuccess,
  showError,
  showWarning,
  showInfo,
} = NotificationMessages.actions;

export default NotificationMessages.reducer;
