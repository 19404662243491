import * as yup from "yup";
import { locale } from "../locale/ja-JP/yup";

/*
-------------------- yupメソッドで使用するメソッドの定義 --------------------
*/

const isHalfNumeric = (value = "") => {
  return value.match(/^[0-9]+$/);
};

/**
 * 全角であるかをチェックします。
 * @param {*} value
 * @returns
 */
const IsEMQuad = (value = "") => {
  return value.match(/^[^\x01-\x7E\xA1-\xDF]+$/);
};

/**
 * ascii文字列であるか確認します。
 * @param {string} value - 文字列
 * @returns true: ascii文字列である false: ascii文字列ではない
 */
const IsASCII = (value) => {
  return value?.match(/^[\x20-\x7e]*$/) ? true : false;
};

/**
 * 半角文字を1バイト、その他文字を2バイトでバイト数を取得します。
 * @param {string} value - 文字列
 * @returns バイト数
 */
const StringByteLength = (value = "") => {
  let result = 0;

  for (const character of value) {
    result += IsASCII(character) ? 1 : 2;
  }

  return result;
};

/**
 * 半角英数であるか確認します。
 * @param {string} value - 文字列
 */
const IsHalfWidthAlphanumeric = (value = "") => {
  return value.match(/^[A-Za-z0-9]*$/);
};

/**
 * 2つの日付を比較し、値を返します。
 * @param {Date} dateA - 日付
 * @param {Date} dateB - 日付
 * @returns -1: 比較できなかった。
 * 0: 同じ日付。
 * 1: dateAの方が新しい日付。
 * 2: dateBの方が新しい日付。
 */
const CompareDate = (dateA, dateB) => {
  if (!dateA || !dateB) {
    return -1;
  }

  if (dateA > dateB) {
    return 1;
  }

  if (dateA < dateB) {
    return 2;
  }

  return 0;
};

/**
 * 指定されたモードで検証し、結果を返します。
 * @param {date} dateA - 日付
 * @param {date} dateB - 日付
 * @param {string} mode - モード 'same(dateAとBは同じ日付)', 'future(dateAの方が新しい日付)', 'past(dateAの方が過去の日付)'
 * @param {bool} includeSame - 同じ値を含めるか
 * @returns 指定されたモードの結果になった場合 true
 */
const ValidDate = ({ dateA, dateB, mode, includeSame = true }) => {
  switch (CompareDate(dateA, dateB)) {
    case 0:
      return includeSame || mode === "same";
    case 1:
      return mode === "future";
    case 2:
      return mode === "past";
    default:
      return false;
  }
};

/**
 * 現在の日付を生成します。
 * @returns {date} - 今日の日付(時間などは0に設定されたもの)
 */
const CreateToday = () => {
  let now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), now.getDate());
};

/*
-------------------- yupメソッド定義 --------------------
*/

yup.addMethod(yup.string, "em", function () {
  return this.test("em", "全角で入力してください。", function (value) {
    if (!value || value === "") {
      return true;
    }

    return IsEMQuad(value) !== null;
  });
});

yup.addMethod(yup.string, "halfNumeric", function (value) {
  return this.test(
    "halfNumeric",
    "半角数字で入力してください。",
    function (value) {
      if (!value || value === "") {
        return true;
      }

      return isHalfNumeric(value) !== null;
    }
  );
});

yup.addMethod(yup.string, "name", function () {
  return this.test(
    "name",
    "全角文字、半角英字、半角スペースで入力してください。",
    function (value) {
      if (!value) {
        return true;
      }

      return value.match(/[0-9]|[ｦ-ﾟ]|[□]/g) === null;
    }
  );
});

// 汎用文字列操作用
yup.addMethod(yup.string, "replace", function (pattern, replacement) {
  return this.transform((val) => val.replace(pattern, replacement));
});

// 郵便番号
yup.addMethod(yup.string, "postalCode", function () {
  return this.test("postalCode", "形式が違います。", function (value) {
    if (!value || value === "") {
      return true;
    }
    return value.match(/^\d{3}-?\d{4}$/) !== null;
  });
});

yup.addMethod(yup.number, "postalCodeFront", function () {
  return this.test(
    "postalCodeFront",
    "3桁で入力してください。",
    function (value) {
      if (!this.originalValue || this.originalValue === "") {
        return true;
      }

      return String(this.originalValue).length === 3;
    }
  );
});

yup.addMethod(yup.number, "postalCodeBack", function () {
  return this.test(
    "postalCodeBack",
    "4桁で入力してください。",
    function (value) {
      if (!this.originalValue || this.originalValue === "") {
        return true;
      }

      return String(this.originalValue).length === 4;
    }
  );
});

yup.addMethod(yup.number, "length", function (length) {
  return this.test(
    "length",
    `${length}桁で入力してください。`,
    function (value) {
      if (!this.originalValue || this.originalValue === "") {
        return true;
      }

      return String(this.originalValue).length === length;
    }
  );
});

// 最大バイト数
yup.addMethod(yup.string, "maxByteLength", function (max) {
  return this.test("maxByteLength", "文字数が多すぎます。", function (value) {
    return StringByteLength(value) <= max;
  });
});

// 半角英数
yup.addMethod(yup.string, "halfWidthAlphanumeric", function () {
  return this.test(
    "halfWidthAlphanumeric",
    "半角英数を入力してください。",
    function (value) {
      return IsHalfWidthAlphanumeric(value);
    }
  );
});

// 対象の日付よりも未来であるか
yup.addMethod(yup.date, "future", function (target, includeSameDate) {
  return this.test(
    "future",
    "未来の日付を入力してください。",
    function (value) {
      if (!value || value === "") {
        return true;
      }
      return ValidDate({
        dateA: value,
        dateB: target ?? CreateToday(),
        mode: "future",
        includeSame: includeSameDate ?? false,
      });
    }
  );
});

// 対象の日付よりも過去であるか
yup.addMethod(yup.date, "past", function (target, includeSameDate) {
  return this.test("past", "過去の日付を入力してください。", function (value) {
    if (!value || value === "") {
      return true;
    }
    return ValidDate({
      dateA: value,
      dateB: target ?? CreateToday(),
      mode: "past",
      includeSame: includeSameDate ?? false,
    });
  });
});

yup.addMethod(yup.string, "phone", function () {
  return this.test(
    "phone",
    "10または11桁の半角数字を入力してください。",
    function (value) {
      if (!value || value === "") {
        return true;
      }

      return String(value).length === 10 || String(value).length === 11;
    }
  );
});

yup.addMethod(yup.string, "phoneFirstNumber", function () {
  return this.test(
    "phone",
    "電話番号は先頭「0」を入力してください。",
    function (value) {
      if (!value || value === "") {
        return true;
      }

      return String(value).charAt(0) === "0";
    }
  );
});

// 桁数
yup.addMethod(
  yup.number,
  "numberOfDigits",
  function (integerPart = 1, decimalPart = 0) {
    return this.test(
      "numberOfDigits",
      `整数部${integerPart}桁${
        decimalPart > 0 ? `、小数部${decimalPart}` : ""
      }までで入力してください。`,
      function (value) {
        if (!value || value === "") {
          return true;
        }
        let reg = `^\\d{1,${integerPart}}${
          decimalPart > 0 ? `(.\\d{1,${decimalPart}})?` : ""
        }$`;
        return value.toString().match(new RegExp(reg)) !== null;
      }
    );
  }
);

// yup は import する度に setLocale する必要がある
yup.setLocale(locale);

/*
-------------------- yupオブジェクト定義 --------------------
*/

export const textSchema = yup
  .string()
  .max(100)
  .transform((value) => (value === "" ? null : value));

export const remarksSchema = yup
  .string()
  .transform((value) => (value === "" ? null : value))
  .max(10000);

export const emailSchema = yup
  .string()
  .max(256)
  .email()
  .transform((value) => (value === "" ? null : value));

export const phoneSchema = yup
  .string()
  .phone()
  .transform((value) => (value === "" ? null : value));

export const urlSchema = yup
  .string()
  .max(2048)
  .url()
  .transform((value) => (value === "" ? null : value));

export default yup;
