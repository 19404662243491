/**
 * 都道府県を選択するコンテナコンポーネントです。
 */
export const Container = ({
  render,
  label,
  value = "",
  onChange = () => {},
  name = "prefecture",
  required = false,
  disabled = false,
  error = false,
  helperText = "",
  ...props
}) => {
  return render({
    ...props,
    value: value,
    onChange: onChange,
    label: label,
    name: name,
    required: required,
    disabled: disabled,
    error: error,
    helperText: helperText,
  });
};
