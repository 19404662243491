import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import {
  Card,
  CardContent,
  Chip,
  Stack,
  Typography,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useContext, useEffect, useRef } from "react";
import { CardActions } from "@mui/material";
import { CheckBox } from "views/molecules/CheckBox";
import { ItemsContext } from "./Items";

/**
 * アスベストのレベルを表示するコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
const AsbestosLevelMark = (props) => {
  const { asbestosLevel } = props;

  if (!asbestosLevel) {
    return <></>;
  }

  return (
    <div>
      <span
        style={{
          width: "32px",
          height: "32px",
          border: "1px solid rgba(0, 0, 0, 0.23)",
          display: "block",
          marginLeft: "auto",
          textAlign: "center",
          fontSize: "30px",
          lineHeight: "26px",
          fontWeight: "bold",
          color: "grey",
          position: "relative",
        }}
      >
        a
        <sup
          style={{
            position: "absolute",
            top: "-7px",
            right: "-8px",
            fontSize: "14px",
            width: "18px",
            lineHeight: "18px",
            height: "18px",
            backgroundColor: "red",
            color: "#fff",
            zIndex: "10",
            textAlign: "center",
            borderRadius: "50%",
            fontWeight: "normal",
          }}
        >
          {asbestosLevel}
        </sup>
      </span>
    </div>
  );
};

/**
 * 「工事に影響があります。」と表示するコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
const EffectWarning = (props) => {
  const { asbestosLevel, style } = props;

  if (!asbestosLevel || asbestosLevel === 3) {
    return <></>;
  }

  return (
    <span {...props} style={style}>
      <ReportProblemIcon fontSize="small" />
      工事に影響があります。
    </span>
  );
};

/**
 * 建材情報を表示するコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Item = (props) => {
  const root = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { value, onClick, onCheckedChange, checked, disabled = false } = props;
  const isWarning = (value?.asbestosLevel ?? 3) < 3;
  const { setSize, windowSize } = useContext(ItemsContext);

  useEffect(() => {
    if (root.current) {
      setSize(props.index, root.current.offsetHeight + 14);
      props.listRef.current.resetAfterIndex(props.index);
    }
  }, [windowSize]);

  return (
    <Card
      sx={{
        border: isWarning && "2px solid #fb8c00",
        margin: "4px",
      }}
      ref={root}
    >
      <CardContent>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Stack spacing={2} direction="row">
            {value?.isSanitary === true && (
              <Chip color="success" label={"製品"} size="small" />
            )}
            <Chip
              size="small"
              sx={{
                backgroundColor: "#b085f5",
                color: "#fff",
              }}
              label={value?.fieldName ?? ""}
            />
            {isMobile === false && (
              <EffectWarning
                asbestosLevel={value?.asbestosLevel}
                style={{
                  color: "#fb8c00",
                }}
              />
            )}
          </Stack>
          <AsbestosLevelMark asbestosLevel={value?.asbestosLevel} />
        </Stack>
        {isMobile === true && (
          <EffectWarning
            asbestosLevel={value?.asbestosLevel}
            style={{
              "@media(max-width : 479px)": {
                display: "block",
              },
              marginBottom: "8px",
              color: "#fb8c00",
            }}
          />
        )}
        <Typography
          sx={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            width: "100%",
            overflow: "hidden",
          }}
        >
          {value?.makerName ?? ""}
        </Typography>
        <Typography
          sx={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            width: "100%",
            overflow: "hidden",
          }}
        >
          {value?.name ?? ""}
        </Typography>
        <Typography
          sx={{
            color: "grey",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            width: "100%",
            overflow: "hidden",
          }}
        >
          {value?.modelNumber ?? ""}
        </Typography>
      </CardContent>
      <CardActions>
        {disabled === false && (
          <CheckBox label="選択" value={checked} onChange={onCheckedChange} />
        )}
        <Button variant="text" onClick={onClick}>
          {"詳細を見る"}
        </Button>
      </CardActions>
    </Card>
  );
};
