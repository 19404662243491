import { Query } from "@apollo/client/react/components";
import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Stack,
} from "@mui/material";
import {
  listLaborBureau,
  listLaborInspectionOffice,
  listRequestMunicipality,
  listRequestMunicipalityDept,
  listRequestPrefecture,
} from "api/graphql/queries";
import React from "react";
import { Group, SelectItem } from "views/molecules";

/**
 * 申請を入力するプレゼンテーションコンポーネントです。
 */
export const Presententer = (props) => {
  return (
    <>
      <Group title={"申請先"}>
        <Stack spacing={2}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={props.onChangeChecked("sanitationLawRequestExist")}
              />
            }
            disabled={props.loading}
            label={"労働安全衛生法（石綿障害予防規則）"}
            name="sanitationLawRequestExist"
            checked={Number(props.value?.sanitationLawRequestExist) === 1}
          />
          <FormHelperText error={!!props.errors?.sanitationLawRequestExist}>
            {props.errors?.sanitationLawRequestExist?.message}
          </FormHelperText>
          <Query query={listLaborBureau}>
            {({ data, error, loading }) => {
              if (error) {
                return error;
              } else {
                return (
                  <SelectItem
                    label="工事現場の管轄労働局"
                    name="laborBureau"
                    value={props.value?.laborBureau}
                    onChange={props.onChangeLaborBureau}
                    options={data?.listLaborBureau}
                    optionValueField={"bureauCode"}
                    optionNameField={"name"}
                    loading={loading}
                    error={!!props.errors?.laborBureau}
                    helperText={props.errors?.laborBureau?.message}
                    disabled={
                      Number(props.value?.sanitationLawRequestExist) === 0 ||
                      props.loading
                    }
                  />
                );
              }
            }}
          </Query>
          {props?.value?.laborBureau && (
            <Query
              query={listLaborInspectionOffice}
              variables={{ code: props?.value?.laborBureau?.bureauCode }}
            >
              {({ data, error, loading }) => {
                if (error) {
                  return error;
                } else {
                  return (
                    <SelectItem
                      label="工事現場の管轄労働基準監督署"
                      name="laborInspectionOffice"
                      value={props.value?.laborInspectionOffice}
                      onChange={props.onChangeSelect("laborInspectionOffice")}
                      options={data?.listLaborInspectionOffice}
                      optionValueField={"inspectionCode"}
                      optionNameField={"name"}
                      loading={loading}
                      error={!!props.errors?.laborInspectionOffice}
                      helperText={props.errors?.laborInspectionOffice?.message}
                      disabled={
                        Number(props.value?.sanitationLawRequestExist) === 0 ||
                        props.loading
                      }
                    />
                  );
                }
              }}
            </Query>
          )}
        </Stack>
        <Stack spacing={2}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={props.onChangeChecked("preventionLawRequestExist")}
              />
            }
            label={"大気汚染防止法"}
            name="preventionLawRequestExist"
            checked={Number(props.value?.preventionLawRequestExist) === 1}
            disabled={props.loading}
          />
          <FormHelperText error={!!props.errors?.preventionLawRequestExist}>
            {props.errors?.preventionLawRequestExist?.message}
          </FormHelperText>
          <Query query={listRequestPrefecture}>
            {({ data, error, loading }) => {
              if (error) {
                return error;
              } else {
                return (
                  <SelectItem
                    label="都道府県"
                    name="requestPrefecture"
                    onChange={props.onChangePrefecture}
                    value={props.value?.requestPrefecture}
                    options={data?.listRequestPrefecture}
                    optionValueField={"prefectureCode"}
                    optionNameField={"name"}
                    loading={loading}
                    error={!!props.errors?.requestPrefecture}
                    helperText={props.errors?.requestPrefecture?.message}
                    disabled={
                      Number(props.value?.preventionLawRequestExist) === 0 ||
                      props.loading
                    }
                  />
                );
              }
            }}
          </Query>
          {props?.value?.requestPrefecture && (
            <Query
              query={listRequestMunicipality}
              variables={{
                code: props?.value?.requestPrefecture?.prefectureCode,
              }}
            >
              {({ data, error, loading }) => {
                if (error) {
                  return error;
                } else {
                  return (
                    <SelectItem
                      label="自治体"
                      name="requestMunicipality"
                      value={props.value?.requestMunicipality}
                      onChange={props.onChangeMunicipality}
                      options={data?.listRequestMunicipality}
                      optionValueField={"municipalityCode"}
                      optionNameField={"name"}
                      loading={loading}
                      error={!!props.errors?.requestMunicipality}
                      helperText={props.errors?.requestMunicipality?.message}
                      disabled={
                        Number(props.value?.preventionLawRequestExist) === 0 ||
                        props.loading
                      }
                    />
                  );
                }
              }}
            </Query>
          )}
          {props?.value?.requestPrefecture &&
            props?.value?.requestMunicipality && (
              <Query
                query={listRequestMunicipalityDept}
                variables={{
                  prefectureId: props?.value?.requestPrefecture?.prefectureCode,
                  municipalityId:
                    props?.value?.requestMunicipality?.municipalityCode,
                }}
              >
                {({ data, error, loading }) => {
                  if (error) {
                    return error;
                  } else {
                    return (
                      <SelectItem
                        label="担当部署"
                        name="requestMunicipalityDept"
                        onChange={props.onChangeSelect(
                          "requestMunicipalityDept"
                        )}
                        value={props.value?.requestMunicipalityDept}
                        options={data?.listRequestMunicipalityDept}
                        optionValueField={"deptCode"}
                        optionNameField={"name"}
                        loading={loading}
                        error={!!props.errors?.requestMunicipalityDept}
                        helperText={
                          props.errors?.requestMunicipalityDept?.message
                        }
                        disabled={
                          Number(props.value?.preventionLawRequestExist) ===
                            0 || props.loading
                        }
                      />
                    );
                  }
                }}
              </Query>
            )}
        </Stack>
      </Group>
    </>
  );
};
