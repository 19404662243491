import { ExitToApp, KeyboardArrowUp } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  Container,
  Fab,
  IconButton,
  Toolbar,
  Tooltip,
  useScrollTrigger,
  Zoom,
} from "@mui/material";
import { ApplicationPaths } from "api/api-authorization/ApiAuthorizationConstants";
import { NotificationMessages } from "ducks/NotificationMessages/Component";
import React from "react";
import { Link } from "react-router-dom";
import { Footer } from "../../molecules/Footer";

function ScrollTop(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 10,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: "fixed", bottom: 116, right: 16 }}
      >
        {children}
      </Box>
    </Zoom>
  );
}

/**
 * 認証後のページテンプレートを表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export function Presententer(props) {
  const { children, path } = props;
  return (
    <>
      <AppBar color="primary">
        <Toolbar>
          <Box
            sx={{
              flexGrow: 1,
            }}
          >
            <Button
              sx={{
                "& a": {
                  color: "#fff",
                  textDecoration: "none",
                },
              }}
              disableElevation
              disableFocusRipple={true}
              disableRipple={true}
            >
              <Link to="/">ARS - {path ?? "Search"}</Link>
            </Button>
          </Box>
          <Tooltip title="ログアウト">
            <Link
              to={`${ApplicationPaths.LogOut}`}
              state={{
                local: true,
              }}
            >
              <IconButton
                aria-label="logout"
                sx={{
                  color: "#fff",
                }}
              >
                <ExitToApp />
              </IconButton>
            </Link>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <Toolbar id="back-to-top-anchor" />
      <Box
        component={"main"}
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          overflow: "auto",
          paddingBottom: "56px",
        }}
      >
        <Container
          maxWidth="sm"
          sx={(theme) => ({
            paddingTop: theme.spacing(2),
            flex: "1 1 auto",
          })}
        >
          {children}
          <NotificationMessages />
        </Container>
        <Footer />
      </Box>
      <ScrollTop>
        <Fab color="secondary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUp />
        </Fab>
      </ScrollTop>
    </>
  );
}
