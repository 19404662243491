import { Button, Stack, MenuItem } from "@mui/material";
import React from "react";
import {
  CheckBoxGroup,
  FullScreenDialog,
  InputField,
  InformationItemGroup,
} from "views/molecules";
import { DatePicker } from "views/atoms";
import { OptionPrefectures } from "utils/Constants";

/**
 * 工事情報検索の表示を行うプレゼンテーションです。
 * @param {string} value 値
 * @param {string} label ラベル
 */
export const Presententer = (props) => {
  return (
    <FullScreenDialog
      open={props.open}
      onClose={props.onCancel}
      title="検索"
      actions={
        <Button color="inherit" variant="text" onClick={props.onSearch}>
          {"検索"}
        </Button>
      }
    >
      <Stack
        p={2}
        spacing={2}
        sx={{ backgroundColor: (theme) => theme.palette.background.paper }}
      >
        <CheckBoxGroup
          label="受注状況"
          value={props.value?.ordersStatus ?? []}
          options={[
            {
              label: "交渉中",
              value: "1",
            },
            {
              label: "受注済",
              value: "2",
            },
            {
              label: "失注",
              value: "3",
            },
          ]}
          onChange={(e) => props.onChange("ordersStatus")(e.value)}
        />
        <Stack spacing={2}>
          <InformationItemGroup title="工事現場情報">
            <Stack spacing={2}>
              <Stack
                spacing={2}
                direction="row"
                sx={{
                  paddingTop: "6px",
                }}
              >
                <InputField
                  label="郵便番号"
                  onChange={(e) =>
                    props.onChange("workPostNoFront")(e.target.value)
                  }
                  value={props.value?.workPostNoFront ?? ""}
                  inputProps={{
                    maxLength: 3,
                  }}
                />
                {"-"}
                <InputField
                  label="郵便番号"
                  onChange={(e) =>
                    props.onChange("workPostNoBack")(e.target.value)
                  }
                  value={props.value?.workPostNoBack ?? ""}
                  inputProps={{
                    maxLength: 4,
                  }}
                />
              </Stack>
              <InputField
                select
                label={"都道府県"}
                value={props.value?.[`workAddressPrefecture`] ?? ""}
                onChange={(e) =>
                  props.onChange(`workAddressPrefecture`)(e.target.value)
                }
                error={Boolean(props.error?.[`workAddressPrefecture`])}
                helperText={props.error?.[`workAddressPrefecture`]?.message}
              >
                <MenuItem value="">
                  <em>未選択</em>
                </MenuItem>
                {OptionPrefectures.map((prefecture, index) => (
                  <MenuItem key={index} value={prefecture.name}>
                    {prefecture.name}
                  </MenuItem>
                ))}
              </InputField>
              <InputField
                label="市区町村"
                onChange={(e) =>
                  props.onChange("workAddressCity")(e.target.value)
                }
                value={props.value?.workAddressCity ?? ""}
              />
              <InputField
                label="町域名"
                onChange={(e) =>
                  props.onChange("workAddressTown")(e.target.value)
                }
                value={props.value?.workAddressTown ?? ""}
              />
              <InputField
                label="その他住所(続き)"
                onChange={(e) =>
                  props.onChange("workAddressOther")(e.target.value)
                }
                value={props.value?.workAddressOther ?? ""}
              />
              <InputField
                label="工事名称"
                onChange={(e) =>
                  props.onChange("constructionName")(e.target.value)
                }
                value={props.value?.constructionName ?? ""}
              />
            </Stack>
          </InformationItemGroup>
          <InformationItemGroup title="解体工事又は改修工事の実施期間">
            <Stack
              spacing={2}
              direction={"row"}
              sx={{
                paddingTop: "6px",
              }}
            >
              <DatePicker
                label={"開始日"}
                value={props.value?.constructionStartDate ?? null}
                onChange={(e) => props.onChange("constructionStartDate")(e)}
              />
              <DatePicker
                label={"終了日"}
                value={props.value?.constructionEndDate ?? null}
                onChange={(e) => props.onChange("constructionEndDate")(e)}
              />
            </Stack>
          </InformationItemGroup>
          {props.uniqueInformationLabel !== "" && (
            <InputField
              label={`${props.uniqueInformationLabel}`}
              onChange={(e) =>
                props.onChange("uniqueInformation")(e.target.value)
              }
              value={props.value?.uniqueInformation ?? ""}
            />
          )}
          <CheckBoxGroup
            label="調査状況"
            value={props.value?.surveyStatus ?? []}
            options={[
              {
                label: "作成中",
                value: "1",
              },
              {
                label: "調査完了",
                value: "2",
              },
              {
                label: "調査不要(解体が無い軽微な工事)",
                value: "3",
              },
            ]}
            onChange={(e) => props.onChange("surveyStatus")(e.value)}
          />
          <CheckBoxGroup
            label="報告状況"
            value={props.value?.reportStatus ?? []}
            options={[
              {
                label: "未報告",
                value: "1",
              },
              {
                label: "報告済",
                value: "2",
              },
              {
                label: "要再報告",
                value: "3",
              },
              {
                label: "報告不要",
                value: "4",
              },
            ]}
            onChange={(e) => props.onChange("reportStatus")(e.value)}
          />
        </Stack>
      </Stack>
    </FullScreenDialog>
  );
};
