import { DateTime } from "luxon";
import { useEffect, useState } from "react";

/**
 * 日付の選択の表示を行うコンテナコンポーネントです
 * @param {string} value 日付
 * @param {func} onChange 日付を更新するイベントです。
 * @param {string} label 日付テキストのラベル
 * @param {string} hideLabel
 * @param {boolean} required 必須設定
 */
export const Container = ({
  render,
  value,
  onChange,
  label = "",
  required = true,
  error = null,
  format = "yyyy/MM/dd",
  disabled = false,
  ...props
}) => {
  const [date, setDate] = useState(new Date());
  const handleOnChange = (date) => {
    const isDate =
      format === "yyyy/MM"
        ? DateTime.fromJSDate(date).toFormat("yyyy/MM")
        : DateTime.fromJSDate(date).toFormat("yyyy/MM/dd");
    onChange && onChange(isDate);
    setDate(isDate);
    console.log(date, isDate);
  };
  useEffect(() => {
    if (value) {
      setDate(value);
    } else {
      setDate(null);
    }
  }, [value]);

  return render({
    label: label,
    date: date,
    datechange: handleOnChange,
    required: required,
    error: error,
    format: format,
    disabled: disabled,
    ...props,
  });
};
