import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * ラジオボタングループを表示するコンポーネントです。
 * @param {string} label ラベル
 * @param {string} value 選択値
 * @fires Container#onChange 変更時
 * @param {string} defaultValue デフォルト値
 * @param {string} name 名前
 * @param {array} options 選択させる項目
 * @param {boolean} row 横並びにするかどうか
 * @param {boolean} twiceRemovable 選択値をクリックすると未選択にできるかどうか
 * @param {boolean} required 必須であるか
 * @param {object} props その他プロパティ
 */
export const RadioGroup = containerPresententer(Container, Presententer);
