
import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 報告のアイテムを複数表示するコンポーネントです。
 * @param {array} value 値
 * @param {array} selectedValue 選択された値
 * @fires Items#onApply 申請クリック時
 * @fires Items#onReport 報告クリック時
 * @fires Items#onCheckedChange チェック変更時
 * @param {boolean} loading 読み込み中であるか
 */
export const Items = containerPresententer(Container, Presententer);
