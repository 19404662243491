export const Container = ({
  render,
  children,
  title,
  deleteIcon = false,
  onClick,
  ...props
}) => {
  return render({
    children: children,
    title: title,
    deleteIcon: deleteIcon,
    onClick: onClick,
    ...props,
  });
};
